import axios, { AxiosResponse } from "axios";
import { Core } from "./paths";
import { Brand, NewBrand } from "src/@types/brand";

const create = (brand: NewBrand): Promise<AxiosResponse> => {
    const url = Core.admin.brands.create();

    return axios.post(url, brand);
};

const update = (brand: Brand): Promise<AxiosResponse> => {
    const url = Core.admin.brands.update();

    return axios.put(url, brand);
};

const enable = (id: string): Promise<AxiosResponse> => {
    const url = Core.admin.brands.enable(id);

    return axios.patch(url);
};

const disable = (id: string): Promise<AxiosResponse> => {
    const url = Core.admin.brands.disable(id);

    return axios.patch(url);
};

const adminSearch = (): Promise<AxiosResponse<Brand[]>> => {
    const url = Core.admin.brands.search();

    return axios.get(url);
};

const publicSearch = (): Promise<AxiosResponse<Brand[]>> => {
    const url = Core.public.brand.search();

    return axios.get(url);
};

const detail = (id: string): Promise<AxiosResponse<any>> => {
    const url = Core.admin.brands.detail(id);

    return axios.get(url);
};

const logs = (id: string): Promise<AxiosResponse> => {
    const url = Core.admin.brands.logs(id);

    return axios.get(url);
};

export {
    create,
    logs,
    detail,
    enable,
    update,
    disable,
    adminSearch,
    publicSearch
};