import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Tooltip } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Iconify from 'src/components/iconify';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { remove } from 'lodash';
import { LogsFilters } from 'src/@types/logs';
import { DEFAULT_LOGS_FILTERS } from './GenericLogsList';
import { DEFAULT_DATE_ERRORS, ToolbarListFilters } from 'src/@types/list';

type LogsGeneralFiltersProps = {
    filters: LogsFilters,
    onFilter: VoidFunction,
    resetAll: VoidFunction,
    optionsFields: ToolbarListFilters[]
}

export default function LogsGeneralFilters({ filters, onFilter, resetAll, optionsFields }: LogsGeneralFiltersProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [resetFilter, setResetFilter] = useState(false);

    const [openTooltip, setOpenTooltip] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [datesErrors, setDatesErrors] = useState(DEFAULT_DATE_ERRORS);

    const [toolbarFilterValues, setToolbarFilterValues] = useState(filters);

    //---- IS DEFAULT - START ----//
    const fullKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize"], []);

    const fullKeyRemover = useCallback((key: string) => {
        return !(fullKeysToRemove.includes(key));
    }, [fullKeysToRemove]);

    const isDefault = useCallback((filter: LogsFilters) => {

        const found = remove(Object.keys(filter), fullKeyRemover)
            .find((element) => filter[element] !== DEFAULT_LOGS_FILTERS[element]);

        return (!found);
    }, [fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    useEffect(() => {
        if (!isDefault(filters)) {
            setToolbarFilterValues({
                ...filters,
                eventType: filters.eventType || DEFAULT_LOGS_FILTERS.eventType,
                from: filters.from || DEFAULT_LOGS_FILTERS.from,
                to: filters.to || DEFAULT_LOGS_FILTERS.to
            });
            if (!filters.from) {
                setDatesErrors(DEFAULT_DATE_ERRORS);
            }
            if (filters.from || filters.all) {
                setShowDelete(true);
            } else {
                setShowDelete(false);
            }
        } else {
            setToolbarFilterValues({
                ...filters,
                eventType: DEFAULT_LOGS_FILTERS.eventType,
                from: DEFAULT_LOGS_FILTERS.from,
                to: DEFAULT_LOGS_FILTERS.to
            });
            setDatesErrors(DEFAULT_DATE_ERRORS);
            setShowDelete(false);
        }
    }, [filters, isDefault]);

    useEffect(() => {
        if (resetFilter) {
            setToolbarFilterValues({ ...DEFAULT_LOGS_FILTERS });
            setResetFilter(false);
            resetAll();
        }
    }, [resetFilter, resetAll]);

    const handleChangeValues = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(toolbarFilterValues));

        valuesCopy[type] = newVal;

        setToolbarFilterValues(valuesCopy);
    };

    const setAllValues = () => {

        for (const [key, value] of Object.entries(toolbarFilterValues)) {

            if (key === "from" || key === "to" || key === "eventType") {
                setValue(key, value);
            } else {
                setValue(key, DEFAULT_LOGS_FILTERS[key]);
            }
        }
    };

    const handleSearch = () => {
        if (!toolbarFilterValues.from && !toolbarFilterValues.to) {
            setDatesErrors({ ...datesErrors, toMissing: true, fromMissing: true });
        } else if (toolbarFilterValues.from && !toolbarFilterValues.to) {
            setDatesErrors({ ...datesErrors, toMissing: true });
        } else if (!toolbarFilterValues.from && toolbarFilterValues.to) {
            setDatesErrors({ ...datesErrors, fromMissing: true });
        } else {
            setAllValues();
            onFilter();
            setShowDelete(true);
        }
    };

    const handleDelete = () => {
        setResetFilter(true);
        setDatesErrors(DEFAULT_DATE_ERRORS);
        setShowDelete(false);
    };

    const handleChangeFrom = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            handleChangeValues(date.format('YYYY-MM-DD'), "from");

            let toInvalidCheck = false;

            if (toolbarFilterValues.to && dayjs(toolbarFilterValues.to).diff(date, "d") < 0) toInvalidCheck = true;

            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: toInvalidCheck });
        }
        else {
            setDatesErrors({ ...datesErrors, fromFormat: true });
        }
    };

    const handleChangeTo = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            if (date.diff(toolbarFilterValues.from, "d") < 0) {
                setDatesErrors({ ...datesErrors, toInvalid: true, toFormat: false, toMissing: false });
            } else {
                handleChangeValues(date.format('YYYY-MM-DD'), "to");
                setDatesErrors({ ...datesErrors, toInvalid: false, toFormat: false, toMissing: false });
            }
        }
        else {
            setDatesErrors({ ...datesErrors, toFormat: true });
        }
    };

    const handleDeleteDate = (type: string) => {

        handleChangeValues("", type);

        if (type === "from") {
            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: true, toInvalid: false });
        } else {
            setDatesErrors({ ...datesErrors, toFormat: false, toMissing: true, toInvalid: false });
        }
    };

    const errorChecker = useMemo(() => {

        const error = Object.keys(datesErrors).find((key) => datesErrors[key]);

        return !!error;
    }, [datesErrors]);

    const getDateErrorMessage = (type: string) => {

        let message = "";

        if (datesErrors.fromFormat && type === "from") message = translate("commons.validation.wrongDate");
        if (datesErrors.toFormat && type === "to") message = translate("commons.validation.wrongDate");
        if (datesErrors.fromMissing && type === "from") message = translate("commons.validation.missingDate");
        if (datesErrors.toMissing && type === "to") message = translate("commons.validation.missingDate");
        if (datesErrors.toInvalid && type === "to") message = translate("commons.validation.invalidDate");

        return message;
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{
                px: 3,
                pt: 2.5,
                pb: { xs: 0, md: 2.5 }
            }}
        >

            <Box
                sx={{
                    minWidth: { xs: "100%", md: 410 },
                    mr: 2,
                    mb: { xs: 2, md: 0 }
                }}
            >
                <Stack
                    spacing={2}
                    direction={{ xs: 'column', sm: 'row' }}
                >
                    <DatePicker
                        label={`${translate('commons.from')}`}
                        views={['year', 'month', 'day']}
                        format={'dd/MM/yyyy'}
                        value={toolbarFilterValues.from ? dayjs(toolbarFilterValues.from).toDate() : null}
                        disableFuture

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), "from");
                        }}

                        onChange={(fromDateValue, inputval) => {

                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeFrom(date);
                            } else {
                                setDatesErrors({ ...datesErrors, fromFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: 'from',
                                fullWidth: true,
                                error: datesErrors.fromFormat || datesErrors.fromMissing,
                                helperText: getDateErrorMessage("from"),
                                InputProps: {
                                    startAdornment: (toolbarFilterValues.from &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteDate("from");
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                    <DatePicker
                        label={`${translate('commons.to')}`}
                        views={['year', 'month', 'day']}
                        format={'dd/MM/yyyy'}
                        value={toolbarFilterValues.to ? dayjs(toolbarFilterValues.to).toDate() : null}
                        disableFuture

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), "to");
                        }}

                        onChange={(fromDateValue, inputval) => {
                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeTo(date);
                            } else {
                                setDatesErrors({ ...datesErrors, toFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: 'to',
                                fullWidth: true,
                                error: datesErrors.toFormat || datesErrors.toMissing || datesErrors.toInvalid,
                                helperText: getDateErrorMessage("to"),
                                InputProps: {
                                    startAdornment: (toolbarFilterValues.to &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteDate("to");
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                </Stack>
            </Box>

            <FormControl
                sx={{ mr: 2 }}
                fullWidth
                variant="outlined"
            >
                <InputLabel
                    id="label"
                    sx={{ '&.Mui-focused': { color: 'grey.500' } }}
                >
                    {`${translate('spareParts.logs.eventType')}`}
                </InputLabel>
                <Select
                    labelId="label"
                    id="eventType"
                    value={toolbarFilterValues.eventType}
                    input={
                        <OutlinedInput label={`${translate('spareParts.logs.eventType')}`} />
                    }
                    onChange={(event) => handleChangeValues(event.target.value, "eventType")}
                >
                    {optionsFields.map((option) => (
                        <MenuItem
                            key={option.key}
                            value={option.key}
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2'
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Tooltip
                    title={`${translate("commons.validation.invalidData")}`}
                    disableFocusListener
                    disableTouchListener
                    disableInteractive
                    open={openTooltip}
                    sx={{
                        mb: () => errorChecker ? 3.1 : 0,
                        width: { xs: "100%" }
                    }}
                >
                    <Box
                        onMouseEnter={() => { if (errorChecker) setOpenTooltip(true); }}
                        onMouseLeave={() => { if (errorChecker) setOpenTooltip(false); }}
                    >
                        <Button
                            startIcon={<Iconify icon={'bx:search-alt'} sx={{ ml: 1.5 }} />}
                            onClick={handleSearch}
                            disabled={errorChecker}
                            size={"large"}
                            sx={{ width: { xs: "100%" } }}
                        />
                    </Box>
                </Tooltip>

                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5 }} />}
                    onClick={handleDelete}
                    sx={{ display: `${(showDelete) ? "inline" : "none"}`, width: { xs: "100%" } }}
                    size={"large"}
                />
            </Box>

        </Stack>
    );
}