import { AccountBalance, Payments, Percent, Settings } from "@mui/icons-material";
import { Box, Card, Divider, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridColDef, GridRowParams, GridSortModel, useGridApiRef } from "@mui/x-data-grid-pro";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { DataGridStyle } from "src/utils/DataGridStyle";
import GenericListFooter from "src/utils/list/utils/GenericListFooter";
import { StatisticBox } from "../newWebshop/WebshopUtilComponents";
import Iconify from "src/components/iconify";
import { useNavigate, useParams } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { ShopCustomerStatisticsSearchResult } from "src/@types/webshop";
import { useEffect } from "react";
import { webshopOperations } from "src/redux/webshop";
import { INFINITE_SCROLL_LIST_FILTERS, InfiniteScrollListFilters } from "src/@types/list";
import { setShopOrderStatistics } from "src/redux/webshop/webshop-slices";

export default function AdminStatisticsDetail() {

    const isDesktop = useResponsive('up', 'md');

    const theme = useTheme();

    const apiRef = useGridApiRef();

    const { translate } = useLocales();

    const { dense, onChangeDense, orderBy, setOrderBy, order, setOrder } = useTable();

    const navigate = useNavigate();

    const { id } = useParams();

    const { shopOrderStatistics, shopCustomerStatisticsList, shopCustomerStatisticsTotalCount, isShopCustomerStatisticsLoading } = useSelector((state: RootState) => state.webshop);

    useEffect(() => {
        if (id)
            dispatch(webshopOperations.searchShopOrderCustomers({
                shopId: id,
                filters: {
                    ...INFINITE_SCROLL_LIST_FILTERS,
                    sortDirection: order === "asc" ? "Ascending" : "Descending",
                    sortField: orderBy ? orderBy : "createdOn",
                }
            }));
    }, [id, order, orderBy]);

    const COLUMNS: GridColDef<ShopCustomerStatisticsSearchResult>[] = [{
        field: 'customerExternalId',
        headerName: `${translate('commons.customer')}`,
        flex: isDesktop ? 1 : undefined,
        minWidth: !isDesktop ? 155 : undefined,
        sortable: true,
        renderCell: (p) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle2">{p.row.customerExternalId}</Typography>
                <Typography variant="body2">{p.row.customerName}</Typography>
            </Box>
        )
    },
    {
        field: 'numberOfOrders',
        headerName: `${translate('commons.quantity')}`,
        flex: isDesktop ? 1 : undefined,
        minWidth: !isDesktop ? 155 : undefined,
        sortable: true,
    },
    {
        field: 'grossAmount',
        headerName: translate('orders.tableHeaders.grossAmount'),
        flex: isDesktop ? 0.85 : undefined,
        minWidth: !isDesktop ? 250 : undefined,
        renderCell: (obj) => <CurrencyAdapter value={obj.row.grossAmount} excludeVatAsterisk />
    },
    {
        field: 'totalRegularDiscount',
        headerName: translate('orders.detail.regularDiscountAmount'),
        flex: isDesktop ? 0.85 : undefined,
        minWidth: !isDesktop ? 250 : undefined,

        renderCell: (obj) => {
            return (
                <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                    <CurrencyAdapter value={obj.row.totalRegularDiscount} isNegative excludeVatAsterisk />
                </Box>
            );
        }
    },
    {
        field: 'totalAdditionalDiscount',
        headerName: translate('orders.detail.extraDiscountAmount'),
        flex: isDesktop ? 0.85 : undefined,
        minWidth: !isDesktop ? 250 : undefined,
        renderCell: (obj) => {
            return (
                <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                    <CurrencyAdapter value={obj.row.totalAdditionalDiscount} isNegative excludeVatAsterisk />
                </Box>
            );
        }
    },
    {
        field: 'netAmount',
        headerName: translate('webshop.detail.products.netAmount'),
        flex: isDesktop ? 0.85 : undefined,
        minWidth: !isDesktop ? 250 : undefined,
        renderCell: (obj) => <CurrencyAdapter value={obj.row.netAmount} excludeVatAsterisk />
    }];

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            if (apiRef.current?.scroll) {
                apiRef.current.scroll({ left: 0, top: 0 });
            }
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const handleSearch = () => {

        const filters: InfiniteScrollListFilters = {
            ...INFINITE_SCROLL_LIST_FILTERS,
            sortDirection: order === "asc" ? "Ascending" : "Descending",
            sortField: orderBy ? orderBy : "createdOn",
            sequenceToken: shopCustomerStatisticsList[shopCustomerStatisticsList.length - 1]?.searchSequenceToken ?? null
        };

        dispatch(webshopOperations.searchShopOrderCustomers({ shopId: id ?? "", filters }));

    };

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h4">
                    {translate('webshop.detail.statistics.title')}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' }/*  isDesktop ? 'row' : 'column' */ }}>
                <StatisticBox
                    Icon={<Payments fontSize="medium" color="success" />}
                    circleColor={theme.palette.success.lighter}
                    label={translate('webshop.detail.statistics.totalGrossPurchased')}
                    value={
                        <>
                            {shopOrderStatistics &&
                                <CurrencyAdapter
                                    variant={"h4"}
                                    value={shopOrderStatistics.grossAmount}
                                    excludeVatAsterisk
                                />
                            }
                        </>
                    }
                />
                <StatisticBox
                    Icon={<Percent fontSize="medium" color="info" />}
                    circleColor={theme.palette.info.lighter}
                    label={translate('basket.detail.totalDiscount')}
                    value={
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            {shopOrderStatistics &&
                                <CurrencyAdapter
                                    variant={"h4"}
                                    value={shopOrderStatistics.totalDiscountAmount}
                                    excludeVatAsterisk
                                />
                            }
                        </Box>
                    }
                />
                <StatisticBox
                    Icon={<AccountBalance fontSize="medium" color="warning" />}
                    circleColor={theme.palette.warning.lighter}
                    label={translate('webshop.detail.statistics.totalNetPurchased')}
                    value={
                        <>
                            {shopOrderStatistics &&
                                <CurrencyAdapter
                                    variant="h4"
                                    value={shopOrderStatistics.netAmount}
                                    excludeVatAsterisk
                                />
                            }
                        </>
                    }
                />
                <StatisticBox
                    Icon={<Settings fontSize="medium" />}
                    circleColor={theme.palette.primary.lighter}
                    label={translate('webshop.detail.statistics.totalQtyPurchased')}
                    value={<Typography variant="h4">{shopOrderStatistics?.numberOfOrders ?? "0"}</Typography>}
                />
            </Box>

            <Box sx={{ my: 3 }}>

                <Typography variant="h6">
                    {translate("webshop.detail.statistics.clientOrderList")}
                </Typography>

                <Card sx={{ mt: 3 }}>

                    <Box sx={{ width: '100%', my: 2, px: 2 }}>
                        <TextField
                            fullWidth
                            label={translate("request.steps.searchCustomer")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify
                                            icon={'eva:search-fill'}
                                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>

                    <DataGridPro
                        apiRef={apiRef}
                        rowCount={100}
                        rows={shopCustomerStatisticsList}
                        columns={COLUMNS}
                        getRowId={(row) => row.customerId}
                        disableColumnResize
                        disableColumnMenu
                        density={(dense && shopCustomerStatisticsList.length > 0) ? 'compact' : 'standard'}
                        sortingMode={"server"}
                        paginationMode={"server"}
                        loading={isShopCustomerStatisticsLoading}
                        onSortModelChange={handleSort}
                        onRowClick={(p: GridRowParams<ShopCustomerStatisticsSearchResult>) => {
                            dispatch(setShopOrderStatistics(p.row));
                            navigate(PATH_DASHBOARD.webshop.customerStatistics(id ?? "", p.row.customerId));
                        }}
                        scrollEndThreshold={20}
                        onRowsScrollEnd={(params) => {
                            if (shopCustomerStatisticsList.length < shopCustomerStatisticsTotalCount && !isShopCustomerStatisticsLoading) {
                                handleSearch();
                            }
                        }}
                        sx={{
                            ...DataGridStyle,
                            height: dense ? 'auto' : { sm: 632, md: 649 },
                            maxHeight: { sm: 632, md: 649 },
                        }}
                        slots={{
                            noRowsOverlay: noData,
                            noResultsOverlay: noData,
                            footer: () => (
                                <>
                                    {shopCustomerStatisticsList.length === 0 && <Divider />}
                                    <GenericListFooter
                                        dense={dense}
                                        onChangeDense={onChangeDense}
                                        totalCount={shopCustomerStatisticsTotalCount}
                                    />
                                </>
                            )
                        }}
                    />
                </Card>
            </Box>

        </Box>
    );
}