import { Box, Button, Card, Chip, Container, Typography } from "@mui/material";
import Page from "src/appComponents/Page";
import { useLocales } from "src/locales";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import { PATH_DASHBOARD } from "src/routes/paths";
import useResponsive from "src/hooks/useResponsive";
import { useEffect, useState } from "react";
import { CustomField, DEFAULT_CUSTOM_FIELD } from "src/@types/customField";
import { detail } from "src/services/customFieldServices";
import { useNavigate, useParams } from "react-router";
import LoadingScreen from "src/appComponents/loading-screen";
import { LabeledText } from "src/appComponents/LabeledText";
import DateZone from "src/appComponents/DateZone";
import { LocalizedString } from "src/@types/commons";
import { convert } from "src/utils/currency";
import useCurrency from "src/appHooks/useCurrency";
import { CurrencyTypes } from "src/@types/vehicle";
import { StyledInfo, StyledRoot } from "src/utils/Detail";
import { getFirstLowerCase } from "src/appUtils/string";

export default function CustomFieldDetail() {

    const isDesktop = useResponsive('up', 'lg');

    const { translate, currentLang } = useLocales();

    const { themeStretch, currency } = useSettingsContext();

    const { rates } = useCurrency();

    const { id } = useParams();

    const [field, setField] = useState<CustomField>(DEFAULT_CUSTOM_FIELD);

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        detail(id!).then(
            response => setField(response.data)
        ).catch(e => console.error(e));

    }, [id, currentLang]);

    useEffect(() => {
        if (field.id) {
            setIsLoading(false);
        }
    }, [field, currentLang]);

    const handleEdit = () => {
        navigate(PATH_DASHBOARD.tenant.CustomFieldEdit(id!));
    };

    const getLang = (tab: LocalizedString[]): string => {
        let x = tab.find(x => x.culture === currentLang.value)?.text;
        if (x)
            return x;

        return tab[0].text || '';
    };

    const getDefaultValue = () => {

        if (field.type.toLowerCase() === 'currency')
            return convert(+field.defaultValue, CurrencyTypes.EUR, currency.label, rates).toFixed(2) + " " + currency.symbol;
        else
            return field.defaultValue;
    };

    return (
        <Page title={`${translate('commons.detail')}`}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('customField.list')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('commons.customField')}`, href: PATH_DASHBOARD.tenant.CustomFieldList },
                            { name: `${field.name[0].text}` },
                            { name: `${translate('commons.detail')}` }
                        ]}
                    />
                    <Card sx={{ p: 3 }}>
                        <Card sx={{
                            mb: 3,
                            height: 280,
                            position: 'relative',
                        }}>
                            <StyledRoot>
                                <StyledInfo>
                                    <Box
                                        sx={{
                                            ml: { md: 3 },
                                            mt: { xs: 1, md: 0 },
                                            color: 'common.white',
                                            textAlign: { xs: 'center', md: 'left' },
                                        }}>

                                        <Typography variant="h4">{`${field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}`}</Typography>
                                        <Button variant="contained" size="small" sx={{ mt: isDesktop ? 2 : 1 }} onClick={handleEdit}>
                                            {`${translate("commons.edit")}`}
                                        </Button>
                                    </Box>

                                </StyledInfo>
                            </StyledRoot>
                        </Card>
                        <Box
                            sx={{
                                px: 3,
                                pb: 3,
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                            }}
                        >
                            <LabeledText label={`${translate('customField.form.id')}`} text={field.id || '—'} />

                            <Box sx={{ display: 'grid', alignItems: 'end', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>
                                <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }}>
                                    {`${translate('commons.creationDate')} :`}
                                </Typography>
                                <DateZone date={field.createdOn} display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: 'left', pl: isDesktop ? 2 : 0 }} />
                            </Box>

                            <LabeledText label={`${translate('commons.type')}`} text={`${translate(`commons.${field.type.toLowerCase()}`)}`} />

                            <LabeledText label={`${translate('customField.form.entity')}`} text={`${translate(`customField.entity.${field.entityType[0].toLowerCase() + field.entityType.slice(1)}`)}`} />

                            {field.type.toLowerCase() !== 'date' && <LabeledText label={`${translate('customField.form.defaultValue')}`} text={field.defaultValue ? getDefaultValue().toString() : '—'} />}

                            {
                                field.type.toLowerCase() === 'date' &&
                                <Box sx={{ display: 'grid', alignItems: 'end', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>

                                    <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }}>
                                        {`${translate('customField.form.defaultValue')} :`}
                                    </Typography>
                                    {field.defaultValue ? <DateZone onlyDate date={new Date(field.defaultValue)} display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: 'left', pl: isDesktop ? 2 : 0 }} />
                                        : <Typography display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: 'left', pl: isDesktop ? 2 : 0 }}>{'—'}</Typography>}
                                </Box>
                            }

                            <LabeledText label={`${translate('commons.visibility')}`} text={`${translate(`commons.${getFirstLowerCase(field.visibility)}`)}` || '—'} />

                            <LabeledText label={`${translate('customField.form.section')}`} text={field.section || '—'} />

                            <div hidden={field.type.toLowerCase() !== 'select'}>
                                <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }} >
                                    {`${translate('commons.options')} : `}
                                </Typography>

                                {field.selectOptions !== null ? field.selectOptions.map((tabs, index) => (
                                    <Chip
                                        sx={{ height: '20px', m: '3px' }}
                                        key={index}
                                        label={getLang(tabs.values) || ''}
                                    />
                                )) : '-'}

                            </div>

                        </Box>
                    </Card>
                </Container>
            )}
        </Page>);
}