import { createAsyncThunk } from "@reduxjs/toolkit";
import { startDocLogsLoading, startDocumentLoading, startOrderLoading, startOrderLogsLoading } from "./orders-slices";
import * as ordersServices from "src/services/ordersServices";
import { DocType, DocumentFilters, OrderFilters } from "src/@types/orders";
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import { LogsFilters } from "src/@types/logs";
import { InfiniteScrollListFilters } from "src/@types/list";

//---- ORDERS -----------------------------------------------------------------

const searchOrders = createAsyncThunk(
    'orders/searchOrders',
    async (options: { filters: OrderFilters, isVendor: boolean, check: boolean }, { rejectWithValue, dispatch }) => {

        const { filters, isVendor, check } = options;

        dispatch(startOrderLoading(check));
        try {
            const { data } = await ordersServices.searchOrders(filters, isVendor);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getOrder = createAsyncThunk(
    'orders/getOrder',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startOrderLoading(false));
        try {
            const { data } = await ordersServices.detailOrder(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchOrdersPowerSearch = createAsyncThunk(
    'orders/searchOrdersPowerSearch',
    async (options: InfiniteScrollListFilters, { rejectWithValue }) => {
        try {
            const { data } = await ordersServices.searchOrders(options, false);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getOrdersLogs = createAsyncThunk(
    'orders/getOrdersLogs',
    async (options: { id: string, filters: LogsFilters }, { rejectWithValue, dispatch }) => {

        const { id, filters } = options;

        dispatch(startOrderLogsLoading());
        try {

            const { data } = await ordersServices.searchOrdersLogs(id, nullifyEmptyKeys(filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

//---- DOCUMENTS -----------------------------------------------------------------

const searchDocuments = createAsyncThunk(
    'orders/searchDocuments',
    async (options: { filters: DocumentFilters, isVendor: boolean, check: boolean }, { rejectWithValue, dispatch }) => {

        const { filters, isVendor, check } = options;

        dispatch(startDocumentLoading(check));
        try {
            const { data } = await ordersServices.searchDocuments(filters, isVendor);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getInvoice = createAsyncThunk(
    'orders/getInvoice',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startDocumentLoading(false));
        try {
            const { data } = await ordersServices.detailInvoice(id);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getDeliveryNote = createAsyncThunk(
    'orders/getDeliveryNote',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startDocumentLoading(false));
        try {
            const { data } = await ordersServices.detailDeliveryNote(id);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getCreditNote = createAsyncThunk(
    'orders/getCreditNote',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startDocumentLoading(false));
        try {
            const { data } = await ordersServices.detailCreditNote(id);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getDocsLogs = createAsyncThunk(
    'orders/getDocsLogs',
    async (options: { id: string, type: DocType, filters: LogsFilters }, { rejectWithValue, dispatch }) => {

        const { id, type, filters } = options;

        dispatch(startDocLogsLoading());
        try {

            const { data } = await ordersServices.searchDocumentsLogs(id, type, nullifyEmptyKeys(filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const ordersOperations = {
    searchOrders,
    getOrder,
    searchDocuments,
    searchOrdersPowerSearch,
    getDeliveryNote,
    getInvoice,
    getOrdersLogs,
    getDocsLogs,
    getCreditNote
};

export default ordersOperations;