import Container from '@mui/material/Container';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { LogsFilters } from 'src/@types/logs';
import { groupOperations } from 'src/redux/group';
import GenericLogsList from 'src/appComponents/logs/GenericLogsList';

const LABELS = [
    "GroupCreated",
    "GroupUpdated",
    "GroupEnabled",
    "GroupDisabled",
    "GroupStatusChanged"
];

export default function GroupLogs() {

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const { areLogsLoading, groupLogs, group } = useSelector((state: RootState) => state.group);

    const { id } = useParams();

    useEffect(() => {
        if ((!group && id) || (group && id && group.groupId !== id)) dispatch(groupOperations.getGroup(id));
    }, [id, currentLang, group]);

    const fetchLogs = useCallback((searchFilters: LogsFilters) => {
        dispatch(groupOperations.getGroupLogs({ id: id!, filters: searchFilters }));
    }, [id]);

    return (
        <Page title={`${translate('groups.logs.title')}`}>

            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>

                <HeaderBreadcrumbs
                    heading={`${translate('groups.logs.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.groups.title')}`, href: PATH_DASHBOARD.groups.list },
                        { name: `${group?.displayName}`, href: PATH_DASHBOARD.groups.detailsTab(id!, "properties-permissions") },
                        { name: `${translate('commons.logs')}` }
                    ]}
                />

                <GenericLogsList
                    labels={LABELS}
                    logsData={{ ...groupLogs, items: groupLogs.items.map((item, index) => ({ ...item, id: index })) }}
                    searchFunc={fetchLogs}
                    isLoading={areLogsLoading}
                    description={translate(`groups.logs.subtitle`)}
                    localesPath={"groups"}
                />

            </Container>
        </Page>
    );
}
