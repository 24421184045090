import { Container, Step, StepLabel, Stepper } from "@mui/material";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useParams } from "react-router";
import { DEFAULT_NEW_GROUP, GroupType, NewGroup, WhiteBlackListDisplay } from "src/@types/group";
import { INFINITE_SCROLL_LIST_FILTERS } from "src/@types/list";
import { OrganizationSearchResult } from "src/@types/organizations";
import { UserSearchResult } from "src/@types/user";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { groupOperations } from "src/redux/group";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import DetailStep from "src/sections/@dashboard/group/newEditGroup/DetailStep";
import PermissionStep from "src/sections/@dashboard/group/newEditGroup/PermissionStep";
import PropertiesStep from "src/sections/@dashboard/group/newEditGroup/PropertiesStep";
import SummaryStep from "src/sections/@dashboard/group/newEditGroup/SummaryStep";
import WhiteBlacklistStep from "src/sections/@dashboard/group/newEditGroup/WhiteBlacklistStep";

export type Action = {
    type: 'update' | 'reset'
    payload: Partial<NewGroup>
};

function reduce(state: NewGroup, action: Action): NewGroup {
    switch (action.type) {

        case 'reset':
            return { ...DEFAULT_NEW_GROUP, ...action.payload };

        case 'update':
            return { ...state, ...action.payload };

        default:
            return state;
    };
}

const STEPS = ['Details', 'Properties', 'Permissions', 'Whitelist', 'Blacklist', 'Complete'];

interface GroupWizard {
    group?: GroupType
}

export default function GroupWizard({ group }: GroupWizard) {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const [state, setState] = useReducer(reduce, (group as (NewGroup | undefined)) ?? DEFAULT_NEW_GROUP);

    const [currentStep, setCurrentStep] = useState(0);

    const [blackList, setBlacklist] = useState<WhiteBlackListDisplay>({
        organizations: [],
        users: [],
    });

    const [whiteList, setWhiteList] = useState<WhiteBlackListDisplay>({
        organizations: [],
        users: []
    });

    const isDesktop = useResponsive('up', 'lg');

    const { id } = useParams();

    const setLists = (list: UserSearchResult[] | OrganizationSearchResult[], type: 'Users' | 'Organizations', white: boolean) => {
        if (white) {
            if (type === "Users")
                setWhiteList(p => ({ ...p, users: list as UserSearchResult[] }));
            else
                setWhiteList(p => ({ ...p, organizations: list as OrganizationSearchResult[] }));
        }
        else {
            if (type === "Users")
                setBlacklist(p => ({ ...p, users: list as UserSearchResult[] }));
            else
                setBlacklist(p => ({ ...p, organizations: list as OrganizationSearchResult[] }));
        }
    };

    useEffect(() => {
        //get all blacklist and whitelist
        if (id && group) {

            dispatch(groupOperations.searchGroupMembersBlackWhite({ filters: { ...INFINITE_SCROLL_LIST_FILTERS, size: 100, blacklists: [id] }, isWhiteList: false, check: false }))
                .unwrap().then(list => setLists(list.results, "Users", false));

            dispatch(groupOperations.searchGroupMembersBlackWhite({ filters: { ...INFINITE_SCROLL_LIST_FILTERS, size: 100, whitelists: [id] }, isWhiteList: true, check: false }))
                .unwrap().then(list => setLists(list.results, "Users", true));

            dispatch(groupOperations.searchGroupOrganizationsBlackWhite({ filters: { ...INFINITE_SCROLL_LIST_FILTERS, size: 100, blacklists: [id] }, check: false, isWhiteList: false, type: state.groupType }))
                .unwrap().then(list => setLists(list.results, "Organizations", false));

            dispatch(groupOperations.searchGroupOrganizationsBlackWhite({ filters: { ...INFINITE_SCROLL_LIST_FILTERS, size: 100, whitelists: [id] }, check: false, isWhiteList: true, type: state.groupType }))
                .unwrap().then(list => setLists(list.results, "Organizations", true));
        }
    }, [id]);

    const props = useMemo(() => ({
        changeStep: setCurrentStep,
        state: state,
        onSubmit: setState
    }), [state]);

    const handleList = useCallback((list: WhiteBlackListDisplay, blackList?: boolean) => {

        if (blackList)
            setBlacklist(list);
        else
            setWhiteList(list);

        const users = list.users.map(v => v.id);

        const organizations = list.organizations.map(v => v.id);

        const idList = {
            users,
            organizations
        };

        setState({
            payload: blackList ? { blacklist: idList } : { whitelist: idList },
            type: 'update'
        });

    }, []);

    const handleSubmit = async () => {
        if (!id)
            await dispatch(groupOperations.createGroup(state)).unwrap();
        else
            await dispatch(groupOperations.updateGroup({ group: state, id })).unwrap();

        dispatch(setSuccessMessage({
            text: translate('groups.messages.success'), goTo: PATH_DASHBOARD.groups.list,
            callback: () => {
                setCurrentStep(0);
                if (!id) {
                    setState({ type: 'reset', payload: {} });
                    setWhiteList({ organizations: [], users: [] });
                    setBlacklist({ organizations: [], users: [] });
                }
            }
        }));
    };

    const STEPS_COMPONENT = [
        <DetailStep key={(state as any)?.groupId} {...props} />,
        <PropertiesStep key={'properties'} {...props} />,
        <PermissionStep key={'permissions'} {...props} />,
        <WhiteBlacklistStep key={'whitelist'} {...props} onSubmit={handleList} list={whiteList} />,
        <WhiteBlacklistStep key={'blacklist'} {...props} onSubmit={handleList} list={blackList} blackList />,
        <SummaryStep key={'summary'} {...props} onSubmit={handleSubmit} blacklist={blackList} whitelist={whiteList} />,
    ];

    return (

        <Container maxWidth={themeStretch ? false : 'lg'}>

            <HeaderBreadcrumbs
                heading={id ? `${translate('groups.form.edit')}` : `${translate('groups.form.new')}`}
                links={[
                    { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                    { name: `${translate('commons.list')}`, href: PATH_DASHBOARD.groups.list },
                    { name: id ? `${translate('groups.form.edit')}` : `${translate('menu.management.groups.new')}` }
                ]}
            />
            <Stepper
                sx={{ mb: 2, px: isDesktop ? 5 : 2, }}
                activeStep={currentStep}
                alternativeLabel
            >
                {STEPS.map(step =>

                    <Step key={step}>
                        <StepLabel>{translate('groups.form.steps.' + step.toLowerCase())}</StepLabel>
                    </Step>
                )}
            </Stepper>
            {STEPS_COMPONENT[currentStep]}
        </Container>

    );

}