
export type Brand = {
    [key: string]: any
    id: string,
    externalId: string,
    createdAt: Date,
    name: string,
    customFields: Record<string, string> | null,
    enabled: boolean
}

export type NewBrand = {
    externalId: string,
    name: string,
    customFields: Record<string, string>
}

//--------------------------------------------------------

export const DEFAULT_NEW_BRAND: NewBrand = {
    externalId: "",
    name: "",
    customFields: {}
};

export const DEFAULT_BRAND: Brand = {
    id: "",
    externalId: "1",
    createdAt: new Date(),
    name: "",
    customFields: {},
    enabled: true
};