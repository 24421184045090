import { alpha, Box, Card, FormControlLabel, Switch, Tab, TablePagination, Tabs, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useSelector } from "react-redux";
import { GroupShop } from "src/@types/webshop";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { RootState } from "src/redux/store";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { WhiteBlackList } from "../../group/newEditGroup/WhiteBlacklistStep";
import { WhiteBlackListDisplay } from "src/@types/group";

const tabSx = {
    justifyContent: 'flex-start',
    py: '13px',
    px: 2,
    m: 1,
    borderRadius: '8px',
    '&:not(:last-of-type)': { mr: 1 },
    "&.Mui-selected": {
        color: "primary.main",
        bgcolor: "primary.lighter"
    }
};

export default function VisibilityDetail() {

    const { displayShop } = useSelector((state: RootState) => state.webshop);

    const { translate } = useLocales();

    const [currentTab, setCurrentTab] = useState(0);

    const isDesktop = useResponsive("up", 'lg');

    const TABS = [
        <GroupsList list={displayShop?.shopAccess.groups ?? []} key={0} />,
        <ListsDetail key={currentTab} list={{ organizations: [], users: [] }} />,
        <ListsDetail key={currentTab} list={{ organizations: [], users: [] }} blacklist />
    ];

    return (
        <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
            <Tabs
                orientation={isDesktop ? "vertical" : 'horizontal'}
                value={currentTab}
                onChange={(_, tab) => setCurrentTab(tab)}
                TabIndicatorProps={{
                    style: { display: 'none' }
                }}
                sx={{
                    bgcolor: theme => alpha(theme.palette.grey[500], 0.12),
                    minWidth: "20%",
                    maxHeight: "20%"
                }}
            >
                <Tab
                    value={0}
                    sx={tabSx}
                    id='0'
                    label={`${translate('webshop.detail.customerCategory')}`}
                />
                <Tab
                    value={1}
                    sx={tabSx}
                    id='1'
                    label={`${translate('groups.detail.whitelist')}`}
                />
                <Tab
                    value={2}
                    sx={tabSx}
                    id='2'
                    label={`${translate('groups.detail.blacklist')}`}
                />
            </Tabs>

            {TABS[currentTab]}

        </Card>
    );

}

interface GroupsListProps {
    list: GroupShop[]
}

function GroupsList({ list }: GroupsListProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const { dense, onChangeDense, page, onChangePage, rowsPerPage, onChangeRowsPerPage } = useTable();

    const COLUMNS: GridColDef<GroupShop>[] = [{
        field: 'groupName',
        headerName: `${translate('commons.name')}`,
        flex: isDesktop ? 0.9 : undefined,
        minWidth: !isDesktop ? 155 : undefined,
        sortable: false
    },
    {
        field: 'numberOfOrganizationInGroups',
        headerName: `${translate('groups.list.columns.numberOfOrganizations')}`,
        flex: isDesktop ? 0.7 : undefined,
        minWidth: !isDesktop ? 155 : undefined,
        headerAlign: 'center',
        align: "center",
        sortable: false
    },
    {
        field: 'numberOfUserInGroups',
        headerName: `${translate('groups.list.columns.numberOfUsers')}`,
        flex: isDesktop ? 0.7 : undefined,
        minWidth: !isDesktop ? 155 : undefined,
        headerAlign: 'center',
        align: "center",
        sortable: false
    }];

    const getHeight = () => {
        let height: string | number = "auto";

        const { length } = list;

        //if (!totalCount) return 120 + 56 * length;

        if (!dense || length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    return (
        <Box sx={{ p: 3, width: "100%", height: '100%' }}>
            <Typography sx={{ mb: 3 }} variant="h6">{translate('webshop.detail.customerCategory')}</Typography>

            <DataGrid
                columns={COLUMNS}
                rows={list}
                getRowId={(row) => row.groupId}
                sx={{ ...DataGridStyle, height: getHeight(), maxHeight: isDesktop ? 650 : 700 }}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{
                            position: 'relative',
                            width: { xs: "90vw", md: "auto" },
                        }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                component="div"
                                count={list.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                sx={{
                                    overflow: "hidden",
                                    "& .MuiTablePagination-input": {
                                        ml: { xs: 0.5, md: "default" },
                                        mr: { xs: 3.5, md: "default" }
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={dense}
                                    onChange={onChangeDense}
                                />}
                                label={`${translate('commons.dense')}`}
                                sx={{
                                    py: { xs: 0, sm: 1.5 },
                                    pb: { xs: 1.5, sm: 0 },
                                    mx: 0,
                                    top: 0,
                                    justifyContent: "center",
                                    width: { xs: "90vw", sm: "auto" },
                                    position: { sm: 'absolute' }
                                }}
                            />
                        </Box>
                    )
                }}
            />

        </Box>
    );
}

interface ListsDetailProps {
    list: WhiteBlackListDisplay,
    blacklist?: boolean
}

function ListsDetail({ list, blacklist }: ListsDetailProps) {

    const { translate } = useLocales();

    const [listType, setListType] = useState<'Users' | 'Organizations'>('Users');

    return (
        <Box sx={{ p: 3, width: "100%", height: '100%' }}>
            <Typography sx={{ mb: 3 }} variant="h6">{blacklist ? translate('groups.detail.blacklist') : translate("groups.detail.whitelist")}</Typography>

            <Tabs value={listType} onChange={(_, val) => setListType(val)} sx={{ mb: 1 }}>
                <Tab label={translate('groups.list.type.user')} value={"Users"} />
                <Tab label={translate('groups.list.type.organization')} value={"Organizations"} />
            </Tabs>
            <WhiteBlackList list={list} type={listType} />
        </Box>
    );
}