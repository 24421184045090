import { Fade, Popover, PopoverOrigin } from '@mui/material';
import getPosition from './getPosition';
import { StyledArrow } from './styles';
import { MenuPopoverProps } from './types';

export default function MenuPopover({
  open,
  children,
  arrow = 'top-right',
  disabledArrow,
  withFade = false,
  sx,
  ...other
}: MenuPopoverProps) {

  const { style, anchorOrigin, transformOrigin } = getPosition(arrow);

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      anchorOrigin={anchorOrigin as PopoverOrigin}
      transformOrigin={transformOrigin as PopoverOrigin}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            width: 'auto',
            overflow: 'inherit',
            ...style,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
            },
            ...sx,
          },
        }
      }}
      TransitionComponent={withFade ? Fade : undefined}
      TransitionProps={{ timeout: 500 }}
      {...other}
    >
      {!disabledArrow && <StyledArrow arrow={arrow} />}

      {children}
    </Popover>
  );
}
