import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse } from "src/@types/commons";
import { DEFAULT_GROUP_STATISTICS, EnableDisableGroupSliceProps, GroupSearchResult, GroupStatistics, GroupType } from "src/@types/group";
import groupOperations from "./group-operations";
import { UserSearchResult } from "src/@types/user";
import { OrganizationSearchResult } from "src/@types/organizations";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";
import { GenericPagedResponse } from "src/@types/list";

interface GroupReducer {
    /* COMMONS */
    error: ErrorResponse,
    isLoading: boolean,
    filtersInUrl: string,
    /* GROUPS LIST */
    groupsList: GroupSearchResult[],
    totalCount: number,
    statistics: GroupStatistics,
    isInfiniteScrolling: boolean,
    /* GROUP LOGS */
    areLogsLoading: boolean,
    groupLogs: GenericPagedResponse<LogsItem>,
    /* GROUP */
    group: GroupType | null,
    /* GROUP MEMBERS */
    isMembersLoading: boolean,
    membersTotalCount: number,
    membersList: UserSearchResult[],
    isMembersInfiniteScrolling: boolean,
    /* GROUP ORGANIZATIONS */
    isOrganizationsLoading: boolean,
    isOrganizationInfiniteScrolling: boolean,
    organizationsTotalCount: number,
    organizationsList: OrganizationSearchResult[],
    /* GROUP WHITELIST */
    isWhitelistLoading: boolean,
    isWhitelistInfiniteScrolling: boolean,
    userWhitelist: UserSearchResult[] | undefined,
    userWhitelistTotalCount: number,
    userWhitelistHasFilter: boolean,
    organizationWhitelist: OrganizationSearchResult[] | undefined,
    organizationWhitelistTotalCount: number,
    organizationWhitelistHasFilter: boolean,
    /* GROUP BLACKLIST */
    isBlacklistLoading: boolean,
    isBlacklistInfiniteScrolling: boolean,
    userBlacklist: UserSearchResult[] | undefined,
    userBlacklistTotalCount: number,
    userBlacklistHasFilter: boolean,
    organizationBlacklist: OrganizationSearchResult[] | undefined,
    organizationBlacklistTotalCount: number,
    organizationBlacklistHasFilter: boolean
}

const initialState: GroupReducer = {
    /* COMMONS */
    error: null,
    isLoading: false,
    filtersInUrl: "",
    /* GROUPS LIST */
    groupsList: [],
    totalCount: 0,
    statistics: DEFAULT_GROUP_STATISTICS,
    isInfiniteScrolling: false,
    /* GROUP LOGS */
    areLogsLoading: false,
    groupLogs: DEFAULT_LOGS_DATA,
    /* GROUP */
    group: null,
    /* GROUP MEMBERS */
    isMembersLoading: false,
    membersTotalCount: 0,
    membersList: [],
    isMembersInfiniteScrolling: false,
    /* GROUP ORGANIZATIONS */
    isOrganizationsLoading: false,
    isOrganizationInfiniteScrolling: false,
    organizationsTotalCount: 0,
    organizationsList: [],
    /* GROUP WHITELIST */
    isWhitelistLoading: false,
    isWhitelistInfiniteScrolling: false,
    userWhitelist: undefined,
    userWhitelistTotalCount: 0,
    userWhitelistHasFilter: true,
    organizationWhitelist: undefined,
    organizationWhitelistTotalCount: 0,
    organizationWhitelistHasFilter: true,
    /* GROUP BLACKLIST */
    isBlacklistLoading: false,
    isBlacklistInfiniteScrolling: false,
    userBlacklist: undefined,
    userBlacklistTotalCount: 0,
    userBlacklistHasFilter: true,
    organizationBlacklist: undefined,
    organizationBlacklistTotalCount: 0,
    organizationBlacklistHasFilter: true
};

const GroupSlice = createSlice({
    name: 'group',
    initialState,
    extraReducers: (builder) => {
        builder
            //--------------------------------------
            .addCase(groupOperations.createGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.createGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.updateGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.updateGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.getGroup.rejected, (state, action) => {
                state.group = initialState.group;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.getGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroups.rejected, (state, action) => {
                state.groupsList = initialState.groupsList;
                state.totalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            .addCase(groupOperations.searchGroups.fulfilled, (state, action) => {
                state.groupsList = state.isInfiniteScrolling ? state.groupsList.concat(action.payload.results) : action.payload.results;
                state.totalCount = action.payload.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            //--------------------------------------
            .addCase(groupOperations.enableDisableGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(groupOperations.getGroupLogs.rejected, (state, action) => {
                state.areLogsLoading = false;
                state.error = action.error.message as string;
                state.groupLogs = initialState.groupLogs;
            })
            .addCase(groupOperations.getGroupLogs.fulfilled, (state, action) => {
                state.areLogsLoading = false;
                state.groupLogs = action.payload;
            })
            //--------------------------------------
            .addCase(groupOperations.getStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.statistics = initialState.statistics;
            })
            .addCase(groupOperations.getStatistics.fulfilled, (state, action) => {
                state.statistics = action.payload;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupMembers.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.membersList = initialState.membersList;
                state.membersTotalCount = initialState.membersTotalCount;
                state.isMembersLoading = false;
                state.isMembersInfiniteScrolling = false;
            })
            .addCase(groupOperations.searchGroupMembers.fulfilled, (state, action) => {
                state.membersList = state.isMembersInfiniteScrolling ? state.membersList.concat(action.payload.results) : action.payload.results;
                state.membersTotalCount = action.payload.totalCount;
                state.isMembersLoading = false;
                state.isMembersInfiniteScrolling = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupMembersBlackWhite.rejected, (state, action) => {
                state.error = action.error.message as string;

                if (action.meta.arg.isWhiteList) {
                    state.userWhitelist = initialState.userWhitelist;
                    state.userWhitelistTotalCount = initialState.userWhitelistTotalCount;
                    state.isWhitelistLoading = false;
                    state.isWhitelistInfiniteScrolling = false;
                } else {
                    state.userBlacklist = initialState.userBlacklist;
                    state.userBlacklistTotalCount = initialState.userBlacklistTotalCount;
                    state.isBlacklistLoading = false;
                    state.isBlacklistInfiniteScrolling = false;
                }
            })
            .addCase(groupOperations.searchGroupMembersBlackWhite.fulfilled, (state, action) => {
                if (action.meta.arg.isWhiteList) {
                    state.userWhitelist = (state.isWhitelistInfiniteScrolling && state.userWhitelist) ? state.userWhitelist.concat(action.payload.results) : action.payload.results;
                    state.userWhitelistTotalCount = action.payload.totalCount;
                    state.isWhitelistLoading = false;
                    state.isWhitelistInfiniteScrolling = false;
                } else {
                    state.userBlacklist = (state.isBlacklistInfiniteScrolling && state.userBlacklist) ? state.userBlacklist.concat(action.payload.results) : action.payload.results;
                    state.userBlacklistTotalCount = action.payload.totalCount;
                    state.isBlacklistLoading = false;
                    state.isBlacklistInfiniteScrolling = false;
                }
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupOrganizations.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.organizationsList = initialState.organizationsList;
                state.organizationsTotalCount = initialState.organizationsTotalCount;
                state.isOrganizationsLoading = false;
                state.isOrganizationInfiniteScrolling = false;
            })
            .addCase(groupOperations.searchGroupOrganizations.fulfilled, (state, action) => {
                state.organizationsList = state.isOrganizationInfiniteScrolling ? state.organizationsList.concat(action.payload.results) : action.payload.results;
                state.organizationsTotalCount = action.payload.totalCount;
                state.isOrganizationsLoading = false;
                state.isOrganizationInfiniteScrolling = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupOrganizationsBlackWhite.rejected, (state, action) => {
                state.error = action.error.message as string;

                if (action.meta.arg.isWhiteList) {
                    state.organizationWhitelist = initialState.organizationWhitelist;
                    state.organizationWhitelistTotalCount = initialState.organizationWhitelistTotalCount;
                    state.isWhitelistLoading = false;
                    state.isWhitelistInfiniteScrolling = false;
                } else {
                    state.organizationBlacklist = initialState.organizationBlacklist;
                    state.organizationBlacklistTotalCount = initialState.organizationBlacklistTotalCount;
                    state.isBlacklistLoading = false;
                    state.isBlacklistInfiniteScrolling = false;
                }
            })
            .addCase(groupOperations.searchGroupOrganizationsBlackWhite.fulfilled, (state, action) => {

                if (action.meta.arg.isWhiteList) {
                    state.organizationWhitelist = (state.isWhitelistInfiniteScrolling && state.organizationWhitelist) ? state.organizationWhitelist.concat(action.payload.results) : action.payload.results;
                    state.organizationWhitelistTotalCount = action.payload.totalCount;
                    state.isWhitelistLoading = false;
                    state.isWhitelistInfiniteScrolling = false;
                } else {
                    state.organizationBlacklist = (state.isBlacklistInfiniteScrolling && state.organizationBlacklist) ? state.organizationBlacklist.concat(action.payload.results) : action.payload.results;
                    state.organizationBlacklistTotalCount = action.payload.totalCount;
                    state.isBlacklistLoading = false;
                    state.isBlacklistInfiniteScrolling = false;
                }
            })
            ;
    },
    reducers: {
        startLoading(state, action: PayloadAction<boolean | undefined>) {
            state.isLoading = true;
            if (action.payload) state.isInfiniteScrolling = true;
        },
        startMembersLoading(state, action: PayloadAction<boolean>) {
            state.isMembersLoading = true;
            if (action.payload) state.isMembersInfiniteScrolling = true;
        },
        startOrganizationsLoading(state, action: PayloadAction<boolean>) {
            state.isOrganizationsLoading = true;
            if (action.payload) state.isOrganizationInfiniteScrolling = true;
        },
        startLogsLoading(state) {
            state.areLogsLoading = true;
        },
        startWhitelistLoading(state, action: PayloadAction<boolean>) {
            state.isWhitelistLoading = true;
            if (action.payload) state.isWhitelistInfiniteScrolling = true;
        },
        startBlacklistLoading(state, action: PayloadAction<boolean>) {
            state.isBlacklistLoading = true;
            if (action.payload) state.isBlacklistInfiniteScrolling = true;
        },
        resetGroup(state) {
            state.group = null;
            state.membersList = [];
            state.organizationsList = [];
            state.organizationBlacklistHasFilter = true;
            state.organizationBlacklist = undefined;
            state.userBlacklistHasFilter = true;
            state.userBlacklist = undefined;
            state.organizationWhitelistHasFilter = true;
            state.organizationWhitelist = undefined;
            state.userWhitelistHasFilter = true;
            state.userWhitelist = undefined;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        setUserBlackHasFilter(state, action: PayloadAction<boolean>) {
            state.userBlacklistHasFilter = action.payload;
        },
        setUserWhiteHasFilter(state, action: PayloadAction<boolean>) {
            state.userWhitelistHasFilter = action.payload;
        },
        setOrganizationBlackHasFilter(state, action: PayloadAction<boolean>) {
            state.organizationBlacklistHasFilter = action.payload;
        },
        setOrganizationWhiteHasFilter(state, action: PayloadAction<boolean>) {
            state.organizationWhitelistHasFilter = action.payload;
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableGroupSL(state, action: PayloadAction<EnableDisableGroupSliceProps>) {

            const { id, tab, action: payloadAction } = action.payload;

            const index = state.groupsList.findIndex((group) => group.groupId === id);

            state.groupsList[index].enabled = payloadAction === "Enable";

            if (tab !== "") state.groupsList.splice(index, 1);

            if (payloadAction === "Enable") {
                state.statistics.disabled -= 1;

                state.statistics.enabled += 1;
            } else {
                state.statistics.disabled += 1;

                state.statistics.enabled -= 1;
            }
        }
    }

});

export const {
    startLoading,
    startMembersLoading,
    startLogsLoading,
    resetGroup,
    setFiltersInUrl,
    enableDisableGroupSL,
    startOrganizationsLoading,
    startWhitelistLoading,
    startBlacklistLoading,
    setUserBlackHasFilter,
    setUserWhiteHasFilter,
    setOrganizationBlackHasFilter,
    setOrganizationWhiteHasFilter
} = GroupSlice.actions;

export default GroupSlice.reducer;