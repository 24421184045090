import { Box, TextField, Typography } from "@mui/material";
import { ContainerSection } from "./WarehouseNewArticle";
import { useLocales } from "src/locales";
import { useState } from "react";
import { FileWithSection } from "src/@types/media";
import UploadForContainer from "src/components/upload/UploadForContainers";

interface WarehouseNewArticleProps {
    changeContainer: (val: string | null) => void,
    changeNote: (val: string) => void,
    changeFile: (val: File[]) => void,
    images: FileWithSection[]
}

const typographySubtitle = {
    color: '#6D6D6D',
    fontSize: 12,
    fontFamily: 'Public Sans',
    fontWeight: '700',
    textTransform: 'uppercase',
    wordWrap: 'break-word'
};

export default function WarehouseWarranty({ changeContainer, changeNote, changeFile, images }: WarehouseNewArticleProps) {

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    const [files, setFiles] = useState<FileWithSection[]>(images);

    return (
        <Box sx={{ m: 3 }}>
            <UploadForContainer files={files} setFiles={(files) => {
                setFiles(files);
                changeFile(files);
            }} />

            <ContainerSection required onContainerChange={(container) => changeContainer(container?.id || null)} contentTypes={"Warranty"} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 3 }}>

                <Typography sx={typographySubtitle}>
                    {translate(`commons.note`)}
                </Typography>

                <TextField
                    name={"description"}
                    variant={"outlined"}
                    rows={4}
                    multiline
                    value={note}
                    onChange={(e) => {
                        setNote(e.target.value);
                        changeNote(e.target.value);
                    }}
                    fullWidth
                    type={"text"}
                    placeholder={`${translate(`commons.insertDesc`)}`}
                    sx={{ width: '100%' }}
                />
            </Box>
        </Box >
    );
}