import { BundleAvailabilityStatusType } from "src/@types/webshop";
import { LabelColor } from "src/components/label";

export const getProductStatusColor = (status: BundleAvailabilityStatusType): LabelColor => {

    let color: LabelColor = 'default';

    switch (status) {
        case "BackOrder":
            color = "warning";
            break;
        case "InStock":
            color = "success";
            break;
        case "LowStock":
            color = "warning";
            break;
        case "OutOfStock":
            color = "error";
            break;
    }

    return color;
};
