import { Box, Card, IconButton, Typography } from '@mui/material';
import { SingleGridElementProps } from 'src/@types/list';
import { ShopBundleSearchResult } from 'src/@types/webshop';
import { alpha } from '@mui/material/styles';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { PATH_DASHBOARD } from 'src/routes/paths';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddToCartMenu from './AddToCartMenu';
import ProductPriceLabel from './ProductPriceLabel';
import Label from 'src/components/label';
import { getFirstLowerCase } from 'src/appUtils/string';
import { getProductStatusColor } from './utils';

export default function SingleProductGridElement({ key, item: bundle, translate, navigate, extraProps, menuOptions }: SingleGridElementProps<ShopBundleSearchResult>) {

    const { quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled, webshopId, hasPermissionToBuy, addedQuantitiesRecord, handleAddToCart } = extraProps;

    return (
        <Card
            key={key}
            sx={{
                p: 1,
                transition: "0.5s",
                cursor: hasPermissionToBuy ? 'pointer' : 'default',
                width: "100%",
                minHeight: "350px",
                height: "35vh",
                maxHeight: "435px",
                ':hover': { bgcolor: (theme) => theme.palette.action.selected }
            }}
            onClick={() => {
                if (!window.getSelection()?.toString() && hasPermissionToBuy && !menuOptions?.openMenu)
                    navigate(PATH_DASHBOARD.webshopPublic.productDetail(webshopId, bundle.bundleId));
            }}
        >

            <Box sx={{ height: "67%", display: "flex", alignItems: "center" }}>
                {bundle.items[0].media[0]?.fileInfo[0]?.url ?
                    <img
                        alt={"product"}
                        src={bundle.items[0].media[0].fileInfo[0].url}
                        style={{
                            borderRadius: "15px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            border: "1px ridge",
                            aspectRatio: "1"
                        }}
                    />
                    :
                    <NoPhotographyIcon sx={{ width: '100%', height: '7.5vh', objectFit: 'contain' }} />
                }

                <Label
                    color={getProductStatusColor(bundle.availability.status)}
                    sx={{ textTransform: 'uppercase', position: "absolute", top: "5%", right: "5%" }}
                >
                    {translate(`statuses.${getFirstLowerCase(bundle.availability.status)}`)}
                </Label>

                {(hasPermissionToBuy && bundle.availability.status !== "OutOfStock") &&
                    <IconButton
                        onClick={(event) => {
                            menuOptions?.setActualCardItem(bundle);
                            menuOptions?.handleOpenMenu(event);
                            event.stopPropagation();
                        }}
                        sx={{
                            position: "absolute", top: "60.5%", right: "5%",
                            p: 1.5, pl: 1.75, pt: 1.75,
                            backgroundColor: (theme) => theme.palette.warning.main,
                            color: (theme) => theme.palette.text.primary,
                            ':hover': { bgcolor: (theme) => alpha(theme.palette.warning.dark, 0.87) }
                        }}
                    >
                        <AddShoppingCartIcon width={"24px"} height={"24px"} />
                    </IconButton>
                }

                <AddToCartMenu
                    handleCloseMenu={menuOptions?.handleCloseMenu!}
                    translate={translate}
                    openMenu={menuOptions?.openMenu!}
                    bundle={bundle}
                    quantitiesRecord={quantitiesRecord}
                    handleChangeQuantity={handleChangeQuantity}
                    getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                    actualCardItem={menuOptions?.actualCardItem ?? null}
                    addedQuantitiesRecord={addedQuantitiesRecord}
                    handleAddToCart={handleAddToCart}
                />
            </Box>

            <Box sx={{ mt: 2, ml: 1, display: 'flex', flexDirection: "column", gap: 1.5, height: "auto" }}>
                <Typography variant={"h6"} noWrap>
                    {bundle.items[0].name}
                </Typography>

                <Typography variant='overline' noWrap sx={{ mt: -1.5 }}>
                    <b>{"[" + bundle.items[0].family.supplier.code + "] - "}</b>{bundle.items[0].family.supplier.description}
                </Typography>

                <ProductPriceLabel
                    grossAmount={bundle.price.grossAmount}
                    translate={translate}
                    discountPercentage={bundle.price.totalDiscountPercentage}
                    unitDiscountedAmount={bundle.price.unitDiscountedAmount}
                />

            </Box>

        </Card>
    );
}