import { Box, Button, Card, Container, Divider } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import Page from "src/appComponents/Page";
import { Upload } from "src/components/upload";
import { handleUpload } from "src/appUtils/UploadHandler";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { v4 } from "uuid";
import { LoadingButton } from "@mui/lab";

export default function NewEditDiscountMatrix() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const [files, setFiles] = useState<File[]>([]);

    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const navigate = useNavigate();

    const handleSave = async () => {

        setLoading(true);

        if (files.length > 0) {
            await handleUpload(files, id ?? v4(), "DiscountMatrix", (v) => setLoading(v), "Bundle");

            dispatch(setSuccessMessage({ text: translate("discountMatrix.messages.uploadSuccess"), returnTo: PATH_DASHBOARD.discountMatrix.list }));
        }
    };

    return (
        <Page title={translate("menu.management.discountMatrix.new")} >
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={translate("menu.management.discountMatrix.new")}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate(`discountMatrix.title`) }
                    ]}
                />
                <Card sx={{ p: 3 }}>

                    <Box sx={{ display: 'flex', gap: 3, width: '100%', flexDirection: 'column' }}>
                        <Box>
                            <Upload
                                onDropAccepted={(files) => {
                                    setFiles(files);
                                }}
                                maxFiles={1}
                                accept={{
                                    'text/csv': ['.csv'],
                                }}
                                file={files[0]}
                            />
                        </Box>
                    </Box>

                    <Divider sx={{ mt: 3 }} />

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', gap: 2 }}>

                        <Button variant='soft' color="inherit" onClick={() => navigate(PATH_DASHBOARD.discountMatrix.list)}>
                            {`${translate("commons.cancel")}`}
                        </Button>

                        <LoadingButton
                            variant="contained"
                            disabled={files.length === 0}
                            loading={loading}
                            onClick={() => handleSave()}
                        >
                            {translate("commons.upload")}
                        </LoadingButton>

                    </Box>
                </Card>
            </Container>
        </Page>
    );

}