import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, MenuItem } from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import Label, { LabelColor } from 'src/components/label';
import useTabs from 'src/appHooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { DEFAULT_ORGANIZATION_FILTERS, OganizationTypesArr, OrganizationFilters, OrganizationSearchResult } from 'src/@types/organizations';
import { organizationsOperations } from 'src/redux/organizations';
import { cloneDeep, isEqual } from 'lodash';
import { brandOperations } from 'src/redux/brand';
import useResponsive from 'src/hooks/useResponsive';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { SidebarListFilters, StatusFilters, ToolbarListFilters } from 'src/@types/list';
import { convertStringArrayToSelectOptions, manageAmountFilters } from 'src/utils/list/utils/functions';
import { getSliderFilterNames } from 'src/utils/list/sidebar/SidebarSlider';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { setFiltersInUrl } from 'src/redux/organizations/organizations-slices';
import organizationService from "src/services/organizationService";
import { getFirstLowerCase } from 'src/appUtils/string';

interface OrganizationListProps {
    isVendor: boolean
}

export default function OrganizationList({ isVendor }: OrganizationListProps) {

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const { isSearchLoading: isLoading, organizationList, totalCount, statistics, filtersInUrl } = useSelector((state: RootState) => state.organizations);

    const { isLoading: isBrandLoading, publicBrandList } = useSelector((state: RootState) => state.brand);

    const [filters, setFilters] = useState<OrganizationFilters>(DEFAULT_ORGANIZATION_FILTERS);

    useEffect(() => {
        if (!publicBrandList)
            dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    //---- SIDEBAR FILTERS ----//
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "name",
            label: translate(`organization.list.table.organization`),
            type: "TextField"
        },
        {
            name: "vat",
            label: translate(`organization.detail.vat`),
            type: "TextField"
        },
        {
            name: "email",
            label: translate(`commons.email`),
            type: "TextField"
        },
        {
            name: "externalId",
            label: translate(`commons.code`),
            type: "TextField"
        },
        {
            name: "type",
            label: translate('commons.type'),
            type: "Select",
            options: convertStringArrayToSelectOptions(OganizationTypesArr.slice(1), translate, "organization.types.")
        },
        ...(!isVendor ?
            [{
                name: "isMonitored",
                label: translate(`organization.list.filter.surveillance`),
                type: "SingleSwitch",
                toParse: true,
                switchesInfo: [
                    {
                        name: "isMonitored",
                        label: translate(`organization.list.filter.surveillance`)
                    }
                ]
            },] : []) as SidebarListFilters[],
        {
            name: "creditBalance",
            label: translate(`organization.form.payment.balance`),
            type: "InputRange"
        },
        {
            name: "creditLimit",
            label: translate(`organization.form.payment.creditLimit`),
            type: "InputRange"
        },
        {
            name: "",
            label: translate(`commons.tags`),
            type: "Section"
        },
        {
            name: "tags",
            label: `${translate("commons.tags")}`,
            type: "TagsAutocomplete",
            tagsService: organizationService.searchTags,
            toParse: true
        }
    ], [isVendor, translate]);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() => [
        {
            key: 'all',
            label: translate('commons.all')
        },
        {
            key: 'name',
            label: translate('commons.name')
        },
        {
            key: 'externalId',
            label: translate('commons.code')
        }
    ], [translate]);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: OrganizationFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean" || typeof filtersToCheck[field] === "number")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_ORGANIZATION_FILTERS[field])
        , []);

    //---- FUNCTION TO USE TO ADAPT FILTERS BEFORE UPDATING FILTERS IN URL ----//
    const updateInUrlFiltersChecks = useCallback((filters: OrganizationFilters) => {

        let newFilters = cloneDeep(filters);

        filtersInSidebar.forEach((filter) => {
            if (filter.type === "InputRange") {
                const [minName, maxName] = getSliderFilterNames(filter.name);

                if (filters[minName] && filters[maxName]) newFilters = manageAmountFilters(minName, maxName, newFilters);
            }
        });

        return newFilters;
    }, [filtersInSidebar]);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((params: { filters: OrganizationFilters & { sequenceToken: any }, check: boolean }) => {
        dispatch(organizationsOperations.searchOrganizations({ ...params, isVendor: isVendor }));
    }, [isVendor]);

    //---- CUSTOM SEARCH STATISTICS FUNCTION ----//
    const customSearchStatistics = useCallback((filters: OrganizationFilters & { sequenceToken: any }) => {
        dispatch(organizationsOperations.searchStatistic({ filters, isVendor: isVendor }));
    }, [isVendor]);

    //---- QUICKFILTERS START ----//
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs("");

    const quickFilters: StatusFilters[] = useMemo(() => [
        {
            key: '',
            label: translate('commons.all'),
            color: 'primary',
        },
        {
            key: 'Enabled',
            label: translate('statuses.enabled'),
            color: 'success',
        },
        {
            key: 'Disabled',
            label: translate('statuses.disabled'),
            color: 'warning',
        },
        {
            key: 'Blocked',
            label: translate('statuses.blocked'),
            color: 'error',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "":
                    return statistics.all;
                case "Enabled":
                    return statistics.enabled;
                case "Disabled":
                    return statistics.disabled;
                case "Blocked":
                    return statistics.blocked;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== 'options') {
            if (isVendor)
                navigate(PATH_DASHBOARD.organizations.vendor.detail(params.id.toString(), params.row.type.toString().toLowerCase()));
            else
                navigate(PATH_DASHBOARD.organizations.customer.detail(params.id.toString(), params.row.type.toString().toLowerCase()));
        }
    };

    const getStatusColor = (status: string): LabelColor => {

        let color = 'default';

        switch (status) {
            case "Enabled":
                color = 'success';
                break;
            case "Disabled":
                color = 'warning';
                break;
            case "Blocked":
                color = 'error';
                break;
        }

        return color as LabelColor;
    };

    const handleLogs = useCallback((id: string, type: string) => {
        if (isVendor)
            navigate(PATH_DASHBOARD.organizations.vendor.logs(id, type.toLowerCase()));
        else
            navigate(PATH_DASHBOARD.organizations.customer.logs(id, type.toLowerCase()));

    }, [navigate, isVendor]);

    const handleEdit = useCallback((id: string, type: string) => {
        if (isVendor)
            navigate(PATH_DASHBOARD.organizations.vendor.edit(id, type.toLowerCase()));
        else
            navigate(PATH_DASHBOARD.organizations.customer.logs(id, type.toLowerCase()));

    }, [navigate, isVendor]);

    const COLUMNS: GridColDef<OrganizationSearchResult>[] = useMemo(() => [
        {
            field: 'externalId',
            headerName: translate('commons.code'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 100 : undefined
        },
        {
            field: 'name',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'brands',
            headerName: translate('commons.brand') + "/" + translate('commons.category'),
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    <Typography variant="body2">
                        {(obj.row.brands && publicBrandList) && publicBrandList.filter(brand => obj.row.brands.includes(brand.id)).map(brand => brand.name).join(', ')}
                    </Typography>
                );
            }
        },
        {
            field: 'regions',
            headerName: translate('organization.list.table.region'),
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 175 : undefined
        },
        {
            field: 'type',
            headerName: translate('organization.list.table.type'),
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 150 : undefined
        },
        ...(!isVendor ?
            [{
                field: 'creditLimit',
                headerName: translate('organization.list.table.credit'),
                flex: isDesktop ? 0.6 : undefined,
                minWidth: !isDesktop ? 150 : undefined,
                sortable: false,
                renderCell: (obj) => <CurrencyAdapter value={obj.row.creditLimit} sx={{ pl: 1 }} />
            }] : []) as GridColDef[],
        {
            field: 'status',
            headerName: translate('returns.tableHeaders.status'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {translate(`statuses.${getFirstLowerCase(obj.row.status)}`)}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.4 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        handleEdit={handleEdit}
                        object={obj}
                        currentRow={actualRow}
                        isVendor={isVendor}
                    />
                );
            }
        }
    ], [actualRow, handleLogs, openMenu, translate, isDesktop, handleEdit, publicBrandList, isVendor]);
    //---- HANDLE TABLE END ----//

    return (
        <InfiniteScrollGenericList
            list={organizationList}
            isLoading={isLoading || isBrandLoading}
            totalCount={totalCount}
            defaultFilters={DEFAULT_ORGANIZATION_FILTERS}
            quickFilters={quickFilters}
            renderQuickFilters={renderQuickFilters}
            toolbarFiltersList={toolbarFiltersList}
            filtersInSidebar={filtersInSidebar}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"Organization"}
            setActualRow={setActualRow}
            handleCellClick={handleCellClick}
            setFiltersCallback={setFilters}
            filterStatus={filterStatus}
            onChangeFilterStatus={onChangeFilterStatus}
            customSearchFunc={customSearch}
            customSearchStatistics={customSearchStatistics}
            filtersInUrl={filtersInUrl}
            setFiltersInUrl={setFiltersInUrl}
            listDescription={translate('organization.messages.subtitle')}
            resetList={() => { }}
            updateInUrlFiltersChecks={updateInUrlFiltersChecks}
            showVatAdvice
        />
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string, type: string) => void,
    handleEdit: (id: string, type: string) => void,
    object: any,
    currentRow: any,
    isVendor: boolean
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, handleEdit, object, currentRow, isVendor }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    {isVendor && <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Organization_Edit]} >
                        <MenuItem
                            onClick={() => {
                                handleEdit(object.id, object.row.type);
                                handleCloseMenu();
                            }}
                        >
                            {translate("commons.edit")}
                        </MenuItem>
                    </PermissionBasedGuard>}

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Organization_ViewLogs]}>
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id, object.row.type);
                                handleCloseMenu();
                            }}
                        >
                            {translate("commons.logs")}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}
