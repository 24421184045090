import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Card, Box, Divider, Typography } from '@mui/material';
import { dispatch } from 'src/redux/store';
import useLocales from 'src/appHooks/useLocales';
import Label from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import { GridCellParams, GridColDef, GridColumnVisibilityModel, GridRowParams, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import { cloneDeep, isArray, isDate, isEqual, isObject, omitBy, remove } from 'lodash';
import useResponsive from 'src/hooks/useResponsive';
import useLocalStorage from 'src/hooks/useLocalStorage';
import GenericFilterSidebar from 'src/utils/list/sidebar/GenericFilterSidebar';
import { DatesGeneralFilterProps, SidebarListFilters, INFINITE_SCROLL_LIST_FILTERS, InfiniteScrollListFilters, InfiniteScrollResponse, StatusFilters, SequenceToken, ToolbarListFilters } from 'src/@types/list';
import GenericFilterSummary from 'src/utils/list/summary/GenericFilterSummary';
import GenericFilterToolbar from 'src/utils/list/toolbar/GenericFilterToolbar';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import VisibilityModelComponent from './toolbar/VisibilityModelComponent';
import GenericListFooter from './utils/GenericListFooter';
import removeEmptyKeys from '../removeEmptyKeys';
import LicenseGuard from 'src/guards/LicenseGuard';
import { LicenseTypes } from 'src/@types/tenant';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { ReportTypeData, ReportTypeDataArr } from 'src/@types/report';
import ReportCreationComponent from './toolbar/ReportCreationComponent';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { getSliderFilterNames } from './sidebar/SidebarSlider';
import GenericFilterToolbarNEW from './toolbar/GenericFilterToolbarNEW';

type TreeViewInfiniteScrollGenericListProps<T extends GridValidRowModel, Q extends InfiniteScrollListFilters<Z>, Z = string> = {
    list: T[],
    resetList: VoidFunction,
    isLoading: boolean,
    totalCount: number,
    defaultFilters: Q,
    specificStatsKeysToDelete?: string[],
    specificFullKeysToDelete?: string[],
    quickFilters?: StatusFilters[],
    renderQuickFilters?: (key: string) => any,
    toolbarFiltersList?: ToolbarListFilters[],
    filtersInSidebar?: SidebarListFilters[],
    updateCheckField: (field: string, filters: Q) => boolean,
    extraSearchFiltersChecks?: (searchFilters: Q) => Q,
    updateInUrlFiltersChecks?: (searchFilters: Q) => Q,
    context: string,
    customfieldContext?: string[],
    datagridColumns: GridColDef<T>[],
    setActualRow: Dispatch<any>,
    handleCellClick?: (params: GridCellParams<any>) => void,
    setFiltersCallback?: Dispatch<SetStateAction<Q>>,
    filterStatus?: string,
    onChangeFilterStatus?: (event: React.SyntheticEvent<Element, Event> | null, newValue: string) => void,
    search?: (options: { filters: Q, check: boolean }) => AsyncThunkAction<InfiniteScrollResponse<(T & SequenceToken)>, any, any>,
    customSearchFunc?: (options: { filters: Q, check: boolean }) => void,
    searchStatistics?: (filters: Q) => AsyncThunkAction<any, any, any>,
    customSearchStatistics?: (filters: Q) => void,
    filtersInUrl?: string,
    setFiltersInUrl?: any,
    listDescription?: string,
    datesGeneralFilter?: DatesGeneralFilterProps,
    reportFilters?: any,
    getDetailPanelContent?: (params: GridRowParams<T>) => ReactNode,
    pinnedColumns?: string[],
    showVatAdvice?: boolean,
    itemCustomfieldContext?: string[],
    //NON-GENERIC SECTION ---------
    extraFunctionForRequest?: (filters: Q, getValues: any, setValue: any) => Promise<void>
}

export default function TreeViewInfiniteScrollGenericList<T extends GridValidRowModel, Q extends InfiniteScrollListFilters<Z>, Z>({
    list,
    resetList,
    isLoading,
    totalCount,
    defaultFilters,
    specificStatsKeysToDelete = [],
    specificFullKeysToDelete = [],
    quickFilters,
    renderQuickFilters,
    toolbarFiltersList,
    filtersInSidebar,
    datagridColumns,
    updateCheckField,
    extraSearchFiltersChecks,
    updateInUrlFiltersChecks,
    context,
    customfieldContext,
    setActualRow,
    handleCellClick,
    setFiltersCallback,
    filterStatus,
    onChangeFilterStatus,
    search,
    searchStatistics,
    customSearchFunc,
    customSearchStatistics,
    filtersInUrl,
    setFiltersInUrl,
    listDescription,
    datesGeneralFilter,
    reportFilters,
    getDetailPanelContent,
    pinnedColumns = [],
    itemCustomfieldContext = [],
    //NON-GENERIC SECTION ---------
    extraFunctionForRequest,
    showVatAdvice
}: TreeViewInfiniteScrollGenericListProps<T, Q, Z>) {

    const {
        order,
        setOrderBy,
        orderBy,
        setOrder,
        dense,
        onChangeDense
    } = useTable({ defaultOrder: "desc" });

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const [filters, setFilters] = useState(defaultFilters);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(false);

    const [resetFormElement, setResetFormElement] = useState("");

    const [showSummary, setShowSummary] = useState(true);

    const methods = useForm<InfiniteScrollListFilters<Z>>({ defaultValues: defaultFilters });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    const apiRef = useGridApiRef();

    const handleResetToTop = useCallback(() => {
        apiRef.current.scroll({ top: 0 });
    }, [apiRef]);

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected
    const statsKeysToDelete: string[] = useMemo(() => ["sortField", "sortDirection", "size", "sequenceToken", "pagination", ...specificStatsKeysToDelete], [specificStatsKeysToDelete]);

    const fullKeysToDelete: string[] = useMemo(() => [], []); //...specificFullKeysToDelete

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToDelete.includes(key));
    }, [statsKeysToDelete]);

    const fullKeyRemover = useCallback((key: string) => {
        return !(statsKeysToDelete.includes(key) || fullKeysToDelete.includes(key));
    }, [fullKeysToDelete, statsKeysToDelete]);

    const isDefault = useCallback((filter: InfiniteScrollListFilters<Z>, controller?: InfiniteScrollListFilters<Z>, forStats?: boolean) => {

        const cleanedFilter = remove(Object.keys(filter), forStats ? statsKeyRemover : fullKeyRemover);

        const cleanedController = remove(Object.keys(controller ? controller : defaultFilters), forStats ? statsKeyRemover : fullKeyRemover);

        const checkKeys = cleanedFilter.length > cleanedController.length ? cleanedFilter : cleanedController;

        const checkObject = controller ? controller : defaultFilters;

        const found = checkKeys.find((element) => !isEqual(filter[element], checkObject[element]));

        return (!found);
    }, [defaultFilters, statsKeyRemover, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- CLOSE AND OPEN SIDEBAR - START ----//
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = useCallback(() => {
        if (resetForm) {
            reset(defaultFilters);
        };
        setOpenSidebar(false);
    }, [defaultFilters, reset, resetForm]);
    //---- CLOSE AND OPEN SIDEBAR - START ----//

    //---- CLEAR FROM SUMMARY FUNC ----//
    const handleClearFromSummary = useCallback((section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    }, [formValues, isDefault]);

    //---- FILTERS IN URL GET/SET - START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(INFINITE_SCROLL_LIST_FILTERS);

    const updateFiltersInUrl = useCallback((filters: Q) => {

        let queryString = Object.keys(filters).filter((field) => updateCheckField(field, filters))
            .map((key) => {
                if (isDate(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key].toISOString())}`;

                if (isArray(filters[key]) || isObject(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        if (setFiltersInUrl) dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate, updateCheckField, setFiltersInUrl]);

    const getFiltersFromUrl = useMemo(() => {

        const { hash } = location;

        const cleanedHash = filtersInUrl?.slice(1) ?? "";

        const decodedQuery = decodeURIComponent(cleanedHash);

        const searchParams = new URLSearchParams(decodedQuery);

        let searchFilters: InfiniteScrollListFilters<Z> = {
            pagination: "After",
            sequenceToken: null,
            size: Number(searchParams.get('size') ?? INFINITE_SCROLL_LIST_FILTERS.size),
            sortField: orderBy || INFINITE_SCROLL_LIST_FILTERS.sortField,
            sortDirection: order === 'desc' ? "Descending" : "Ascending",
            status: filterStatus as Z || undefined
        };

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                ...(Object.fromEntries(searchParams.entries())),
                ...searchFilters
            };

            if (filtersInSidebar) filtersInSidebar.filter((field) => field.toParse && searchFilters[field.name]).forEach((field) => searchFilters[field.name] = JSON.parse(searchFilters[field.name]));

            if (searchParams.get('customFields')) searchFilters["customFields"] = JSON.parse(searchParams.get('customFields')!);

            if (searchParams.get('itemCustomFields')) searchFilters["itemCustomFields"] = JSON.parse(searchParams.get('itemCustomFields')!);

            if (searchParams.get('from')) searchFilters.from = new Date(searchFilters.from.toString());
            if (searchParams.get('to')) searchFilters.to = new Date(searchFilters.to.toString());
        }

        searchFilters = removeEmptyKeys(searchFilters) as InfiniteScrollListFilters<Z>;

        return searchFilters as Q;

    }, [location, filtersInUrl, orderBy, order, filterStatus, filtersInSidebar]);
    //---- FILTERS IN URL GET/SET - END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: Q) => {

        var searchFilters: any = {};

        if (isEqual(values, defaultFilters)) {
            searchFilters = INFINITE_SCROLL_LIST_FILTERS;
        } else {
            Object.keys(values).forEach((field) => searchFilters[field] = values[field] ?? defaultFilters[field]);

            searchFilters = {
                ...searchFilters,
                size: INFINITE_SCROLL_LIST_FILTERS.size,
                sortField: orderBy,
                sortDirection: order === 'desc' ? "Descending" : "Ascending",
                pagination: "After",
                sequenceToken: null
            };
        }

        if (updateInUrlFiltersChecks) {
            searchFilters = updateInUrlFiltersChecks(searchFilters);
            reset(searchFilters);
        }

        updateFiltersInUrl(searchFilters);
    }, [defaultFilters, order, orderBy, updateFiltersInUrl, reset, updateInUrlFiltersChecks]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    const adjustLastFiltered = useCallback((values: Q) => {

        const result = Object.entries(values).reduce((acc, [key, value]) => {

            if (!isEqual(value, defaultFilters[key as keyof Q])) acc[key as keyof Q] = value;

            return acc;
        }, {} as Partial<Q>);

        return {
            ...result,
            ...INFINITE_SCROLL_LIST_FILTERS
        };
    }, [defaultFilters]);

    const onSearch = useCallback((filtersFromUrl: Q) => {

        if (isLoading) return;

        const cleanedFilters = removeEmptyKeys(filters);

        const isInfiniteScrolling = isEqual(filtersFromUrl, cleanedFilters);

        if (!isInfiniteScrolling) {
            setFilters(filtersFromUrl);
            updateFiltersInUrl(filtersFromUrl);
            handleResetToTop();
        }

        if (setFiltersCallback) setFiltersCallback(filtersFromUrl);

        const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrl.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl = { ...filtersFromUrl, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        const searchOptions = { filters: { ...updatedFiltersFromUrl, sequenceToken: isInfiniteScrolling ? list[list.length - 1].searchSequenceToken : null }, check: isInfiniteScrolling };

        if (customSearchFunc) customSearchFunc(searchOptions);
        else if (search) dispatch(search(searchOptions));

        if ((searchStatistics || customSearchStatistics) && !isInfiniteScrolling &&
            (firstRender
                || isEqual(filtersFromUrl, omitBy(INFINITE_SCROLL_LIST_FILTERS, (x) => x === undefined || x === null))
                || !isDefault(filtersFromUrl, lastStatsFilters as Q, true)
            )
        ) {
            if (customSearchStatistics) customSearchStatistics(updatedFiltersFromUrl);
            else if (searchStatistics) dispatch(searchStatistics(updatedFiltersFromUrl));

            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
        }

        //NON-GENERIC SECTION ---------
        if (extraFunctionForRequest) extraFunctionForRequest(filtersFromUrl, getValues, setValue);

        if (firstRender) setFirstRender(false);

    }, [isLoading, filters, setFiltersCallback, customSearchFunc, list, search, firstRender, isDefault, getValues, setValue, handleResetToTop,
        lastStatsFilters, searchStatistics, updateFiltersInUrl, adjustLastFiltered, customSearchStatistics, extraFunctionForRequest]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filtersToCheck: Q, olderFilters: Q) => {

        const differencesToOld = Object.keys(filtersToCheck).filter((filter) => filtersToCheck[filter] !== olderFilters[filter]);

        if (differencesToOld.includes("sortField") || differencesToOld.includes("sortDirection")) return true;

        const differencesToFilters = Object.keys(olderFilters).filter((filter) => olderFilters[filter] !== filtersToCheck[filter]);

        return differencesToFilters.includes("sortField") || differencesToFilters.includes("sortDirection");

    }, []);

    useEffect(() => {

        let searchFilters = cloneDeep(getFiltersFromUrl);

        if (extraSearchFiltersChecks) searchFilters = extraSearchFiltersChecks(searchFilters);

        if (!isEqual(searchFilters, filters) || lastUsedLang !== currentLang.label) {

            //if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;

            onSearch(searchFilters as Q);

            if (lastUsedLang !== currentLang.label) setLastUsedLang(currentLang.label);
        }

    }, [location, orderBy, order, firstRender, filterStatus, currentLang, lastUsedLang,
        filters, isOrderDiff, extraSearchFiltersChecks, onSearch, getFiltersFromUrl]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = useCallback(() => {
        fetchData(getValues() as Q);
        setResetForm(false);
        setOpenSidebar(false);
        resetList();
    }, [fetchData, getValues, resetList]);

    //---- FILTERS RESET - START ----//
    const handleResetAllFilter = useCallback(() => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        resetList();
        setResetForm(true);
        setShowSummary(false);
        fetchData(defaultFilters);
        reset(defaultFilters);
    }, [defaultFilters, fetchData, handleCloseSidebar, openSidebar, reset, resetList]);

    const handleResetSingleFilter = useCallback((fieldName: string, value?: any, isRange?: boolean) => {
        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues as Q);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);

        } else if (isRange) {
            const [minLabel, maxLabel] = getSliderFilterNames(fieldName);

            formValues[minLabel] = defaultFilters[minLabel];
            setValue(minLabel, defaultFilters[minLabel]);

            formValues[maxLabel] = defaultFilters[maxLabel];
            setValue(maxLabel, defaultFilters[maxLabel]);

            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);
        } else {
            formValues[fieldName] = defaultFilters[fieldName];
            setValue(fieldName, defaultFilters[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);
        }
    }, [defaultFilters, fetchData, formValues, handleClearFromSummary, setValue]);
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE - START ----//
    const getHeight = useCallback(() => {
        let height: string | number = "auto";

        if (!dense || list.length === 0) {
            height = isDesktop ? 632 : 649;
        }

        return height;
    }, [dense, isDesktop, list]);

    const getMaxHeight = useCallback(() => {
        return isDesktop ? 632 : 649;
    }, [isDesktop]);

    const handleSort = useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy(INFINITE_SCROLL_LIST_FILTERS.sortField);
            setOrder(INFINITE_SCROLL_LIST_FILTERS.sortDirection === "Descending" ? "desc" : "asc");
        }
    }, [setOrder, setOrderBy]);
    //---- HANDLE TABLE - END ----//

    //---- HANDLE COLUMN VISIBILITY - START -----//
    const [columns, setColumns] = useLocalStorage<Record<string, GridColumnVisibilityModel>>("columns", {});

    const [visibility, setVisibility] = useState<GridColumnVisibilityModel>(columns[context] || (() => datagridColumns.reduce((prev, curr) => {
        Object.assign(prev, { [curr.field]: true });

        return prev;
    }, {})));

    useEffect(() => {
        setColumns({ ...columns, [context]: visibility });
    }, [visibility]);
    //---- HANDLE COLUMN VISIBILITY - END -----//

    return (
        <>
            <Card>

                {(filtersInSidebar || listDescription) &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: { xs: 2, md: 3.5 },
                            pt: 1.5,
                            pb: (quickFilters || !(toolbarFiltersList || datesGeneralFilter?.showDates)) ? 1.5 : 0
                        }}
                    >
                        {listDescription ?
                            <Box>
                                <Typography variant="body2">
                                    {listDescription}
                                </Typography>
                            </Box>
                            :
                            <Box />
                        }

                        <FormProvider methods={methods}>
                            <Box sx={{ display: 'flex', alignItems: "center", gap: 1 }}>

                                {(reportFilters && ReportTypeDataArr.includes(context as ReportTypeData)) &&
                                    <LicenseGuard license={LicenseTypes.Report}>
                                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Report_View]}>
                                            <ReportCreationComponent allFilters={reportFilters} reportType={context as ReportTypeData} />
                                        </PermissionBasedGuard>
                                    </LicenseGuard>
                                }

                                <VisibilityModelComponent
                                    columns={datagridColumns}
                                    model={visibility}
                                    onChangeModel={setVisibility}
                                />

                                {filtersInSidebar &&
                                    <GenericFilterSidebar
                                        isOpen={openSidebar}
                                        onOpen={handleOpenSidebar}
                                        onClose={handleCloseSidebar}
                                        onFilter={handleSearchFilters}
                                        onResetAll={handleResetAllFilter}
                                        defaultFilters={defaultFilters}
                                        resetTrigger={resetForm}
                                        filterValues={{ ...formValues, ...(getFiltersFromUrl) }}
                                        filterList={filtersInSidebar}
                                        isDefault={isDefault}
                                        setShowSummary={setShowSummary}
                                        customfieldContext={customfieldContext ? customfieldContext : [context]}
                                        resetFormElement={resetFormElement}
                                        setResetFormElement={setResetFormElement}
                                    />
                                }
                            </Box>
                        </FormProvider>

                    </Box>
                }

                {(quickFilters && renderQuickFilters) &&
                    <>
                        <Divider />

                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons={!isDesktop}
                            value={filterStatus}
                            onChange={(e, value) => {
                                //resetList();
                                if (onChangeFilterStatus) onChangeFilterStatus(e, value);
                            }}
                            sx={{
                                px: { xs: 0, md: 2 },
                                bgcolor: 'background.neutral'
                            }}
                        >
                            {quickFilters.map((tab) => (
                                <Tab
                                    disableRipple
                                    key={tab.key}
                                    label={tab.label}
                                    value={tab.key}
                                    icon={
                                        <Label color={tab?.color} sx={{ mr: 1 }}>
                                            {renderQuickFilters(tab.key)}
                                        </Label>
                                    }
                                />
                            ))}

                        </Tabs>

                        <Divider />
                    </>
                }

                {(toolbarFiltersList || datesGeneralFilter?.showDates) &&
                    <FormProvider methods={methods}>
                        <GenericFilterToolbarNEW
                            filterValues={{ ...formValues, ...getFiltersFromUrl }}
                            defaultFilters={defaultFilters}
                            onSearch={handleSearchFilters}
                            onResetAll={handleResetAllFilter}
                            optionsFields={(toolbarFiltersList && toolbarFiltersList.length > 0) ? toolbarFiltersList : undefined}
                            datesGeneralFilter={datesGeneralFilter}
                            showSummary={showSummary}
                            setShowSummary={setShowSummary}
                            isDefault={isDefault}
                        />
                    </FormProvider>}

                {filtersInSidebar &&
                    <GenericFilterSummary
                        showSummary={showSummary && !openSidebar && !isDefault({ ...formValues, ...(getFiltersFromUrl) })}
                        defaultFilters={defaultFilters}
                        filterValues={{ ...formValues, ...getFiltersFromUrl }}
                        filterList={filtersInSidebar}
                        onResetFilter={handleResetSingleFilter}
                        onResetAll={handleResetAllFilter}
                        customfieldContext={[context]}
                        itemCustomfieldContext={itemCustomfieldContext}
                        isToolbarShown={!!(toolbarFiltersList || datesGeneralFilter?.showDates)}
                    />
                }

                <Divider />

                <DataGridPro
                    apiRef={apiRef}
                    rowCount={100}
                    rows={list as any}
                    columns={datagridColumns as any}
                    disableColumnResize
                    density={(dense && list.length > 0) ? 'compact' : 'standard'}
                    sortingMode={"server"}
                    paginationMode={"server"}
                    onSortModelChange={handleSort}
                    loading={isLoading}
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={getDetailPanelContent}
                    columnVisibilityModel={visibility}
                    slots={{
                        detailPanelExpandIcon: KeyboardArrowDown,
                        detailPanelCollapseIcon: KeyboardArrowUp,
                        noRowsOverlay: noData,
                        noResultsOverlay: noData,
                        footer: () => (
                            <>
                                {list.length === 0 && <Divider />}
                                <GenericListFooter
                                    dense={dense}
                                    onChangeDense={onChangeDense}
                                    totalCount={totalCount}
                                />
                            </>
                        )
                    }}
                    disableColumnMenu
                    onCellClick={(params) => {
                        setActualRow(params);

                        if (!window.getSelection()?.toString() && handleCellClick)
                            handleCellClick(params);
                    }}
                    scrollEndThreshold={20}
                    onRowsScrollEnd={(params) => {
                        if (list.length < totalCount) {
                            onSearch(getFiltersFromUrl);
                        }
                    }}
                    sx={{
                        ...DataGridStyle,
                        height: getHeight(),
                        maxHeight: getMaxHeight(),
                        '& .MuiDataGrid-cell': {
                            cursor: handleCellClick ? 'pointer' : 'default'
                        }
                    }}
                    initialState={{ pinnedColumns: { right: pinnedColumns } }}
                />

            </Card>

            {showVatAdvice &&
                <Typography sx={{ textAlign: 'right', mt: 2, mr: 3 }} fontSize={'13px'}>
                    {translate('orders.messages.vatAdvice')}
                </Typography>
            }
        </>
    );
}