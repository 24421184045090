import { createAsyncThunk } from "@reduxjs/toolkit";
import * as basketServices from "src/services/basketServices";
import { BasketParams, CheckoutParams } from "src/@types/basket";
import { startCheckoutLoading, startLoading } from "./basket-slices";
import * as shopServices from "src/services/webshopServices";

//---- PUBLIC - BASKET ----------------------------------------------------------------------

const detailPublicBasket = createAsyncThunk(
    'basket/detailPublicBasket',
    async (_, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await basketServices.detailPublicBasket();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }

    }
);

const addItemPublicBasket = createAsyncThunk(
    'basket/addItemPublicBasket',
    async (options: BasketParams, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await basketServices.addItemPublicBasket(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }

    }
);

const removeItemPublicBasket = createAsyncThunk(
    'basket/removeItemPublicBasket',
    async (options: BasketParams, { rejectWithValue }) => {
        try {
            const { data } = await basketServices.removeItemPublicBasket(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }

    }
);

const checkoutPublicBasket = createAsyncThunk(
    'basket/checkoutPublicBasket',
    async (options: CheckoutParams, { dispatch, rejectWithValue }) => {
        dispatch(startCheckoutLoading());
        try {
            const { data, status } = await basketServices.checkout(options);

            if (!data && status >= 400) {
                throw new Error('Something went wrong');
            }

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getShopsBasket = createAsyncThunk(
    'basket/getShopsBasket',
    async (ids: string[], { rejectWithValue }) => {
        try {

            const { data, status } = await shopServices.searchShopByIds(ids);

            if (status >= 400)
                throw new Error('Something went wrong');

            return data;
        }
        catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const basketOperations = {
    removeItemPublicBasket,
    addItemPublicBasket,
    detailPublicBasket,
    checkoutPublicBasket,
    getShopsBasket
};

export default basketOperations;