import { Box, Button, Card, Divider, Modal, Stack, TextField, Typography } from "@mui/material";
import { useLocales } from "src/locales";
import { GreenStatusType, GreenStatusesArr, RedStatusType, RedStatusesArr, Request, YellowStatusType, YellowStatusesArr, ApprovedType, Alert, DEFAULT_ALERT, DEFAULT_WAREHOUSE_ACCEPT, WarrantyApprove, RequestType } from "src/@types/request";
import useResponsive from "src/hooks/useResponsive";
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import Label, { LabelColor } from "src/components/label";
import { DeleteForever } from '@mui/icons-material';
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { PATH_DASHBOARD } from "src/routes/paths";
import { RootState, dispatch, useSelector } from "src/redux/store";
import { requestOperations } from "src/redux/request";
import BacklogDeliveryDetail from "./BacklogDeliveryDetail";
import BacklogConfirmRequest from "./BacklogConfirmRequest";
import { DocData } from "src/@types/orders";
import { getAlert } from "src/services/requestsServices";
import SurveillanceDrawer from "./SurveillanceDrawer";
import { useParams } from "react-router";
import PartsDetail from "src/components/request-utils/PartsDetail";
import { AmendNotesAlert, ApproverNotesAlert, ClaimNotesAlert, fortyAlertCheck, FortyDaysAlert, FrequencyFeeAlert, HasSurveillanceAlert, InvoiceFeeAlert, RefusedNotesAlert } from "src/components/request-utils/FeesAndNotes";
import { handleUpload } from "src/appUtils/UploadHandler";
import { ApprovedTypeDetail, BigAndHeavyDetail, ContainerNameDetail, QuantityDetail } from "src/components/request-utils/LabelDetail";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { setStatus } from "src/redux/request/request-slices";
import { FileWithSection } from "src/@types/media";

interface BacklogRequestReturnDetailProps {
    request: Request,
    carrier: boolean,
    documents?: DocData[]
}

export default function BacklogRequestReturnDetail({ request, carrier, documents }: BacklogRequestReturnDetailProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const [confirmed, setConfirmed] = useState(false);

    const [refused, setRefused] = useState(false);

    const [openAmend, setOpenAmend] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);

    const [openPaid, setOpenPaid] = useState(false);

    const [alerts, setAlerts] = useState<Alert>(DEFAULT_ALERT);

    const { order } = useSelector((state: RootState) => state.orders);

    const { container } = useSelector((state: RootState) => state.container);

    const { idRequest } = useParams();

    const getIcon = () => {
        switch (request.requestType) {
            case 'NewPart':
                return <PublishedWithChangesIcon />;
            case 'DamagedPart':
                return <CancelIcon />;
            case 'WrongShipment':
                return <LocalShippingIcon />;
            case 'Core':
                return <KeyboardReturnIcon />;
            case 'Quality':
                return <FactCheckIcon />;
            case 'Warranty':
                return <BrokenImageIcon />;
            case "":
                return <></>;
        };
    };

    const [destination, setDestination] = useState('');

    const getStatusColor = (status: string): LabelColor => {

        let color = 'default';

        if (status) {
            if (GreenStatusesArr.includes(status as GreenStatusType)) color = "success";
            else if (RedStatusesArr.includes(status as RedStatusType)) color = "error";
            else if (YellowStatusesArr.includes(status as YellowStatusType)) color = "warning";
        }

        return color as LabelColor;
    };

    const isAR = useMemo(() => request.requestType === "Warranty" && request.warranty && request.warranty.invoices.length === 1, [request]);

    const fixFee = (fees: {
        repairFee: number,
        returnFee: number,
        surveillanceFee: number,
        invoiceDataFee: number
    }) => {

        const skipFees: RequestType[] = ["Warranty", "Core", "WrongShipment"];

        if (skipFees.includes(request.requestType))
            return {
                repairFee: 0,
                returnFee: 0,
                surveillanceFee: 0,
                invoiceDataFee: 0
            };

        if (destination !== "RepairFee") {
            fees.repairFee = 0;
        }

        return fees;
    };

    const handleSubmit = async (
        note: string,
        customFields?: Record<string, string>,
        fees?: {
            repairFee: number,
            returnFee: number,
            surveillanceFee: number,
            invoiceDataFee: number
        },
        warrantyObj?: WarrantyApprove,
        file?: FileWithSection[]
    ) => {

        let successText = "";

        if (fees)
            fees = fixFee(fees);

        if (request.requestType === "Warranty" && isAR && !refused) {
            let approvedProducts = request.items.map((v) => ({
                productId: v.product.id,
                approvedQuantity: v.requiredQuantity,
                bigAndHeavy: v.bigAndHeavy
            }));

            await dispatch(requestOperations.approveRequest({ id: request.id, note: "", approvedProducts, approvedType: destination === "" ? null : (destination as ApprovedType), customFields: customFields!, ...fees! })).unwrap();

            successText = translate('request.messages.approved');
        }
        if (request.requestType === "Warranty" && !isAR && confirmed) {
            if (destination === "T")
                await dispatch(requestOperations.warehouseAcceptRequest({ ...DEFAULT_WAREHOUSE_ACCEPT, id: request.id, containerId: null })).unwrap();
            if (destination === "N")
                await dispatch(requestOperations.rejectRequestBackoffice({ id: request.id, note: note, customFields: {} })).unwrap();
            if (destination === "E") {

                if (warrantyObj && file) {
                    customFields = {
                        "invoice": warrantyObj.invoice,
                        "invoiceDate": warrantyObj.invoiceDate
                    };
                    await handleUpload(file, request.id, "ReturnRequest");
                    await dispatch(requestOperations.approveRequest({ id: request.id, note: "", approvedType: null, customFields, approvedProducts: warrantyObj.approvedProducts, ...fees! })).unwrap();

                    successText = translate('request.messages.approved');
                }
            }

        }
        else if (refused) {
            await dispatch(requestOperations.rejectRequestBackoffice({ id: request.id, note: note, customFields: {} })).unwrap();

            successText = translate('request.messages.refused');
        }
        else if (confirmed) {

            let approvedProducts = request.items.map((v) => ({
                productId: v.product.id,
                approvedQuantity: v.requiredQuantity,
                bigAndHeavy: v.bigAndHeavy
            }));

            let textSwitch = false;

            if (request.requestType === "Core")
                await dispatch(requestOperations.approveRequest({ id: request.id, note: null, approvedType: "ReturnToSupplier", customFields: {}, approvedProducts, ...fees! })).unwrap();
            else if (request.requestType === "WrongShipment" && request.requestReason === "SurplusDelivered")
                await dispatch(requestOperations.approveRequest({ id: request.id, note: null, approvedType: "BackInStock", customFields: {}, approvedProducts, ...fees! })).unwrap();
            else if ((request.requestType === "DamagedPart" || request.requestType === "Quality") && destination === "RepairFee") {
                await dispatch(requestOperations.approveRequest({ id: request.id, note: null, approvedProducts, approvedType: (destination as ApprovedType), customFields: customFields!, ...fees! })).unwrap();
                //await dispatch(requestOperations.warehouseAcceptRequest({ ...DEFAULT_WAREHOUSE_ACCEPT, id: request.id, containerCode: null })).unwrap();
                textSwitch = true;
            }
            else if (request.requestReason === "MissingParts") {
                await dispatch(requestOperations.warehouseAcceptRequest({ ...DEFAULT_WAREHOUSE_ACCEPT, id: request.id, containerId: null })).unwrap();
            }
            else
                await dispatch(requestOperations.approveRequest({ id: request.id, note: "", approvedProducts, approvedType: destination === "" ? null : (destination as ApprovedType), customFields: customFields!, ...fees! })).unwrap();

            successText = translate(`request.messages.${textSwitch ? "accepted" : "approved"}`);
        }

        if (!request.deliveryOrganization)
            successText = translate('request.messages.approvedNoLabel');

        dispatch(setSuccessMessage({ text: successText, returnTo: carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.vendor.list }));
    };

    const handlePaidSubmit = async (note: string) => {
        await dispatch(requestOperations.paidRequest({ id: request.id, note, customFields: {} })).unwrap();

        dispatch(setSuccessMessage({ text: translate('request.messages.paidSuccess'), returnTo: carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.vendor.list }));
    };

    useEffect(() => {
        setDestination("");
    }, [confirmed]);

    useEffect(() => {
        if (idRequest === request.id && request.items.length === 1 && order && order.id === request.items[0].orderId
            && request.status === "Created" && request.requestType !== "WrongShipment" && request.requestType !== "Warranty") {
            getAlert({ orderId: order.id, productId: request.items[0].product.id, requestTypes: request.requestType }).then((val) => setAlerts(val.data));
        }
        else if (request.approverNotes) {
            setAlerts({
                frequencyFee: request.approverNotes.returnFee,
                invoiceFee: request.approverNotes.invoiceDataFee,
                hasSurveillance: false
            });
        }
    }, [idRequest, request, order]);

    const handlePickup = async () => {
        if (request.status === "RefusedReadyForPickUp")
            await dispatch(requestOperations.refusedPickedUpOp({ id: request.id, note: request.refusedNotes?.note || "", customFields: {} })).unwrap();
        else
            await dispatch(requestOperations.pickedUpRequest({ id: request.id, note: "", customFields: {} })).unwrap();

        dispatch(setSuccessMessage({ text: translate('request.pickupConfirm'), returnTo: carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.vendor.list }));
    };

    const handlePrint = async () => {
        await dispatch(requestOperations.refusedPrintedOp({ id: request.id, note: request.refusedNotes?.note || "", customFields: {} })).unwrap();

        dispatch(setSuccessMessage({ text: translate('request.messages.printedConfirm'), returnTo: carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.vendor.list }));
    };

    const handleLostClose = async (lost?: boolean) => {
        if (lost)
            await dispatch(requestOperations.lostRequest(request.id)).unwrap();

        else
            await dispatch(requestOperations.closeRequest(request.id)).unwrap();

        dispatch(setSuccessMessage({
            text: translate(lost ? 'request.messages.lostSuccess' : 'request.messages.closeSuccess'),
            returnTo: carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.vendor.list,
            callback: () => {
                if (lost)
                    dispatch(setStatus("Lost"));
                else
                    dispatch(setStatus("Closed"));
            }
        }));
    };

    const handleAmend = async (note: string) => {
        await dispatch(requestOperations.toBeAmend({ id: request.id, note: note || "", customFields: {} })).unwrap();

        dispatch(setSuccessMessage({ text: translate('request.messages.amendMessage'), returnTo: carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.vendor.list }));
    };

    const handleBackPrint = async () => { };

    const allQuantities = useMemo(() => {

        let counter = 0;

        if (request.items.length > 0) request.items.forEach((item) => counter += item.requiredQuantity || item.approvedQuantity);
        if (request.missingItems.length > 0) request.missingItems.forEach((item) => counter += item.requiredQuantity || item.approvedQuantity);
        if (request.exceededItems.length > 0) request.exceededItems.forEach((item) => counter += item.quantity);

        return counter;

    }, [request]);

    //----------------------------- CLAIM SECTION
    const [openClaim, setOpenClaim] = useState(false);

    const handleClaim = async (note: string) => {
        await dispatch(requestOperations.closeComplaint({ id: request.id, note: note })).unwrap();
        dispatch(setSuccessMessage({
            text: translate("request.messages.claimCloseSuccess"),
            returnTo: PATH_DASHBOARD.request.vendor.list,
            callback: () => dispatch(requestOperations.getRequest({ id: request.id }))
        }));
    };
    //-----------------------------

    const showFortyAlert = useMemo(() => {

        if (request.warranty && request.warranty.invoices.length === 1) return fortyAlertCheck(new Date(request.warranty.invoices[0].date), new Date(request.createdOn));

        return false;
    }, [request.createdOn, request.warranty]);

    return (
        <Box>

            <RefuseModal
                refused={refused}
                handleSubmit={handleSubmit}
                setRefused={setRefused}
            />

            <SurveillanceDrawer
                isOpen={openDrawer}
                onClose={() => setOpenDrawer(false)}
            />

            <AmendModal
                isOpen={openAmend}
                setIsOpen={setOpenAmend}
                handleAmend={handleAmend}
            />

            <PaidModal
                handleSubmit={handlePaidSubmit}
                paid={openPaid}
                setPaid={setOpenPaid}
            />

            <ClaimModal
                isOpen={openClaim}
                setIsOpen={setOpenClaim}
                handleSubmit={handleClaim}
            />

            <Card>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2
                    }}
                >
                    <Stack>
                        <Box
                            sx={{
                                display: 'inline-flex',
                                justifyContent: 'flex-start',
                                alignItems: "flex-start",
                                flexDirection: isDesktop ? "row" : "column",
                                gap: 1.5, mb: 1
                            }}
                        >
                            <Box>
                                <Typography variant="h4">
                                    {`${translate('returns.filter.idRequest')} ${request.prettyName}`}
                                </Typography>

                                <Typography variant="body2">
                                    {`${translate(`commons.createdOn`)} `}
                                    <b>{dayjs(request.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                                </Typography>
                            </Box>

                            <Label
                                color={getStatusColor(request.status)}
                                sx={{ textTransform: 'uppercase', ml: 2, mt: 1 }}
                            >
                                {translate(`commons.order.returnRequest.${request.status}`)}
                            </Label>
                        </Box>

                        <Typography
                            variant="h6"
                            sx={{
                                mb: isDesktop ? 1 : 0,
                                mt: isDesktop ? 1 : 3,
                                display: 'flex',
                                alignContent: 'center',
                                gap: 2
                            }}
                        >
                            {getIcon()}
                            {translate(`request.${request.requestType}`)}
                            {request.requestReason && ` - ${translate(`request.selectReason.${request.requestReason[0].toLowerCase() + request.requestReason.slice(1)}`)}`}
                        </Typography>
                    </Stack>

                    <Box
                        sx={{
                            display: 'flex', gap: 2,
                            alignContent: 'center',
                            flexDirection: isDesktop ? "row" : "column"
                        }}
                    >

                        {request.acceptNotes && request.acceptNotes.containerId && container &&
                            <ContainerNameDetail containerCode={container.name} />
                        }

                        {request.approverNotes && request.approverNotes.approvedType &&
                            <ApprovedTypeDetail approvedType={request.approverNotes.approvedType} />
                        }

                        {(request.items[0]?.bigAndHeavy || request.missingItems[0]?.bigAndHeavy || request.exceededItems[0]?.bigAndHeavy) &&
                            <BigAndHeavyDetail />
                        }

                        <QuantityDetail quantity={allQuantities} />

                    </Box>
                </Box>

                <Divider />

                <PartsDetail
                    items={request.requestType === "WrongShipment" ? request.missingItems : request.items}
                    clickToDetail
                    missingChanges={request.requestType === "WrongShipment" && request.requestReason === "MissingParts"}
                    exceededItems={request.exceededItems}
                    customFields={request.customFields}
                    showDepositRef={request.requestType === "Warranty" || request.requestType === "Core"}
                    icar={request.approverNotes?.customFields["stellantis-europe-returnrequestapprover-icar-request"] || ""}
                    sap={request.approverNotes?.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] || ""}
                />
            </Card>

            {request.refusedNotes && <RefusedNotesAlert refusedNotes={request.refusedNotes} />}

            {request.toBeAmendedNotes && request.status === "ToBeAmended" && <AmendNotesAlert amendNotes={request.toBeAmendedNotes} />}

            {alerts.frequencyFee > 0 && <FrequencyFeeAlert frequencyFee={alerts.frequencyFee} />}

            {alerts.invoiceFee > 0 && <InvoiceFeeAlert invoiceFee={alerts.invoiceFee} />}

            {alerts.hasSurveillance && <HasSurveillanceAlert />}

            {request.approverNotes && request.approverNotes.surveillanceFee > 0 && <ApproverNotesAlert approverNotes={request.approverNotes} />}

            {request.customerClaimNotes && <ClaimNotesAlert isVendor customerClaimNotes={request.customerClaimNotes} vendorClaimNotes={request.vendorClaimNotes} />}

            {showFortyAlert && <FortyDaysAlert />}

            {!confirmed ?
                <BacklogDeliveryDetail
                    handlePaid={() => setOpenPaid(true)}
                    handleLostClose={(v) => handleLostClose(v)}
                    request={request}
                    setConfirmed={setConfirmed}
                    setRefused={setRefused}
                    handlePickup={handlePickup}
                    carrier={carrier}
                    handleBackPrint={handleBackPrint}
                    handlePrint={handlePrint}
                    documents={documents}
                    handleSubmit={handleSubmit}
                    handleAmend={() => setOpenAmend(true)}
                    isAr={isAR}
                    handleClaim={() => setOpenClaim(true)}
                />
                :
                <BacklogConfirmRequest
                    isAr={isAR}
                    handleSubmit={handleSubmit}
                    request={request}
                    setConfirmed={setConfirmed}
                    destination={destination}
                    onChangeDestination={(v) => setDestination(v)}
                    fees={alerts}
                />
            }

            <Box display={'flex'}>
                <Typography sx={{ mx: "auto", mt: 3 }} fontSize={"12px"}>
                    {`${translate('request.lastModified')} `}
                    <b>{dayjs(request.modifiedOn ? request.modifiedOn : request.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                </Typography>
            </Box>
        </Box>
    );
}
//--------------------------------------------------------------------------------

interface RefuseModalProps {
    refused: boolean,
    handleSubmit: (note: string) => void,
    setRefused: (v: boolean) => void
}

function RefuseModal({ handleSubmit, refused, setRefused }: RefuseModalProps) {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    useEffect(() => setNote(""), [refused]);

    return (
        <Modal
            open={refused}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography variant="h6" textAlign={'center'}>{`${translate(`request.refuse`)}`}</Typography>
                    <Typography variant="body1" textAlign={'center'}>{`${translate(`request.messages.refuseDesc`)}`}</Typography>
                </Box>
                <TextField
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ my: 2 }}
                    multiline
                    rows={2}
                    fullWidth
                    variant="outlined"
                    placeholder={`${translate(`request.insertNote`)}`}
                />
                <Box sx={{ mt: 2, justifyContent: 'space-between', ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'default' : 'column-reverse' }}>

                    <Button variant="outlined" color="inherit" sx={{ borderRadius: '100px' }} onClick={() => { setRefused(false); setNote(""); }}>
                        {`${translate(`commons.cancel`)}`}
                    </Button>

                    <Button variant="contained" disabled={!note} startIcon={<DeleteForever />} color="error" sx={{ borderRadius: '100px' }} onClick={() => { handleSubmit(note); setRefused(false); }}>
                        {`${translate('commons.confirm')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}

//--------------------------------------------------------------------------------

interface AmendModalProps {
    isOpen: boolean,
    handleAmend: (note: string) => void,
    setIsOpen: (v: boolean) => void
}

function AmendModal({ handleAmend, isOpen, setIsOpen }: AmendModalProps) {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    useEffect(() => setNote(""), [isOpen]);

    return (
        <Modal
            open={isOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography variant="h6" textAlign={'center'}>{`${translate(`request.messages.amendTitle`)}`}</Typography>
                    <Typography variant="body1" textAlign={'center'}>{`${translate(`request.messages.amendDesc`)}`}</Typography>
                </Box>
                <TextField
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ my: 2 }}
                    multiline
                    rows={2}
                    fullWidth
                    variant="outlined"
                    autoFocus
                    placeholder={`${translate(`request.insertNote`)}`}
                />
                <Box sx={{ mt: 2, justifyContent: 'space-between', ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'default' : 'column-reverse' }}>

                    <Button
                        variant={"outlined"}
                        color={"inherit"}
                        sx={{ borderRadius: '100px' }}
                        onClick={() => { setIsOpen(false); setNote(""); }}
                    >
                        {`${translate(`commons.cancel`)}`}
                    </Button>

                    <Button
                        variant={"contained"}
                        disabled={!note}
                        color={"primary"}
                        sx={{ borderRadius: '100px' }}
                        onClick={() => { handleAmend(note); setIsOpen(false); }}
                    >
                        {`${translate('commons.confirm')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}

//--------------------------------------------------------------------------------

interface PaidModalProps {
    paid: boolean,
    handleSubmit: (note: string) => void,
    setPaid: (v: boolean) => void
}

function PaidModal({ handleSubmit, paid, setPaid }: PaidModalProps) {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    useEffect(() => setNote(""), [paid]);

    return (
        <Modal
            open={paid}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography variant="h6" textAlign={'center'}>{`${translate(`request.detail.addCredit`)}`}</Typography>
                    <Typography variant="body1" textAlign={'center'}>{`${translate(`request.messages.creditNoteDesc`)}`}</Typography>
                </Box>
                <TextField
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ my: 2 }}
                    fullWidth
                    variant="outlined"
                    placeholder={`${translate(`request.insertNote`)}`}
                />
                <Box sx={{ mt: 2, justifyContent: 'space-between', ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'default' : 'column-reverse' }}>

                    <Button variant="outlined" color="error" sx={{ borderRadius: '100px' }} onClick={() => { setPaid(false); setNote(""); }}>
                        {`${translate(`commons.cancel`)}`}
                    </Button>

                    <Button variant="contained" disabled={!note} color="success" sx={{ borderRadius: '100px' }} onClick={() => { handleSubmit(note); setPaid(false); }}>
                        {`${translate('commons.confirm')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>);
}

//--------------------------------------------------------------------------------

interface ClaimModalProps {
    isOpen: boolean,
    setIsOpen: (v: boolean) => void,
    handleSubmit: (note: string) => Promise<void>
}

function ClaimModal({ handleSubmit, isOpen, setIsOpen }: ClaimModalProps) {

    const isDesktop = useResponsive('up', "lg");

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    useEffect(() => setNote(""), [isOpen]);

    return (
        <Modal open={isOpen}>

            <Card
                sx={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: isDesktop ? 5 : 2,
                    maxWidth: "45%", minWidth: 450
                }}
            >
                <Typography variant="h6" textAlign={'center'}>
                    {`${translate("request.messages.claimSubmit")}`}
                </Typography>

                <TextField
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ my: 2 }}
                    multiline
                    rows={3}
                    fullWidth
                    variant={"outlined"}
                    placeholder={`${translate(`request.insertNote`)}`}
                />

                <Box
                    sx={{
                        mt: 2,
                        justifyContent: 'space-between',
                        ml: isDesktop ? 'auto' : 0,
                        display: 'flex',
                        gap: 1,
                        flexDirection: isDesktop ? 'default' : 'column-reverse'
                    }}
                >

                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => {
                            setIsOpen(false);
                            setNote("");
                        }}
                    >
                        {`${translate(`commons.cancel`)}`}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => {
                            handleSubmit(note);
                            setIsOpen(false);
                        }}
                    >
                        {`${translate('commons.confirm')}`}
                    </Button>

                </Box>

            </Card>

        </Modal>
    );
}
