import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoading, startLoadingBrand } from "./brand-slices";
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import { Brand, NewBrand } from "src/@types/brand";
import * as brandServices from '../../services/brandServices';

const getBrandList = createAsyncThunk(
    'brand/getBrandList',
    async (isAdmin: boolean, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = isAdmin ? await brandServices.adminSearch() : await brandServices.publicSearch();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return [data, isAdmin];
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const createBrand = createAsyncThunk(
    'brand/createBrand',
    async (brand: NewBrand, { rejectWithValue, dispatch }) => {
        dispatch(startLoadingBrand());
        try {
            const { data } = await brandServices.create(nullifyEmptyKeys(brand));

            if (!data) {
                throw new Error('Something went wrong');
            }
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateBrand = createAsyncThunk(
    'brand/updateBrand',
    async (brand: Brand, { rejectWithValue, dispatch }) => {
        dispatch(startLoadingBrand());
        try {
            const { status } = await brandServices.update(brand);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.message);
        }
    }
);

const getBrand = createAsyncThunk(
    'brand/getBrand',
    async (brandId: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoadingBrand());
        try {
            const { data } = await brandServices.detail(brandId);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableBrand = createAsyncThunk(
    'brand/enableBrand',
    async (brandId: string, { rejectWithValue, dispatch, }) => {
        try {
            const { status } = await brandServices.enable(brandId);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return status;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const disableBrand = createAsyncThunk(
    'brand/disableBrand',
    async (brandId: string, { rejectWithValue, dispatch, }) => {
        try {
            const { status } = await brandServices.disable(brandId);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return status;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getBrandLogs = createAsyncThunk(
    'brand/getBrandLogs',
    async (options: any, { rejectWithValue }) => {
        try {
            const { data } = await brandServices.logs(options);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const brandOperations = {
    getBrandList,
    createBrand,
    updateBrand,
    getBrand,
    enableBrand,
    disableBrand,
    getBrandLogs
};

export default brandOperations;
