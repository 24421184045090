import * as Yup from 'yup';
import { IBANRegExp, emailRegExp, faxRegExp, phoneNumberRegExp, zipCodeRegExp } from 'src/constants';
import { DEFAULT_GENERIC_ORGANIZATION, GenericOrganization, GenericOrganizationEditWithFather } from 'src/@types/organizations';

export const typesOfOrganization = ["Company", "Branch", "Hub", "Holding"];

export const globalSchema = Yup.object().shape({
    name: Yup.string().trim().required('commons.validation.requiredField'),
    type: Yup.string().trim().required('commons.validation.requiredField').oneOf(["Company", "Branch", "Hub", "Holding"]), //, 'commons.validation.typeOfType'
    parentType: Yup.string().trim().test('type', 'commons.validation.requiredField', function (type) {
        const field = this?.parent.type;

        if (field && field !== 'Hub') {
            return type !== '';
        }

        return true;
    }),
    parentId: Yup.string().trim().test('type', 'commons.validation.requiredField', function (type) {
        const field = this?.parent.type;

        if (field && field !== 'Hub') {
            return type !== '';
        }

        return true;
    }),
    address: Yup.object().shape({
        zipCode: Yup.string().nullable().transform((_, val) => val ? val : null).matches(zipCodeRegExp, 'commons.validation.wrongZip'),
        country: Yup.string().trim().required('commons.validation.requiredField'),
    }),
    contact: Yup.object().shape({
        privateEmail: Yup.string().nullable().transform((_, val) => val ? val : null).matches(emailRegExp, 'commons.validation.wrongEmail'),
        businessEmail: Yup.string().nullable().transform((_, val) => val ? val : null).matches(emailRegExp, 'commons.validation.wrongEmail'),
        privatePhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        businessPhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        privateMobilePhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        businessMobilePhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        fax: Yup.string().nullable().transform((_, val) => val ? val : null).matches(faxRegExp, 'commons.validation.wrongFax'),
    }),
    payment: Yup.object().shape({
        iban: Yup.string().nullable().transform((_, val) => val ? val : null).matches(IBANRegExp, 'commons.validation.wrongIban'),
    })
});

export const step1Schema = Yup.object().shape({
    name: Yup.string().trim().required('commons.validation.requiredField'),
    timeZoneId: Yup.string().trim().required('user.validation.culture'),
    preferredCurrency: Yup.string().trim().required('user.validation.culture'),
    type: Yup.string().trim().required('commons.validation.requiredField').oneOf(["Company", "Branch", "Hub", "Holding"], 'commons.validation.typeOfType'),
    parentId: Yup.string().trim().test('type', 'commons.validation.requiredField', function (type) {
        const field = this?.parent.type;

        if (field && field !== 'Hub') {
            return type !== '';
        }

        return true;
    }),
    parentType: Yup.string().trim().test('type', 'commons.validation.requiredField', function (type) {
        const field = this?.parent.type;

        if (field && field !== 'Hub') {
            return type !== '';
        }

        return true;
    })
});

export const step2Schema = Yup.object().shape({
    address: Yup.object().shape({
        zipCode: Yup.string().nullable().transform((_, val) => val ? val : null).matches(zipCodeRegExp, 'commons.validation.wrongZip'),
        country: Yup.string().trim().required('commons.validation.requiredField')
    })
});

export const step3Schema = Yup.object().shape({
    contact: Yup.object().shape({
        privateEmail: Yup.string().nullable().transform((_, val) => val ? val : null).matches(emailRegExp, 'commons.validation.wrongEmail'),
        businessEmail: Yup.string().nullable().transform((_, val) => val ? val : null).matches(emailRegExp, 'commons.validation.wrongEmail'),
        privatePhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        businessPhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        privateMobilePhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        businessMobilePhone: Yup.string().nullable().transform((_, val) => val ? val : null).matches(phoneNumberRegExp, 'commons.validation.wrongPhone'),
        fax: Yup.string().nullable().transform((_, val) => val ? val : null).matches(faxRegExp, 'commons.validation.wrongFax'),
    })
});

export const step4Schema = Yup.object().shape({
    payment: Yup.object().shape({
        iban: Yup.string().nullable().transform((_, val) => val ? val : null).matches(IBANRegExp, 'commons.validation.wrongIban'),
    })
});

export function defaultAddress(address: any): any {
    return {
        address: address?.address || DEFAULT_GENERIC_ORGANIZATION.address?.address,
        zipCode: address?.zipCode || DEFAULT_GENERIC_ORGANIZATION.address?.zipCode,
        district: address?.district || DEFAULT_GENERIC_ORGANIZATION.address?.district,
        country: address?.country || DEFAULT_GENERIC_ORGANIZATION.address?.country,
        city: address?.city || DEFAULT_GENERIC_ORGANIZATION.address?.city,
    };
}

export function defaultContact(contact: any): any {
    return {
        privateEmail: contact?.privateEmail || DEFAULT_GENERIC_ORGANIZATION.contact?.privateEmail,
        businessEmail: contact?.businessEmail || DEFAULT_GENERIC_ORGANIZATION.contact?.businessEmail,
        privatePhone: contact?.privatePhone || DEFAULT_GENERIC_ORGANIZATION.contact?.privatePhone,
        businessPhone: contact?.businessPhone || DEFAULT_GENERIC_ORGANIZATION.contact?.businessPhone,
        privateMobilePhone: contact?.privateMobilePhone || DEFAULT_GENERIC_ORGANIZATION.contact?.privateMobilePhone,
        businessMobilePhone: contact?.businessMobilePhone || DEFAULT_GENERIC_ORGANIZATION.contact?.businessMobilePhone,
        fax: contact?.fax || DEFAULT_GENERIC_ORGANIZATION.contact?.fax
    };
}

export function defaultPayment(payment: any): any {
    return {
        iban: payment?.iban || DEFAULT_GENERIC_ORGANIZATION.payment?.iban,
        mode: payment?.mode || DEFAULT_GENERIC_ORGANIZATION.payment?.mode,
        creditLimit: payment?.creditLimit || DEFAULT_GENERIC_ORGANIZATION.payment?.creditLimit,
        balance: payment?.balance || DEFAULT_GENERIC_ORGANIZATION.payment?.balance
    };
}

function toParent(parent: GenericOrganization): any {

    const formatted = {
        id: parent.id || "",
        externalId: parent.externalId,
        name: parent.name,
        brands: parent.brands,
        region: parent.regions,
        status: parent.status,
        creditbalance: parent.payment?.balance,
        creditLimit: parent.payment?.creditLimit,
        createdOn: parent.createdOn,
        type: parent.type
    };

    if (formatted.type === "Hub") {
        return formatted;
    }

    return { ...formatted, parentId: parent.parentId };
}

export function defaultOrganization(organization: GenericOrganizationEditWithFather, parent: GenericOrganization): GenericOrganizationEditWithFather {

    return {
        id: organization?.id || DEFAULT_GENERIC_ORGANIZATION.id,
        type: organization?.type || DEFAULT_GENERIC_ORGANIZATION.type,
        status: organization?.status || DEFAULT_GENERIC_ORGANIZATION.status,
        createdOn: organization?.createdOn || DEFAULT_GENERIC_ORGANIZATION.createdOn,
        country: organization?.country || DEFAULT_GENERIC_ORGANIZATION.country,
        name: organization?.name || DEFAULT_GENERIC_ORGANIZATION.name,
        externalId: organization?.externalId || DEFAULT_GENERIC_ORGANIZATION.externalId,
        vat: organization?.vat || DEFAULT_GENERIC_ORGANIZATION.vat,
        address: defaultAddress(organization?.address),
        contact: defaultContact(organization?.contact),
        payment: defaultPayment(organization?.payment),
        customFields: organization?.customFields || DEFAULT_GENERIC_ORGANIZATION.customFields,
        tags: organization?.tags || DEFAULT_GENERIC_ORGANIZATION.tags,
        brands: organization?.brands || DEFAULT_GENERIC_ORGANIZATION.brands,
        regions: organization?.regions || DEFAULT_GENERIC_ORGANIZATION.regions,
        parentId: organization?.parentId || DEFAULT_GENERIC_ORGANIZATION.parentId,
        selectedBrands: organization?.selectedBrands || DEFAULT_GENERIC_ORGANIZATION.selectedBrands,
        parentType: parent.type,
        parent: toParent(parent),
        note: (organization?.note && organization.note.length) > 0 ? organization?.note : [{ culture: "en", text: "" }],
        hubId: organization.hubId,
        parentName: organization.parentName || DEFAULT_GENERIC_ORGANIZATION.parentName,
        timeZoneId: organization.timeZoneId || DEFAULT_GENERIC_ORGANIZATION.timeZoneId,
        preferredCurrency: organization.preferredCurrency || DEFAULT_GENERIC_ORGANIZATION.preferredCurrency
    };
}