import { Box, Stack, Badge, Button, Drawer, Divider, IconButton, Typography, ButtonGroup, TextField, MenuItem, FormControlLabel, Switch, FormGroup, Tooltip, AutocompleteRenderOptionState } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { NAV } from 'src/config';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomFieldSidebarRenderer from 'src/utils/CustomFieldSidebarRenderer';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocales } from 'src/locales';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { ClusterPermissionTypes, OrganizationPermissionTypes } from 'src/@types/permissions';
import { SidebarListFilters, GenericPagedResponse, InfiniteScrollResponse } from 'src/@types/list';
import { isArray, isEqual } from 'lodash';
import { RHFSelect } from 'src/components/hook-form';
import SidebarAutocomplete from './SidebarAutocomplete';
import SidebarSlider, { getSliderFilterNames } from './SidebarSlider';
import { AxiosResponse } from 'axios';
import { SelectItem, Tag } from 'src/@types/commons';
import SidebarMultiSelect from './SidebarMultiSelect';
import InfiniteAutocomplete, { AltInfiniteAutocomplete } from 'src/appComponents/InfiniteAutocomplete';
import { ToolbarActionButtonStyle } from '../utils/style';
import SidebarInputRange from './SidebarInputRange';

type GenericFilterSidebarProps = {
    isOpen: boolean,
    onOpen: VoidFunction,
    onClose: VoidFunction,
    onFilter: VoidFunction,
    onResetAll: VoidFunction,
    resetTrigger: boolean,
    filterValues: any,
    defaultFilters: any,
    filterList: SidebarListFilters[],
    isDefault: (filter: any, controller?: any) => boolean,
    resetFormElement: string,
    setResetFormElement: (value: string) => void,
    setShowSummary?: (value: boolean) => void,
    customfieldContext?: string[]
}

export default function GenericFilterSidebar({
    isOpen,
    onOpen,
    onClose,
    onFilter,
    onResetAll,
    defaultFilters,
    resetTrigger,
    filterValues,
    filterList,
    isDefault,
    resetFormElement,
    setResetFormElement,
    setShowSummary,
    customfieldContext
}: GenericFilterSidebarProps) {

    const { setValue, getValues } = useFormContext();

    const { translate } = useLocales();

    const [sidebarValues, setSidebarValues] = useState(filterValues);

    const [sidebarValuesAfterFilter, setSidebarValuesAfterFilter] = useState(filterValues);

    const [inEditAutocomplete, setInEditAutocomplete] = useState<boolean>(false);

    useEffect(() => {
        if (resetTrigger) setSidebarValues(defaultFilters);
    }, [defaultFilters, resetTrigger]);

    const handleChangeValues = useCallback((sidebarValues: any, newValue: any, name: string, nameId?: string) => {

        const valuesCopy = JSON.parse(JSON.stringify(sidebarValues));

        if (filterList.find((filter) => filter.name === name && filter.type === "MultiSelect")) {

            if (isArray(newValue)) {
                const indexOfAll = newValue.indexOf('All');

                if (indexOfAll >= 0) {
                    if (indexOfAll === newValue.length - 1) valuesCopy[name] = defaultFilters[name];
                    else {
                        newValue.splice(indexOfAll, 1);
                        valuesCopy[name] = newValue;
                    }
                } else if (newValue.length > 0) {
                    valuesCopy[name] = newValue;
                } else valuesCopy[name] = defaultFilters[name];
            }
        } else if (nameId) {
            valuesCopy[nameId] = newValue.id;

            valuesCopy[name] = newValue;
        } else {
            valuesCopy[name] = newValue;
        }

        setSidebarValues(valuesCopy);
    }, [defaultFilters, filterList]);

    useEffect(() => {
        if (resetFormElement) {

            if (filterList.find((filter) => filter.name === resetFormElement && (filter.type === "Slider" || filter.type === "InputRange"))) {
                const [minLabel, maxLabel] = getSliderFilterNames(resetFormElement);

                handleChangeValues(sidebarValues, [defaultFilters[minLabel], defaultFilters[maxLabel]], resetFormElement);
            } else {
                handleChangeValues(sidebarValues, defaultFilters[resetFormElement], resetFormElement);
            }
            setResetFormElement("");
        }
    }, [resetFormElement, handleChangeValues, sidebarValues, setResetFormElement, defaultFilters, filterList]);

    useEffect(() => {
        if (!isEqual(filterValues, sidebarValues)) {
            setSidebarValues(filterValues);
            setSidebarValuesAfterFilter(filterValues);
        }
    }, [filterValues]);

    const setAllValues = () => {
        for (const [key, value] of Object.entries(sidebarValues)) {

            if (key === "all") {
                setValue(key, defaultFilters[key]);
            } else {
                const formValue = getValues(key);

                if (!isEqual(value, formValue)) {
                    setValue(key, value);
                }
            }
        }
        setSidebarValuesAfterFilter(sidebarValues);
    };

    const handleOnFilter = () => {
        setAllValues();
        onFilter();
        if (setShowSummary) setShowSummary(true);
    };

    const handleCloseFilter = () => {

        if (resetTrigger) {
            setSidebarValues(defaultFilters);
        } else if (!isEqual(sidebarValues, sidebarValuesAfterFilter)) {
            setSidebarValues(sidebarValuesAfterFilter);
        }
        onClose();
    };

    const handleOnResetAll = () => {
        setSidebarValues(defaultFilters);
        onResetAll();
    };

    const dotControllerFilters = useMemo(() => {
        if (sidebarValuesAfterFilter) return sidebarValuesAfterFilter;

        return defaultFilters;
    }, [defaultFilters, sidebarValuesAfterFilter]);

    const filtersComponents = useMemo(() => ({
        Section: (label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            const component: JSX.Element = (
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                    {label}
                </Typography>
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        TextField: (name: string, label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            const component: JSX.Element = (
                <TextField
                    name={name}
                    label={label}
                    variant={"outlined"}
                    value={sidebarValues[name]}
                    onChange={(event) => handleChangeValues(sidebarValues, event.target.value, name)}
                />
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        Select: (name: string, label: string, options: SelectItem[], permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            const component: JSX.Element = (
                <RHFSelect
                    name={name}
                    label={label}
                    value={sidebarValues[name]}
                    onChange={(event) => handleChangeValues(sidebarValues, event.target.value, name)}
                >
                    <MenuItem key={"all"} value={"all"}>
                        {`${translate('commons.all')}`}
                    </MenuItem>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RHFSelect>
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        MultiSelect: (name: string, label: string, options: SelectItem[], permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            const component: JSX.Element = (
                <SidebarMultiSelect
                    name={name}
                    label={label}
                    options={options}
                    sidebarValues={sidebarValues}
                    defaultFilterValues={defaultFilters}
                    handleChangeValues={handleChangeValues}
                />
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        Slider: (name: string, label: string, stepValue: number, stepsNumber: number, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            const component: JSX.Element = (
                <SidebarSlider
                    name={name}
                    label={label}
                    sidebarValues={sidebarValues}
                    defaultFilterValues={defaultFilters}
                    stepValue={stepValue}
                    stepsNumber={stepsNumber}
                    handleChangeValues={handleChangeValues}
                />
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        InputRange: (name: string, label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            const component: JSX.Element = (
                <SidebarInputRange
                    name={name}
                    label={label}
                    sidebarValues={sidebarValues}
                    handleChangeValues={handleChangeValues}
                />
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        SingleSwitch: (switchInfo: { name: string, label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[] }) => {

            const component: JSX.Element = (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                        {switchInfo.label}
                    </Typography>
                    <Switch
                        onChange={(_, checked) => handleChangeValues(sidebarValues, checked, switchInfo.name)}
                        checked={sidebarValues[switchInfo.name]}
                    />
                </Box>
            );

            if (switchInfo.permissions) return (
                <PermissionBasedGuard permissions={switchInfo.permissions} key={"switch." + switchInfo.name}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        SwitchGroup: (switchesInfo: { name: string, label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[] }[]) => {

            const component = (info: any) => (
                <FormControlLabel
                    control={
                        <Switch
                            onChange={(event) => handleChangeValues(sidebarValues, event.target.checked, info.name)}
                            checked={sidebarValues[info.name]}
                        />
                    }
                    label={info.label}
                />);

            const anyPermissions = switchesInfo.find((info) => info.permissions);

            if (anyPermissions) return (
                <FormGroup>
                    {switchesInfo.map((info, index) =>
                        <PermissionBasedGuard permissions={info.permissions} key={"switch." + index}>
                            {component(info)}
                        </PermissionBasedGuard>
                    )}
                </FormGroup>
            );

            return (
                <FormGroup>
                    {switchesInfo.map((info, index) =>
                        <div key={"switch." + index}>
                            {component(info)}
                        </div>
                    )}
                </FormGroup>
            );
        },
        PagedAutocomplete: (
            name: string,
            nameId: string,
            label: string,
            renderOptionComponent: ((
                props: React.HTMLAttributes<HTMLLIElement> & { key: any; },
                option: any,
                state: AutocompleteRenderOptionState,
                ownerState: any
            ) => React.ReactNode),
            operation: (filters: any) => Promise<GenericPagedResponse<any>>,
            permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]
        ) => {

            const component: JSX.Element = (
                <InfiniteAutocomplete<any>
                    searchCallBack={operation}

                    getOptionLabel={(option) => option?.name ?? "---"}

                    isOptionEqualToValue={(option, value) => option.id === value.id}

                    filterOptions={(options) => options}

                    onChange={(_, v) => {
                        if (v && typeof v !== "string")
                            handleChangeValues(sidebarValues, v, name, nameId);
                        else handleChangeValues(sidebarValues, defaultFilters[name], name, nameId);
                    }}

                    renderOption={renderOptionComponent}

                    value={sidebarValues[name]?.id ? sidebarValues[name] : null}

                    renderInput={(params) =>
                        <TextField
                            label={label}
                            {...params}
                            InputProps={{ ...params.InputProps }}
                        />
                    }

                    customPageSize={8}
                />
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        InfiniteAutocomplete: (
            name: string,
            nameId: string,
            label: string,
            renderOptionComponent: ((
                props: React.HTMLAttributes<HTMLLIElement> & { key: any; },
                option: any,
                state: AutocompleteRenderOptionState,
                ownerState: any
            ) => React.ReactNode),
            operation: (filters: any) => Promise<InfiniteScrollResponse<any>>,
            permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]
        ) => {

            const component: JSX.Element = (
                <AltInfiniteAutocomplete<any>
                    searchCallBack={operation}

                    getOptionLabel={(option) => option?.name ?? "---"}

                    isOptionEqualToValue={(option, value) => option.id === value.id}

                    filterOptions={(options) => options}

                    onChange={(_, v) => {
                        if (v && typeof v !== "string")
                            handleChangeValues(sidebarValues, v, name, nameId);
                        else handleChangeValues(sidebarValues, defaultFilters[name], name, nameId);
                    }}

                    renderOption={renderOptionComponent}

                    value={sidebarValues[name]?.id ? sidebarValues[name] : null}

                    renderInput={(params) =>
                        <TextField
                            label={label}
                            {...params}
                            InputProps={{ ...params.InputProps }}
                        />
                    }
                />
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        },
        TagsAutocomplete: (name: string, label: string,
            service: (tag: string, size: number) => Promise<AxiosResponse<Tag[]>>,
            permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            const component: JSX.Element = (
                <SidebarAutocomplete
                    name={name}
                    label={label}
                    defaultAutocompleteValues={sidebarValues[name]?.length ? sidebarValues[name] : getValues(name)}
                    filterValuesFromForm={sidebarValues}
                    handleChangeValues={handleChangeValues}
                    inEditTags={inEditAutocomplete}
                    setInEditTags={setInEditAutocomplete}
                    service={service}
                />
            );

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    {component}
                </PermissionBasedGuard>
            );

            return (component);
        }
    }), [defaultFilters, getValues, handleChangeValues, inEditAutocomplete, sidebarValues, translate]);

    return (
        <>
            <Tooltip title={`${translate('list.tooltip.sidebar')}`}>
                <IconButton
                    onClick={onOpen}
                    size={'large'}
                    sx={{ ...ToolbarActionButtonStyle }}
                >
                    <FilterListIcon sx={{ mx: 1 }} />
                </IconButton>
            </Tooltip>

            <Drawer
                anchor="right"
                open={isOpen}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: NAV.W_DASHBOARD },
                    style: { boxShadow: 'none' }
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 1, py: 2 }}
                >
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {translate('commons.filters')}
                    </Typography>

                    <IconButton onClick={handleCloseFilter}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>
                    <Stack spacing={2.5} sx={{ p: 2.5 }}>

                        {filterList.map((filter, index) => {

                            let JSXFilter: JSX.Element = <></>;

                            const { type, label, permissions, name, options, renderOptionComponent, switchesInfo,
                                pagedOperation, infiniteOperation, nameId, tagsService, stepValue, stepsNumber } = filter;

                            switch (type) {
                                case 'Section':
                                    JSXFilter = filtersComponents.Section(label, permissions);
                                    break;
                                case 'TextField':
                                    JSXFilter = filtersComponents.TextField(name, label, permissions);
                                    break;
                                case 'Select':
                                    if (options)
                                        JSXFilter = filtersComponents.Select(name, label, options, permissions);
                                    break;
                                case 'MultiSelect':
                                    if (options)
                                        JSXFilter = filtersComponents.MultiSelect(name, label, options, permissions);
                                    break;
                                case 'PagedAutocomplete':
                                    if (renderOptionComponent && pagedOperation && nameId)
                                        JSXFilter = filtersComponents.PagedAutocomplete(name, nameId, label, renderOptionComponent, pagedOperation, permissions);
                                    break;
                                case 'InfiniteAutocomplete':
                                    if (renderOptionComponent && infiniteOperation && nameId)
                                        JSXFilter = filtersComponents.InfiniteAutocomplete(name, nameId, label, renderOptionComponent, infiniteOperation, permissions);
                                    break;
                                case 'TagsAutocomplete':
                                    if (tagsService)
                                        JSXFilter = filtersComponents.TagsAutocomplete(name, label, tagsService, permissions);
                                    break;
                                case 'Slider':
                                    if (stepValue && stepsNumber)
                                        JSXFilter = filtersComponents.Slider(name, label, stepValue, stepsNumber, permissions);
                                    break;
                                case 'InputRange':
                                    JSXFilter = filtersComponents.InputRange(name, label, permissions);
                                    break;
                                case 'SingleSwitch':
                                    if (switchesInfo)
                                        JSXFilter = filtersComponents.SingleSwitch(switchesInfo[0]);
                                    break;
                                case 'SwitchGroup':
                                    if (switchesInfo)
                                        JSXFilter = filtersComponents.SwitchGroup(switchesInfo);
                                    break;
                            }

                            return <Stack key={name + "." + index} spacing={1}>{JSXFilter}</Stack>;
                        })}

                        {customfieldContext &&
                            <CustomFieldSidebarRenderer
                                context={customfieldContext}
                                filterValues={sidebarValues}
                                setFilterValues={setSidebarValues}
                            />
                        }

                    </Stack>
                </Scrollbar>

                <Box sx={{ p: 1, pb: 2, position: 'relative' }}>

                    <Badge
                        color="error"
                        variant="dot"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={isDefault(sidebarValues, dotControllerFilters)}
                        sx={{ width: 1, right: 25, top: 20, position: 'absolute' }}
                    />

                    <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnResetAll}
                            startIcon={<Iconify icon="ic:round-clear-all" />}
                            sx={{ px: 1 }}
                        >
                            {translate('commons.clear')}
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnFilter}
                            startIcon={<Iconify icon="eva:search-fill" />}
                            disabled={inEditAutocomplete}
                            sx={{ px: 1 }}
                        >
                            {translate('commons.apply')}
                        </Button>
                    </ButtonGroup>

                </Box>

            </Drawer>
        </>
    );
}