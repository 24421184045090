import { GenericPagedResponse } from "./list";

export type LogsFilters = {
  [key: string]: any
  from?: string,
  to?: string,
  eventType?: string,
  pageIndex: number,
  pageSize: number
}

export type LogsItem = {
  eventType: string,
  eventDate: Date,
  loggedUser?: {
    firstName: string,
    lastName: string,
    id: string,
  }
}

export type LogsData = {
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  pageIndex: number,
  pageSize: number,
  totalCount: number,
  totalPages: number,
  items: LogsItem[]
}

export type FetchDataFuncArgs = {
  [key: string]: any
  pageIndex: number,
  pageSize: number,
  id: string,
  startDate?: string,
  endDate?: string,
  eventType?: string
}

//----------------------------------------------------------------------------------------------------------

export const DEFAULT_LOGS_DATA: GenericPagedResponse<LogsItem> = {
  pageIndex: 0,
  pageSize: 10,
  totalCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  totalPages: 0,
  items: []
};