import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Card, Divider, Grid, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import { GridColDef, GridRowIdGetter, GridSortModel } from '@mui/x-data-grid';
import { DeleteForever, Search } from '@mui/icons-material';
import useResponsive from 'src/hooks/useResponsive';
import { dispatch } from 'src/redux/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import useTable from 'src/appHooks/useTable';
import SaveModal from 'src/components/modals/SaveModal';
import AddIcon from '@mui/icons-material/Add';
import { noData } from 'src/components/empty-content/EmptyContent';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { PartSummaryComponentProps, Rule, RuleTabs } from 'src/@types/rules';
import { cloneDeep, debounce, difference } from 'lodash';
import { setSuccessMessage } from 'src/redux/modal/modal-slices';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { INFINITE_SCROLL_LIST_FILTERS, InfiniteScrollListFilters, InfiniteScrollResponse, SequenceToken } from 'src/@types/list';
import GenericListFooter from 'src/utils/list/utils/GenericListFooter';
import { RulesClearModal } from '../modal/RulesClearModal';
import { AxiosResponse } from 'axios';
import { AsyncThunk } from '@reduxjs/toolkit';

type GenericPartViewEditProps<Q extends SequenceToken, T extends InfiniteScrollListFilters> = {
    defaultItems: string[],
    saveModalText: string,
    clearModalText: string,
    successMessage: string,
    title: string,
    addItemText: string,
    searchService: (filters: T) => Promise<AxiosResponse<InfiniteScrollResponse<Q>>>,
    searchOperation: AsyncThunk<InfiniteScrollResponse<Q>, { filters: T, check: boolean }, any>,
    updateOperation: AsyncThunk<Rule, string[], any>,
    columns: GridColDef<Q>[],
    itemsList: Q[],
    totalCount: number,
    isLoading: boolean,
    PartSummaryComponent: (props: PartSummaryComponentProps<Q>) => JSX.Element,
    getRowIdFunction?: GridRowIdGetter<Q>
}

export default function GenericPartViewEdit<Q extends SequenceToken, T extends InfiniteScrollListFilters>({
    defaultItems = [],
    successMessage,
    saveModalText,
    clearModalText,
    title,
    addItemText,
    searchService,
    searchOperation,
    updateOperation,
    columns,
    itemsList,
    totalCount,
    isLoading,
    PartSummaryComponent,
    getRowIdFunction
}: GenericPartViewEditProps<Q, T>) {

    const isDesktop = useResponsive('up', 'md');

    const isSM = useResponsive('up', 'sm');

    const navigate = useNavigate();

    const { translate } = useLocales();

    const { rulesTab } = useParams();

    //----MANAGE MODALS
    const [isOpenSave, setIsOpenSave] = useState(false);

    const [isOpenList, setIsOpenList] = useState(false);

    const [openClear, setOpenClear] = useState(false);

    const toggleSave = () => {
        isOpenSave ? setIsOpenSave(false) : setIsOpenSave(true);
    };

    const toggleList = () => {
        isOpenList ? setIsOpenList(false) : setIsOpenList(true);
    };

    const onQuit = () => {
        navigate(PATH_DASHBOARD.rules.listTab(rulesTab as RuleTabs));
    };

    const [itemsSelected, setItemsSelected] = useState<Q[]>([]);

    useEffect(() => {
        if (defaultItems.length > 0) {
            searchService({ ...INFINITE_SCROLL_LIST_FILTERS, size: 100, ids: defaultItems } as unknown as T).then(val => {
                setItemsSelected(val.data.results);
            });
        }
    }, [defaultItems, searchService]);

    const deleteItem = (id: string) => {
        setItemsSelected((prev) => prev.filter((item) => item.id !== id));
    };

    const handleSubmit = async () => {
        setIsOpenSave(false);

        const selectedIds = cloneDeep(itemsSelected).map((item) => item.id);

        await dispatch(updateOperation(selectedIds)).unwrap();

        dispatch(setSuccessMessage({ text: successMessage, returnTo: PATH_DASHBOARD.rules.listTab(rulesTab as RuleTabs) }));
    };

    const handleClear = () => {
        setItemsSelected([]);
        setOpenClear(false);
    };

    return (
        <>
            <SaveModal
                toggle={toggleSave}
                isOpen={isOpenSave}
                handleSave={handleSubmit}
                saveText={saveModalText}
            />

            <RulesClearModal
                isOpen={openClear}
                toggleOpen={() => setOpenClear(p => !p)}
                submit={handleClear}
                clearText={clearModalText}
            />

            <PartsListModal
                isOpen={isOpenList}
                toggleModal={toggleList}
                selected={itemsSelected}
                setSelected={setItemsSelected}
                itemsList={itemsList}
                totalCount={totalCount}
                isLoading={isLoading}
                searchOperation={searchOperation}
                columns={columns}
                addItemText={addItemText}
                getRowIdFunction={getRowIdFunction}
            />

            <Card sx={{ mt: 2 }}>

                {itemsSelected.length === 0 &&
                    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>

                        <Typography variant="h5" sx={{ pt: 3, px: 2.5 }}>
                            {title}
                        </Typography>

                        <Grid container sx={{ px: 3, pt: 2.5, pb: 7.5 }}>
                            <Grid item xs={12}>
                                <Button
                                    variant={"soft"}
                                    size={"large"}
                                    fullWidth
                                    sx={{ height: "200%" }}
                                    startIcon={<AddIcon />}
                                    onClick={toggleList}
                                >
                                    {addItemText}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }

                {itemsSelected.length > 0 &&
                    <Box>
                        <Box sx={{ display: 'flex', pt: 3, pb: 1.5, px: 2.5, flexDirection: isDesktop ? 'row' : 'column' }}>

                            <Typography variant="h5">
                                {title}
                            </Typography>

                            <Button
                                variant={"text"}
                                size={"medium"}
                                onClick={toggleList}
                                startIcon={<AddIcon />}
                                sx={{ ml: "auto", height: "auto", mt: (isDesktop ? 0 : 1.5) }}
                            >
                                {addItemText}
                            </Button>
                        </Box>

                        <Box sx={{ overflow: 'auto', maxHeight: 500 }}>
                            {itemsSelected.map((item, index) => PartSummaryComponent(
                                {
                                    key: item.id + "summary.item",
                                    item: item,
                                    deleteItem: deleteItem,
                                    isLastItem: index === (itemsSelected.length - 1),
                                    isSM: isSM,
                                    translate: translate
                                }
                            ))}
                        </Box>
                    </Box>
                }

                <Divider />

                {isDesktop ?
                    <Box sx={{ display: 'flex', px: 3, py: 3, gap: 1, flexDirection: isDesktop ? 'row' : 'column-reverse' }}>

                        <Button
                            variant={"soft"}
                            size={"medium"}
                            sx={{ borderRadius: 100 }}
                            onClick={onQuit}
                        >
                            {translate("commons.cancel")}
                        </Button>

                        <Box sx={{ ml: 'auto', display: 'flex', gap: 1, flexDirection: 'default' }}>

                            {itemsSelected.length > 0 &&
                                <Button
                                    startIcon={<DeleteForever />}
                                    variant={'contained'}
                                    color={'error'}
                                    size={"medium"}
                                    onClick={() => setOpenClear(true)}
                                    sx={{ borderRadius: 100 }}
                                >
                                    {translate('commons.empty')}
                                </Button>
                            }

                            <Button
                                variant={"contained"}
                                size={"medium"}
                                onClick={toggleSave}
                                sx={{ ml: 1, borderRadius: 100 }}
                            >
                                {translate("commons.justSave")}
                            </Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{ display: 'flex', px: 2, py: 1, gap: 1, flexDirection: 'column' }}>

                        <Box sx={{ mx: 2, mt: 1.5, display: 'flex', gap: 3 }}>

                            <Button
                                variant={"soft"}
                                size={"medium"}
                                sx={{ borderRadius: 100 }}
                                onClick={onQuit}
                                fullWidth
                            >
                                {translate("commons.cancel")}
                            </Button>

                            {itemsSelected.length > 0 &&
                                <Button
                                    startIcon={<DeleteForever />}
                                    variant={'contained'}
                                    color={'error'}
                                    size={"medium"}
                                    onClick={() => setOpenClear(true)}
                                    sx={{ borderRadius: 100 }}
                                    fullWidth
                                >
                                    {translate('commons.empty')}
                                </Button>
                            }
                        </Box>

                        <Button
                            variant={"contained"}
                            size={"medium"}
                            onClick={toggleSave}
                            sx={{ my: 1.5, mx: 2, borderRadius: 100 }}

                        >
                            {translate("commons.justSave")}
                        </Button>
                    </Box>
                }

            </Card >
        </>
    );
}

//---------- PART LIST MODAL ---------------//

type PartsListModalType<Q extends SequenceToken, T extends InfiniteScrollListFilters> = {
    isOpen: boolean,
    toggleModal: () => void,
    selected: Q[],
    setSelected: (value: Q[]) => void,
    itemsList: Q[],
    totalCount: number,
    isLoading: boolean,
    searchOperation: AsyncThunk<InfiniteScrollResponse<Q>, { filters: T, check: boolean }, any>,
    columns: GridColDef<Q>[],
    addItemText: string,
    getRowIdFunction: GridRowIdGetter<Q> | undefined
}

function PartsListModal<Q extends SequenceToken, T extends InfiniteScrollListFilters>({
    isOpen,
    toggleModal,
    selected,
    setSelected,
    itemsList,
    totalCount,
    isLoading,
    searchOperation,
    columns,
    addItemText,
    getRowIdFunction
}: PartsListModalType<Q, T>) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const {
        onChangeDense,
        dense,
        order,
        orderBy,
        setOrder,
        setOrderBy
    } = useTable();

    const [searchValue, setSearchValue] = useState("");

    const [itemsSelected, setItemsSelected] = useState<Q[]>(selected);

    const apiRef = useGridApiRef();

    const handleResetToTop = useCallback(() => {
        if (apiRef.current?.scroll)
            apiRef.current.scroll({ top: 0 });
    }, [apiRef]);

    const [filters, setFilters] = useState<T>(INFINITE_SCROLL_LIST_FILTERS as T);

    useEffect(() => {
        setItemsSelected(selected);
    }, [selected]);

    useEffect(() => {
        setFilters((prev) => ({
            ...prev,
            sortDirection: order === 'desc' ? "Descending" : "Ascending",
            sortField: orderBy || INFINITE_SCROLL_LIST_FILTERS.sortField,
            all: searchValue || undefined
        }));
    }, [order, orderBy, searchValue]);

    //calls search only after the user has stopped typing and 500 milliseconds have passed
    const debounced = useRef(
        debounce((options: { filters: T, check: boolean }) => {
            dispatch(searchOperation(options));
        }, 500));

    const handleSearch = useCallback((filters: T, isScrolling?: boolean) => {
        if (isLoading) return;

        if (!isScrolling) {
            handleResetToTop();
        }

        const searchOptions: { filters: T, check: boolean } = {
            filters: {
                ...filters,
                sequenceToken: isScrolling ? itemsList[itemsList.length - 1].searchSequenceToken : null,
            },
            check: !!isScrolling
        };

        debounced.current(searchOptions);

    }, [handleResetToTop, isLoading, itemsList]);

    useEffect(() => {
        if (isOpen) handleSearch(filters);
    }, [filters, isOpen]);

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy(INFINITE_SCROLL_LIST_FILTERS.sortField);
            setOrder(INFINITE_SCROLL_LIST_FILTERS.sortDirection === "Descending" ? "desc" : "asc");
        }
    };

    const handleSubmit = () => {
        setSelected(itemsSelected);
        toggleModal();
    };

    const handleClear = () => {
        setItemsSelected([]);
    };

    const handleCancel = () => {
        setItemsSelected(selected);
        toggleModal();
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || itemsList.length === 0) {
            height = isDesktop ? 632 : 649;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 632 : 649;
    };

    return (
        <Modal
            open={isOpen}
            onClose={toggleModal}
            sx={{ m: 'auto' }}
        >
            <Card
                sx={{
                    p: 3,
                    maxHeight: '95vh',
                    overflowY: "auto",
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "60vw"
                }}
            >
                <Typography variant='h5'>
                    {addItemText}
                </Typography>

                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>

                    <TextField
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={translate("commons.search")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <DataGridPro
                        apiRef={apiRef ?? undefined}
                        rowCount={100}
                        rows={itemsList}
                        columns={columns}
                        disableColumnMenu
                        disableColumnResize
                        disableRowSelectionOnClick
                        keepNonExistentRowsSelected
                        checkboxSelection
                        getRowId={getRowIdFunction}
                        rowSelectionModel={itemsSelected.map((item) => item.id)}
                        onRowSelectionModelChange={(v, t) => {

                            const itemsSelectedIds = itemsSelected.map((item) => item.id);

                            const differenceArr = difference(v, itemsSelectedIds);

                            if (differenceArr.length > 0) {
                                differenceArr.forEach((item) => {
                                    setItemsSelected(prev => prev.concat(t.api.getRow(item)!));
                                });
                            } else {
                                const differenceArrReverse = difference(itemsSelectedIds, v);

                                setItemsSelected(prev => prev.filter(v => !differenceArrReverse.includes(v.id)));
                            }
                        }}
                        density={(dense && itemsList.length > 0) ? 'compact' : 'standard'}
                        sortingMode={"server"}
                        paginationMode={"server"}
                        onSortModelChange={handleSort}
                        loading={isLoading}
                        slots={{
                            noRowsOverlay: noData,
                            noResultsOverlay: noData,
                            footer: () => (
                                <>
                                    {itemsList.length === 0 && <Divider />}
                                    <GenericListFooter
                                        dense={dense}
                                        onChangeDense={onChangeDense}
                                        totalCount={totalCount}
                                    />
                                </>
                            )
                        }}
                        onRowClick={(row) => {
                            if (itemsSelected.find(item => item.id === row.id))
                                setItemsSelected(prev => prev.filter(v => v.id !== row.id));
                            else
                                setItemsSelected(prev => prev.concat([row.row]));
                        }}
                        scrollEndThreshold={300}
                        onRowsScrollEnd={(params) => {
                            if (itemsList.length < totalCount) {
                                handleSearch(filters, true);
                            }
                        }}
                        sx={{
                            ...DataGridStyle,
                            height: getHeight(),
                            minHeight: getHeight(),
                            maxHeight: getMaxHeight()
                        }}
                    />
                </Box>

                <Divider />

                {isDesktop ?
                    <Box sx={{ justifyContent: 'space-between', ml: 'auto', display: 'flex', gap: 1, p: 3 }}>

                        <Button variant={"soft"} color={"inherit"} onClick={handleCancel}>
                            {translate("commons.cancel")}
                        </Button>

                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                startIcon={<DeleteForever />}
                                variant={'contained'}
                                color={'error'}
                                onClick={handleClear}
                            >
                                {translate('rules.form.clear')}
                            </Button>
                            <Button
                                type={"submit"}
                                variant={"contained"}
                                onClick={handleSubmit}
                            >
                                {translate("commons.add")}
                            </Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{ display: 'flex', px: 2, py: 1, gap: 1, flexDirection: 'column' }}>

                        <Box sx={{ mx: 2, mt: 1.5, display: 'flex', gap: 3 }}>
                            <Button
                                variant={"soft"}
                                color={"inherit"}
                                onClick={handleCancel}
                                fullWidth
                            >
                                {translate("commons.cancel")}
                            </Button>

                            <Button
                                startIcon={<DeleteForever />}
                                variant={'contained'}
                                color={'error'}
                                onClick={handleClear}
                                fullWidth
                            >
                                {translate('commons.empty')}
                            </Button>
                        </Box>

                        <Button
                            type={"submit"}
                            variant={"contained"}
                            onClick={handleSubmit}
                            sx={{ my: 1.5, mx: 2 }}
                        >
                            {translate("commons.add")}
                        </Button>

                    </Box>
                }

            </Card>
        </Modal>
    );
}