import { BarChart, Description, Gavel, ShoppingBasket, Visibility } from "@mui/icons-material";
import LabelIcon from "@mui/icons-material/Label";
import { Box, Button, Card, Container, Tab, Tabs, Typography, } from "@mui/material";
import { alpha } from "@mui/system";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import DateZone from "src/appComponents/DateZone";
import LoadingScreen from "src/appComponents/loading-screen";
import Page from "src/appComponents/Page";
import { CustomAvatar } from "src/components/custom-avatar";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import Label, { LabelColor } from "src/components/label";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { dispatch, RootState } from "src/redux/store";
import { webshopOperations } from "src/redux/webshop";
import { PATH_DASHBOARD } from "src/routes/paths";
import VisibilityDetail from "src/sections/@dashboard/webshop/detail/VisibilityDetail";
import { bgBlur } from "src/utils/cssStyles";
import { StyledInfo, StyledRoot } from "src/utils/Detail";
import GeneralDetail from "src/sections/@dashboard/webshop/detail/GeneralDetail";
import TermsDetail from "src/sections/@dashboard/webshop/detail/TermsDetails";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import DiscountModelDetail from "src/sections/@dashboard/webshop/detail/DiscountModelDetail";
import ProductsList from "src/sections/@dashboard/webshop/detail/product/ProductsList";
import CartShopPopover from "src/sections/@dashboard/webshop/publicShop/CartShopPopover";
import AdminStatisticsDetail from "src/sections/@dashboard/webshop/detail/AdminStatisticsDetail";
import PublicStatisticsDetail from "src/sections/@dashboard/webshop/detail/PublicStatisticDetail";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";

interface WebshopDetailProps {
    isPublic?: boolean
}

export default function WebshopDetail({ isPublic }: WebshopDetailProps) {

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { id } = useParams();

    const navigate = useNavigate();

    const { displayShop: webshop, isWebshopLoading } = useSelector((state: RootState) => state.webshop);

    const { organizationId } = useUserOrganizationContext();

    //"products"

    const profileCover = webshop?.media.find((me) => me.section === "profile");

    const backgroundCover = webshop?.media.find((me) => me.section === "cover");

    const displayName = webshop?.createdBy?.firstName + " " + webshop?.createdBy?.lastName;

    const [currentTab, setCurrentTab] = useState("products");

    useEffect(() => {
        if (id) {
            dispatch(webshopOperations.getDisplayShop({ id, isPublic }));

            if (isPublic)
                dispatch(webshopOperations.getPublicShopOrderStatistics(id));
            else
                dispatch(webshopOperations.getAdminShopOrderStatistics(id));
        }
    }, [id, currentLang, isPublic]);

    const labelColors: Record<string, string> = {
        published: 'success',
        created: "warning",
        preview: "warning",
        expired: 'error',
        disabled: 'error',
        draft: 'default'
    };

    const TABS = useMemo(() => [
        {
            value: 'products',
            label: `${translate('webshop.detail.tabs.products')}`,
            icon: <ShoppingBasket />,
            component: <ProductsList isPublic={isPublic} />
        },
        {
            value: 'details',
            label: `${translate('webshop.detail.tabs.details')}`,
            icon: <Description />,
            component: <GeneralDetail isPublic={isPublic} />
        },
        ...(!isPublic ? [
            {
                value: 'visibility',
                label: `${translate('webshop.detail.tabs.visibility')}`,
                icon: <Visibility />,
                component: <VisibilityDetail />
            },
            ...(webshop?.discountModel ? [{
                value: 'discountModel',
                label: `${translate('webshop.detail.tabs.discountModel')}`,
                icon: <LabelIcon />,
                component: <DiscountModelDetail />
            }] : [])
        ] : []),
        ...(webshop?.termAndCondition ? [{
            value: 'terms',
            label: `${translate('webshop.detail.tabs.terms')}`,
            icon: <Gavel />,
            component: <TermsDetail />
        }] : []),
        {
            value: 'statistics',
            label: translate('webshop.detail.tabs.statistics'),
            icon: <BarChart />,
            component: isPublic ? <PublicStatisticsDetail isVendor={false} customerId={organizationId} shopId={webshop?.id ?? ""} /> : <AdminStatisticsDetail />
        }
    ], [isPublic, organizationId, translate, webshop]);

    const currentTabComponent = TABS.find(tab => tab.value === currentTab)?.component ?? <Fragment />;

    return (
        <Page title={translate("webshop.detail.title")}>

            {isWebshopLoading ? <LoadingScreen /> :

                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={translate("webshop.detail.title")}
                        links={[
                            { name: translate("commons.home"), href: PATH_DASHBOARD.root },
                            { name: translate('menu.management.webshop.public.title'), href: isPublic ? PATH_DASHBOARD.webshopPublic.list : PATH_DASHBOARD.webshop.list },
                            { name: webshop?.title || "" },
                        ]}
                        action={
                            <>
                                {!isPublic &&
                                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Admin_Edit]}>
                                        <Button
                                            variant="contained"
                                            onClick={() => navigate(PATH_DASHBOARD.webshop.edit(id ?? ""))}>
                                            {`${translate('commons.edit')}`}
                                        </Button>
                                    </PermissionBasedGuard>
                                }
                            </>
                        }
                    />
                    {isPublic &&

                        <Box sx={{ ml: 'auto', width: 'fit-content', position: 'fixed', top: '12vh', right: '2vw', mb: 3, zIndex: 10 }}>
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Buy]}>
                                <CartShopPopover />
                            </PermissionBasedGuard>
                        </Box>
                    }
                    <Card sx={{ mb: 3, height: 280, position: 'relative' }}>

                        <StyledRoot sx={{ '&:before': { ...bgBlur({ imgUrl: backgroundCover?.fileInfo[backgroundCover.fileInfo.length - 1]?.url ?? undefined }) } }}>

                            <StyledInfo>
                                <CustomAvatar
                                    name={webshop?.title}
                                    alt={webshop?.title}
                                    src={profileCover?.fileInfo[0]?.url ?? undefined}
                                    sx={{
                                        mx: 'auto',
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderColor: 'common.white',
                                        color: "white",
                                        width: { xs: 80, md: 128 },
                                        height: { xs: 80, md: 128 }
                                    }}
                                />
                                <Box
                                    sx={{
                                        ml: { md: 3 },
                                        mt: { xs: 1, md: 0 },
                                        color: 'common.white',
                                        textAlign: { xs: 'center', md: 'left' }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', }}>
                                        <Typography color={"white"} fontSize={"24px"} variant="h4">
                                            {webshop?.title ?? "—"}
                                        </Typography>

                                        {!isPublic &&
                                            <Label
                                                variant={"filled"}
                                                color={(labelColors[(webshop?.status.toLowerCase()) ?? ''] || "success") as LabelColor}
                                                sx={{ textTransform: 'uppercase' }}
                                            >
                                                {webshop?.status}
                                            </Label>
                                        }

                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>

                                        {!!webshop?.endDate &&
                                            <Box sx={{ display: 'flex' }}>

                                                <Typography variant="body1" sx={{ color: alpha("#FFFFFF", 0.72), mr: 1 }}>
                                                    {translate("webshop.detail.expiringOn")}
                                                </Typography>

                                                <DateZone variant="body1" sx={{ color: alpha("#FFFFFF", 0.72) }} date={webshop?.endDate ? new Date(webshop.endDate) : new Date()} onlyDate shortMonth />
                                                {!isPublic && ","}

                                            </Box>}
                                        {!isPublic &&
                                            <Typography variant="body1" sx={{ color: alpha("#FFFFFF", 0.72) }}>
                                                {translate('containers.detail.createdBy') + " " + displayName}
                                            </Typography>
                                        }
                                    </Box>
                                </Box>
                            </StyledInfo>
                        </StyledRoot>

                        <Box
                            sx={{
                                width: 1,
                                bottom: 0,
                                zIndex: 9,
                                position: 'absolute',
                                bgcolor: 'background.paper',
                                display: "flex !important",
                                justifyContent: "flex-end !important"
                            }}
                        >
                            <Tabs
                                value={currentTab}
                                onChange={(_, newValue) => setCurrentTab(newValue)}
                                sx={{ pr: { md: 3 } }}
                            >
                                {TABS.map((tab) => (
                                    <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                                ))}
                            </Tabs>
                        </Box>
                    </Card>

                    <Box>{currentTabComponent}</Box>

                </Container>
            }
        </Page>
    );
}