import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResponse } from "src/@types/commons";
import discountMatrixOperations from "./discountMatrix-operations";
import { DiscountMatrixRowsSearchResult, DiscountMatrixSearchResult, EnableDisableDiscountMatrixProps } from "src/@types/discountMatrix";

interface DiscountMatrixReducer {
    error: ErrorResponse,
    //---- LIST -------------------
    isLoading: boolean,
    isInfiniteScrolling: boolean,
    totalCount: number,
    filtersInUrl: string,
    discountMatrixList: DiscountMatrixSearchResult[],
    //---- ROWS -------------------
    areRowsLoading: boolean,
    areRowsInfiniteScrolling: boolean,
    rowsTotalCount: number,
    rowsFiltersInUrl: string,
    discountMatrixRowsList: DiscountMatrixRowsSearchResult[]
}

const initialState: DiscountMatrixReducer = {
    error: null,
    //---- LIST -------------------
    isLoading: false,
    isInfiniteScrolling: false,
    totalCount: 0,
    filtersInUrl: "",
    discountMatrixList: [],
    //---- ROWS -------------------
    areRowsLoading: false,
    areRowsInfiniteScrolling: false,
    rowsTotalCount: 0,
    rowsFiltersInUrl: "",
    discountMatrixRowsList: []
};

const DiscountMatrixSlice = createSlice({
    name: "discountMatrix",
    initialState,
    extraReducers: (builder) => {
        builder
            //------ LIST ----------------- 
            .addCase(discountMatrixOperations.searchDiscountMatrixes.rejected, (state, action) => {
                state.discountMatrixList = initialState.discountMatrixList;
                state.error = action.error.message as string;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            .addCase(discountMatrixOperations.searchDiscountMatrixes.fulfilled, (state, action) => {
                state.discountMatrixList = state.isInfiniteScrolling ? state.discountMatrixList.concat(action.payload.results) : action.payload.results;
                state.totalCount = action.payload.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            //------ ROWS -----------------
            .addCase(discountMatrixOperations.searchDiscountMatrixRows.rejected, (state, action) => {
                state.discountMatrixRowsList = initialState.discountMatrixRowsList;
                state.error = action.error.message as string;
                state.areRowsLoading = false;
                state.areRowsInfiniteScrolling = false;
            })
            .addCase(discountMatrixOperations.searchDiscountMatrixRows.fulfilled, (state, action) => {
                state.discountMatrixRowsList = state.areRowsInfiniteScrolling ? state.discountMatrixRowsList.concat(action.payload.results) : action.payload.results;
                state.rowsTotalCount = action.payload.totalCount;
                state.areRowsLoading = false;
                state.areRowsInfiniteScrolling = false;
            })
            ;
    },
    reducers: {
        //------ LIST ----------------- 
        startLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = true;
            if (action.payload) state.isInfiniteScrolling = true;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        //------ ROWS -----------------
        startRowsLoading(state, action: PayloadAction<boolean>) {
            state.areRowsLoading = true;
            if (action.payload) state.areRowsInfiniteScrolling = true;
        },
        setRowsFiltersInUrl(state, action: PayloadAction<string>) {
            state.rowsFiltersInUrl = action.payload;
        },
        /*         resetWebshop(state) {
                    state.webshop = null;
                }, */
        enableDisableDiscountMatrixSL(state, action: PayloadAction<EnableDisableDiscountMatrixProps>) {
            const { id, action: payloadAction } = action.payload;

            const index = state.discountMatrixList.findIndex((term) => term.discountMatrixId === id);

            state.discountMatrixList[index].enabled = payloadAction === "enable";
        },
        renameDiscountMatrixSL(state, action: PayloadAction<{ id: string, name: string }>) {
            const { id, name } = action.payload;

            const index = state.discountMatrixList.findIndex((term) => term.discountMatrixId === id);

            state.discountMatrixList[index].name = name;

        }
    }
});

export const {
    startLoading,
    setFiltersInUrl,
    startRowsLoading,
    setRowsFiltersInUrl,
    enableDisableDiscountMatrixSL,
    renameDiscountMatrixSL
} = DiscountMatrixSlice.actions;

export default DiscountMatrixSlice.reducer;