import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useCallback, useMemo } from 'react';
import { SelectItem } from 'src/@types/commons';
import { CustomField } from 'src/@types/customField';
import useTenant from 'src/appHooks/useTenant';
import { useLocales } from 'src/locales';

export const WrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'stretch',
    margin: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.divider}`
}));

export const LabelStyle = styled('span')(({ theme }) => ({
    ...theme.typography.subtitle2,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderRight: `solid 1px ${theme.palette.divider}`
}));

interface GenericSummaryChipProps {
    name: string,
    nameId?: string,
    isRange?: boolean,
    label: string,
    value: string | boolean,
    onResetFilter: (el: string, value?: any, isRange?: boolean) => void
}

export function GenericSummaryChip({ name, nameId, isRange, label, value, onResetFilter }: GenericSummaryChipProps) {
    return (
        <WrapperStyle>
            <LabelStyle>{label}</LabelStyle>

            <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                <Chip
                    key={"summary" + name}
                    label={value.toString()}
                    size="small"
                    onDelete={() => {
                        if (isRange) onResetFilter(name, undefined, true);
                        else {
                            onResetFilter(name);
                            if (nameId) onResetFilter(nameId);
                        }
                    }}
                    sx={{ m: 0.5 }}
                />
            </Stack>
        </WrapperStyle>
    );
}

interface CustomFieldsSummaryChipProps {
    contextList: string[],
    values: Record<string, string>,
    onResetFilter: (el: any, value?: any) => void
}

export function CustomFieldsSummaryChip({ contextList, values, onResetFilter }: CustomFieldsSummaryChipProps) {

    const { currentLang } = useLocales();

    const { customFields } = useTenant();

    const summaryCustomFields = useMemo(() => {

        let fields: CustomField[] = [];

        contextList.forEach((context) => {
            const fieldsWithContext = customFields.filter((field) => field.enabled && field.searchable && field.entityType.toLowerCase() === context.toLowerCase());

            fields = fields.concat(fieldsWithContext);
        });

        return fields.sort((a, b) => a.name[0].text!.toLowerCase().localeCompare(b.name[0].text!.toLowerCase()));

    }, [customFields, contextList]);

    const getLabel = useCallback((name: string) => {

        const customFound = summaryCustomFields.find(x => x.id === name);

        return customFound!.name.find(x => x.culture === currentLang.value)?.text || customFound!.name[0].text;

    }, [currentLang, summaryCustomFields]);

    return (
        Object.entries(values).map(([key, val], index) =>
            <WrapperStyle key={key + "." + index}>
                <LabelStyle>{getLabel(key)}</LabelStyle>

                <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                    <Chip
                        key={key}
                        label={val.toString()}
                        size={"small"}
                        onDelete={() => onResetFilter("customFields", key)}
                        sx={{ m: 0.5 }}
                    />
                </Stack>
            </WrapperStyle>
        )
    );
}

interface MultipleSummaryChipProps {
    name: string,
    nameId?: string,
    label: string,
    values: string[],
    onResetFilter: (el: any, value?: any) => void,
    valuesLabels?: SelectItem[],
    optionIsObject?: boolean
}

export function MultipleSummaryChip({ name, nameId, label, values, onResetFilter, valuesLabels, optionIsObject }: MultipleSummaryChipProps) {

    return (
        <WrapperStyle>
            <LabelStyle>{label}</LabelStyle>

            <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                {values.map((obj: string) => (
                    <Chip
                        key={obj}
                        label={valuesLabels ? valuesLabels.find((item) => item.value === obj)?.label : obj}
                        size="small"
                        onDelete={() => onResetFilter(name, obj)}
                        sx={{ m: 0.5 }}
                    />
                ))}
            </Stack>
        </WrapperStyle>
    );
} 