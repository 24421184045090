import { Box, Card, Checkbox, Divider, FormControlLabel, TextField, Typography } from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import { useReducer, useState } from "react";
import { NumericFormat } from "react-number-format";
import { DEFAULT_BASKET_RULE, MAX_DURATION, MIN_DURATION, NewBasketRule } from "src/@types/webshop";
import BoxSwitchWithChild from "src/components/custom-input/BoxSwitchWithChild";
import { useLocales } from "src/locales";
import { BasketRuleFormAction, durationToMinutes } from "src/pages/dashboard/basketRules/NewEditBasketRule";
import * as Yup from 'yup';
import { alpha, useTheme } from '@mui/material/styles';
import StepButtons from "src/components/buttons/StepButtons";
import { PATH_DASHBOARD } from "src/routes/paths";

const schema = Yup.object().shape({
    name: Yup.string().trim().required('commons.validation.requiredField'),
    minutes: Yup.number().required('commons.validation.requiredField').min(5).max(60),
    minItems: Yup.number()
        .when('maxItems', (maxItems, schema) =>
            typeof maxItems[0] === 'number' && maxItems[0] !== undefined && !isNaN(maxItems[0])
                ? schema.max(maxItems[0] - 1, 'basketRule.messages.error.minError')
                : schema
        ),
    minAmount: Yup.number()
        .when('maxAmount', (maxAmount, schema) =>
            typeof maxAmount[0] === 'number' && maxAmount[0] !== undefined && !isNaN(maxAmount[0])
                ? schema.max(maxAmount[0] - 1, 'basketRule.messages.error.minError')
                : schema
        )
});

function reduce(state: NewBasketRule, action: BasketRuleFormAction): NewBasketRule {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        case 'reset':
            return { ...DEFAULT_BASKET_RULE, ...action.payload };
        default:
            return state;
    }
}

interface GeneralStepProps {
    state: NewBasketRule,
    onSubmit: (basket: BasketRuleFormAction) => Promise<void>
}

export default function GeneralStep({ state, onSubmit }: GeneralStepProps) {

    const { translate } = useLocales();

    const [basketRule, setBasketRule] = useReducer(reduce, { ...state, minutes: state.duration ? durationToMinutes(state.duration) : DEFAULT_BASKET_RULE.minutes });

    const [changeMinAmount, setChangeMinAmount] = useState<boolean>(!!basketRule.minAmount);

    const [changeMaxAmount, setChangeMaxAmount] = useState<boolean>(!!basketRule.maxAmount);

    const [changeMinItems, setChangeMinItems] = useState<boolean>(!!basketRule.minItems);

    const [changeMaxItems, setChangeMaxItems] = useState<boolean>(!!basketRule.maxItems);

    const formik = useFormik({
        initialValues: basketRule,
        validationSchema: schema,
        validateOnMount: true,
        onSubmit: (val) => {
            setBasketRule({ payload: val, type: 'update' });
            onSubmit({ payload: val, type: 'update' });
        }
    });

    const resetForm = {
        resetMinAmount: () => formik.setFieldValue("minAmount", DEFAULT_BASKET_RULE.minAmount),
        resetMaxAmount: () => formik.setFieldValue("maxAmount", DEFAULT_BASKET_RULE.maxAmount),
        resetMinItems: () => formik.setFieldValue("minItems", DEFAULT_BASKET_RULE.minItems),
        resetMaxItems: () => formik.setFieldValue("maxItems", DEFAULT_BASKET_RULE.maxItems)
    };

    const checkResetForm = () => {
        if (!changeMinAmount) resetForm.resetMinAmount();
        if (!changeMaxAmount) resetForm.resetMaxAmount();
        if (!changeMinItems) resetForm.resetMinItems();
        if (!changeMaxItems) resetForm.resetMaxItems();
    };

    const resetAmout = () => {
        setChangeMinAmount(false);
        setChangeMaxAmount(false);
    };

    const resetItems = () => {
        setChangeMinItems(false);
        setChangeMaxItems(false);
    };

    return (
        <Card>

            <Box sx={{ p: 3 }}>
                <Typography variant="h5">
                    {translate('groups.form.steps.details')}
                </Typography>

                <TextField
                    fullWidth
                    required
                    name={'name'}
                    label={translate('commons.name')}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && !!formik.errors.name}
                    helperText={(formik.touched.name && !!formik.errors.name) && translate(formik.errors.name)}
                    sx={{ mt: 3 }}
                />

                <BoxSwitchWithChild
                    label={translate('basketRule.form.timedCart.title')}
                    subtitle={translate('basketRule.form.timedCart.subtitle')}
                    sx={{ my: 3 }}
                    alwaysOpen
                    required
                >
                    <Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>

                            <Typography variant="subtitle1">
                                {translate('basketRule.form.timedCart.duration')}
                            </Typography>

                            <Box sx={{ width: "15.5%" }}>
                                <NumericFormat
                                    customInput={TextField}
                                    name={"minutes"}
                                    value={formik.values.minutes}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    error={formik.touched.minutes && !!formik.errors.minutes}

                                    onValueChange={(e) => formik.setFieldValue("minutes", Number(e.floatValue))}
                                    onBlur={(e) => {
                                        if (!formik.values.minutes) formik.setFieldValue("minutes", MIN_DURATION);
                                        formik.handleBlur(e);
                                    }}

                                    helperText={
                                        <Box sx={{ display: "flex", justifyContent: "space-between", mx: -1, my: -0.5 }}>
                                            <Box sx={{ display: "flex", gap: 0.5 }}>
                                                <Typography variant="body2">{translate('commons.min')}:</Typography>
                                                <Typography variant="body2">{MIN_DURATION}</Typography>
                                            </Box>

                                            <Box sx={{ display: "flex", gap: 0.5 }}>
                                                <Typography variant="body2">{translate('commons.max')}:</Typography>
                                                <Typography variant="body2">{MAX_DURATION}</Typography>
                                            </Box>
                                        </Box>
                                    }
                                />

                            </Box>

                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center", mt: 1, ml: -1 }}>
                            <Checkbox
                                name={"resetTimeWhenItemIsAdded"}
                                checked={formik.values.resetTimeWhenItemIsAdded}
                                onChange={(e) => formik.setFieldValue("resetTimeWhenItemIsAdded", e.target.checked)}
                            />
                            <Typography variant="subtitle1">
                                {translate('basketRule.form.timedCart.timer')}
                            </Typography>
                        </Box>

                    </Box>
                </BoxSwitchWithChild>

                <BoxSwitchWithChild
                    label={translate('basketRule.form.cartAmount.title')}
                    subtitle={translate('basketRule.form.cartAmount.subtitle')}
                    sx={{ my: 3 }}
                    callBack={resetAmout}
                    checkOpen={changeMinAmount || changeMaxAmount}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", mt: 3 }}>

                        <Typography variant="subtitle1" sx={{ mt: 1.85 }}>
                            {translate('basketRule.form.cartAmount.amount')}
                        </Typography>

                        <Box
                            sx={{
                                display: "flex", justifyContent: "flex-end",
                                ...((formik.touched.minAmount && !!formik.errors.minAmount) && { mb: 3 })
                            }}
                        >

                            <FormValueWithCheck
                                check={changeMinAmount}
                                setCheck={setChangeMinAmount}
                                name={"minAmount"}
                                value={formik.values.minAmount}
                                setFieldValue={formik.setFieldValue}
                                isMin
                                isError={formik.touched.minAmount && !!formik.errors.minAmount}
                                helperText={formik.errors.minAmount}
                                handleBlur={formik.handleBlur}
                            />

                            <FormValueWithCheck
                                check={changeMaxAmount}
                                setCheck={setChangeMaxAmount}
                                name={"maxAmount"}
                                value={formik.values.maxAmount}
                                setFieldValue={formik.setFieldValue}
                                handleBlur={formik.handleBlur}
                            />
                        </Box>

                    </Box>
                </BoxSwitchWithChild>

                <BoxSwitchWithChild
                    label={translate('basketRule.form.cartQuantity.title')}
                    subtitle={translate('basketRule.form.cartQuantity.subtitle')}
                    callBack={resetItems}
                    checkOpen={changeMinItems || changeMaxItems}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", mt: 3 }}>

                        <Typography variant="subtitle1" sx={{ mt: 1.85 }}>
                            {translate('commons.quantity')}
                        </Typography>

                        <Box
                            sx={{
                                display: "flex", justifyContent: "flex-end",
                                ...((formik.touched.minItems && !!formik.errors.minItems) && { mb: 3 })
                            }}
                        >

                            <FormValueWithCheck
                                check={changeMinItems}
                                setCheck={setChangeMinItems}
                                name={"minItems"}
                                value={formik.values.minItems}
                                setFieldValue={formik.setFieldValue}
                                isMin
                                isError={formik.touched.minItems && !!formik.errors.minItems}
                                helperText={formik.errors.minItems}
                                handleBlur={formik.handleBlur}
                            />

                            <FormValueWithCheck
                                check={changeMaxItems}
                                setCheck={setChangeMaxItems}
                                name={"maxItems"}
                                value={formik.values.maxItems}
                                setFieldValue={formik.setFieldValue}
                                handleBlur={formik.handleBlur}
                            />
                        </Box>

                    </Box>
                </BoxSwitchWithChild>
            </Box >

            <Divider />

            <Box sx={{ p: 3 }}>
                <StepButtons
                    returnPage={PATH_DASHBOARD.basketRules.list}
                    onSave={() => {
                        checkResetForm();
                        formik.handleSubmit();
                    }}
                    disabled={!(formik.isValid)}
                    confirm
                />
            </Box>

        </Card >
    );
}

interface FormValueWithCheckProps {
    check: boolean,
    setCheck: (value: boolean) => void,
    isMin?: boolean,
    name: string,
    value: number | undefined | null,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<NewBasketRule>>,
    isError?: boolean,
    helperText?: string,
    handleBlur: (e: React.FocusEvent<any, Element>) => void
}

function FormValueWithCheck({ check, setCheck, isMin, name, value, setFieldValue, isError, helperText, handleBlur }: FormValueWithCheckProps) {

    const { translate } = useLocales();

    const theme = useTheme();

    return (
        <>
            <FormControlLabel
                control={<Checkbox checked={check} onChange={(e) => setCheck(e.target.checked)} />}
                label={translate(`commons.${isMin ? "min" : "max"}`) + ":"}
                sx={{
                    '& .MuiFormControlLabel-label': { pt: 0.35, fontSize: 16 },
                    mt: -0.2, ...(!isMin && { ml: 5 })
                }}
            />
            <NumericFormat
                key={name}
                customInput={TextField}
                name={name}
                value={value ?? (isMin ? DEFAULT_BASKET_RULE[name] : "")}
                decimalScale={0}
                fixedDecimalScale={true}
                allowNegative={false}
                placeholder={isMin ? "" : 'Indefinite'}
                onValueChange={(e) => {
                    if (isMin) setFieldValue(name, isNaN(Number(e.floatValue)) ? 0 : Number(e.floatValue));
                    else setFieldValue(name, Number(e.floatValue));
                }}
                onBlur={(e) => {
                    if (!value) setFieldValue(name, DEFAULT_BASKET_RULE[name]);
                    handleBlur(e);
                }}
                disabled={!check}
                error={isError}
                helperText={isError && translate(helperText)}
                sx={{
                    width: "25%", maxWidth: "25%",
                    '& .MuiFormHelperText-root.Mui-error': { ml: 0.5, mt: 8, position: "absolute", width: "200px" },
                    '& .MuiOutlinedInput-root': { '& fieldset': { background: !check ? alpha(theme.palette.grey[300], 0.20) : "" } },
                    ...(!isMin && { '& .MuiOutlinedInput-input': { fontWeight: value ? "inherit" : 600 } })
                }}
            />
        </>
    );
}