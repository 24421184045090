import { Tooltip, MenuItem, Checkbox, Typography, IconButton } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel, GridValidRowModel } from "@mui/x-data-grid";
import { useState } from "react";
import { getFirstUpperCase } from "src/appUtils/string";
import MenuPopover from "src/components/menu-popover";
import SvgColor from "src/components/svg-color";
import { useLocales } from "src/locales";
import { alpha } from '@mui/material/styles';
import { ToolbarActionButtonStyle } from "../utils/style";

interface VisibilityModelComponentProps<T extends GridValidRowModel> {
    columns?: GridColDef<T>[],
    model: GridColumnVisibilityModel
    onChangeModel: (model: GridColumnVisibilityModel) => void
}

export default function VisibilityModelComponent<T extends GridValidRowModel>({ columns, model, onChangeModel }: VisibilityModelComponentProps<T>) {

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const { translate } = useLocales();

    return (
        <>
            <Tooltip title={`${translate('list.tooltip.editColumns')}`}>
                <IconButton
                    onClick={(e) => setOpenPopover(e.currentTarget)}
                    size={'large'}
                    sx={{ ...ToolbarActionButtonStyle }}
                >
                    <SvgColor src={'/assets/icons/components/ic_edit_columns.svg'} sx={{ mx: 1 }} />
                </IconButton>
            </Tooltip>

            <MenuPopover
                open={openPopover}
                onClose={() => setOpenPopover(null)}
                sx={{ maxHeight: '40vh', overflowY: 'auto' }}
            >

                {Object.entries(model).map(([key, val]) => (
                    <MenuItem
                        key={key}
                        onClick={() => onChangeModel({ ...model, [key]: !val })}
                        sx={{ display: 'flex' }}
                    >
                        <Checkbox disableRipple checked={val} sx={{ ml: -0.5 }} />
                        <Typography sx={{ ml: -1, mr: 1.5 }} variant='body2'>{columns?.find((col) => col.field === key)?.headerName || getFirstUpperCase(key)}</Typography>
                    </MenuItem>
                ))}

            </MenuPopover>
        </>
    );
}