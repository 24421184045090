import { Card, Tabs, Typography, Box, Divider, Tab, useTheme } from "@mui/material";
import { alpha } from "@mui/system";
import { useState, SyntheticEvent, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Brand } from "src/@types/brand";
import { CustomField } from "src/@types/customField";
import { DEFAULT_ORGANIZATION_SURVEILLANCE_VALUE, GenericOrganization, SurveillanceMonth } from "src/@types/organizations";
import useTenant from "src/appHooks/useTenant";
import { useLocales } from "src/locales";
import { RootState, dispatch } from "src/redux/store";
import StarsIcon from '@mui/icons-material/Stars';
import TabUnselectedIcon from '@mui/icons-material/TabUnselected';
import StoreIcon from '@mui/icons-material/Store';
import { CurrencyTypes } from "src/@types/vehicle";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import { organizationsOperations } from "src/redux/organizations";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import useResponsive from "src/hooks/useResponsive";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridStyle } from "src/utils/DataGridStyle";

interface DetailProps {
    organization: GenericOrganization,
    isVendor: boolean,
    parsedBrands: Brand[]
}

const DEFAULT_SECTIONS = ["contact", "fiscal", "address", "general"];

export default function OrganizationGeneralDetail({ organization, isVendor, parsedBrands }: DetailProps) {

    const { hub, surveillance } = useSelector((state: RootState) => state.organizations);

    const [value, setValue] = useState(0);

    const { translate, currentLang } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const theme = useTheme();

    const navigate = useNavigate();

    const { customFields } = useTenant();

    const [parsedFields, setParsedFields] = useState<CustomField[]>([]);

    const [sectionList, setSectionList] = useState<string[]>([]);

    const backPath = isVendor ? PATH_DASHBOARD.organizations.vendor : PATH_DASHBOARD.organizations.customer;

    const handleChange = (e: SyntheticEvent, newVal: number) => {
        setValue(newVal);
    };

    useEffect(() => {
        if (organization.type === "Company" || organization.type === "Branch")
            dispatch(organizationsOperations.getOrganizationSurveillance(organization.id)).unwrap();
    }, [hub, organization, currentLang]);

    useEffect(() => {
        let fields = customFields.filter(field =>
            field.enabled &&
            field.entityType === 'Organization' &&
            field.visibility !== 'never' &&
            (field.visibility === 'always' || !!(organization.customFields && organization.customFields[field.id])));

        let sectionList = Array.from(new Set(fields.filter(f => !DEFAULT_SECTIONS.includes(f.section.toLowerCase())).map(f => f.section)));
        setSectionList(sectionList);
        setParsedFields(fields);

    }, [customFields, organization.customFields]);

    const getLang = (field: CustomField) => {

        let tmp = field.name.find((f) => f.culture === currentLang.value);

        return tmp ? tmp.text : field.name[0].text;
    };

    const lastMonth: SurveillanceMonth = useMemo(() => (surveillance && surveillance.months.length > 0) ? surveillance.months[surveillance.months.length - 1] : DEFAULT_ORGANIZATION_SURVEILLANCE_VALUE.months[0], [surveillance]);

    const newPartPercentage: number = useMemo(() => lastMonth.returnRequestPerType.NewPart && lastMonth.numberOfOrderedItems ? ((lastMonth.returnRequestPerType.NewPart / lastMonth.numberOfOrderedItems) * 100) : 0, [lastMonth]);

    const productDamagedPercentage: number = useMemo(() => lastMonth.returnRequestPerType.ProductDamaged && lastMonth.numberOfOrderedItems ? ((lastMonth.returnRequestPerType.ProductDamaged / lastMonth.numberOfOrderedItems) * 100) : 0, [lastMonth]);

    const packagingDamagedPercentage: number = useMemo(() => lastMonth.returnRequestPerType.PackagingDamaged && lastMonth.numberOfOrderedItems ? ((lastMonth.returnRequestPerType.PackagingDamaged / lastMonth.numberOfOrderedItems) * 100) : 0, [lastMonth]);

    const usedArticleIsDamagePercentage: number = useMemo(() => lastMonth.returnRequestPerType.UsedArticleIsDamage && lastMonth.numberOfOrderedItems ? ((lastMonth.returnRequestPerType.UsedArticleIsDamage / lastMonth.numberOfOrderedItems) * 100) : 0, [lastMonth]);

    const returnRateTotal: number = useMemo(() => newPartPercentage + productDamagedPercentage + packagingDamagedPercentage + usedArticleIsDamagePercentage, [newPartPercentage, packagingDamagedPercentage, productDamagedPercentage, usedArticleIsDamagePercentage]);

    const returnRateRows: any[] = useMemo(() => [
        {
            id: 0,
            total: (returnRateTotal.toFixed(2)) + "%",
            newPart: newPartPercentage.toFixed(2) + "%",
            damaged: `${(productDamagedPercentage + packagingDamagedPercentage).toFixed(2)}% (${productDamagedPercentage.toFixed(2)}% + ${packagingDamagedPercentage.toFixed(2)}%)`,
            quality: usedArticleIsDamagePercentage.toFixed(2) + "%"
        }
    ], [newPartPercentage, packagingDamagedPercentage, productDamagedPercentage, returnRateTotal, usedArticleIsDamagePercentage]);

    const surveillanceTotal: number = useMemo(() => +(surveillance ? (surveillance.surveillance.quality + surveillance.surveillance.newPart + surveillance.surveillance.damagePackaging + surveillance.surveillance.damageUnPackaging) : 0).toFixed(2), [surveillance]);

    const surveillanceRows: any[] = useMemo(() => [
        {
            id: 0,
            total: (surveillanceTotal) + "%",
            newPart: surveillance?.surveillance.newPart.toFixed(2) + "%",
            damaged: `${((surveillance?.surveillance?.damagePackaging ?? 0) + (surveillance?.surveillance?.damageUnPackaging ?? 0)).toFixed(2)}% (${surveillance?.surveillance.damagePackaging.toFixed(2)}% + ${surveillance?.surveillance.damageUnPackaging.toFixed(2)}%)`,
            quality: surveillance?.surveillance.quality.toFixed(2) + "%"
        }
    ], [surveillance, surveillanceTotal]);

    const returnRateLabels: string[] = useMemo(() => [
        "total",
        "newPart",
        "damaged",
        "quality"
    ], []);

    const customerReturnRateColumns: GridColDef<any>[] = useMemo(() => [

        ...(returnRateLabels.map(((label) => ({
            field: label,
            headerName: translate(`organization.detail.surveillance.${label}`),
            flex: isDesktop ? (label.includes("damaged") ? 2.25 : 1) : undefined,
            minWidth: !isDesktop ? (label.includes("damaged") ? 200 : 130) : undefined,
            headerAlign: "center",
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography sx={{ color: (label === "total" && obj.row[label] > surveillanceTotal) ? "red" : "inherit" }} variant="inherit">
                        {obj.row[label]}{(label === "total" && obj.row[label] > surveillanceTotal) && "*"}
                    </Typography>
                );
            }
        })))) as GridColDef<any>[]
    ], [isDesktop, returnRateLabels, translate, surveillanceTotal]);

    const surveillanceColumns: GridColDef<any>[] = useMemo(() => [

        ...(returnRateLabels.map(((label) => ({
            field: label,
            headerName: translate(`organization.detail.surveillance.${label}`),
            flex: isDesktop ? (label.includes("damaged") ? 2.25 : 1) : undefined,
            minWidth: !isDesktop ? (label.includes("damaged") ? 200 : 130) : undefined,
            headerAlign: "center",
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography variant="inherit">
                        {obj.row[label]}
                    </Typography>
                );
            }
        })))) as GridColDef<any>[]
    ], [isDesktop, returnRateLabels, translate]);

    const { hubId } = organization;

    const tabs = [(
        <Box key={0} sx={{ p: 3, width: "100%", bgcolor: 'background.paper' }}>

            <Typography sx={{ mb: 3 }} variant="h6">
                {`${translate('commons.moreDetail')}`}
            </Typography>

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('organization.detail.hubCountry')}`}
                    value={hub && hubId ? hub.address && hub.address.country || '—' : '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.types.hub')}`}
                    value={(hub && hubId) ? hub.name : '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.brands.title')}`}
                    value={(organization.brands && organization.brands.length) ? parsedBrands.map(x => x.name).join(", ") : "—"}
                />
                <GridLabeledText
                    label={`${translate('organization.detail.region')}`}
                    value={(organization.regions && organization.regions.join(", ")) || '—'}
                />
            </Box>

            <Divider sx={{ my: 2 }} />

            <GridLabeledText
                label={`${translate('commons.note')}`}
                value={organization.note || '—'}
                left={1.5}
                right={10.5}
            />

            {parsedFields.filter(x => x.section.toLowerCase() === 'general').length > 0 && <Divider sx={{ my: 4 }} />}

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'general').map(field =>
                    <GridLabeledText
                        key={field.id}
                        label={getLang(field)!}
                        value={organization.customFields && organization.customFields[field.id] || '—'}
                    />
                )}
            </Box>
        </Box>
    ),
    (
        <Box key={1} sx={{ p: 3, width: "100%", bgcolor: 'background.paper' }}>

            <Typography variant="h6">
                {`${translate('commons.address')}`}
            </Typography>

            <Box sx={{ my: 3 }}>
                <GridLabeledText
                    label={`${translate('commons.address')}`}
                    value={organization.address?.address || '—'}
                    left={2.8}
                    right={9.2}
                />
            </Box>

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('organization.detail.zipcode')}`}
                    value={organization.address?.zipCode || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.detail.city')}`}
                    value={organization.address?.city || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.detail.district')}`}
                    value={organization.address?.district || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.detail.country')}`}
                    value={organization.address?.country || '—'}
                />
            </Box>

            {parsedFields.filter(x => x.section.toLowerCase() === 'address').length > 0 && <Divider sx={{ my: 4 }} />}

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'address').map(field =>
                    <GridLabeledText
                        key={field.id}
                        label={getLang(field)!}
                        value={organization.customFields && organization.customFields[field.id] || '—'}
                    />
                )}
            </Box>
        </Box>
    ),
    (
        <Box key={2} sx={{ p: 3, width: "100%", bgcolor: 'background.paper' }}>

            <Typography sx={{ mb: 3 }} variant="h6">
                {`${translate('organization.form.steps.contact')}`}
            </Typography>

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 5,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('organization.form.contact.businessEmail')}`}
                    value={organization.contact?.businessEmail || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.contact.privateEmail')}`}
                    value={organization.contact?.privateEmail || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.contact.businessPhone')}`}
                    value={organization.contact?.businessPhone || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.contact.businessMobile')}`}
                    value={organization.contact?.businessMobilePhone || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.contact.privatePhone')}`}
                    value={organization.contact?.privatePhone || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.contact.businessMobile')}`}
                    value={organization.contact?.privateMobilePhone || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.contact.fax')}`}
                    value={organization.contact?.fax || '—'}
                />
            </Box>

            {parsedFields.filter(x => x.section.toLowerCase() === 'contact').length > 0 && <Divider sx={{ my: 4 }} />}

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'contact').map(field =>
                    <GridLabeledText
                        key={field.id}
                        label={getLang(field)!}
                        value={organization.customFields && organization.customFields[field.id] || '—'}
                    />
                )}
            </Box>
        </Box >
    ),
    (
        <Box key={2} sx={{ p: 3, width: "100%", bgcolor: 'background.paper' }}>

            <Typography sx={{ mb: 3 }} variant="h6">
                {`${translate('organization.detail.fiscal')}`}
            </Typography>

            <Box
                sx={{
                    mt: 1, mb: 3,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('organization.form.payment.iban')}`}
                    value={organization.payment?.iban || '—'}
                />
                <GridLabeledText
                    label={`${translate('organization.form.payment.mode')}`}
                    value={organization.payment?.mode || '—'}
                />
                {!isVendor && <GridLabeledText
                    label={`${translate('organization.form.payment.creditLimit')}`}
                    value={{
                        withVat: organization.payment?.creditLimit,
                        currentCurrency: CurrencyTypes.EUR
                    }}
                    isCurrency
                />}
                <GridLabeledText
                    label={`${translate('organization.form.payment.balance')}`}
                    value={{
                        withVat: organization.payment?.balance,
                        currentCurrency: CurrencyTypes.EUR
                    }}
                    isCurrency
                />
                <GridLabeledText
                    label={`${translate('organization.detail.vat')}`}
                    value={organization.vat || '—'}
                />
            </Box>

            {surveillance &&
                <>
                    <Divider />

                    <Box
                        sx={{
                            mt: 3, display: 'grid',
                            columnGap: 8, rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                        }}
                    >
                        <GridLabeledText
                            label={`${translate('organization.detail.surveillance.customerTurnover')}`}
                            value={surveillance.percentage.toFixed(2) || '—'}
                        />
                    </Box>

                    <Typography sx={{ my: 3 }} variant="h6">
                        {`${translate('organization.detail.surveillance.customer')}`}
                    </Typography>

                    <DataGrid
                        rows={returnRateRows}
                        columns={customerReturnRateColumns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        disableColumnResize
                        hideFooter
                        sx={{
                            ...DataGridStyle,
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: (theme) => theme.palette.primary.lighter
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: (theme) => theme.palette.primary.lighter
                            },
                            '& .MuiDataGrid-columnHeader:focus ': {
                                backgroundColor: (theme) => theme.palette.primary.lighter
                            },
                            height: 110,
                            maxHeight: 110
                        }}
                    />

                    {surveillanceTotal > returnRateTotal &&
                        <Typography sx={{ textAlign: 'right', mt: 1.5, mr: 1, color: "red" }} fontSize={'14.5px'}>
                            {`${translate('organization.detail.surveillance.alert')}`.replace("X", surveillance.surveillance.fee.toString())}
                        </Typography>
                    }

                    <Typography sx={{ my: 3 }} variant="h6">
                        {`${translate('organization.detail.surveillance.threshold')}`}
                    </Typography>

                    <DataGrid
                        rows={surveillanceRows}
                        columns={surveillanceColumns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        disableColumnResize
                        hideFooter
                        sx={{
                            ...DataGridStyle,
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: (theme) => theme.palette.primary.lighter
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: (theme) => theme.palette.primary.lighter
                            },
                            '& .MuiDataGrid-columnHeader:focus ': {
                                backgroundColor: (theme) => theme.palette.primary.lighter
                            },
                            height: 110,
                            maxHeight: 110
                        }}
                    />
                </>
            }

            {parsedFields.filter(x => x.section.toLowerCase() === 'fiscal').length > 0 && <Divider sx={{ my: 4 }} />}

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 5,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'fiscal').map(field =>
                    <GridLabeledText
                        key={field.id}
                        label={getLang(field)!}
                        value={organization.customFields && organization.customFields[field.id] || '—'}
                    />
                )}
            </Box>
        </Box>
    ),
    ].concat(sectionList.map((section, ind) => {

        let fields = parsedFields.filter(f => f.section === section);

        return (
            <Box key={ind} sx={{ p: 3, width: "100%", bgcolor: 'background.paper' }}>
                <Typography variant="h6" sx={{ mb: 3 }}>{section === 'OtherInfo' ? `${translate('customField.section.otherInfo')}` : section}</Typography>
                <Box
                    sx={{
                        mt: 1,
                        display: 'grid',
                        columnGap: 8,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                    }}
                >
                    {fields.map(
                        field => (
                            <GridLabeledText
                                key={field.id}
                                label={getLang(field)!}
                                value={organization.customFields && organization.customFields[field.id] || '—'}
                            />
                        )
                    )}

                </Box>
            </Box>
        );
    }));

    return (
        <Box>
            <Box sx={{ my: 3, display: 'flex', flexDirection: isDesktop ? 'row' : 'column', alignItems: 'center', justifyContent: 'space-between', gap: 3, flexShrink: 0 }}>

                <Card sx={{ display: 'flex', p: '24px', width: isDesktop ? '33%' : '100%', alignContent: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', mr: 3 }}>
                        <Typography variant="subtitle2">{`${translate('organization.form.brands.brandCategory')}`}</Typography>
                        <Typography variant="h6">{(organization.brands && organization.brands.length) ? parsedBrands.map(x => x.name).join(", ") : "—"}</Typography>
                    </Box>
                    <Box >
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill={theme.palette.info.lighter} />
                            <foreignObject x="12" y="12" width="24" height="24">
                                <StarsIcon fontSize="medium" color="info" />
                            </foreignObject>
                        </svg>
                    </Box>
                </Card>

                <Card sx={{ display: 'flex', p: '24px', width: isDesktop ? '33%' : '100%', alignContent: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', mr: 3 }}>
                        <Typography variant="subtitle2">{`${translate('organization.detail.region')}`}</Typography>
                        <Typography variant="h6">{(organization.regions && organization.regions.length) ? organization.regions.join(", ") : "—"}</Typography>
                    </Box>
                    <Box >
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill={theme.palette.success.lighter} />
                            <foreignObject x="12" y="12" width="24" height="24">
                                <TabUnselectedIcon fontSize="medium" color="success" />
                            </foreignObject>
                        </svg>
                    </Box>
                </Card>

                <Card
                    onClick={() => { navigate(backPath.detailsTab(organization.parentId, 'general', organization.parentType)); }}
                    sx={{ display: 'flex', p: '24px', width: isDesktop ? '33%' : '100%', alignContent: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', mr: 3 }}>
                        <Typography variant="subtitle2">{organization.parentType}</Typography>
                        <Typography variant="h6">{organization.parentName}</Typography>
                    </Box>
                    <Box >
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill={theme.palette.warning.lighter} />
                            <foreignObject x="12" y="12" width="24" height="24">
                                <StoreIcon fontSize="medium" color="warning" />
                            </foreignObject>
                        </svg>
                    </Box>
                </Card>

            </Box>

            <Card sx={{ flexGrow: 1, display: 'flex' }}>
                <Tabs
                    orientation='vertical'
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: { display: 'none' }
                    }}
                    sx={{
                        bgcolor: alpha(theme.palette.grey[500], 0.12),
                        minWidth: "20%",
                        maxHeight: "20%"
                    }}
                >
                    <Tab
                        value={0}
                        sx={{
                            justifyContent: 'flex-start',
                            py: '13px',
                            px: 2,
                            m: 1,
                            borderRadius: '8px',
                            color: value === 0 ? 'primary' : '',
                            backgroundColor: value === 0 ? 'primary.lighter' : '',
                            '&:not(:last-of-type)': { mr: 1 },
                            "&.Mui-selected": {
                                color: "primary.main"
                            }
                        }}
                        id='0'
                        label={`${translate('commons.moreDetail')}`}
                    />
                    {organization.address &&
                        <Tab
                            value={1}
                            sx={{
                                justifyContent: 'flex-start',
                                py: '13px',
                                px: 2,
                                m: 1,
                                borderRadius: '8px',
                                backgroundColor: value === 1 ? 'primary.lighter' : '',
                                '&:not(:last-of-type)': { mr: 1 },
                                "&.Mui-selected": {
                                    color: "primary.main"
                                }
                            }} id='1' label={`${translate('commons.address')}`}
                        />
                    }
                    {organization.contact &&
                        <Tab
                            value={2}
                            sx={{
                                justifyContent: 'flex-start',
                                py: '13px',
                                px: 2,
                                m: 1,
                                borderRadius: '8px',
                                backgroundColor: value === 2 ? 'primary.lighter' : '',
                                '&:not(:last-of-type)': { mr: 1 },
                                "&.Mui-selected": {
                                    color: "primary.main"
                                }
                            }} id='2' label={`${translate('organization.form.steps.contact')}`}
                        />
                    }
                    {organization.payment &&
                        <Tab
                            value={3}
                            sx={{
                                justifyContent: 'flex-start',
                                py: '13px',
                                px: 2,
                                m: 1,
                                borderRadius: '8px',
                                backgroundColor: value === 3 ? 'primary.lighter' : '',
                                '&:not(:last-of-type)': { mr: 1 },
                                "&.Mui-selected": {
                                    color: "primary.main"
                                }
                            }}
                            id='3'
                            label={`${translate('organization.detail.fiscal')}`}
                        />
                    }
                    {sectionList.map((b, ind) => (
                        <Tab
                            key={ind}
                            sx={{
                                justifyContent: 'flex-start',
                                py: '13px',
                                px: 2,
                                m: 1,
                                borderRadius: '8px',
                                backgroundColor: value === ind + 4 ? 'primary.lighter' : '',
                                '&:not(:last-of-type)': { mr: 1 },
                                "&.Mui-selected": {
                                    color: "primary.main"
                                }
                            }}
                            id={(ind + 4).toString()}
                            label={b === 'OtherInfo' ? `${translate('customField.section.otherInfo')}` : b} />))}
                </Tabs>

                {tabs[value]}

            </Card>
        </Box>
    );
}