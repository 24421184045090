import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { useLocales } from "src/locales";
import { getSliderFilterNames } from "./SidebarSlider";
import { NumericFormat } from "react-number-format";

type rangeType = "min" | "max";

interface SidebarInputRangeProps {
    name: string,
    label: string,
    sidebarValues: any,
    handleChangeValues: (values: any, newVal: any, type: string) => void
};

export default function SidebarInputRange({ name, label, sidebarValues, handleChangeValues }: SidebarInputRangeProps) {

    const { translate } = useLocales();

    const [minLabel, maxLabel] = getSliderFilterNames(name);

    const [inputMin, setInputMin] = useState<number | undefined>(sidebarValues[minLabel]);

    const [inputMax, setInputMax] = useState<number | undefined>(sidebarValues[maxLabel]);

    const handleInputChange = (inputValue: number | undefined, type: rangeType) => {
        if (type === "min") {
            setInputMin(inputValue);
            handleChangeValues(sidebarValues, inputValue, minLabel);
        } else {
            setInputMax(inputValue);
            handleChangeValues(sidebarValues, inputValue, maxLabel);
        }
    };

    return (
        <Stack spacing={1}>
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                {label}
            </Typography>

            <Stack direction="row" spacing={1} sx={{ pt: 1, alignItems: 'center' }}>
                <InputRange
                    label={translate('commons.min')}
                    value={inputMin}
                    onChange={handleInputChange}
                    name={minLabel}
                />
                <Typography variant="h4">
                    {"-"}
                </Typography>
                <InputRange
                    label={translate('commons.max')}
                    value={inputMax}
                    onChange={handleInputChange}
                    name={maxLabel}
                />
            </Stack>
        </Stack>
    );
}

//-------------------------------------------

interface InputRangeProps {
    label: string,
    value?: number,
    onChange: (value: number | undefined, type: rangeType) => void,
    name: string
};

function InputRange({ label, value, onChange, name }: InputRangeProps) {

    const type: rangeType = name.includes("min") ? "min" : "max";

    return (
        <NumericFormat
            customInput={TextField}
            name={name}
            label={label}
            value={value}
            allowNegative
            onValueChange={(value) => onChange(value.floatValue, type)}
            sx={{ "& .MuiInputBase-input": { height: "1rem", pb: 2.5 } }}
        />
    );
}