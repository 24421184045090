import { PriceAmount } from "./currency";
import { INFINITE_SCROLL_LIST_FILTERS, InfiniteScrollListFilters, SequenceToken } from "./list";

export interface SparePartsFilters extends InfiniteScrollListFilters {
    [key: string]: any
    all?: string,
    externalId?: string,
    organizationId?: string,
    onlyEnabled?: boolean,
    onlyDeprecated?: boolean,
    tags?: string[]
}

export type SparePartsStatistics = {
    [key: string]: number
    all: number,
    deprecated: number,
    disable: number,
    enabled: number
}

export type SparePart = {
    id: string,
    createdOn: Date,
    externalId: string,
    ownerId: string,
    price: {
        catalogue: PriceAmount,
        selling: PriceAmount,
        supplier: PriceAmount
    },
    enabled: boolean,
    deprecated: boolean,
    replacement: {
        replacedBy: string,
        replacementDate: Date
    },
    manufacturer: {
        description: string,
        code: string
    },
    supplier: {
        description: string,
        code: string
    },
    customFields: Record<string, string>,
    tags: string[],
    name: string,
    description: string,
    europeanArticleNumber: number,
    dimensions: {
        packageWeight: number,
        packageHeight: number,
        packageWidth: number,
        packageDepth: number,
        packageVolume: number
    },
    quantity: {
        stockQuantity: number,
        reservationQuantity: number,
        availableQuantity: number,
        returnQuantity: number,
        pendingReception: number
    },
    family: {
        supplier: {
            description: string,
            code: string
        },
        internal: {
            description: string,
            code: string
        }
    },
    category: {
        supplier: {
            description: string,
            code: string
        },
        internal: {
            description: string,
            code: string
        }
    },
    class: {
        supplier: {
            description: string,
            code: string
        },
        internal: {
            description: string,
            code: string
        }
    }
}

export interface SparePartSearchResult extends SequenceToken {
    id: string,
    name: string,
    enabled: boolean,
    deprecated: boolean,
    vendorId: string,
    externalId: string,
    sellAmount: PriceAmount,
    family: {
        code: string,
        description: string
    },
    category: {
        code: string,
        description: string
    },
    class: {
        code: string,
        description: string
    },
    customFields: Record<string, string>,
    tags: string[]
}

export interface CategoriesFilter extends InfiniteScrollListFilters {
    ids?: string[]
    all?: string
}

export interface CategoriesSearchResult extends SequenceToken {
    code: string,
    description: string,
    numberOfParts: number
}

/* --------------------------------------------------- */

export const DEFAULT_SPARE_PARTS_FILTERS: SparePartsFilters = {
    ...INFINITE_SCROLL_LIST_FILTERS,
    all: "",
    externalId: "",
    organizationId: "",
    onlyEnabled: false,
    onlyDeprecated: false,
    tags: [],
    customFields: {}
};

export const DEFAULT_SPARE_PARTS_STATISTICS: SparePartsStatistics = {
    all: 0,
    deprecated: 0,
    disable: 0,
    enabled: 0
};