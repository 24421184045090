import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import { DEFAULT_ORDERS_FILTERS, DocType, OrderFilters, OrderSearchResult } from 'src/@types/orders';
import { ordersOperations } from 'src/redux/orders';
import { isEqual } from 'lodash';
import ItemsTableRow from 'src/sections/@dashboard/orders/list/ItemsTableRow';
import TreeViewInfiniteScrollGenericList from 'src/utils/list/TreeViewInfiniteScrollGenericList';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import useResponsive from 'src/hooks/useResponsive';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import DateZone from 'src/appComponents/DateZone';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Box, Divider, MenuItem, Typography } from '@mui/material';
import { Newspaper, Receipt } from '@mui/icons-material';
import { setOrdersFiltersInUrl } from 'src/redux/orders/orders-slices';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { SidebarListFilters, ToolbarListFilters } from 'src/@types/list';
import { organizationsOperations } from 'src/redux/organizations';
import * as ordersServices from "src/services/ordersServices";
import organizationService from 'src/services/organizationService';
import { OrganizationFilters } from 'src/@types/organizations';
import { manageAmountFilters } from 'src/utils/list/utils/functions';

interface OrganizationOrdersProps {
  isVendor: boolean,
  customerId?: string
}

export default function OrdersList({ isVendor, customerId }: OrganizationOrdersProps) {

  const { translate } = useLocales();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const { orderList, isOrderLoading, totalCount, ordersFiltersInUrl } = useSelector((state: RootState) => state.orders);

  const fullKeysToDelete: string[] = ["from", "to"];

  //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
  const updateCheckField = useCallback((field: string, filtersToCheck: OrderFilters) =>
    ((filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && field !== "customer")
    &&
    !isEqual(filtersToCheck[field], DEFAULT_ORDERS_FILTERS[field])
    , []);

  //---- CUSTOM SEARCH FUNCTION ----//
  const customSearch = useCallback((options: { filters: OrderFilters & { sequenceToken: any }, check: boolean }) => {

    const itemCustomFieldsFilters: Record<string, string> = Object.entries(options.filters.itemCustomFields || {})
      .map(([k, val]) => ({
        key: "itemCustomFields." + k,
        value: val
      }))
      .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

    const updatedFilters = { ...options.filters, ...itemCustomFieldsFilters, customerId: customerId ? customerId : options.filters.customerId };

    delete updatedFilters.itemCustomFields;

    dispatch(ordersOperations.searchOrders({ ...options, isVendor: isVendor, filters: updatedFilters }));
  }, [isVendor, customerId]);

  //---- EXTRA FUNCTION IN SEARCH ----//
  const extraFunctionInSearch = useCallback(async (filters: OrderFilters, getValues: any, setValue: any) => {
    if (filters.customerId && filters.customerId !== getValues("customer")?.id) {

      const { data } = await organizationService.detailGeneric(filters.customerId);

      setValue("customer", data);
    }
  }, []);

  //---- FUNCTION TO USE TO ADAPT FILTERS BEFORE UPDATING FILTERS IN URL ----//
  const updateInUrlFiltersChecks = useCallback((filters: OrderFilters) => {
    if (filters.minAmount && filters.maxAmount) return manageAmountFilters("minAmount", "maxAmount", filters);

    return filters;
  }, []);

  //---- TOOLBAR OPTIONS ----//
  const toolbarFiltersList: ToolbarListFilters[] = useMemo(() => [
    {
      key: 'itemExternalId',
      label: translate('request.partReference')
    },
    {
      key: 'documentExternalId',
      label: translate('request.documentReference')
    },
    {
      key: 'itemCustomFields',
      label: translate('request.detail.depositReference'),
      isCustomfield: "stellantis-europe-spareparts-deposit-number"
    }
  ], [translate]);

  //---- SIDEBAR FILTERS ----// 
  const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
    {
      name: "prettyName",
      label: translate(`orders.tableHeaders.prettyName`),
      type: "TextField"
    },
    {
      name: "documentExternalId",
      label: translate(`orders.filter.documentExternalId`),
      type: "TextField"
    },
    {
      name: "amount",
      label: translate(`orders.tableHeaders.netAmount`),
      type: "InputRange"
    },
    ...(isVendor && !customerId ?
      [{
        name: "",
        label: translate(`commons.customer`),
        type: "Section",
        permissions: [OrganizationPermissionTypes.Customer_View]
      },
      {
        name: "customer",
        label: translate(`commons.customer`),
        type: "InfiniteAutocomplete",
        nameId: "customerId",
        infiniteOperation: (filters: OrganizationFilters) => dispatch(organizationsOperations.searchOrganizations({ filters, isVendor: false, check: false })).unwrap(),
        permissions: [OrganizationPermissionTypes.Customer_View],
        renderOptionComponent: (props: any, option: any) => {
          return (
            <Box {...props} component={'li'} key={option.id} >
              <Typography sx={{ mr: 2 }}>{option.name}</Typography>
            </Box>
          );
        }
      }] : []) as SidebarListFilters[],
    {
      name: "",
      label: translate(`orders.filter.part`),
      type: "Section"
    },
    {
      name: "itemName",
      label: translate(`orders.filter.itemName`),
      type: "TextField"
    },
    {
      name: "itemExternalId",
      label: translate(`request.partReference`),
      type: "TextField"
    },
    {
      name: "",
      label: translate(`commons.tags`),
      type: "Section"
    },
    {
      name: "tags",
      label: `${translate("commons.tags")}`,
      type: "TagsAutocomplete",
      tagsService: ordersServices.searchOrdersTags,
      toParse: true
    }
  ], [customerId, isVendor, translate]);

  //---- HANDLE TABLE START ----//
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const [actualRow, setActualRow] = useState<GridCellParams<OrderSearchResult> | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleCellClick = (params: GridCellParams<OrderSearchResult>) => {
    if (params.field !== GRID_DETAIL_PANEL_TOGGLE_FIELD && params.field !== 'options') {

      isVendor ?
        navigate(PATH_DASHBOARD.orders.vendor.detail(params.row.id)) :
        navigate(PATH_DASHBOARD.orders.customer.detail(params.row.id));

    }
  };

  const handleLogs = useCallback((id: string) => {
    if (isVendor)
      navigate(PATH_DASHBOARD.orders.vendor.logs(id));
    else
      navigate(PATH_DASHBOARD.orders.customer.logs(id));
  }, [navigate, isVendor]);

  const handleDocumentDetail = useCallback((id: string, type: DocType) => {
    if (isVendor)
      navigate(PATH_DASHBOARD.orders.vendor.documents.detail(id, type));
    else
      navigate(PATH_DASHBOARD.orders.customer.documents.detail(id, type));
  }, [navigate, isVendor]);

  const COLUMNS: GridColDef<OrderSearchResult>[] = [
    {
      field: 'prettyName',
      headerName: translate('orders.tableHeaders.prettyName'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      sortable: true,
      renderCell: (param) =>
        <Typography variant="subtitle2">{param.row.prettyName ?? "—"}</Typography>
    },
    {
      field: 'deliveryNoteExternalId',
      headerName: translate('orders.tableHeaders.deliveryNote'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      sortable: true,
      renderCell: (param) => param.row.deliveryNoteExternalId ?
        <Typography variant="subtitle2">{param.row.deliveryNoteExternalId}</Typography>
        :
        param.row.deliveryNoteId ?
          <Newspaper sx={{ mx: 1.75 }} />
          :
          <Typography variant="subtitle2">{"—"}</Typography>

    },
    {
      field: 'invoiceExternalId',
      headerName: translate('orders.tableHeaders.invoice'),
      flex: isDesktop ? 0.9 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      sortable: true,
      renderCell: (param) => param.row.invoiceExternalId ?
        <Typography variant="subtitle2">{param.row.invoiceExternalId}</Typography>
        :
        param.row.invoiceId ?
          <Receipt sx={{ mx: 1.75 }} />
          :
          <Typography variant="subtitle2">{"—"}</Typography>
    },
    {
      field: 'totalQuantity',
      headerName: translate('orders.tableHeaders.quantity'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      headerAlign: 'center',
      align: "center",
      sortable: true
    },
    {
      field: 'grossAmount.withoutVat',
      headerName: translate('orders.tableHeaders.grossAmount'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      sortable: true,
      renderCell: (obj) => <CurrencyAdapter value={obj.row.grossAmount} />
    },
    {
      field: 'totalDiscountAmount.withoutVat',
      headerName: translate('orders.tableHeaders.discountAmount'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      sortable: true,
      renderCell: (obj) => <CurrencyAdapter value={obj.row.totalDiscountAmount} />
    },
    {
      field: 'netAmount.withoutVat',
      headerName: translate('orders.tableHeaders.netAmount'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      sortable: true,
      renderCell: (obj) => <CurrencyAdapter value={obj.row.netAmount} />
    },
    {
      field: 'createdOn',
      headerName: translate('orders.tableHeaders.createdOn'),
      flex: isDesktop ? 1.35 : undefined,
      minWidth: !isDesktop ? 155 : undefined,
      sortable: true,
      renderCell: (params) =>
        <DateZone
          date={params.row.createdOn}
          noSeconds
          shortMonth
        />
    },
    {
      field: 'options',
      headerName: ``,
      flex: isDesktop ? 0.2 : undefined,
      maxWidth: !isDesktop ? 70 : undefined,
      headerAlign: 'center',
      align: "center",
      sortable: false,
      renderCell: (obj) => {
        return (
          <OptionsComponent
            openMenu={openMenu}
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
            object={obj}
            currentRow={actualRow}
            handleLogs={handleLogs}
            handleDocumentDetail={handleDocumentDetail}
          />
        );
      }
    }
  ];
  //---- HANDLE TABLE END ----//

  return (
    <TreeViewInfiniteScrollGenericList
      list={orderList}
      isLoading={isOrderLoading}
      totalCount={totalCount}
      defaultFilters={DEFAULT_ORDERS_FILTERS}
      specificFullKeysToDelete={fullKeysToDelete}
      toolbarFiltersList={toolbarFiltersList}
      filtersInSidebar={filtersInSidebar}
      datagridColumns={COLUMNS}
      updateCheckField={updateCheckField}
      context={'Orders'}
      itemCustomfieldContext={["SpareParts"]}
      setActualRow={setActualRow}
      handleCellClick={handleCellClick}
      resetList={() => { }}
      customSearchFunc={customSearch}
      filtersInUrl={ordersFiltersInUrl}
      setFiltersInUrl={setOrdersFiltersInUrl}
      listDescription={translate('orders.messages.subtitle')}
      updateInUrlFiltersChecks={updateInUrlFiltersChecks}
      datesGeneralFilter={{
        showDates: true
      }}
      getDetailPanelContent={(params) =>
        <ItemsTableRow
          dense={false}
          order={params.row}
        />
      }
      showVatAdvice
      //----------------
      extraFunctionForRequest={extraFunctionInSearch}
    />
  );
}

//-------------------------------------------------------------------------------------------------------------------------------------------------

type OptionsComponentProps = {
  openMenu: HTMLElement | null,
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
  handleCloseMenu: () => void,
  object: GridCellParams<OrderSearchResult>,
  currentRow: GridCellParams<OrderSearchResult> | null,
  handleLogs: (id: string) => void,
  handleDocumentDetail?: (id: string, type: DocType) => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleDocumentDetail, handleLogs }: OptionsComponentProps) {

  const { translate } = useLocales();

  const { id, invoiceId, deliveryNoteId } = object.row;

  const showDivider: boolean = !!(handleDocumentDetail && (invoiceId || deliveryNoteId));

  return (
    <TableMoreMenu
      showMenu={!!currentRow && object.id === currentRow.id}
      open={openMenu}
      onOpen={(event) => handleOpenMenu(event)}
      onClose={() => handleCloseMenu()}
      actions={
        <>
          {(handleDocumentDetail && invoiceId) &&
            <MenuItem
              onClick={() => {
                handleDocumentDetail(invoiceId, "Invoice");
                handleCloseMenu();
              }}
            >
              {`${translate("orders.invoiceDetail")}`}
            </MenuItem>
          }

          {(handleDocumentDetail && deliveryNoteId) &&
            <MenuItem
              onClick={() => {
                handleDocumentDetail(deliveryNoteId, "DeliveryNote");
                handleCloseMenu();
              }}
            >
              {`${translate("orders.deliveryNoteDetail")}`}
            </MenuItem>
          }

          {showDivider && <Divider sx={{ my: "0 !important" }} />}

          <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerViewLogs, OrganizationPermissionTypes.WebShop_Orders_VendorViewLogs]}>
            <MenuItem
              onClick={() => {
                handleLogs(id);
                handleCloseMenu();
              }}
            >
              {`${translate("commons.logs")}`}
            </MenuItem>
          </PermissionBasedGuard>

        </>
      }
    />
  );
}