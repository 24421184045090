import { Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { reportsOperations } from 'src/redux/reports';
import DocumentsList from './DocumentsList';

interface DocumentsListManagerProps {
    isVendor?: boolean
}

export default function DocumentsListManager({ isVendor }: DocumentsListManagerProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    //reset to refresh the request when its status changes
    /*     useEffect(() => {
            if (request) dispatch(resetRequest());
        }, [request]); */

    useEffect(() => {
        dispatch(reportsOperations.getTemplates("document"));
    }, []);

    return (
        <Page title={translate(`menu.management.documents.list`)}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate(`menu.management.documents.list`)}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate('menu.management.documents.list') },
                    ]}
                />

                <DocumentsList isVendor={!!isVendor} key={"docs.list." + isVendor} />

            </Container>
        </Page>
    );
}