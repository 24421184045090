import { createAsyncThunk } from "@reduxjs/toolkit";
import { EnableDisableUserProps, OrganizationIdItem, UserFilters, UserToCreateEdit } from "src/@types/user";
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import { enableDisableUserSL, resetPasswordSuccess, startGroupsLoading, startLoading, startMatrixFilterUserLoading, startUserLogsLoading } from "./users-slices";
import userService from "src/services/userService";

const createUser = createAsyncThunk(
    'user/createUser',
    async (user: UserToCreateEdit, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await userService.create(nullifyEmptyKeys(user));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getUserById = createAsyncThunk(
    'user/getUserById',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await userService.detail(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const updateUser = createAsyncThunk(
    'user/updateUser',
    async (user: UserToCreateEdit, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { status } = await userService.update(nullifyEmptyKeys(user));

            if (status > 400) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const enableDisableUser = createAsyncThunk(
    'user/enableDisableUser',
    async (options: EnableDisableUserProps, { dispatch, rejectWithValue }) => {

        try {
            const { data, status } = options.action === 'Enable' ?
                await userService.enable(options.id) :
                await userService.disable(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableUserSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const associateUser = createAsyncThunk(
    'user/associateUser',
    async (param: { userId: string, organizations: OrganizationIdItem[] }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const response = await userService.associate(param.userId, param.organizations);

            if (response.status < 200 || response.status >= 300)
                throw new Error(`Error associating user: '${param.userId}' to companies`);

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const userSetAdminRoles = createAsyncThunk(
    'user/userSetAdminRoles',
    async (param: { userId: string, roles: string[] }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const response = await userService.assignRoles(param.userId, param.roles);

            if (response.status < 200 || response.status >= 300)
                throw new Error(`Error associating administrative roles to the user`);

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const resetUserPassword = createAsyncThunk(
    'user/resetUserPassword',
    async (userId: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const response = await userService.resetPassword(userId);

            if (response.status < 200 || response.status >= 300)
                throw new Error(`Error resetting password: '${userId}'`);

            dispatch(resetPasswordSuccess());
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchUsers = createAsyncThunk(
    'user/searchUsers',
    async (options: { filters: UserFilters, check: boolean }, { rejectWithValue, dispatch }) => {

        let { filters, check } = options;

        dispatch(startLoading(check));
        try {
            const { data } = await userService.search(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getStatistics = createAsyncThunk(
    'user/getStatistics',
    async (filters: UserFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());

        try {
            const { data } = await userService.statistics(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchUsersPowersearch = createAsyncThunk(
    'user/searchUsersPowersearch',
    async (filters: UserFilters, { rejectWithValue }) => {

        try {
            const { data } = await userService.powersearch(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

async function verifyEmail(email: string): Promise<boolean> {
    try {
        const response = await userService.verifyEmail(email);

        return response.status >= 200 || response.status < 300;
    } catch {
        return false;
    }
}

async function verifyUsername(username: string, provider: string): Promise<boolean> {
    try {

        const response = await userService.verifyUsername(username, provider);

        return response.status >= 200 || response.status < 300;
    } catch {
        return false;
    }
}

const getUsersMatrixFilter = createAsyncThunk(
    'user/getUsersMatrixFilter',
    async (options: UserFilters, { rejectWithValue, dispatch }) => {
        dispatch(startMatrixFilterUserLoading());
        try {
            const { data } = await userService.powersearch(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getUserLogs = createAsyncThunk(
    'user/getUserLogs',
    async (params: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startUserLogsLoading());
        try {
            const { data } = await userService.userLogsSearch(params.id, nullifyEmptyKeys(params.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getProviders = createAsyncThunk(
    'user/getProviders',
    async (onlySignUpProviders: boolean, { rejectWithValue }) => {
        try {
            const { data, status } = await userService.getProviders(onlySignUpProviders);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        }
        catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const searchUserGroups = createAsyncThunk(
    'user/searchUserGroups',
    async (groupIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startGroupsLoading());
        try {
            const { data } = await userService.searchUserGroups(groupIds);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const usersOperations = {
    createUser,
    updateUser,
    enableDisableUser,
    searchUsers,
    getStatistics,
    verifyEmail,
    verifyUsername,
    getUserById,
    searchUsersPowersearch,
    getUsersMatrixFilter,
    getUserLogs,
    resetUserPassword,
    associateUser,
    userSetAdminRoles,
    getProviders,
    searchUserGroups
};

export default usersOperations;
