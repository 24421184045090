
import axios, { AxiosResponse } from "axios";
import { Shop } from "./paths";
import { Basket, BasketParams, CheckoutParams } from "src/@types/basket";

//----- PUBLIC - BASKET --------------------------------------------------------------

const detailPublicBasket = (): Promise<AxiosResponse<Basket>> => {
    const url = Shop.public.basket.detail();

    return axios.get(url);
};

const addItemPublicBasket = (params: BasketParams): Promise<AxiosResponse<Basket>> => {
    const url = Shop.public.basket.addItem();

    return axios.patch(url, params);
};

const removeItemPublicBasket = (params: BasketParams): Promise<AxiosResponse<Basket>> => {
    const url = Shop.public.basket.removeItem();

    return axios.patch(url, params);
};

const checkout = (params: CheckoutParams): Promise<AxiosResponse> => {
    const url = Shop.public.basket.checkout();

    return axios.post(url, params);
};

export {
    detailPublicBasket,
    addItemPublicBasket,
    removeItemPublicBasket,
    checkout
};
