import Page from 'src/appComponents/Page';
import { useLocales } from 'src/locales';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { dispatch, RootState } from 'src/redux/store';
import { webshopOperations } from 'src/redux/webshop';
import { useSelector } from 'react-redux';
import LoadingScreen from 'src/appComponents/loading-screen';
import WebshopWizard from 'src/sections/@dashboard/webshop/newWebshop/WebshopWizard';
import { termConditionOperations } from 'src/redux/termCondition';
import { resetTermCondition } from 'src/redux/termCondition/termCondition-slices';

export default function NewEditWebshop() {

    const { translate } = useLocales();

    const { id: isEdit, webshopId } = useParams();

    const { webshop, isWebshopLoading } = useSelector((state: RootState) => state.webshop);

    const id = isEdit ?? webshopId;

    useEffect(() => {

        if (id && id !== webshop?.id) {

            dispatch(webshopOperations.getShop(id)).unwrap()
                .then(
                    (v) => {
                        if (v.termAndCondition?.id)
                            dispatch(termConditionOperations.getTermCondition(v.termAndCondition.id));
                        else
                            dispatch(resetTermCondition());
                    }
                );
        }

        // return () => void dispatch(resetWebshop());
    }, [id, webshop?.id]);

    return (
        <Page title={!isEdit ? translate("menu.management.webshop.admin.new") : translate("menu.management.webshop.admin.edit")}>
            {isWebshopLoading ?
                <LoadingScreen /> :
                <WebshopWizard key={isEdit} webshop={id ? (webshop ?? undefined) : undefined} />
            }
        </Page>
    );
}