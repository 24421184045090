import { createAsyncThunk } from '@reduxjs/toolkit';
import vehicleService from 'src/services/vehicleService';
import { MediaType, ServiceSearchFilters, Vehicle } from 'src/@types/vehicle';
import nullifyEmptyKeys from 'src/utils/nullifyEmptyKeys';
import { FileWithPreview } from 'src/sections/@dashboard/vehicle/newEdit/VehicleNewEditMedia';
import { v4 as uuidv4 } from 'uuid';
import { finishLoading, startLoading } from './vehicle-slices';
import { ContractualData } from 'src/@types/contractualData';
import { LogisticsData } from 'src/@types/logistics';
import { CommercialData } from 'src/@types/commercialData';

const createVehicle = createAsyncThunk(
  'vehicle/create',
  async (vehicle: Vehicle, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await vehicleService.create(nullifyEmptyKeys(vehicle));

      if (!data) {
        throw new Error('Something went wrong');
      }

      return data;
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.response?.statusText);
    }
  }
);

const updateVehicle = createAsyncThunk(
  'vehicle/update',
  async (vehicle: Vehicle, { rejectWithValue, dispatch }) => {
    try {
      const { status } = await vehicleService.update(nullifyEmptyKeys(vehicle));

      if (status >= 400) {
        throw new Error('Something went wrong');
      }

      return true;
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.message);
    }
  }
);

const getFilteredVehicles = createAsyncThunk(
  'vehicle/getFilteredVehicles',
  async (search: ServiceSearchFilters, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await vehicleService.search(search);

      if (!data) {
        throw new Error('Something went wrong');
      }

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehiclesPowersearch = createAsyncThunk(
  'vehicle/getVehiclesPowersearch',
  async (
    search: { pageIndex: number; pageSize: number; all: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await vehicleService.powersearch(search);

      if (!data) {
        throw new Error('Something went wrong');
      }

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleById = createAsyncThunk(
  'vehicle/getVehicleById',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await vehicleService.detail(id);

      if (!data) {
        throw new Error('Something went wrong');
      }

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleDisplayById = createAsyncThunk(
  'vehicle/getVehicleDisplayById',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await vehicleService.display(id);

      if (!data) {
        throw new Error('Something went wrong');
      }

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getBrands = createAsyncThunk('vehicle/getBrand', async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await vehicleService.suggestBrand();

    if (!data) {
      throw new Error('Something went wrong');
    }

    return data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const getModels = createAsyncThunk(
  'vehicle/getModels',
  async (brand: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await vehicleService.suggestModel(brand.toLowerCase());

      if (!data) {
        throw new Error('Something went wrong');
      }

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const removeMedia = createAsyncThunk(
  'vehicle/removeMedia',
  async (
    { id, mediaType, mediaUrls }: { id: string; mediaType: MediaType; mediaUrls: string[] },
    { rejectWithValue }
  ) => {
    try {
      vehicleService.removeMedia(id, mediaType, mediaUrls);

      return id;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const uploadMadia = createAsyncThunk(
  'vehicle/uploadMedia',
  async (
    { id, files, type }: { id: string; files: (string | FileWithPreview)[]; type: MediaType },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(startLoading());
      Promise.all(
        files.map(async (f) => {
          try {
            const isAFile = typeof f !== typeof '';

            if (!isAFile) return await Promise.resolve();

            const uploadResourceUrlResponse = await vehicleService.getMediaUploadUrl(
              id,
              `${type}`,
              `${uuidv4()}${((f as File).name?.match(/\.[0-9a-z]+$/i) || ['.unknown'])[0]}`
            );

            if (uploadResourceUrlResponse?.data?.uploadResourceUrl) {
              await vehicleService.uploadMedia(
                uploadResourceUrlResponse.data.uploadResourceUrl,
                f as File
              );
            }

            return await Promise.resolve();
          } catch (e) {
            console.error(e);
          }
        })
      ).then((values) => {
        dispatch(finishLoading());
      });

      return type;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleLogs = createAsyncThunk(
  'vehicle/getVehicleLogs',
  async (
    options: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await vehicleService.searchVehicleLogs(options);

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleContractualDataLogs = createAsyncThunk(
  'vehicle/getVehicleContractualDataLogs',
  async (
    options: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await vehicleService.searchContractualDataLogs(options);

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleContractualData = createAsyncThunk(
  'vehicle/getVehicleContractualData',
  async (
    vehicleID: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await vehicleService.searchContractualData(vehicleID);

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const updateContractualData = createAsyncThunk(
  'vehicle/updateContract',
  async (contractualData: ContractualData, { rejectWithValue, dispatch }) => {
    try {

      const { status } = await vehicleService.updateContractualData(nullifyEmptyKeys(contractualData));

      if (status >= 400) {
        throw new Error('Something went wrong');
      }

      return true;
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.message);
    }
  }
);

const createContractualData = createAsyncThunk(
  'vehicle/createContractualData',
  async (contractualData: ContractualData, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const { data } = await vehicleService.createContractualData(nullifyEmptyKeys(contractualData));

      if (!data) {
        throw new Error('Something went wrong');
      }
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.response?.statusText);
    }
  }
);

const enableVehicle = createAsyncThunk(
  'vehicle/enableVehicle',
  async ({ id }: { id: string }, { rejectWithValue, dispatch, }) => {
    try {
      const { status } = await vehicleService.enable(id);

      if (status >= 400) {
        throw new Error('Something went wrong');
      }

      return status;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const disableVehicle = createAsyncThunk(
  'vehicle/disableVehicle',
  async ({ id }: { id: string }, { rejectWithValue, dispatch, }) => {
    try {
      const { status } = await vehicleService.disable(id);

      if (status >= 400) {
        throw new Error('Something went wrong');
      }

      return status;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleLogistics = createAsyncThunk(
  'vehicle/getVehicleLogistics',
  async (
    vehicleID: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await vehicleService.searchLogistics(vehicleID);

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleLogisticsLogs = createAsyncThunk(
  'vehicle/getVehicleLogisticsLogs',
  async (
    options: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await vehicleService.searchLogisticsLogs(options);

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const updateLogisticsData = createAsyncThunk(
  'vehicle/updateLogisticsData',
  async (logisticsData: LogisticsData, { rejectWithValue, dispatch }) => {
    try {
      const { status } = await vehicleService.updateLogisticsData(nullifyEmptyKeys(logisticsData));

      if (status >= 400) {
        throw new Error('Something went wrong');
      }

      return true;
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.message);
    }
  }
);

const createLogisticsData = createAsyncThunk(
  'vehicle/createLogisticsData',
  async (logisticsData: LogisticsData, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const { data } = await vehicleService.createLogisticsData(nullifyEmptyKeys(logisticsData));

      if (!data) {
        throw new Error('Something went wrong');
      }
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.response?.statusText);
    }
  }
);

const getVehicleCommercialData = createAsyncThunk(
  'vehicle/getVehicleCommercialData',
  async (
    vehicleID: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await vehicleService.searchCommercialData(vehicleID);

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getVehicleCommercialDataLogs = createAsyncThunk(
  'vehicle/getVehicleCommercialDataLogs',
  async (
    options: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await vehicleService.searchCommercialDataLogs(options);

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const updateCommercialData = createAsyncThunk(
  'vehicle/updateCommercialData',
  async (commercialData: CommercialData, { rejectWithValue, dispatch }) => {
    try {
      const { status } = await vehicleService.updateCommercialData(nullifyEmptyKeys(commercialData));

      if (status >= 400) {
        throw new Error('Something went wrong');
      }

      return true;
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.message);
    }
  }
);

const createCommercialData = createAsyncThunk(
  'vehicle/createCommercialData',
  async (commercialData: CommercialData, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const { data } = await vehicleService.createCommercialData(nullifyEmptyKeys(commercialData));

      if (!data) {
        throw new Error('Something went wrong');
      }
    } catch (err) {
      if (err?.response?.data?.errors) {
        return rejectWithValue(err.response.data.errors);
      }

      return rejectWithValue(err?.response?.statusText);
    }
  }
);

const vehicleOperations = {
  createVehicle,
  updateVehicle,
  getFilteredVehicles,
  getBrands,
  getModels,
  getVehicleById,
  getVehicleDisplayById,
  removeMedia,
  uploadMadia,
  getVehiclesPowersearch,
  getVehicleLogs,
  getVehicleContractualDataLogs,
  updateContractualData,
  createContractualData,
  getVehicleContractualData,
  enableVehicle,
  disableVehicle,
  getVehicleLogistics,
  getVehicleLogisticsLogs,
  updateLogisticsData,
  createLogisticsData,
  getVehicleCommercialDataLogs,
  updateCommercialData,
  createCommercialData,
  getVehicleCommercialData,
};

export default vehicleOperations;
