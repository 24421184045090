import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import { groupOperations } from 'src/redux/group';
import { isEqual } from 'lodash';
import { DEFAULT_ORGANIZATION_FILTERS, OrganizationFilters, OrganizationSearchResult } from 'src/@types/organizations';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { ToolbarListFilters } from 'src/@types/list';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';

export default function GroupOrganizationsList() {

    const navigate = useNavigate();

    const { id: groupId } = useParams();

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const { isOrganizationsLoading, organizationsList, organizationsTotalCount, group } = useSelector((state: RootState) => state.group);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() =>
        [
            { key: 'all', label: translate('commons.all') },
            { key: 'name', label: translate('commons.name') },
            { key: 'externalId', label: translate('commons.code') }
        ], [translate]);

    const updateCheckField = useCallback((field: string, filtersToCheck: OrganizationFilters) => (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && !isEqual(filtersToCheck[field], DEFAULT_ORGANIZATION_FILTERS[field]), []);

    //---- CUSTOM SEARCH FUNC----//
    const isDefault = (filter: OrganizationFilters) => {

        const found = Object.keys(filter).find((field) => isEqual(filter[field], DEFAULT_ORGANIZATION_FILTERS[field]));

        return !!found;
    };

    const onSearch = useCallback((params: { filters: OrganizationFilters & { sequenceToken: any }, check: boolean }) => {

        const { filters, check } = params;

        if (group && (!organizationsList || isDefault(filters))) {
            dispatch(groupOperations.searchGroupOrganizations({ filters: { ...filters, groups: [groupId!] }, check: check, isVendor: group.groupType === "Organization" }));
        }
    }, [group, groupId, organizationsList]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        if (params.field !== 'options' && group) {
            if (group.groupType === "Organization")
                navigate(PATH_DASHBOARD.organizations.vendor.detail(params.row.id, params.row.type.toLowerCase()));
            else
                navigate(PATH_DASHBOARD.organizations.customer.detail(params.row.id, params.row.type.toLowerCase()));
        }
    };

    const handleLogs = useCallback((id: string, type: string) => {
        if (group) {
            if (group.groupType === "Organization")
                navigate(PATH_DASHBOARD.organizations.vendor.logs(id, type.toLowerCase()));
            else
                navigate(PATH_DASHBOARD.organizations.customer.logs(id, type.toLowerCase()));
        }
    }, [navigate, group]);

    const COLUMNS: GridColDef<OrganizationSearchResult>[] = useMemo(() => [
        {
            field: 'externalId',
            headerName: translate('commons.code'),
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 100 : undefined
        },
        {
            field: 'name',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'type',
            headerName: translate('organization.list.table.type'),
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 150 : undefined
        },
        {
            field: 'credit',
            headerName: translate('organization.list.table.credit'),
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 150 : undefined,
            renderCell: (obj) => <CurrencyAdapter value={obj.row.creditLimit} sx={{ pl: 1 }} />
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.4 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                    />
                );
            }
        }
    ], [actualRow, handleLogs, openMenu, translate, isDesktop]);
    //---- HANDLE TABLE END ----//

    return (
        <InfiniteScrollGenericList
            totalCount={organizationsTotalCount}
            list={organizationsList ?? []}
            isLoading={isOrganizationsLoading}
            defaultFilters={DEFAULT_ORGANIZATION_FILTERS}
            toolbarFiltersList={toolbarFiltersList}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"GroupOrganizations"}
            setActualRow={setActualRow}
            handleCellClick={handleCellClick}
            customSearchFunc={onSearch}
            resetList={() => { }}
        />
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string, type: string) => void,
    object: any,
    currentRow: any
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, object, currentRow }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Organization_ViewLogs]}>
                    <MenuItem
                        onClick={() => {
                            handleLogs(object.id, object.row.type);
                            handleCloseMenu();
                        }}
                    >
                        {translate("commons.logs")}
                    </MenuItem>
                </PermissionBasedGuard>
            }
        />
    );
}