import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse } from "src/@types/commons";
import termConditionOperations from "./termCondition-operations";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";
import { DefaultTermConditionProps, EnableDisableTermConditionProps, TermCondition, TermConditionSearchResult } from "src/@types/termCondition";
import { GenericPagedResponse } from "src/@types/list";

interface TermConditionReducer {
    error: ErrorResponse,
    isLoading: boolean,
    isLogsLoading: boolean,
    filtersInUrl: string,
    termCondition: TermCondition | null,
    totalCount: number,
    termConditionsList: TermConditionSearchResult[],
    termConditionLogs: GenericPagedResponse<LogsItem>,
    pageIndex: number,
    pageSize: number
}

const initialState: TermConditionReducer = {
    error: null,
    isLoading: false,
    isLogsLoading: false,
    filtersInUrl: "",
    termCondition: null,
    totalCount: 0,
    termConditionsList: [],
    termConditionLogs: DEFAULT_LOGS_DATA,
    pageIndex: 0,
    pageSize: 10
};

const TermConditionSlice = createSlice({
    name: 'termAndCondition',
    initialState,
    extraReducers: (builder) => {
        builder
            //--------------------------------------
            .addCase(termConditionOperations.createTermCondition.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(termConditionOperations.createTermCondition.fulfilled, (state, action) => {
                state.termCondition = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(termConditionOperations.updateTermCondition.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(termConditionOperations.updateTermCondition.fulfilled, (state, action) => {
                state.termCondition = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(termConditionOperations.getDefaultTermCondition.rejected, (state, action) => {
                state.termCondition = initialState.termCondition;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(termConditionOperations.getDefaultTermCondition.fulfilled, (state, action) => {
                state.termCondition = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(termConditionOperations.getTermCondition.rejected, (state, action) => {
                state.termCondition = initialState.termCondition;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(termConditionOperations.getTermCondition.fulfilled, (state, action) => {
                state.termCondition = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(termConditionOperations.searchTermConditions.rejected, (state, action) => {
                state.termConditionsList = initialState.termConditionsList;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(termConditionOperations.searchTermConditions.fulfilled, (state, action) => {
                state.termConditionsList = action.payload.items;
                state.totalCount = action.payload.totalCount;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(termConditionOperations.enableDisableTermCondition.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(termConditionOperations.getTermConditionLogs.rejected, (state, action) => {
                state.isLogsLoading = false;
                state.error = action.error.message as string;
                state.termConditionLogs = initialState.termConditionLogs;
            })
            .addCase(termConditionOperations.getTermConditionLogs.fulfilled, (state, action) => {
                state.isLogsLoading = false;
                state.termConditionLogs = action.payload;
            })
            //--------------------------------------
            .addCase(termConditionOperations.defaultTermCondition.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            ;
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startLogsLoading(state) {
            state.isLogsLoading = true;
        },
        resetTermCondition(state) {
            state.termCondition = null;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        //RESET LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableTermConditionSL(state, action: PayloadAction<EnableDisableTermConditionProps>) {
            const { id, action: payloadAction } = action.payload;

            const index = state.termConditionsList.findIndex((term) => term.id === id);

            state.termConditionsList[index].enabled = payloadAction === "enable";
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        defaultTermConditionSL(state, action: PayloadAction<DefaultTermConditionProps>) {

            const { id, entityType } = action.payload;

            const newDefaultIndex = state.termConditionsList.findIndex((term) => term.id === id);

            state.termConditionsList[newDefaultIndex].isDefault = true;

            const oldDefaultIndex = state.termConditionsList.findIndex((term) => term.id !== id && term.isDefault && term.entityType === entityType);

            if (oldDefaultIndex >= 0) state.termConditionsList[oldDefaultIndex].isDefault = false;
        }
    }

});

export const {
    startLoading,
    startLogsLoading,
    resetTermCondition,
    setFiltersInUrl,
    resetPageIndexSize,
    enableDisableTermConditionSL,
    defaultTermConditionSL
} = TermConditionSlice.actions;

export default TermConditionSlice.reducer;