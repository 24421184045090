import axios, { AxiosResponse } from 'axios';
import { Product } from './paths';
import { Tag } from 'src/@types/commons';
import { CategoriesFilter, CategoriesSearchResult, SparePartsFilters, SparePart, SparePartSearchResult, SparePartsStatistics } from 'src/@types/spareParts';
import { CustomFieldSuggest } from 'src/@types/customField';
import { GenericPagedResponse, InfiniteScrollResponse } from 'src/@types/list';

const searchStatistics = (filters: SparePartsFilters): Promise<AxiosResponse<SparePartsStatistics>> => {

    const url = Product.admin.spareParts.statistic();

    return axios.get<SparePartsStatistics>(url, { params: filters, paramsSerializer: { indexes: null } });
};

const search = (filters: SparePartsFilters,): Promise<AxiosResponse<InfiniteScrollResponse<SparePartSearchResult>>> => {

    const url = Product.admin.spareParts.search();

    return axios.get<InfiniteScrollResponse<SparePartSearchResult>>(url, { params: filters, paramsSerializer: { indexes: null } });
};

const searchTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {

    const url = Product.admin.spareParts.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const searchCategories = (options: CategoriesFilter): Promise<AxiosResponse<InfiniteScrollResponse<CategoriesSearchResult>>> => {

    const url = Product.admin.spareParts.searchCategories();

    return axios.get<InfiniteScrollResponse<CategoriesSearchResult>>(url, { params: options, paramsSerializer: { indexes: null } });
};

const detail = (id: string): Promise<AxiosResponse<SparePart>> => {
    const url = Product.admin.spareParts.detail(id);

    return axios.get(url);
};

const enable = (id: string): Promise<AxiosResponse> => {
    const url = Product.admin.spareParts.enable(id);

    return axios.patch(url);
};

const disable = (id: string): Promise<AxiosResponse> => {
    const url = Product.admin.spareParts.disable(id);

    return axios.patch(url);
};

const searchLogs = (id: string, filters: any): Promise<AxiosResponse<GenericPagedResponse<any>>> => {
    const url = Product.admin.spareParts.searchLogs(id);

    return axios.get<any>(url, { params: filters });
};

const customFieldSuggest = (options: CustomFieldSuggest): Promise<AxiosResponse<any>> => {
    const url = Product.admin.spareParts.customFieldSuggest();

    return axios.get<any>(url, { params: options });
};

const defaultExport = {
    search,
    searchStatistics,
    searchTags,
    detail,
    enable,
    disable,
    searchLogs,
    searchCategories,
    customFieldSuggest
};

export default defaultExport;