import { LicenseTypes } from "./tenant";

export enum ClusterPermissionTypes {
    // Users
    UserView = "User_View",
    UserViewLogs = "User_ViewLogs",
    UserCreate = "User_Create",
    UserEdit = "User_Edit",
    UserEnableDisable = "User_EnableDisable",
    UserManageRoles = "User_ManageRoles",
    UserManageOrganizations = "User_ManageOrganizations",

    // Roles
    RoleView = "Role_View",
    RoleViewLogs = "Role_ViewLogs",
    RoleCreate = "Role_Create",
    RoleEdit = "Role_Edit",
    RoleEnableDisable = "Role_EnableDisable",

    //Notifications
    Notification_Create = "Notification_Create",
    Notification_Edit = "Notification_Edit",
    Notification_View = "Notification_View",
}

export const ClusterPermissionsList = {
    Users: [
        ClusterPermissionTypes.UserView,
        ClusterPermissionTypes.UserViewLogs,
        ClusterPermissionTypes.UserCreate,
        ClusterPermissionTypes.UserEdit,
        ClusterPermissionTypes.UserEnableDisable,
        ClusterPermissionTypes.UserManageRoles,
        ClusterPermissionTypes.UserManageOrganizations
    ],
    Roles: [
        ClusterPermissionTypes.RoleView,
        ClusterPermissionTypes.RoleViewLogs,
        ClusterPermissionTypes.RoleCreate,
        ClusterPermissionTypes.RoleEdit,
        ClusterPermissionTypes.RoleEnableDisable
    ],
    Notifications: [
        ClusterPermissionTypes.Notification_Create,
        ClusterPermissionTypes.Notification_Edit,
        ClusterPermissionTypes.Notification_View,
    ]
} as Readonly<{ [key: string]: ClusterPermissionTypes[] }>;

export enum OrganizationPermissionTypes {
    // Organization
    Organization_View = "Organization_View",
    Organization_ViewLogs = "Organization_ViewLogs",
    Organization_Create = "Organization_Create",
    Organization_Edit = "Organization_Edit",
    Organization_EnableDisable = "Organization_EnableDisable",

    // RV Template
    Rv_Template_View = "Rv_Template_View",
    Rv_Template_ViewLogs = "Rv_Template_ViewLogs",
    Rv_Template_Create = "Rv_Template_Create",
    Rv_Template_Edit = "Rv_Template_Edit",
    Rv_Template_EnableDisable = "Rv_Template_EnableDisable",

    // Rv Matrix
    Rv_Matrix_View = "Rv_Matrix_View",
    Rv_Matrix_ViewLogs = "Rv_Matrix_ViewLogs",
    Rv_Matrix_Create = "Rv_Matrix_Create",
    Rv_Matrix_Edit = "Rv_Matrix_Edit",
    Rv_Matrix_EnableDisable = "Rv_Matrix_EnableDisable",
    Rv_Matrix_Download = "Rv_Matrix_Download",
    Rv_Matrix_SendToApproval = "Rv_Matrix_SendToApproval",
    Rv_Matrix_ApproveReject = "Rv_Matrix_ApproveReject",

    // WebShop
    WebShop_Orders_VendorView = "WebShop_Orders_VendorView",
    WebShop_Orders_VendorViewLogs = "WebShop_Orders_VendorViewLogs",
    WebShop_Orders_CustomerView = "WebShop_Orders_CustomerView",
    WebShop_Orders_CustomerViewLogs = "WebShop_Orders_CustomerViewLogs",
    WebShop_Admin_View = "WebShop_Admin_View",
    WebShop_Admin_ViewLogs = "WebShop_Admin_ViewLogs",
    WebShop_Admin_Create = "WebShop_Admin_Create",
    WebShop_Admin_Edit = "WebShop_Admin_Edit",
    WebShop_Admin_EnableDisable = "WebShop_Admin_EnableDisable",
    WebShop_Admin_ManageDiscounts = "WebShop_Admin_ManageDiscounts",
    WebShop_Admin_PublishUnpublish = "WebShop_Admin_PublishUnpublish",
    WebShop_Admin_ViewBaskets = "WebShop_Admin_ViewBaskets",

    //Public Webshop
    WebShop_View = "WebShop_View",
    WebShop_Buy = "WebShop_Buy",

    //Basket
    WebShop_BasketRule_View = "WebShop_BasketRule_View",
    WebShop_BasketRule_ViewLogs = "WebShop_BasketRule_ViewLogs",
    WebShop_BasketRule_Create = "WebShop_BasketRule_Create",
    WebShop_BasketRule_Edit = "WebShop_BasketRule_Edit",
    WebShop_BasketRule_EnableDisable = "WebShop_BasketRule_EnableDisable",

    // Return Requests Customer
    WebShop_ReturnRequests_Requests_CustomerView = "WebShop_ReturnRequests_Requests_CustomerView",
    WebShop_ReturnRequests_Requests_CustomerViewLogs = "WebShop_ReturnRequests_Requests_CustomerViewLogs",
    WebShop_ReturnRequests_Requests_CustomerCreate = "WebShop_ReturnRequests_Requests_CustomerCreate",
    WebShop_ReturnRequests_Requests_CustomerEdit = "WebShop_ReturnRequests_Requests_CustomerEdit",
    WebShop_ReturnRequests_Requests_CustomerEnableDisable = "WebShop_ReturnRequests_Requests_CustomerEnableDisable",
    WebShop_ReturnRequests_Requests_CustomerManageLabel = "WebShop_ReturnRequests_Requests_CustomerManageLabel",
    WebShop_ReturnRequests_Requests_CustomerSkipRules = "WebShop_ReturnRequests_Requests_CustomerSkipRules",
    WebShop_ReturnRequests_Requests_CustomerOpenClaim = "WebShop_ReturnRequests_Requests_CustomerOpenClaim",

    // Return Requests Vendor
    WebShop_ReturnRequests_Requests_VendorView = "WebShop_ReturnRequests_Requests_VendorView",
    WebShop_ReturnRequests_Requests_VendorViewLogs = "WebShop_ReturnRequests_Requests_VendorViewLogs",
    WebShop_ReturnRequests_Requests_VendorCreate = "WebShop_ReturnRequests_Requests_VendorCreate",
    WebShop_ReturnRequests_Requests_VendorEdit = "WebShop_ReturnRequests_Requests_VendorEdit",
    WebShop_ReturnRequests_Requests_VendorEnableDisable = "WebShop_ReturnRequests_Requests_VendorEnableDisable",
    WebShop_ReturnRequests_Requests_VendorValidation = "WebShop_ReturnRequests_Requests_VendorValidation",
    WebShop_ReturnRequests_Requests_VendorAnomaly = "WebShop_ReturnRequests_Requests_VendorAnomaly",
    WebShop_ReturnRequests_Requests_VendorSkipRules = "WebShop_ReturnRequests_Requests_VendorSkipRules",
    WebShop_ReturnRequests_Requests_VendorCloseClaim = "WebShop_ReturnRequests_Requests_VendorCloseClaim",

    // Return Requests Carrier
    WebShop_ReturnRequests_Requests_CarrierManagePickUp = "WebShop_ReturnRequests_Requests_CarrierManagePickUp",
    WebShop_ReturnRequests_Requests_CarrierView = "WebShop_ReturnRequests_Requests_CarrierView",

    // Return Requests Warehouse
    WebShop_ReturnRequests_Requests_VendorWarehouseValidation = "WebShop_ReturnRequests_Requests_VendorWarehouseValidation",

    // Rules
    WebShop_ReturnRequests_Rules_VendorView = "WebShop_ReturnRequests_Rules_VendorView",
    WebShop_ReturnRequests_Rules_VendorViewLogs = "WebShop_ReturnRequests_Rules_VendorViewLogs",
    WebShop_ReturnRequests_Rules_VendorEdit = "WebShop_ReturnRequests_Rules_VendorEdit",
    WebShop_ReturnRequests_Rules_VendorEnableDisable = "WebShop_ReturnRequests_Rules_VendorEnableDisable",

    // Product
    Product_SpareParts_View = "Product_SpareParts_View",
    Product_SpareParts_ViewLogs = "Product_SpareParts_ViewLogs",
    Product_SpareParts_Create = "Product_SpareParts_Create",
    Product_SpareParts_Edit = "Product_SpareParts_Edit",
    Product_SpareParts_EnableDisable = "Product_SpareParts_EnableDisable",
    Product_Vehicles_View = "Product_Vehicles_View",
    Product_Vehicles_ViewLogs = "Product_Vehicles_ViewLogs",
    Product_Vehicles_Create = "Product_Vehicles_Create",
    Product_Vehicles_Edit = "Product_Vehicles_Edit",
    Product_Vehicles_EnableDisable = "Product_Vehicles_EnableDisable",
    Product_Vehicles_ViewContractualData = "Product_Vehicles_ViewContractualData",
    Product_Vehicles_ViewContractualDataLogs = "Product_Vehicles_ViewContractualDataLogs",
    Product_Vehicles_EditContractualData = "Product_Vehicles_EditContractualData",
    Product_Vehicles_ViewLogisticData = "Product_Vehicles_ViewLogisticData",
    Product_Vehicles_ViewLogisticDataLogs = "Product_Vehicles_ViewLogisticDataLogs",
    Product_Vehicles_EditLogisticData = "Product_Vehicles_EditLogisticData",
    Product_Vehicles_ViewCommercialData = "Product_Vehicles_ViewCommercialData",
    Product_Vehicles_ViewCommercialDataLogs = "Product_Vehicles_ViewCommercialDataLogs",
    Product_Vehicles_EditCommercialData = "Product_Vehicles_EditCommercialData",

    //Customer
    Customer_View = "Customer_View",
    Customer_Create = "Customer_Create",
    Customer_Edit = "Customer_Edit",
    Customer_EnableDisable = "Customer_EnableDisable",

    //Shipping
    Shipping_Container_Template_View = "Shipping_Container_Template_View",
    Shipping_Container_Template_Create = "Shipping_Container_Template_Create",
    Shipping_Container_Template_Edit = "Shipping_Container_Template_Edit",
    Shipping_Container_Template_EnableDisable = "Shipping_Container_Template_EnableDisable",
    Shipping_Container_View = "Shipping_Container_View",
    Shipping_Container_ViewLogs = "Shipping_Container_ViewLogs",
    Shipping_Container_Open = "Shipping_Container_Open",
    Shipping_Container_Create = "Shipping_Container_Create",
    Shipping_Container_Ship = "Shipping_Container_Ship",
    Shipping_Container_Close = "Shipping_Container_Close",
    Shipping_Container_AddItems = "Shipping_Container_AddItems",
    Shipping_Container_RemoveItems = "Shipping_Container_RemoveItems",

    //Groups
    Group_View = "Group_View",
    Group_ViewLogs = "Group_ViewLogs",
    Group_Create = "Group_Create",
    Group_Edit = "Group_Edit",
    Group_EnableDisable = "Group_EnableDisable",

    //Terms and Condition
    TermsAndCondition_View = "TermsAndCondition_View",
    TermsAndCondition_ViewLogs = "TermsAndCondition_ViewLogs",
    TermsAndCondition_Create = "TermsAndCondition_Create",
    TermsAndCondition_Edit = "TermsAndCondition_Edit",
    TermsAndCondition_EnableDisable = "TermsAndCondition_EnableDisable",

    // Report
    Report_View = "Report_View",

    //Ticket
    Support_TicketManagement = "Support_TicketManagement"
}

export const PermissionCategoryArr = [
    'Organization',
    'RvTemplate',
    'RvMatrix',
    'WebShop',
    'ProductVehicle',
    'ProductSparePart',
    'Customer',
    'Shipping',
    'Groups',
    'Users',
    'Roles',
    'Notifications',
    'TermsCondtions',
    'Report'
] as const;

export type PermissionCategory = typeof PermissionCategoryArr[number];

export const OrganizationPermissionsList = {
    Organization: [
        OrganizationPermissionTypes.Organization_View,
        OrganizationPermissionTypes.Organization_ViewLogs,
        OrganizationPermissionTypes.Organization_Create,
        OrganizationPermissionTypes.Organization_Edit,
        OrganizationPermissionTypes.Organization_EnableDisable,
        OrganizationPermissionTypes.Support_TicketManagement
    ],
    RvTemplate: [
        OrganizationPermissionTypes.Rv_Template_View,
        OrganizationPermissionTypes.Rv_Template_ViewLogs,
        OrganizationPermissionTypes.Rv_Template_Create,
        OrganizationPermissionTypes.Rv_Template_Edit,
        OrganizationPermissionTypes.Rv_Template_EnableDisable
    ],
    RvMatrix: [
        OrganizationPermissionTypes.Rv_Matrix_View,
        OrganizationPermissionTypes.Rv_Matrix_ViewLogs,
        OrganizationPermissionTypes.Rv_Matrix_Create,
        OrganizationPermissionTypes.Rv_Matrix_Edit,
        OrganizationPermissionTypes.Rv_Matrix_EnableDisable,
        OrganizationPermissionTypes.Rv_Matrix_Download,
        OrganizationPermissionTypes.Rv_Matrix_SendToApproval,
        OrganizationPermissionTypes.Rv_Matrix_ApproveReject
    ],
    WebShop: [
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorViewLogs,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEnableDisable,
        OrganizationPermissionTypes.WebShop_Orders_VendorView,
        OrganizationPermissionTypes.WebShop_Orders_VendorViewLogs,
        OrganizationPermissionTypes.WebShop_Orders_CustomerView,
        OrganizationPermissionTypes.WebShop_Orders_CustomerViewLogs,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerViewLogs,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerCreate,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerEdit,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerEnableDisable,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerManageLabel,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorViewLogs,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorCreate,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorEdit,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorEnableDisable,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorWarehouseValidation,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorSkipRules,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerSkipRules,
        OrganizationPermissionTypes.WebShop_Admin_View,
        OrganizationPermissionTypes.WebShop_Admin_ViewLogs,
        OrganizationPermissionTypes.WebShop_Admin_Create,
        OrganizationPermissionTypes.WebShop_Admin_Edit,
        OrganizationPermissionTypes.WebShop_Admin_EnableDisable,
        OrganizationPermissionTypes.WebShop_Admin_ManageDiscounts,
        OrganizationPermissionTypes.WebShop_Admin_PublishUnpublish,
        OrganizationPermissionTypes.WebShop_Admin_ViewBaskets,
        OrganizationPermissionTypes.WebShop_BasketRule_View,
        OrganizationPermissionTypes.WebShop_BasketRule_ViewLogs,
        OrganizationPermissionTypes.WebShop_BasketRule_Create,
        OrganizationPermissionTypes.WebShop_BasketRule_Edit,
        OrganizationPermissionTypes.WebShop_BasketRule_EnableDisable,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerOpenClaim,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorCloseClaim,
        OrganizationPermissionTypes.WebShop_View,
        OrganizationPermissionTypes.WebShop_Buy
    ],
    ProductVehicle: [
        OrganizationPermissionTypes.Product_Vehicles_View,
        OrganizationPermissionTypes.Product_Vehicles_ViewLogs,
        OrganizationPermissionTypes.Product_Vehicles_Create,
        OrganizationPermissionTypes.Product_Vehicles_Edit,
        OrganizationPermissionTypes.Product_Vehicles_EnableDisable,
        OrganizationPermissionTypes.Product_Vehicles_ViewContractualData,
        OrganizationPermissionTypes.Product_Vehicles_ViewContractualDataLogs,
        OrganizationPermissionTypes.Product_Vehicles_EditContractualData,
        OrganizationPermissionTypes.Product_Vehicles_ViewLogisticData,
        OrganizationPermissionTypes.Product_Vehicles_ViewLogisticDataLogs,
        OrganizationPermissionTypes.Product_Vehicles_EditLogisticData,
        OrganizationPermissionTypes.Product_Vehicles_ViewCommercialData,
        OrganizationPermissionTypes.Product_Vehicles_ViewCommercialDataLogs,
        OrganizationPermissionTypes.Product_Vehicles_EditCommercialData
    ],
    ProductSparePart: [
        OrganizationPermissionTypes.Product_SpareParts_View,
        OrganizationPermissionTypes.Product_SpareParts_ViewLogs,
        OrganizationPermissionTypes.Product_SpareParts_Create,
        OrganizationPermissionTypes.Product_SpareParts_Edit,
        OrganizationPermissionTypes.Product_SpareParts_EnableDisable
    ],
    Customer: [
        OrganizationPermissionTypes.Customer_Create,
        OrganizationPermissionTypes.Customer_Edit,
        OrganizationPermissionTypes.Customer_EnableDisable,
        OrganizationPermissionTypes.Customer_View
    ],
    Shipping: [
        OrganizationPermissionTypes.Shipping_Container_Template_View,
        OrganizationPermissionTypes.Shipping_Container_Template_Create,
        OrganizationPermissionTypes.Shipping_Container_Template_Edit,
        OrganizationPermissionTypes.Shipping_Container_Template_EnableDisable,
        OrganizationPermissionTypes.Shipping_Container_View,
        OrganizationPermissionTypes.Shipping_Container_ViewLogs,
        OrganizationPermissionTypes.Shipping_Container_Open,
        OrganizationPermissionTypes.Shipping_Container_Create,
        OrganizationPermissionTypes.Shipping_Container_Ship,
        OrganizationPermissionTypes.Shipping_Container_Close,
        OrganizationPermissionTypes.Shipping_Container_RemoveItems,
        OrganizationPermissionTypes.Shipping_Container_AddItems
    ],
    Groups: [
        OrganizationPermissionTypes.Group_View,
        OrganizationPermissionTypes.Group_ViewLogs,
        OrganizationPermissionTypes.Group_Create,
        OrganizationPermissionTypes.Group_Edit,
        OrganizationPermissionTypes.Group_EnableDisable
    ],
    TermsCondtions: [
        OrganizationPermissionTypes.TermsAndCondition_View,
        OrganizationPermissionTypes.TermsAndCondition_ViewLogs,
        OrganizationPermissionTypes.TermsAndCondition_Create,
        OrganizationPermissionTypes.TermsAndCondition_Edit,
        OrganizationPermissionTypes.TermsAndCondition_EnableDisable
    ],
    Report: [
        OrganizationPermissionTypes.Report_View
    ]
} as Readonly<{ [key: string]: OrganizationPermissionTypes[] }>;

export const ALL_PERMISSIONS = { ...ClusterPermissionsList, ...OrganizationPermissionsList };

export const LicensePermissionMap: Record<LicenseTypes, PermissionCategory[]> = {
    [LicenseTypes.Core]: ['Organization', 'Customer', 'TermsCondtions'],
    [LicenseTypes.Rv]: ['RvMatrix', 'RvTemplate'],
    [LicenseTypes.ProductVehicle]: ['ProductVehicle'],
    [LicenseTypes.ProductSparePart]: ['ProductSparePart'],
    [LicenseTypes.WebShop]: ['WebShop'],
    [LicenseTypes.Report]: ['Report'],
    [LicenseTypes.Shipping]: ['Shipping'],
    [LicenseTypes.Group]: ['Groups'],
    [LicenseTypes.Notification]: []
};

export type Permission = {
    id: OrganizationPermissionTypes | ClusterPermissionTypes,
    label: string,
    type: PermissionCategory
}