import { INFINITE_SCROLL_LIST_FILTERS, InfiniteScrollListFilters, SequenceToken } from "./list";
import { OrganizationSearchResult } from "./organizations";
import { UserSearchResult } from "./user";

export const PropertyCategoryArr = ["Organization", "User"] as const;

export type PropertyCategory = typeof PropertyCategoryArr[number];

export type GroupCategory = "None" | "Organization" | "Customer";

export const GroupStatusesArr = ["Creating", "Updating", "Active"] as const;

export type GroupStatusType = typeof GroupStatusesArr[number];

export const GroupEnableStatusesArr = ["", "Enabled", "Disabled"] as const;

export type GroupEnableStatusType = typeof GroupEnableStatusesArr[number];

export type WhiteBlackListDisplay = {
    users: UserSearchResult[],
    organizations: OrganizationSearchResult[]
}

export type WhiteListSearchProduct = {
    id: string
    code?: string,
    name: string,
    type: PropertyCategory
}

export type PropertyType = {
    name: string,
    values: string[],
    category: PropertyCategory
}

export type NewGroup = {
    displayName: string,
    permissionDependency: {
        permissions: string[],
        atLeastOne: boolean
    },
    propertyDependency: {
        properties: PropertyType[]
    },
    blacklist: {
        users: string[],
        organizations: string[]
    },
    whitelist: {
        users: string[],
        organizations: string[]
    }
    groupType: GroupCategory
};

export type GroupStatistics = {
    all: number,
    disabled: number,
    enabled: number
}

export interface GroupSearchResult extends SequenceToken {
    groupId: string,
    groupType: GroupCategory,
    organizationId: string,
    displayName: string,
    createdOn: string,
    enabled: boolean,
    numberOfOrganizations: number,
    numberOfUsers: number,
    status: GroupStatusType
}

export interface GroupFilters extends InfiniteScrollListFilters {
    [key: string]: any
    all?: string,
    displayName?: string,
    groupType?: PropertyCategory | "all",
    onlyEnabled?: boolean,
    from?: Date,
    to?: Date,
    statusSidebar?: GroupStatusType | "all"
}

export type GroupType = {
    groupId: string,
    organizationId: string,
    displayName: string,
    groupType: GroupCategory,
    permissionDependency: {
        permissions: string[],
        atLeastOne: true
    },
    propertyDependency: {
        properties: PropertyType[]
    },
    createdOn: string,
    status: GroupStatusType,
    enabled: boolean,
    numberOfUsers: number,
    numberOfOrganizations: number,
    blacklistCount: number,
    whitelistCount: number
};

export type EnableDisableGroupSliceProps = {
    id: string,
    action: "Enable" | "Disable"
    tab: GroupEnableStatusType
}

//-------------------------------------------------------------------------
export const DEFAULT_NEW_GROUP: NewGroup = {
    displayName: "",
    permissionDependency: {
        atLeastOne: false,
        permissions: []
    },
    propertyDependency: {
        properties: []
    },
    blacklist: {
        organizations: [],
        users: []
    },
    whitelist: {
        organizations: [],
        users: []
    },
    groupType: 'None'
};

export const DEFAULT_GROUP_STATISTICS: GroupStatistics = {
    all: 0,
    disabled: 0,
    enabled: 0
};

export const DEFAULT_GROUP_FILTERS: GroupFilters = {
    ...INFINITE_SCROLL_LIST_FILTERS,
    all: "",
    displayName: "",
    groupType: "all",
    onlyEnabled: undefined,
    statusSidebar: "all",
    from: undefined,
    to: undefined,
    customFields: {}
};