import { Payments, Percent, AccountBalance, Settings } from "@mui/icons-material";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { useLocales } from "src/locales";
import { Level } from "src/@types/webshop";
import { DiscountThresholdProgressBar, StatisticBox } from "../newWebshop/WebshopUtilComponents";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { DEFAULT_ORDERS_FILTERS, OrderFilters, OrderSearchResult } from "src/@types/orders";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import DateZone from "src/appComponents/DateZone";
import { getPrice } from "src/utils/currency";
import useResponsive from "src/hooks/useResponsive";
import { CurrencyTypes } from "src/@types/vehicle";
import { PriceAmount } from "src/@types/currency";
import { useCallback, useEffect, useMemo } from "react";
import { useSettingsContext } from "src/components/settings";
import TreeViewInfiniteScrollGenericList from "src/utils/list/TreeViewInfiniteScrollGenericList";
import { SidebarListFilters, ToolbarListFilters } from "src/@types/list";
import * as ordersServices from "src/services/ordersServices";
import { isEqual } from "lodash";
import { setOrdersFiltersInUrl } from "src/redux/orders/orders-slices";
import ItemsTableRow from "../../orders/list/ItemsTableRow";
import { ordersOperations } from "src/redux/orders";
import { manageAmountFilters } from "src/utils/list/utils/functions";
import organizationService from "src/services/organizationService";
import { PATH_DASHBOARD } from "src/routes/paths";
import { webshopOperations } from "src/redux/webshop";

interface PublicStatisticsDetailProps {
    isVendor: boolean,
    shopId: string,
    customerId: string
}

export default function PublicStatisticsDetail({ isVendor, customerId, shopId }: PublicStatisticsDetailProps) {

    const theme = useTheme();

    const { translate } = useLocales();

    const { shopOrderStatistics, displayShop } = useSelector((state: RootState) => state.webshop);

    const multipleOrderthreshold = displayShop?.discountModel?.multiLevel?.thresholds;

    const isMultiple = displayShop?.discountModel?.multiLevel?.isMultiOrder;

    useEffect(() => {
        dispatch(webshopOperations.getAdminShopCustomerOrderStatistics({ shopId, customerId }));
    }, [customerId, shopId]);

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h4">
                    {translate('webshop.detail.statistics.title')}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' }/*  isDesktop ? 'row' : 'column' */ }}>
                <StatisticBox
                    Icon={<Payments fontSize="medium" color="success" />}
                    circleColor={theme.palette.success.lighter}
                    label={translate('webshop.detail.statistics.totalGrossPurchased')}
                    value={
                        <>
                            {shopOrderStatistics &&
                                <CurrencyAdapter
                                    variant={"h4"}
                                    value={shopOrderStatistics.grossAmount}
                                    excludeVatAsterisk
                                />
                            }
                        </>
                    }
                />
                <StatisticBox
                    Icon={<Percent fontSize="medium" color="info" />}
                    circleColor={theme.palette.info.lighter}
                    label={translate('basket.detail.totalDiscount')}
                    value={
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            {shopOrderStatistics &&
                                <CurrencyAdapter
                                    variant={"h4"}
                                    value={shopOrderStatistics.totalDiscountAmount}
                                    excludeVatAsterisk
                                />
                            }
                        </Box>
                    }
                />
                <StatisticBox
                    Icon={<AccountBalance fontSize="medium" color="warning" />}
                    circleColor={theme.palette.warning.lighter}
                    label={translate('webshop.detail.statistics.totalNetPurchased')}
                    value={
                        <>
                            {shopOrderStatistics &&
                                <CurrencyAdapter
                                    variant={"h4"}
                                    value={shopOrderStatistics.netAmount}
                                    excludeVatAsterisk
                                />
                            }
                        </>
                    }
                />
                <StatisticBox
                    Icon={<Settings fontSize="medium" />}
                    circleColor={theme.palette.primary.lighter}
                    label={translate('webshop.detail.statistics.totalQtyPurchased')}
                    value={<Typography variant="h4">{shopOrderStatistics?.numberOfOrders ?? "0"}</Typography>}
                />
            </Box>

            {isMultiple && multipleOrderthreshold && shopOrderStatistics?.grossAmount &&
                <ThresholdsProgess currentAmount={shopOrderStatistics.grossAmount} thresholds={multipleOrderthreshold} />
            }
            <Box>

                <Typography sx={{ my: 3 }} variant="h6">{translate('webshop.detail.statistics.orderList')}</Typography>
                <Orders isVendor={isVendor} customerId={customerId} shopId={shopId} />
            </Box>
        </Box>
    );
}

interface ThresholdsProgessProps {
    currentAmount: PriceAmount,
    thresholds: Level[]
    lightView?: boolean
}

export function ThresholdsProgess({ currentAmount, thresholds, lightView }: ThresholdsProgessProps) {

    const { translate } = useLocales();

    const { currency } = useSettingsContext();

    const price = useMemo(() => +getPrice(currentAmount), [currentAmount]);

    const nextThreshold = useMemo(() => {
        return thresholds.find(t => t.toAmount && (t.toAmount > +price)) ?? thresholds[thresholds.length - 1];
    }, [price, thresholds]);

    const isLast = thresholds[thresholds.length - 1].toAmount === nextThreshold.toAmount;

    const light = (
        <Box sx={{ width: '100%', padding: 3 }}>

            <Typography variant="subtitle1">{translate('webshop.detail.statistics.istantThresholdDscount')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CurrencyAdapter
                    variant="h6"
                    fontWeight="bold"
                    value={{
                        withVat: price,
                        currentCurrency: currentAmount.currentCurrency ?? CurrencyTypes.EUR
                    }}
                />
                {!isLast &&
                    <Typography component="span" sx={{ fontSize: 14, marginLeft: 1 }}>
                        +{(nextThreshold.toAmount ?? 0) - price} {currency.symbol} {translate("webshop.detail.statistics.toNextThreshold")}
                    </Typography>}
            </Box>
            <Box sx={{ px: 2 }}>
                <DiscountThresholdProgressBar currentAmount={price} thresholds={thresholds} />
            </Box>
        </Box>);

    return (
        lightView ? light :
            <Box>
                <Typography sx={{ my: 3 }} variant="h6">
                    {translate('webshop.detail.statistics.multipleOrderThresholds')}
                </Typography>

                <Card sx={{ p: 3 }}>
                    <Box sx={{ width: '100%', padding: 2 }}>

                        <Typography variant="h6">{translate('webshop.detail.statistics.istantThresholdDscount')}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CurrencyAdapter
                                variant="h4"
                                fontWeight="bold"
                                value={{
                                    withVat: price,
                                    currentCurrency: currentAmount.currentCurrency ?? CurrencyTypes.EUR
                                }}
                            />
                            {!isLast &&
                                <Typography component="span" sx={{ fontSize: 14, marginLeft: 1 }}>
                                    +{(nextThreshold.toAmount ?? 0) - price} {currency.symbol} {translate("webshop.detail.statistics.toNextThreshold")}
                                </Typography>}
                        </Box>
                        <DiscountThresholdProgressBar currentAmount={price} thresholds={thresholds} />
                    </Box>
                </Card>
            </Box>
    );
}

function Orders({ isVendor, customerId, shopId }: { isVendor: boolean, customerId: string, shopId: string }) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const { orderList, isOrderLoading, totalCount, ordersFiltersInUrl } = useSelector((state: RootState) => state.orders);

    const fullKeysToDelete: string[] = ["from", "to"];

    const updateCheckField = useCallback((field: string, filtersToCheck: OrderFilters) =>
        ((filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && field !== "customer")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_ORDERS_FILTERS[field])
        , []);

    const handleCellClick = (params: GridCellParams<OrderSearchResult>) => {
        if (params.field !== GRID_DETAIL_PANEL_TOGGLE_FIELD) {
            window.open(isVendor ? PATH_DASHBOARD.orders.vendor.detail(params.row.id) : PATH_DASHBOARD.orders.customer.detail(params.row.id), '_blank');
        }
    };

    const customSearch = useCallback((options: { filters: OrderFilters & { sequenceToken: any }, check: boolean }) => {

        const itemCustomFieldsFilters: Record<string, string> = Object.entries(options.filters.itemCustomFields || {})
            .map(([k, val]) => ({
                key: "itemCustomFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFilters = { ...options.filters, ...itemCustomFieldsFilters, customerId, shopId };

        delete updatedFilters.itemCustomFields;

        dispatch(ordersOperations.searchOrders({ ...options, isVendor, filters: updatedFilters }));
    }, [customerId, isVendor, shopId]);

    //---- FUNCTION TO USE TO ADAPT FILTERS BEFORE UPDATING FILTERS IN URL ----//
    const updateInUrlFiltersChecks = useCallback((filters: OrderFilters) => {
        if (filters.minAmount && filters.maxAmount) return manageAmountFilters("minAmount", "maxAmount", filters);

        return filters;
    }, []);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() => [
        {
            key: 'itemExternalId',
            label: translate('request.partReference')
        }
    ], [translate]);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "prettyName",
            label: translate('webshop.detail.statistics.tableHeaders.idOrder'),
            type: "TextField"
        },
        {
            name: "amount",
            label: translate(`orders.tableHeaders.netAmount`),
            type: "InputRange"
        },
        {
            name: "",
            label: translate(`orders.filter.part`),
            type: "Section"
        },
        {
            name: "itemName",
            label: translate(`orders.filter.itemName`),
            type: "TextField"
        },
        {
            name: "itemExternalId",
            label: translate(`request.partReference`),
            type: "TextField"
        },
        {
            name: "",
            label: translate(`commons.tags`),
            type: "Section"
        },
        {
            name: "tags",
            label: `${translate("commons.tags")}`,
            type: "TagsAutocomplete",
            tagsService: ordersServices.searchOrdersTags,
            toParse: true
        }
    ], [translate]);

    const COLUMNS: GridColDef<OrderSearchResult>[] = [
        {
            field: 'prettyName',
            headerName: `${translate('webshop.detail.statistics.tableHeaders.idOrder')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (param) =>
                <Typography variant="subtitle2">{param.row.prettyName ?? "—"}</Typography>
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.35 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) =>
                <DateZone
                    date={params.row.createdOn}
                    noSeconds
                    shortMonth
                    fontSize={"0.875rem"}
                />
        },
        {
            field: 'totalQuantity',
            headerName: `${translate('orders.tableHeaders.quantity')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: true,
        },
        {
            field: 'grossAmount.withoutVat',
            headerName: `${translate('orders.tableHeaders.grossAmount')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) => <CurrencyAdapter value={params.row.grossAmount} />
        },
        {
            field: 'totalDiscountAmount.withoutVat',
            headerName: `${translate('orders.tableHeaders.discountAmount')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) => <CurrencyAdapter value={params.row.totalDiscountAmount} />
        },
        {
            field: 'netAmount.withoutVat',
            headerName: `${translate('orders.tableHeaders.netAmount')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) => <CurrencyAdapter value={params.row.netAmount} />
        }
    ];

    //---- EXTRA FUNCTION IN SEARCH ----//
    const extraFunctionInSearch = useCallback(async (filters: OrderFilters, getValues: any, setValue: any) => {
        if (filters.customerId && filters.customerId !== getValues("customer")?.id) {

            const { data } = await organizationService.detailGeneric(filters.customerId);

            setValue("customer", data);
        }
    }, []);

    return (
        <TreeViewInfiniteScrollGenericList
            list={orderList}
            isLoading={isOrderLoading}
            totalCount={totalCount}
            defaultFilters={DEFAULT_ORDERS_FILTERS}
            specificFullKeysToDelete={fullKeysToDelete}
            toolbarFiltersList={toolbarFiltersList}
            filtersInSidebar={filtersInSidebar}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={'Orders'}
            itemCustomfieldContext={["SpareParts"]}
            setActualRow={() => { }}
            handleCellClick={handleCellClick}
            resetList={() => { }}
            customSearchFunc={customSearch}
            filtersInUrl={ordersFiltersInUrl}
            setFiltersInUrl={setOrdersFiltersInUrl}
            listDescription={translate('orders.messages.subtitle')}
            updateInUrlFiltersChecks={updateInUrlFiltersChecks}
            datesGeneralFilter={{
                showDates: true
            }}
            getDetailPanelContent={(params) =>
                <ItemsTableRow
                    dense={false}
                    order={params.row}
                />
            }
            showVatAdvice
            //----------------
            extraFunctionForRequest={extraFunctionInSearch}
        />
    );
}