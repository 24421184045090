import { Box, Chip, TextField, Typography, alpha, useTheme } from "@mui/material";
import { useLocales } from "src/locales";
import { UIEvent, useCallback, useEffect, useMemo, useState } from "react";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { OrganizationSearchResult } from "src/@types/organizations";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import organizationService from "src/services/organizationService";
import { useFormContext } from "react-hook-form";
import { UserManager, UserOrganization } from "src/@types/user";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { organizationsOperations } from "src/redux/organizations";
import { AltInfiniteAutocomplete } from "src/appComponents/InfiniteAutocomplete";

export default function OrganizationAssignment() {

    const { translate } = useLocales();

    const { watch, setValue } = useFormContext();

    const user = watch();

    const theme = useTheme();

    const { isLoading, user: userFromState } = useSelector((state: RootState) => state.user);

    const { isLoading: orgLoading } = useSelector((state: RootState) => state.organizations);

    const userToShow: UserManager = useMemo(() => {
        if (userFromState && userFromState.organizations) return userFromState;

        return user as UserManager;
    }, [user, userFromState]);

    const [isCompaniesLoading, setIsCompaniesLoading] = useState<boolean>(true);

    const [userCompanies, setUserCompanies] = useState<UserOrganization[]>(userToShow.organizations ?? []);

    const getCompanies = useCallback(async () => {

        try {

            if (userToShow.organizations && userToShow.organizations.length > 0) {
                const com = await organizationService.detailMultiple(userToShow.organizations.map((org: UserOrganization) => org.organizationId));

                setUserCompanies(com.data.map((organization) => {

                    let tmp: UserOrganization = {
                        organizationId: organization.id,
                        name: organization.name,
                        type: organization.type as "Company" | "Branch" | "Hub" | "Holding" | "",
                        roles: userToShow.organizations.find((org: UserOrganization) => org.organizationId === organization.id)?.roles || [],
                        externalId: organization.externalId
                    };

                    return tmp;
                }));
            }
            setIsCompaniesLoading(false);
        } catch {
            setIsCompaniesLoading(false);
        }
    }, [userToShow]);

    useEffect(() => {
        getCompanies();
    }, []);

    const filterOptions = (options: OrganizationSearchResult[], { inputValue }: { inputValue: string }) => {

        const inputLower = inputValue.toLowerCase();

        const userIds = userCompanies.map(v => v.organizationId);

        options = options.filter((option) => !userIds.includes(option.id));

        if (inputValue === '') {
            return options;
        }

        return options.filter(
            (option) =>
                option.name.toLowerCase().includes(inputLower) ||
                (option.externalId && option.externalId.includes(inputLower))
        );
    };

    const handleChange = (organization: OrganizationSearchResult | string) => {

        if (typeof organization !== "string") {
            if (userCompanies.find((v) => v.organizationId === organization.id))
                return;

            let tmp: UserOrganization = {
                organizationId: organization.id,
                name: organization.name,
                type: organization.type as "Company" | "Branch" | "Hub" | "Holding" | "",
                roles: [],
                externalId: organization.externalId
            };

            setUserCompanies(prev => prev.concat([tmp]));
        }
    };

    useEffect(() => {
        setValue("organizations", userCompanies);
    }, [userCompanies, setValue]);

    return (
        <>
            {(isCompaniesLoading || isLoading) ? <LoadingScreen /> : (
                <Box>

                    <Typography variant="h5">
                        {`${translate('commons.entity')}`}
                    </Typography>

                    <Typography variant="subtitle2" sx={{ mt: 3, mb: 2 }}>
                        {`${translate('user.form.selectEntity')}`}
                    </Typography>

                    <Box sx={{ justifyContent: 'center', display: 'flex', pb: 0 }}>
                        <AltInfiniteAutocomplete<OrganizationSearchResult>
                            fullWidth
                            id="organizationSelection"
                            searchCallBack={(params) => dispatch(organizationsOperations.searchOrganizations({
                                filters:
                                {
                                    ...params,
                                    status: 'Enabled'
                                },
                                check: !!params.sequenceToken,
                                isVendor: true
                            })).unwrap()}
                            loading={orgLoading}
                            onChange={(_, v) => v && handleChange(v)}
                            getOptionLabel={(option) => typeof option !== "string" ? option.name : ""}
                            filterOptions={filterOptions}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    <Typography sx={{ mr: 2 }}>{option.externalId ? option.externalId + " - " : ""}{option.name}</Typography>
                                    <Chip label={option.type} />
                                </li>
                            }
                            renderInput={(params) => (<TextField {...params} label={`${translate('user.form.searchIdName')}`} />)}
                        />
                    </Box>

                    {
                        userCompanies.map((userCompanies, index) => (

                            <Box key={index} sx={{ my: 3 }}>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <GroupWorkIcon />
                                        <Typography variant="h5" sx={{ ml: 2 }}>{userCompanies.type}</Typography>
                                    </div>

                                    <Box sx={{ mt: 2, display: 'flex', gap: '16px', alignSelf: 'stretch', alignItems: 'center' }}>

                                        <TextField
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    background: alpha(theme.palette.grey[400], 0.3),
                                                    borderRadius: '8px'
                                                }
                                            }}
                                            label={translate(`commons.code`)}
                                            fullWidth
                                            disabled
                                            value={userCompanies.externalId || '-'}
                                        />

                                        <TextField
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    background: alpha(theme.palette.grey[400], 0.3),
                                                    borderRadius: '8px'
                                                }
                                            }}
                                            label={translate(`commons.name`)}
                                            fullWidth
                                            disabled
                                            value={userCompanies.name}
                                        />

                                        <DeleteForeverIcon
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => setUserCompanies(prev => prev.filter(x => x.organizationId !== userCompanies.organizationId))}
                                        />

                                    </Box>
                                </Box>
                            </Box>
                        ))
                    }

                </Box>
            )}
        </>
    );
}