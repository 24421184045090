import axios, { AxiosResponse } from 'axios';
import { StorageUriHttpResponse, Tag } from 'src/@types/commons';
import { MediaType, ServiceSearchFilters, Vehicle, VehicleSearchResult } from '../@types/vehicle';
import { Product } from './paths';
import { omit } from "lodash";
import { ContractualData } from 'src/@types/contractualData';
import { LogisticsData } from 'src/@types/logistics';
import { CommercialData } from 'src/@types/commercialData';
import { GenericPagedResponse } from 'src/@types/list';

const detail = (id: string): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.detail(id);

    return axios.get<Vehicle>(url);
};

const display = (id: string): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.display(id);

    return axios.get<Vehicle>(url);
};

const create = (vehicle: Vehicle): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.create();

    return axios.post(url, vehicle);
};

const update = (vehicle: Vehicle): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.update();

    return axios.put(url, vehicle);
};

const enable = (vehicleId: string): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.enable(vehicleId);

    return axios.patch(url);
};

const disable = (vehicleId: string): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.disable(vehicleId);

    return axios.patch(url);
};

const search = (options: ServiceSearchFilters)
    : Promise<AxiosResponse<GenericPagedResponse<VehicleSearchResult>>> => {
    const url = Product.admin.vehicle.search();

    return axios.get<GenericPagedResponse<VehicleSearchResult>>(url, {
        params: options
    });
};

const powersearch = (options: { pageIndex: number, pageSize: number, all: string })
    : Promise<AxiosResponse<GenericPagedResponse<VehicleSearchResult>>> => {
    const url = Product.admin.vehicle.search();

    return axios.get<GenericPagedResponse<VehicleSearchResult>>(url, {
        params: options
    });
};

const searchTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.vehicle.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const suggestBrand = (searchTerm?: string | null): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.vehicle.searchBrand();

    return axios.get<Tag[]>(url, {
        params: {
            query: searchTerm || "",
            limit: 3
        }
    });
};

const suggestModel = (brand: string, searchTerm?: string | null): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.vehicle.searchModel();

    return axios.get<Tag[]>(url, {
        params: {
            brand: brand,
            query: searchTerm || "",
            limit: 3
        }
    });
};

const suggestModelCode = (brand: string, searchTerm?: string | null): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.vehicle.searchModelCode();

    return axios.get<Tag[]>(url, {
        params: {
            brand: brand,
            query: searchTerm || "",
            limit: 3
        }
    });
};

const suggestVersion = (brand: string, model: string, searchTerm?: string | null): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.vehicle.searchVersion();

    return axios.get<Tag[]>(url, {
        params: {
            brand: brand,
            model: model,
            query: searchTerm || "",
            limit: 3
        }
    });
};

const suggestCarLine = (brand: string, searchTerm?: string | null): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.vehicle.searchCarline();

    return axios.get<Tag[]>(url, {
        params: {
            brand: brand,
            query: searchTerm || "",
            limit: 3
        }
    });
};

const getMediaUploadUrl = (vehicleId: string, mediaType: string, filename: string): Promise<AxiosResponse<StorageUriHttpResponse>> => {
    const url = Product.admin.vehicle.getMediaUploadUrl(vehicleId, mediaType, filename);

    return axios.get<StorageUriHttpResponse>(url);
};

const uploadMedia = (uploadUrl: string, media: File): Promise<AxiosResponse<StorageUriHttpResponse>> =>
    axios.put<StorageUriHttpResponse>(uploadUrl, media, {
        headers: {
            'Content-Type': media.type
        }
    });

const removeMedia = (vehicleId: string, mediaType: MediaType, mediaUrls: string[]): Promise<AxiosResponse<void>> => {
    const url = Product.admin.vehicle.removeMedia(vehicleId);

    return axios.patch<void>(url, {
        mediaType,
        mediaUrls
    });
};

const searchVehicleLogs = (options: any)
    : Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.logs(options?.id);

    return axios.get<any>(url, {
        params: omit(options, "id"),
        paramsSerializer: {
            indexes: null
        }
    });
};

const searchContractualDataLogs = (options: any)
    : Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.getContractualDataLogs(options?.id);

    return axios.get<any>(url, {
        params: omit(options, "id"),
        paramsSerializer: {
            indexes: null
        }
    });
};

const searchContractualData = (vehicleID: any)
    : Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.getContractualData(vehicleID);

    return axios.get<any>(url);
};

const updateContractualData = (contract: ContractualData): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.putContractualData();

    return axios.put(url, contract);
};

const createContractualData = (contract: ContractualData): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.postContractualData();

    return axios.post(url, contract);
};

const searchLogistics = (vehicleID: any)
    : Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.getLogistics(vehicleID);

    return axios.get<any>(url);
};

const searchLogisticsLogs = (options: any)
    : Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.getLogisticsLogs(options?.id);

    return axios.get<any>(url, {
        params: omit(options, "id"),
        paramsSerializer: {
            indexes: null
        }
    });
};

const updateLogisticsData = (logistic: LogisticsData): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.putLogistics();

    return axios.put(url, logistic);
};

const createLogisticsData = (logistic: LogisticsData): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.postLogistics();

    return axios.post(url, logistic);
};

const searchCommercialDataLogs = (options: any)
    : Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.getCommercialDataLogs(options?.id);

    return axios.get<any>(url, {
        params: omit(options, "id"),
        paramsSerializer: {
            indexes: null
        }
    });
};

const searchCommercialData = (vehicleID: any)
    : Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.getCommercialData(vehicleID);

    return axios.get<any>(url);
};

const updateCommercialData = (contract: CommercialData): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.putCommercialData();

    return axios.put(url, contract);
};

const createCommercialData = (contract: CommercialData): Promise<AxiosResponse> => {
    const url = Product.admin.vehicle.postCommercialData();

    return axios.post(url, contract);
};

const defaultExport = {
    searchTags,
    search,
    disable,
    enable,
    update,
    create,
    detail,
    display,
    suggestBrand,
    suggestVersion,
    suggestModel,
    suggestCarLine,
    suggestModelCode,
    getMediaUploadUrl,
    uploadMedia,
    removeMedia,
    powersearch,
    searchVehicleLogs,
    searchContractualData,
    searchContractualDataLogs,
    updateContractualData,
    createContractualData,
    searchLogistics,
    searchLogisticsLogs,
    updateLogisticsData,
    createLogisticsData,
    searchCommercialData,
    searchCommercialDataLogs,
    updateCommercialData,
    createCommercialData,
};

export default defaultExport;
