import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import Label from 'src/components/label';
import CheckIcon from '@mui/icons-material/Check';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { LoadingButton } from '@mui/lab';
import { keyframes } from "@emotion/react";
import { display } from '@mui/system';
import { Box } from '@mui/material';

interface AddToCartButtonProps {
    quantity: number,
    addedQuantityToCart: number,
    translate: (text: unknown, options?: any) => string,
    handleAddToCart: (bundleId: string, quantity: number) => Promise<void>,
    bundleId: string,
    isCardView?: boolean
}

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
`;

export default function AddToCartButton({ quantity, addedQuantityToCart, translate, handleAddToCart, bundleId, isCardView = false }: AddToCartButtonProps) {

    const { isBasketLoading } = useSelector((state: RootState) => state.basket);

    const addedLabel: JSX.Element = (
        <Label
            color={'success'}
            fontSize={"14px"}
            fontWeight={"700"}
            sx={{
                borderRadius: "100px", px: 2, py: 2,
                animation: `${fadeOut} 1s ease-in-out 3s forwards`
            }}
        >
            <CheckIcon sx={{ mr: 1 }} />
            {addedQuantityToCart + " " + translate(`webshop.detail.products.added`)}
        </Label>
    );

    return (
        <>
            {isCardView ?
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {quantity > 0 ?
                        <LoadingButton
                            loading={isBasketLoading}
                            variant={"contained"}
                            color={"warning"}
                            fullWidth
                            onClick={() => handleAddToCart(bundleId, quantity)}
                            disabled={quantity <= 0}
                            startIcon={<AddShoppingCartIcon />}
                            sx={{ borderRadius: '100px', py: 1 }}
                        >
                            {translate('basket.detail.add')}
                        </LoadingButton>
                        :
                        (addedQuantityToCart > 0 && addedLabel)
                    }
                </Box>
                :
                <>
                    {quantity > 0 ?
                        <LoadingButton
                            loading={isBasketLoading}
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => handleAddToCart(bundleId, quantity)}
                            sx={{ borderRadius: "100px", mr: 1 }}
                        >
                            {translate(`webshop.detail.products.addToCart`)}
                        </LoadingButton>
                        :
                        (addedQuantityToCart > 0 && addedLabel)
                    }
                </>
            }
        </>
    );
}