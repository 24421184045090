import { Delete, ExpandMore, NoPhotography } from "@mui/icons-material";
import { IconButton, Typography, Box, Accordion, AccordionSummary, AccordionDetails, alpha } from "@mui/material";
import { GridColDef, DataGridPro } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { BasketItem, BasketParams, BasketShop } from "src/@types/basket";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { getPrice } from "src/utils/currency";
import { getLocalizedString } from "src/utils/CustomFieldManagment";
import { DataGridStyle } from "src/utils/DataGridStyle";
import RemoveProductCartModal from "./RemoveProductCartModal";
import { noData } from "src/components/empty-content/EmptyContent";
import { MarkdownStyle } from "../../inbox/detail/InboxDetail";
import Markdown from 'src/components/markdown';
import { ThresholdsProgess } from "../detail/PublicStatisticDetail";

interface CartShopInfoProps {
    shop?: BasketShop,
    showTerms?: boolean
};

export default function CartShopInfo({ shop, showTerms }: CartShopInfoProps) {

    const isDesktop = useResponsive('up', 'lg');

    const { translate, currentLang } = useLocales();

    const [removeParams, setRemoveParams] = useState<BasketParams>();

    const [open, setOpen] = useState(false);

    const [expand, setExpand] = useState(false);

    // const { basketShops } = useSelector((state: RootState) => state.basket);

    // const current = basketShops.find((s) => s.id === shop?.shopId);

    const COLUMNS: GridColDef<BasketItem>[] = [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'flex', alignItems: "center" }}>

                    {obj.row.media[0] ?
                        <img
                            alt={""}
                            src={obj.row.media[0]?.fileInfo[0]?.url ?? ""}
                            style={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                aspectRatio: "1",
                                objectFit: "cover",
                                border: "1px ridge"
                            }}
                        />
                        :
                        <Box
                            sx={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                border: "1px ridge",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: (theme) => theme.palette.divider
                            }}
                        >
                            <NoPhotography sx={{ width: "30px", height: "30px" }} />
                        </Box>

                    }

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                        <Typography variant='subtitle2' sx={{ mb: -0.5 }}>
                            {getLocalizedString(obj.row.name, currentLang)}
                        </Typography>
                        {obj.row.family &&
                            <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                                <b>{"[" + obj.row.family?.supplier?.code + "] - "}</b>{obj.row.family?.supplier?.description}
                            </Typography>
                        }
                    </Box>
                </Box>
        },
        {
            field: 'unitGrossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => <CurrencyAdapter value={obj.row.unitGrossAmount} excludeVatAsterisk />
        },

        {
            field: 'quantity',
            headerName: translate('commons.quantity'),
            flex: 0.5
        },
        {
            field: 'discountAmount',
            headerName: translate('webshop.detail.products.lineDiscountAmount'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                        <CurrencyAdapter value={obj.row.discountAmount} isNegative excludeVatAsterisk />
                        <Typography variant="subtitle2" color={'text.secondary'}>-{getPrice(obj.row.discountPercentage as any)}%</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'netAmount',
            headerName: translate('webshop.detail.products.netAmount'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                        <CurrencyAdapter value={obj.row.netAmount} excludeVatAsterisk />
                        <CurrencyAdapter
                            value={obj.row.grossAmount}
                            color={'text.secondary'}
                            sx={{ textDecoration: 'line-through' }}
                            excludeVatAsterisk
                        />
                    </Box>
                );
            }
        },
        {
            field: "actions",
            align: "center",
            headerName: "",
            flex: 0.25,
            renderCell: (obj) =>
                <IconButton onClick={() => {
                    setRemoveParams({
                        bundleId: obj.row.id,
                        shopId: shop?.shopId ?? "",
                        quantity: obj.row.quantity
                    });

                    setOpen(true);
                }}>
                    <Delete />
                </IconButton>
        }
    ];

    return (
        <Box sx={{ p: 2 }}>
            <RemoveProductCartModal open={open} onClose={() => setOpen(false)} removeParams={removeParams} />
            <Box>
                <Typography variant="h6">{shop?.shopName}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>{shop?.price.quantity} {translate('basket.detail.item_plural')}</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
                <DataGridPro
                    rows={shop?.items ?? []}
                    columns={COLUMNS}
                    pagination={false}
                    sx={{ ...DataGridStyle, maxWidth: !isDesktop ? 500 : '100%', overflowX: 'auto' }}
                    hideFooterRowCount
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnSorting
                    disableColumnMenu
                    slots={{
                        noRowsOverlay: noData,
                        noResultsOverlay: noData,
                    }}
                />
            </Box>
            <Box sx={{ px: 1, display: 'flex', gap: 1, flexDirection: 'column' }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="body2">
                        {translate('basket.detail.shopGrossAmount')}
                    </Typography>
                    {shop && <CurrencyAdapter value={shop.price.grossAmount} excludeVatAsterisk />}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="body2">
                        {translate('basket.detail.shopRegularDiscount')}
                    </Typography>
                    {shop && <CurrencyAdapter value={shop.price.regularDiscountAmount} isNegative excludeVatAsterisk />}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="body2">
                        {translate('basket.detail.shopAdditionalDiscount')}
                    </Typography>
                    {shop && <CurrencyAdapter value={shop.price.additionalDiscountAmount} isNegative excludeVatAsterisk />}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="body2">
                        {translate('basket.detail.shopTotalDiscount')}
                    </Typography>
                    {shop && <CurrencyAdapter value={shop.price.discountAmount} isNegative excludeVatAsterisk />}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="subtitle1">
                        {translate('basket.detail.totalShop')}
                    </Typography>
                    {shop && <CurrencyAdapter value={shop.price.netAmount} excludeVatAsterisk />}
                </Box>

                {shop?.discountModel?.multiLevel?.isMultiOrder === false && shop &&
                    <ThresholdsProgess
                        lightView
                        currentAmount={shop.price.grossAmount}
                        thresholds={shop.discountModel.multiLevel.thresholds}
                    />
                }

                {showTerms && shop &&

                    <Accordion
                        expanded={expand}
                        onChange={() => setExpand(!expand)}
                        sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.25), borderRadius: '8px' }}>
                        <AccordionSummary
                            sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.25), borderRadius: '8px', color: 'black' }}
                            expandIcon={<ExpandMore />}>

                            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, width: '100%', justifyContent: 'space-between' }}>

                                <Typography variant="subtitle2">{`${translate('termsCondition.title_singular')}`}</Typography>
                                <Typography>{!expand ? `${translate('commons.readMore')}` : `${translate('commons.readLess')}`}</Typography>

                            </Box>
                        </AccordionSummary>
                        {shop.termAndCodition &&
                            <AccordionDetails sx={{ m: 2 }}>

                                <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
                                    <MarkdownStyle>
                                        <Markdown children={shop.termAndCodition.termAndCondition} />
                                    </MarkdownStyle>
                                </Typography>
                            </AccordionDetails>}
                    </Accordion>
                }
            </Box>
        </Box>
    );
}

