import { Delete } from "@mui/icons-material";
import { Box, Button, Divider, MenuItem, Radio, Stack, TextField, Typography } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { OrganizationFilters, OrganizationSearchResult } from "src/@types/organizations";
import { useAuth } from "src/appHooks/useAuth";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import Iconify from "src/components/iconify";
import useLocalStorage from "src/hooks/useLocalStorage";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { organizationsOperations } from "src/redux/organizations";
import { resetOrganizationList } from "src/redux/organizations/organizations-slices";
import { RootState, dispatch } from "src/redux/store";
import { DataGridStyle } from "src/utils/DataGridStyle";
import VisibilityModelComponent from "src/utils/list/toolbar/VisibilityModelComponent";
import GenericListFooter from "src/utils/list/utils/GenericListFooter";

interface SearchCustomerProps {
    organization: string,
    onChange: (org: string) => void,
}

export default function SearchCustomer({ organization, onChange }: SearchCustomerProps) {
    const { organizationList, isSearchLoading, totalCount } = useSelector((state: RootState) => state.organizations);

    const apiRef = useGridApiRef();

    const isDesktop = useResponsive('up', 'md');

    const [searchBy, setSearchBy] = useState('all');

    const [search, setSearch] = useState('');

    const [isFiltered, setIsFiltered] = useState(false);

    const [selectionModel, setSelectionModel] = useState<string>(organization);

    const { user } = useAuth();

    const debounced = useRef(debounce((value: string) => handleSearch(false, false, value), 500));

    const {
        page,
        rowsPerPage,
        onChangeDense,
        dense,
        order,
        setOrder,
        setOrderBy,
        orderBy,
        setPage
    } = useTable();

    const { translate } = useLocales();

    const handleSearch = (scrolling: boolean, clear?: boolean, value?: string) => {
        let options: OrganizationFilters = {
            sequenceToken: !scrolling ? null : (organizationList[organizationList.length - 1]?.searchSequenceToken ?? null),
            size: 12,
            status: "Enabled",
            pagination: 'After',
            sortField: 'name',
            sortDirection: order === "asc" ? "Ascending" : "Descending",
        };

        if (clear) {
            dispatch(organizationsOperations.searchOrganizations({ filters: options, isVendor: true, check: scrolling }));
            setSearch("");

            return;
        }

        if (orderBy) {
            options = {
                ...options,
                sortField: orderBy
            };
        }

        let all: string | undefined;

        if (value && value.length > 3)
            all = value;
        else if (search.length > 3)
            all = search;
        else
            all = undefined;

        options = { ...options, [searchBy]: all };

        dispatch(organizationsOperations.searchOrganizations({ filters: options, check: scrolling, isVendor: false }));

        if (search || value)
            setIsFiltered(true);
        else
            setIsFiltered(false);
    };

    const searchOrg = (val: string) => {
        if (val.length >= 3 || val.length === 0)
            debounced.current(val);

        setSearch(val);
    };

    const handleChange = (value: GridRenderCellParams<OrganizationSearchResult> | GridRowParams<OrganizationSearchResult>) => {
        setSelectionModel(value.id.toString());
        onChange(value.id.toString());
    };

    useEffect(() => {
        if (totalCount <= rowsPerPage * page) {
            setPage(0);
        }
    }, [page, rowsPerPage, setPage, totalCount]);

    useEffect(() => {

        handleSearch(false);

        return () => { dispatch(resetOrganizationList()); };

    }, [page, rowsPerPage, user, order, orderBy]);

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    useEffect(() => {

        debounced.current = debounce((value: string) => handleSearch(false, false, value), 500);

    }, [searchBy]);

    const SELECT_ITEMS = [
        { key: 'all', label: `${translate('commons.all')}` },
        { key: 'name', label: `${translate('commons.name')}` },
        { key: 'externalId', label: `${translate('commons.code')}` }
    ];

    const columns: GridColDef<OrganizationSearchResult>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.25,
            sortable: false,
            align: 'left',
            renderCell: (v) =>
                <Radio
                    checked={selectionModel === v.id.toString()}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleChange(v);
                    }}
                />
        },
        {
            field: 'externalId',

            headerName: `${translate('commons.code')}`,
            flex: 0.5,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: 0.75,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
        {
            field: 'regions',
            headerName: `${translate('organization.list.table.region')}`,
            flex: 0.75,
            valueGetter: (value: string[]) => {
                if (!value.length) {
                    return '—';
                }

                return value.join();
            },
        },
        {
            field: 'type',
            headerName: `${translate('organization.list.table.type')}`,
            flex: 0.75,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
    ];

    const [visibiltyColumns, setvisibiltyColumns] = useLocalStorage<Record<string, GridColumnVisibilityModel>>("columns", {});

    const [visibility, setVisibility] = useState<GridColumnVisibilityModel>(
        visibiltyColumns["searchCustomer"]
        ||
        (() => (columns.filter((header) => header.field).reduce((prev, curr) => {
            Object.assign(prev, { [curr.field]: true });

            return prev;
        }, {}))));

    useEffect(() => {
        setvisibiltyColumns({ ...visibiltyColumns, ["searchCustomer"]: visibility });
    }, [visibility]);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mx: 3, mt: 2, mb: 1 }}>
                <Typography variant="h6" sx={{ m: 3 }}>
                    {`${translate('request.messages.researchCustomer')}`}
                </Typography>
                <VisibilityModelComponent
                    columns={columns}
                    model={visibility}
                    onChangeModel={setVisibility}
                />
            </Box>
            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
                <TextField
                    fullWidth
                    label={`${translate("returns.searchType")}`}
                    value={searchBy}
                    onChange={(e) => {
                        setSearchBy(e.target.value);

                    }}
                    select
                    SelectProps={{
                        MenuProps: {
                            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        },
                    }}
                    sx={{
                        maxWidth: { sm: 240 },
                        textTransform: 'capitalize',
                    }}
                >
                    {SELECT_ITEMS.map((item, index) =>
                        <MenuItem
                            key={index}
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                            }}
                            value={item.key} >
                            {item.label}
                        </MenuItem>
                    )}

                </TextField>

                <TextField
                    fullWidth
                    sx={{ m: 2 }}
                    id="orderSelect"
                    value={search}
                    label={`${translate('commons.search')}`}
                    onChange={e => searchOrg(e.target.value)}
                />

                <Button
                    variant="text"
                    sx={{ borderRadius: '8px' }}
                    onClick={() => handleSearch(false)}
                >
                    <Iconify
                        icon={'eva:search-fill'}
                        sx={{ width: 20, height: 20 }}
                    />
                </Button>
                {
                    isFiltered &&
                    <Button
                        variant="text"
                        onClick={() => {
                            setSearch('');
                            setIsFiltered(false);
                            handleSearch(false, true);
                        }}
                    >
                        <Delete />
                    </Button>
                }
            </Stack>
            <DataGridPro
                apiRef={apiRef}
                rowCount={100}
                columns={columns}
                rows={organizationList}
                loading={isSearchLoading}
                disableColumnResize
                disableColumnMenu
                disableRowSelectionOnClick
                pagination={false}
                density={(dense && organizationList.length > 0) ? 'compact' : 'standard'}
                sortingMode={"server"}
                paginationMode={"server"}
                onSortModelChange={handleSort}
                columnVisibilityModel={visibility}
                scrollEndThreshold={20}
                onRowsScrollEnd={() => {

                    if (organizationList.length < totalCount && organizationList.length >= 12) {
                        handleSearch(true);
                    }
                }}
                onRowClick={(p) => {
                    handleChange(p);
                }}
                sx={{
                    ...DataGridStyle,
                    //cursor: 'pointer',
                    height: () => {
                        let height: string | number = "auto";

                        if (!dense || !organizationList.length) {
                            height = isDesktop ? 632 : 649;
                        }

                        return height;
                    },
                    maxHeight: isDesktop ? 632 : 649,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'break-spaces',
                        lineHeight: 1,
                    },
                }}
                slots={{
                    noRowsOverlay: noData,
                    noResultsOverlay: noData,
                    footer: () => (
                        <>
                            {organizationList.length === 0 && <Divider />}
                            <GenericListFooter
                                dense={dense}
                                onChangeDense={onChangeDense}
                                totalCount={totalCount}
                            />
                        </>
                    )
                }}
            />
        </Box >
    );
}
