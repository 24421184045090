import axios, { AxiosResponse } from 'axios';
import { Report } from './paths';
import { Fields, ExecutionFilter, ExecutionSearchResult, NewReportScheduled, ReportFilters, ReportSearchResult, ReportTemplate, ReportType, ReportTypeData } from 'src/@types/report';
import { GenericPagedResponse } from 'src/@types/list';

const create = (report: NewReportScheduled, isScheduled: boolean, type: ReportTypeData, category: string): Promise<AxiosResponse> => {
    const fixedType = type[0].toLowerCase() + type.slice(1);

    const url = Report.admin.specific.create(isScheduled, fixedType, category);

    return axios.post(url, report);
};

const detail = (id: string, isScheduled: boolean, type: ReportTypeData, category: string): Promise<AxiosResponse<ReportType>> => {
    const fixedType = type[0].toLowerCase() + type.slice(1);

    const url = Report.admin.specific.get(id, isScheduled, fixedType, category);

    return axios.get<ReportType>(url);
};

const edit = (report: ReportType & { id: string }, id: string, type: ReportTypeData): Promise<AxiosResponse> => {
    const fixedType = type[0].toLowerCase() + type.slice(1);

    const url = Report.admin.specific.update(id, fixedType);

    return axios.put(url, report);
};

const enable = (id: string, type: ReportTypeData): Promise<AxiosResponse> => {
    const fixedType = type[0].toLowerCase() + type.slice(1);

    const url = Report.admin.specific.enable(id, fixedType);

    return axios.patch(url);
};

const disable = (id: string, type: ReportTypeData): Promise<AxiosResponse> => {
    const fixedType = type[0].toLowerCase() + type.slice(1);

    const url = Report.admin.specific.disable(id, fixedType);

    return axios.patch(url);
};

const columns = (type: ReportTypeData): Promise<AxiosResponse<Fields[]>> => {
    const fixedType = type[0].toLowerCase() + type.slice(1);

    let url = "";

    if (type === 'container')
        url = Report.admin.specific.containerFields();
    else
        url = Report.admin.specific.fields(fixedType);

    return axios.get(url);
};

const templates = (type: ReportTypeData): Promise<AxiosResponse<ReportTemplate[]>> => {
    const fixedType = type[0].toLowerCase() + type.slice(1);

    let url = "";

    if (type === 'container')
        url = Report.admin.specific.containerTemplates();
    else
        url = Report.admin.specific.templates(fixedType);

    return axios.get(url);
};

const search = (options: ReportFilters, isScheduled: boolean): Promise<AxiosResponse<GenericPagedResponse<ReportSearchResult>>> => {

    const url = Report.admin.general.search(isScheduled);

    return axios.get<GenericPagedResponse<ReportSearchResult>>(url, { params: options });
};

const searchExecution = (options: ExecutionFilter, id: string): Promise<AxiosResponse<GenericPagedResponse<ExecutionSearchResult>>> => {
    const url = Report.admin.general.execution(id);

    return axios.get<GenericPagedResponse<ExecutionSearchResult>>(url, { params: options });
};

export {
    create,
    edit,
    detail,
    enable,
    disable,
    search,
    columns,
    searchExecution,
    templates
};
