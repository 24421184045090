import { Box, Card, Container, TextField, Button, FormHelperText, Divider } from "@mui/material";
import Page from "src/appComponents/Page";
import useLocales from "src/appHooks/useLocales";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useEffect, useState } from "react";
import { organizationRoleCreate } from "src/services/roleServices";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import PermissionTable from "src/components/table/PermissionTable";
import { dispatch } from "src/redux/store";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

export default function NewOrganizationRole() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const [newRole, setNewRole] = useState<string[]>([]);

    const [roleName, setRoleName] = useState<string>('');

    const [nameErrorText, setNameErrorText] = useState('');

    const [generalErrorText, setGeneralErrorText] = useState('');

    const handleSubmit = async () => {

        const param = {
            name: roleName.trim(),
            permissions: newRole as OrganizationPermissionTypes[]
        };

        await organizationRoleCreate(param);

        dispatch(setSuccessMessage({ text: translate('role.messages.success'), returnTo: PATH_DASHBOARD.tenant.role.organization.list }));
    };

    const handleBlur = (e: any) => {
        if (!e.target.value) {
            setNameErrorText(`${translate('commons.validation.requiredField')}`);
        } else if (e.target.value.length > 15) {
            setNameErrorText(`${translate('role.validation.nameLength')}`);
        } else {
            setNameErrorText('');
        }
    };

    useEffect(() => {
        if (newRole.length === 0 && !(roleName.trim()) && !nameErrorText) {
            setGeneralErrorText(`${translate('role.validation.roleAndName')}`);
        } else if (newRole.length === 0) {
            setGeneralErrorText(`${translate('role.validation.oneRole')}`);
        } else if (!(roleName.trim()) && !nameErrorText) {
            setGeneralErrorText(`${translate('role.validation.justName')}`);
        } else {
            setGeneralErrorText("");
        }
    }, [newRole, roleName, translate, nameErrorText]);

    return (
        <Page title={`${translate('role.form.new.operation')}`}>

            <Container maxWidth={themeStretch ? false : 'lg'} >
                <HeaderBreadcrumbs
                    heading={`${translate('role.form.new.operation')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.list')}`, href: PATH_DASHBOARD.tenant.role.organization.list },
                        { name: `${translate('role.form.new.operation')}` }
                    ]}
                />

                <Card sx={{ p: 3 }}>
                    <Box>

                        <TextField
                            label={`${translate('role.form.name')}`}
                            fullWidth
                            required
                            sx={{

                                textAlign: 'center',
                                alignSelf: 'center',

                                width: '100%'
                            }}
                            onChange={e => setRoleName(e.target.value)}
                            onBlur={e => handleBlur(e)}
                            error={!!nameErrorText}
                            helperText={nameErrorText}
                        />

                        <Divider sx={{ my: 3 }} />
                        <PermissionTable onChange={(per) => setNewRole(per)} />

                    </Box>
                    <Box hidden={!generalErrorText}>
                        <FormHelperText sx={{ pb: 1.2, textAlign: "right !important" }} error>
                            {generalErrorText}
                        </FormHelperText>
                    </Box>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.role.organization.list)}>
                            {`${translate("commons.cancel")}`}
                        </Button>
                        <LoadingButton disabled={!!nameErrorText || !!generalErrorText} type="submit" variant="contained" onClick={handleSubmit}>
                            {`${translate("role.form.create")}`}
                        </LoadingButton>
                    </Box>

                </Card>
            </Container>

        </Page>
    );
}