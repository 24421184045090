import { useLocales } from 'src/locales';
import { Box, Button, Card, Modal, Typography } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import useResponsive from 'src/hooks/useResponsive';

interface RulesClearModalProps {
    isOpen: boolean,
    toggleOpen: VoidFunction,
    submit: VoidFunction,
    clearText?: string
}

export function RulesClearModal({ isOpen, toggleOpen, submit, clearText }: RulesClearModalProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    return (
        <Modal
            open={isOpen}
            onClose={toggleOpen}
        >
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '25vw' : '70vw',
                    p: isDesktop ? 4 : 2
                }}
            >
                <Box sx={{ textAlign: "center" }}>

                    <Typography variant="h4">
                        {translate(`rules.messages.changeConfirm`)}
                    </Typography>

                    <Typography variant='body1' sx={{ my: 6 }}>
                        {translate(`rules.messages.${clearText ? clearText : "ruleClearQst"}`)}
                    </Typography>

                    <Box sx={{ mx: 2, display: "flex", justifyContent: "space-between" }}>
                        <Button
                            variant={"soft"}
                            size={"medium"}
                            onClick={toggleOpen}
                            sx={{ borderRadius: "100px" }}
                        >
                            {translate('commons.close')}
                        </Button>
                        <Button
                            startIcon={<DeleteForever />}
                            color={"error"}
                            onClick={submit}
                            variant={"contained"}
                            size={"medium"}
                            sx={{ borderRadius: "100px" }}
                        >
                            {translate('commons.empty')}
                        </Button>
                    </Box>

                </Box>
            </Card>
        </Modal>
    );
}