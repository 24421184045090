import { Box, Button, IconButton, InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useState } from 'react';
import { useLocales } from 'src/locales';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { DatesGeneralFilterProps, ToolbarListFilters } from 'src/@types/list';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useFormik } from 'formik';
import * as Yup from "yup";
import EventIcon from '@mui/icons-material/Event';

const validationSchema = Yup.object().shape({
    from: Yup.date().nullable(),
    to: Yup.date().nullable(),
    searchValue: Yup.string().nullable(),
}).test("selectAll", "SelectAll", function (values) {
    const { from, to, searchValue } = values;

    if (from && !to) {
        return this.createError({ path: "to" });
    }
    if (!from && to) {
        return this.createError({ path: "from" }); //, message: "Seleziona entrambe le date" 
    }
    if (from && to && to < from) {
        return this.createError({ path: "to" });
    }
    if (!(from && to) && !searchValue) {
        return this.createError({ path: "searchValue" });
    }

    return true;
});

interface GenericFilterToolbarProps {
    filterValues: any,
    defaultFilters: any,
    onSearch: VoidFunction,
    onResetAll: VoidFunction,
    optionsFields?: ToolbarListFilters[],
    datesGeneralFilter?: DatesGeneralFilterProps,
    showSummary?: boolean,
    setShowSummary?: (value: boolean) => void,
    isDefault: (filter: any, controller?: any) => boolean
}

/**
 * 
 * @param filterValues             actual values of filters
 * @param defaultFiltersValues     default values of filters
 * @param onSearch                 function called when clicking the search button 
 * @param onResetAll               function called when clicking the delete button 
 * @param optionsFields    
 * @param showDates                boolean: used to show dates filters
 * @param datesNames               [string, string]: used when dates filters have different names then the default ones (["from","to"])
 * @param dateSearchBy             [string, string]: used when the searchBy select is referring to dates instead of TextField. First string is the name of the select filter, second string is the value associated to the TextField  
 * 
 */

export default function GenericFilterToolbarNEW({ filterValues, defaultFilters, onSearch, onResetAll, optionsFields, datesGeneralFilter, showSummary, setShowSummary, isDefault }: GenericFilterToolbarProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const STARTING_TOOLBAR_FILTERS = {
        ...filterValues,
        searchType: optionsFields ? optionsFields[0].key : "all",
        searchValue: ""
    };

    const formik = useFormik<any>({
        initialValues: STARTING_TOOLBAR_FILTERS,
        validateOnMount: true,
        validationSchema,
        onSubmit: () => handleSearch()
    });

    const isSearchTypeCustomfield = useCallback((field: string) => {
        return optionsFields?.find((option) => option.key === field && option.isCustomfield)?.isCustomfield ?? "";
    }, [optionsFields]);

    //---------------------------------------------------------------------------

    const searchServiceAndValue = useCallback(() => {

        let returnOption: string = "all";

        if (datesGeneralFilter?.dateSearchBy) {
            returnOption = filterValues[datesGeneralFilter.dateSearchBy[0]];
        } else if (optionsFields) {
            returnOption = optionsFields.find((option) => filterValues[option.key] && !isEqual(filterValues[option.key], defaultFilters[option.key]))?.key ?? optionsFields[0].key;
        }

        return returnOption;

    }, [datesGeneralFilter, filterValues, optionsFields, defaultFilters]);

    useEffect(() => {
        if (isDefault(filterValues)) {
            formik.resetForm();
            setShowDeleteButton(false);
        } else {
            const searchType = searchServiceAndValue();

            const isCustomfield = isSearchTypeCustomfield(searchType);

            let newFilterValues = {
                ...filterValues,
                searchType: searchType,
                searchValue: datesGeneralFilter?.dateSearchBy ?
                    filterValues[datesGeneralFilter?.dateSearchBy[1]] :
                    isCustomfield ? (filterValues[searchType][isCustomfield] ?? "") : filterValues[searchType] ?? ""
            };

            formik.setValues(newFilterValues);

            if ((newFilterValues.from && newFilterValues.to) || !!newFilterValues.searchValue) setShowDeleteButton(true);
            else setShowDeleteButton(false);
        }
    }, [datesGeneralFilter, filterValues, isDefault, isSearchTypeCustomfield, searchServiceAndValue]);

    //---------------------------------------------------------------------------

    const setAllValues = () => {

        const optionsKeys = optionsFields?.map(({ key, label }) => key) || [];

        for (const [key, value] of Object.entries(formik.values)) {

            if (key !== "searchType" && key !== "searchValue") {

                if (key === "from" && value) {
                    setValue("from", dayjs(value as any).startOf('day').toDate());
                } else if (key === "to" && value) {
                    setValue("to", dayjs(value as any).endOf('day').toDate());
                } else if (datesGeneralFilter?.dateSearchBy && datesGeneralFilter.dateSearchBy[0] === key) {
                    setValue(datesGeneralFilter.dateSearchBy[0], formik.values.searchType);
                    setValue(datesGeneralFilter.dateSearchBy[1], formik.values.searchValue);
                } else if (formik.values.searchType === key) {
                    const isCustomfield = isSearchTypeCustomfield(key);

                    if (isCustomfield) setValue(key + "." + isCustomfield, formik.values.searchValue);
                    else setValue(key, formik.values.searchValue);

                } else if (optionsKeys.includes(key)) {
                    setValue(key, defaultFilters[key]);
                }
            }
        }
    };

    const handleSearch = () => {
        setAllValues();
        onSearch();
        setShowDeleteButton(true);
        if (setShowSummary) setShowSummary(false);
    };

    const handleDelete = () => {
        onResetAll();
        setShowDeleteButton(false);
    };

    const deleteDateButton = (position: "start" | "end" | undefined) => {

        const field = position === "start" ? "from" : "to";

        return (
            <IconButton
                edge="start"
                onClick={(event) => {
                    event.stopPropagation();
                    formik.setFieldValue(field, defaultFilters[field]);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ px: 1.5, pt: 2, pb: { xs: 0, md: 2 }, display: 'flex', alignItems: 'center' }}
            gap={2}
        >
            {(optionsFields && optionsFields.length > 1) &&
                <TextField
                    select
                    name={'searchType'}
                    label={translate('returns.searchType')}
                    value={formik.values.searchType}
                    onChange={formik.handleChange}
                    sx={{
                        minWidth: { xs: "100%", md: 180 },
                        mb: { xs: 2, md: 0 }
                    }}
                    variant={"outlined"}
                >
                    {optionsFields.map((option) => (
                        <MenuItem
                            key={option.key}
                            value={option.key}
                            sx={{
                                mx: 1, my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize'
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            }

            <DateRangePicker
                value={[formik.values.from, formik.values.to]}
                onChange={(newValue) => {
                    formik.setFieldValue("from", newValue[0]);
                    formik.setFieldValue("to", newValue[1]);
                }}
                slotProps={{
                    textField: ({ position }) => ({
                        label: translate(`commons.${position === "start" ? "from" : "to"}`),
                        fullWidth: true,
                        error: (
                            (position === "start" && !!formik.errors.from)
                            ||
                            (position === "end" && !!formik.errors.to)
                        ),
                        InputProps: {
                            endAdornment:
                                <IconButton edge="end">
                                    <EventIcon />
                                </IconButton>,
                            startAdornment: (((position === "start" && formik.values.from) || (position === "end" && formik.values.to)) &&
                                <>
                                    {(position === "start" && formik.values.from) &&
                                        <>{deleteDateButton('start')}</>
                                    }
                                    {(position === "end" && formik.values.to) &&
                                        <>{deleteDateButton('end')}</>
                                    }
                                </>
                            )
                        }
                    })
                }}
                sx={{
                    minWidth: { xs: "100%", md: optionsFields ? 400 : 450 },
                    "& .MuiTypography-root": { display: "none" }
                }}
            />

            <TextField
                name={"searchValue"}
                placeholder={translate('commons.search')}
                variant={"outlined"}
                value={formik.values.searchValue}
                onChange={formik.handleChange}
                sx={{ width: "100%" }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify
                                icon={'eva:search-fill'}
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{ shrink: true }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    startIcon={<Iconify icon={'bx:search-alt'} sx={{ ml: 1.5, mt: 0.5 }} />}
                    onClick={() => formik.handleSubmit()}
                    disabled={!(formik.isValid && ((formik.values.from && formik.values.to) || formik.values.searchValue))}
                    size={"large"}
                    sx={{ mt: 0.5, width: { xs: "100%" } }}
                />

                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                    onClick={handleDelete}
                    size={"large"}
                    sx={{
                        display: `${(showDeleteButton) ? "inline" : "none"}`,
                        mt: 0.5, width: { xs: "100%" }
                    }}
                />
            </Box >

        </Stack >
    );
}