import { Typography, TypographyProps } from "@mui/material";
import { PriceAmount } from "src/@types/currency";
import useCurrency from "src/appHooks/useCurrency";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { convert, getPrice } from "src/utils/currency";

interface CurrencyAdapterProps extends TypographyProps {
    value: PriceAmount,
    isNegative?: boolean,
    excludeVatAsterisk?: boolean
}

export default function CurrencyAdapter({ value, isNegative, excludeVatAsterisk, ...TypographyProps }: CurrencyAdapterProps) {

    const { currency: currentCurrency } = useSettingsContext();

    const { currentLang } = useLocales();

    const { rates } = useCurrency();

    const convertedValue = value ? Math.round((convert(getPrice(value), value.currentCurrency, currentCurrency.label, rates) + Number.EPSILON) * 100) / 100 : undefined;

    return (
        <Typography variant={"body2"} {...TypographyProps}>
            {convertedValue ?
                (
                    (isNegative ? "- " : "")
                    +
                    convertedValue.toLocaleString(currentLang.locale.code, { style: "currency", currency: currentCurrency.label, maximumFractionDigits: 2, minimumFractionDigits: 2 })
                    +
                    ((!excludeVatAsterisk && value.withoutVat && value.withoutVat >= 0) ? " *" : "")
                )
                :
                '—'
            }
        </Typography>
    );
}