import { Box, Divider, Typography } from "@mui/material";
import { useMemo, useEffect } from "react";
import { Order } from "src/@types/orders";
import { RequestNew } from "src/@types/request";
import MoreDetails from "src/components/request-utils/MoreDetails";
import { useLocales } from "src/locales";

interface CompleteQualityProps {
    request: RequestNew,
    order: Order,
    files: File[]
}

export default function CompleteQuality({ request, order, files }: CompleteQualityProps) {

    const { translate } = useLocales();

    const imagesUrls = useMemo(() => files.map(i => URL.createObjectURL(i)), [files]);

    useEffect(() => {
        //avoid memory leak
        return () => imagesUrls.forEach(i => URL.revokeObjectURL(i));
    }, [imagesUrls]);

    const whatReason: Readonly<Record<string, JSX.Element>> = {
        PartOrderedIsNotComplete:
            <>
                <Typography variant="subtitle1">{`${translate('commons.description')}`} </Typography>
                <Typography variant="body1">{request.note}</Typography>
                <Divider sx={{ my: 3 }} />
                <Typography variant="subtitle1" >{`${translate('request.form.damagedPic')}`}</Typography>
            </>,
        OriginalPackagingContainsWrongParts:
            <>
                <Typography variant="subtitle1" >{translate('commons.product.itemExternalId')} </Typography>
                <Typography sx={{ mb: 1 }} variant="body1">{request.WrongProductExternalId ?? ""}</Typography>

                <Typography variant="subtitle1" >{`${translate('commons.description')}`} </Typography>
                <Typography variant="body1">{request.note}</Typography>

                {files.length > 0 &&
                    <>
                        <Divider sx={{ my: 3 }} />
                        <Typography variant="subtitle1" marginTop={2}>{`${translate('request.form.pictureAll')}`}</Typography>
                    </>
                }
            </>,
        ThePartDoesntWork:
            <>
                <Typography variant="subtitle1">{`${translate('commons.description')}`} </Typography>
                <Typography variant="body1">{request.note}</Typography>
                {files.length > 0 &&
                    <>
                        <Divider sx={{ my: 3 }} />
                        <Typography variant="subtitle1" marginTop={2}>{`${translate('request.form.pictureAll')}`}</Typography>
                    </>
                }
            </>
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ my: 2, mx: 3, overflowX: 'auto' }}>

                {whatReason[request.requestReason!]}

                {files.length > 0 &&
                    <Box sx={{ m: 2, display: 'flex', flexDirection: 'row', gap: 3, overflowX: 'auto' }}>
                        {files.map((file: File, ind: number) => (
                            <Box key={ind} sx={{ borderRadius: '10px' }}>
                                <img
                                    style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '10px', border: '1px ridge', borderColor: '#d3d4d4' }}
                                    alt={file.name}
                                    src={imagesUrls[ind]} />
                            </Box>
                        ))}
                    </Box>}
            </Box>

            <Divider sx={{ mb: 2 }} />

            {order && <MoreDetails order={order} />}
        </Box>
    );
}