import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocales } from "src/locales";
import { sparePartsOperations } from "src/redux/spareParts";
import { dispatch, RootState } from "src/redux/store";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import Page from "src/appComponents/Page";
import LoadingScreen from "src/appComponents/loading-screen";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSettingsContext } from "src/components/settings";
import { Card, Typography, Box, Container, useTheme, Tabs, alpha, Tab, Divider } from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import { bgBlur } from "src/utils/cssStyles";
import SettingsIcon from '@mui/icons-material/Settings';
import useResponsive from "src/hooks/useResponsive";
import { LabeledText } from "src/appComponents/LabeledText";
import DateZone from "src/appComponents/DateZone";
import { CustomField } from "src/@types/customField";
import useTenant from "src/appHooks/useTenant";
import { organizationsOperations } from "src/redux/organizations";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BookIcon from '@mui/icons-material/Book';
import SparePartsLogs from "./SparePartsLogs";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import { StyledInfo, StyledRoot } from "src/utils/Detail";

const DEFAULT_SECTIONS = [
    "Commercial Data",
    "General",
    "Technical Data"
];

export default function SparePartsDetail() {

    const { isLoading, sparePart } = useSelector((state: RootState) => state.spareParts);

    const { organization } = useSelector((state: RootState) => state.organizations);

    const [value, setValue] = useState(0);

    const [currentTab, setCurrentTab] = useState('general');

    const [parsedFields, setParsedFields] = useState<CustomField[]>([]);

    const [sectionList, setSectionList] = useState<string[]>([]);

    const { id } = useParams();

    const theme = useTheme();

    const isDesktop = useResponsive('up', 'lg');

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { customFields } = useTenant();

    useEffect(() => {
        if (id)
            dispatch(sparePartsOperations.getSparePart(id));
    }, [id, currentLang]);

    useEffect(() => {
        if (sparePart && (!organization || sparePart.ownerId !== organization.id))
            dispatch(organizationsOperations.getGeneric(sparePart.ownerId));

    }, [organization, sparePart]);

    useEffect(() => {
        if (customFields && sparePart) {
            var fields = customFields.filter(field =>
                field.enabled &&
                field.entityType === 'SpareParts' &&
                field.visibility !== 'never' &&
                (field.visibility === 'always' || !!(sparePart?.customFields && sparePart?.customFields[field.id])));

            let sectionList = Array.from(new Set(fields.filter(f => !DEFAULT_SECTIONS.includes(f.section)).map(f => f.section)));
            setSectionList(sectionList);
            setParsedFields(fields);
        }
    }, [customFields, sparePart]);

    const getLang = (field: CustomField) => {

        let tmp = field.name.find((f) => f.culture === currentLang.value);

        return tmp ? tmp.text : field.name[0].text;
    };

    const MENU_TABS = useMemo(() => [{
        value: 'general',
        label: `${translate('commons.general')}`,
        icon: <AccountBoxIcon />,
    },
    {
        value: 'logs',
        label: `${translate('commons.logs')}`,
        icon: <BookIcon />,
    }], [translate]);

    const TABS_NAMES = ["General", "Technical Data", "Commercial Data", "family"].concat(sectionList);

    const TABS = [sparePart && (
        <Box key={0} sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('commons.general')}`}</Typography>
            <Box
                sx={{
                    p: 1,
                    rowGap: 1,
                    display: 'grid',
                    columnGap: 8,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <LabeledText label={`${translate('commons.description')}`} text={sparePart?.description || "─"} />
                <LabeledText label={`${translate('commons.vendor')}`} text={organization ? (organization.externalId ? organization.externalId + "-" + organization.name : organization.name) : "─"} />

                <Box sx={{ display: 'grid', alignItems: 'end', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>
                    <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }}>
                        {`${translate('commons.creationDate')} :`}
                    </Typography>
                    <DateZone date={new Date(sparePart?.createdOn)} display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: 'left', pl: isDesktop ? 2 : 0 }} />
                </Box>

                <LabeledText label={`${translate('spareParts.detail.europeanArticleNumber')}`} text={"" + (sparePart?.europeanArticleNumber || "─")} />

            </Box>
            {sparePart?.manufacturer && <>
                <Divider sx={{ my: 1 }} />
                <Typography variant="overline">{`${translate('spareParts.detail.manufacturer')}`}</Typography>
                <Box sx={{
                    p: 2,
                    rowGap: 1,
                    display: 'grid',
                    columnGap: 8,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}>
                    <LabeledText label={`${translate('commons.code')}`} text={sparePart?.manufacturer.code || "─"} />
                    <LabeledText label={`${translate('commons.description')}`} text={sparePart?.manufacturer.description || "─"} />
                </Box></>}
            <Divider sx={{ my: 1 }} />
            <Typography variant="overline">{`${translate('spareParts.detail.supplier')}`}</Typography>
            <Box sx={{
                display: 'grid',
                columnGap: 8,
                p: 2,
                rowGap: 1,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}>
                <LabeledText label={`${translate('commons.code')}`} text={sparePart?.supplier.code || "─"} />
                <LabeledText label={`${translate('commons.description')}`} text={sparePart?.supplier.description || "─"} />
            </Box>
            {sparePart.replacement && <>
                <Divider sx={{ my: 1 }} />
                <Typography variant="overline">{`${translate('spareParts.detail.replacement')}`}</Typography>
                <Box sx={{
                    display: 'grid',
                    columnGap: 8,
                    p: 2,
                    rowGap: 1,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}>
                    <LabeledText label={`${translate('spareParts.detail.replacedBy')}`} text={sparePart?.replacement.replacedBy || "─"} />
                    <Box sx={{ display: 'grid', alignItems: 'end', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>
                        <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }}>
                            {`${translate('spareParts.detail.replacementDate')} :`}
                        </Typography>
                        <DateZone date={new Date(sparePart?.replacement.replacementDate)} display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: 'left', pl: isDesktop ? 2 : 0 }} />
                    </Box>
                </Box>
            </>}
            {parsedFields && <Divider sx={{ my: 2 }} />}

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    p: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'general').map(field =>
                    <LabeledText key={field.id} label={getLang(field)!} text={sparePart.customFields && sparePart.customFields[field.id] || '—'} />)}
            </Box>
        </Box >
    ),
    sparePart && (

        <Box key={1} sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('spareParts.detail.technicalData')}`}</Typography>
            <Typography variant="overline">{`${translate('spareParts.detail.dimensions')}`}</Typography>
            {sparePart.dimensions && <Box
                sx={{
                    my: 1,
                    display: 'grid',
                    columnGap: 8,
                    p: 2,
                    rowGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText label={`${translate('spareParts.detail.weight')}`} value={"" + (sparePart?.dimensions.packageWeight || "─")} metricType={"weight"} />
                <GridLabeledText label={`${translate('spareParts.detail.height')}`} value={"" + (sparePart?.dimensions.packageHeight || "─")} metricType={"dimension"} />
                <GridLabeledText label={`${translate('spareParts.detail.width')}`} value={"" + (sparePart?.dimensions.packageWidth || "─")} metricType={"dimension"} />
                <GridLabeledText label={`${translate('spareParts.detail.depth')}`} value={"" + (sparePart?.dimensions.packageDepth || "─")} metricType={"dimension"} />
                <GridLabeledText label={`${translate('spareParts.detail.volume')}`} value={"" + (sparePart?.dimensions.packageVolume || "─")} metricType={"volume"} />

            </Box>}

            {sparePart?.quantity && <>
                <Divider sx={{ my: 1 }} />
                <Typography variant="overline">{`${translate('commons.quantity')}`}</Typography>
                <Box sx={{
                    my: 1,
                    display: 'grid',
                    columnGap: 8,
                    p: 2,
                    rowGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}>
                    <LabeledText label={`${translate('spareParts.list.tableHeaders.stockQuantity')}`} text={"" + (sparePart?.quantity.stockQuantity || "─")} />
                    <LabeledText label={`${translate('spareParts.list.tableHeaders.reservationQuantity')}`} text={"" + (sparePart?.quantity.reservationQuantity || "─")} />
                    <LabeledText label={`${translate('spareParts.list.tableHeaders.availableQuantity')}`} text={"" + (sparePart?.quantity.availableQuantity || "─")} />
                    <LabeledText label={`${translate('spareParts.list.tableHeaders.returnQuantity')}`} text={"" + (sparePart?.quantity.returnQuantity || "─")} />
                    <LabeledText label={`${translate('spareParts.list.tableHeaders.pendingReception')}`} text={"" + (sparePart?.quantity.pendingReception || "─")} />
                </Box></>}
            {parsedFields && <Divider sx={{ my: 2 }} />}

            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    p: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'technical data').map(field =>
                    <LabeledText key={field.id} label={getLang(field)!} text={sparePart.customFields && sparePart.customFields[field.id] || '—'} />)}
            </Box>
        </Box>
    ),
    sparePart && (
        <Box key={2} sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('spareParts.detail.commercialData')}`}</Typography>
            <Typography variant="overline">{`${translate('spareParts.detail.catalogue')}`}</Typography>
            <Box
                sx={{
                    my: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    isCurrency
                    label={`${translate('orders.detail.withVat')}`}
                    value={{
                        withVat: sparePart?.price?.catalogue?.withVat,
                        currentCurrency: sparePart?.price?.catalogue?.currentCurrency
                    }}
                />
                <GridLabeledText
                    isCurrency
                    label={`${translate('orders.detail.withoutVat')}`}
                    value={{
                        withoutVat: sparePart?.price?.catalogue?.withoutVat,
                        currentCurrency: sparePart?.price?.catalogue?.currentCurrency
                    }}
                />
                <GridLabeledText label={`${translate('spareParts.detail.currentCurrency')}`} value={"" + (sparePart?.price?.catalogue?.currentCurrency || "─")} />
            </Box>

            <Divider sx={{ my: 1 }} />

            <Typography variant="overline">{`${translate('spareParts.detail.supplier')}`}</Typography>
            <Box
                sx={{
                    my: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    isCurrency
                    label={`${translate('orders.detail.withVat')}`}
                    value={{
                        withVat: sparePart?.price?.supplier?.withVat,
                        currentCurrency: sparePart?.price?.supplier?.currentCurrency
                    }}
                />
                <GridLabeledText
                    isCurrency
                    label={`${translate('orders.detail.withoutVat')}`}
                    value={{
                        withoutVat: sparePart?.price?.supplier?.withoutVat,
                        currentCurrency: sparePart?.price?.supplier?.currentCurrency
                    }}
                />
                <LabeledText label={`${translate('spareParts.detail.currentCurrency')}`} text={sparePart?.price?.supplier?.currentCurrency || "─"} />
            </Box>

            <Divider sx={{ my: 1 }} />

            <Typography variant="overline">{`${translate('spareParts.detail.selling')}`}</Typography>
            <Box
                sx={{
                    my: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    isCurrency
                    label={`${translate('orders.detail.withVat')}`}
                    value={{
                        withVat: sparePart?.price?.selling?.withVat,
                        currentCurrency: sparePart?.price?.selling?.currentCurrency
                    }}
                />
                <GridLabeledText
                    isCurrency
                    label={`${translate('orders.detail.withoutVat')}`}
                    value={{
                        withoutVat: sparePart?.price?.selling?.withoutVat,
                        currentCurrency: sparePart?.price?.selling?.currentCurrency
                    }}
                />
                <LabeledText label={`${translate('spareParts.detail.currentCurrency')}`} text={sparePart?.price.selling?.currentCurrency || "─"} />
            </Box>

            {parsedFields && <Divider sx={{ my: 2 }} />}

            <Box
                sx={{
                    mt: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'commercial data').map(field =>
                    <LabeledText key={field.id} label={getLang(field)!} text={sparePart.customFields && sparePart.customFields[field.id] || '—'} />)}
            </Box>
        </Box>
    ),
    sparePart && (
        <Box key={1} sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('spareParts.detail.family')}`}</Typography>
            <Typography variant="overline">{`${translate('spareParts.detail.supplier')}`}</Typography>
            <Box
                sx={{
                    my: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <LabeledText label={`${translate('commons.description')}`} text={sparePart?.family.supplier.description || "─"} />
                <LabeledText label={`${translate('commons.code')}`} text={sparePart?.family.supplier.code || "─"} />

            </Box>

            <Typography variant="overline">{`${translate('spareParts.detail.internal')}`}</Typography>
            <Box
                sx={{
                    my: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <LabeledText label={`${translate('commons.description')}`} text={sparePart?.family.internal.description || "─"} />
                <LabeledText label={`${translate('commons.code')}`} text={sparePart?.family.internal.code || "─"} />

            </Box>
            <Divider sx={{ my: 1 }} />
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('spareParts.detail.category')}`}</Typography>
            {sparePart?.category?.supplier && <>
                <Typography variant="overline">{`${translate('spareParts.detail.supplier')}`}</Typography>
                <Box
                    sx={{
                        my: 1,
                        p: 2,
                        display: 'grid',
                        columnGap: 8,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                >
                    <LabeledText label={`${translate('commons.description')}`} text={sparePart?.category.supplier.description || "─"} />
                    <LabeledText label={`${translate('commons.code')}`} text={sparePart?.category.supplier.code || "─"} />

                </Box></>}
            <Typography variant="overline">{`${translate('spareParts.detail.internal')}`}</Typography>
            {sparePart.category && <Box
                sx={{
                    mt: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <LabeledText label={`${translate('commons.description')}`} text={sparePart?.category.internal.description || "─"} />
                <LabeledText label={`${translate('commons.code')}`} text={sparePart?.category.internal.code || "─"} />

            </Box>}
            <Divider sx={{ my: 1 }} />
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('spareParts.detail.class')}`}</Typography>

            {sparePart.class?.supplier && <>
                <Typography variant="overline">{`${translate('spareParts.detail.supplier')}`}</Typography>
                <Box
                    sx={{
                        my: 1,
                        p: 2,
                        display: 'grid',
                        columnGap: 8,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                >
                    <LabeledText label={`${translate('commons.description')}`} text={sparePart?.class.supplier.description || "─"} />
                    <LabeledText label={`${translate('commons.code')}`} text={sparePart?.class.supplier.code || "─"} />

                </Box></>}

            <Typography variant="overline">{`${translate('spareParts.detail.internal')}`}</Typography>
            {sparePart?.class && <Box
                sx={{
                    my: 1,
                    p: 2,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <LabeledText label={`${translate('commons.description')}`} text={sparePart?.class.internal.description || "─"} />
                <LabeledText label={`${translate('commons.code')}`} text={sparePart?.class.internal.code || "─"} />
            </Box>}
        </Box>
    )].concat(sectionList.map((section, ind) => {
        let fields = parsedFields.filter(f => f.section === section);

        return (
            <Box key={ind} sx={{ p: 3, bgcolor: 'background.paper' }}>
                <Typography sx={{ mb: 2 }} variant="h6">{section === 'OtherInfo' ? `${translate('customField.section.otherInfo')}` : section}</Typography>
                <Box
                    sx={{
                        my: 1,
                        p: 2,
                        display: 'grid',
                        columnGap: 8,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                >
                    {fields.map(
                        field => (
                            <LabeledText key={field.id} label={getLang(field) || "─"} text={sparePart?.customFields && sparePart?.customFields[field.id] || '—'} />
                        )
                    )}

                </Box>
            </Box>
        );
    }));

    return (
        <Page title={`${translate("spareParts.detail.title")}`}>
            {isLoading ? (<LoadingScreen />) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('spareParts.detail.title')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("commons.list")}`, href: PATH_DASHBOARD.spareParts.list },
                            { name: sparePart?.externalId + " - " + sparePart?.name }
                        ]}
                    />
                    <Card
                        sx={{
                            mb: 3,
                            height: 280,
                            position: 'relative',
                        }}
                    >
                        <StyledRoot
                            sx={{
                                '&:before': {
                                    ...bgBlur({
                                        color: theme.palette.primary.light,
                                    }),
                                }
                            }}
                        >
                            <StyledInfo>
                                <CustomAvatar
                                    name={''}

                                    sx={{
                                        mx: 'auto',
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderColor: 'common.white',
                                        color: "white",
                                        width: { xs: 80, md: 128 },
                                        height: { xs: 80, md: 128 },
                                    }}
                                >
                                    <SettingsIcon />
                                </CustomAvatar>

                                <Box
                                    sx={{
                                        ml: { md: 3 },
                                        mt: { xs: 1, md: 0 },
                                        color: 'common.white',
                                        textAlign: { xs: 'center', md: 'left' },
                                    }}
                                >
                                    <Typography variant="overline" lineHeight={"18px"} fontWeight={700} color={"GrayText"}>{sparePart?.externalId}</Typography>
                                    <Typography color={"black"} fontSize={"24px"} variant="h4">{`${sparePart?.name}`}</Typography>

                                    {/* <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_SparePartsEdit]}>
                                        <Button variant="contained" size="small" sx={{ my: isDesktop ? 2 : 1 }} onClick={() => { }}>
                                            {`${translate("commons.edit")}`}
                                        </Button>
                                    </PermissionBasedGuard> */}
                                </Box>
                            </StyledInfo>
                        </StyledRoot>
                        <Tabs
                            value={currentTab}
                            onChange={(_, newValue) => setCurrentTab(newValue)}
                            sx={{
                                width: 1,
                                bottom: 0,
                                zIndex: 9,
                                position: 'absolute',
                                bgcolor: 'background.paper',
                                '& .MuiTabs-flexContainer': {
                                    pr: { md: 3 },
                                    justifyContent: {
                                        sm: 'center',
                                        md: 'flex-end',
                                    },
                                },
                            }}
                        >
                            {MENU_TABS.map((tab, ind) => <Tab key={ind} value={tab.value} icon={tab.icon} label={tab.label} />)}
                        </Tabs>
                    </Card>
                    <Box>
                        {currentTab === 'general' ?
                            <Card sx={{ flexGrow: 1, display: 'flex' }}>
                                <Tabs
                                    orientation='vertical'
                                    value={value}
                                    onChange={(_, val) => setValue(val)}
                                    TabIndicatorProps={{
                                        style: { display: 'none' }
                                    }}
                                    sx={{
                                        bgcolor: alpha(theme.palette.grey[500], 0.12),
                                        minWidth: "20%",
                                        maxHeight: "20%"
                                    }}
                                >
                                    {TABS_NAMES.map((tab, ind) => (
                                        <Tab key={ind}
                                            value={ind}
                                            label={tab === 'OtherInfo' ? `${translate('customField.section.otherInfo')}` : tab}
                                            sx={{
                                                justifyContent: 'flex-start',
                                                py: '13px',
                                                px: 2,
                                                m: 1,
                                                borderRadius: '8px',
                                                color: value === ind ? 'primary' : '',
                                                backgroundColor: value === ind ? 'primary.lighter' : '',
                                                '&:not(:last-of-type)': { mr: 1 },
                                                "&.Mui-selected": {
                                                    color: "primary.main"
                                                }
                                            }} />

                                    ))}
                                </Tabs>
                                {
                                    TABS[value]
                                }
                            </Card>
                            :
                            <SparePartsLogs />}
                    </Box>
                </Container>)}
        </Page>
    );
}