import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import webshopOperations from "./webshop-operations";
import { ErrorResponse } from "src/@types/commons";
import { BasketRuleSearchResult, BasketRuleStatistics, BasketRuleType, DEFAULT_BASKET_RULES_STATISTICS, EnableDisableBasketRuleSliceProps, ShopSearchResult, Shop, ShopStatistics, DEFAULT_SHOP_STATISTICS, EnableDisableShopSliceProps, ShopDisplay, ShopBundleSearchResult, PublicShopSearchResult, Bundle, ShopOrderStatistics, ShopCustomerStatisticsSearchResult } from "src/@types/webshop";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";
import { GenericPagedResponse } from "src/@types/list";

interface WebshopReducer {
    error: ErrorResponse,
    areLogsLoading: boolean,
    //---- BASKET RULES --------------
    isBasketRuleLoading: boolean,
    isBasketRuleInfiniteScrolling: boolean,
    basketRule: BasketRuleType | null,
    basketRulesTotalCount: number,
    basketRuleFiltersInUrl: string,
    basketRuleList: BasketRuleSearchResult[],
    basketRuleLogs: GenericPagedResponse<LogsItem>,
    basketRuleStatistics: BasketRuleStatistics,
    //---- WEBSHOP -------------------
    isWebshopLoading: boolean,
    isWebshopInfiniteScrolling: boolean,
    webshop: Shop | null,
    displayShop: ShopDisplay | null
    webshopTotalCount: number,
    webshopFiltersInUrl: string,
    webshopList: ShopSearchResult[],
    webshopLogs: GenericPagedResponse<LogsItem>,
    webshopStatistics: ShopStatistics,
    isShopCreatingLoading: boolean,
    //----  WEBSHOP STATISTICS ---------------
    isShopCustomerStatisticsLoading: boolean,
    shopCustomerStatisticsList: ShopCustomerStatisticsSearchResult[],
    currentCustomerStatistic: ShopCustomerStatisticsSearchResult | null,
    shopCustomerStatisticsTotalCount: number,
    //---- WEBSHOP - BUNDLE --------
    isBundleLoading: boolean,
    isBundleInfiniteScrolling: boolean,
    bundleTotalCount: number,
    bundleList: ShopBundleSearchResult[],
    bundle: Bundle | null,
    //---- PUBLIC - WEBSHOP -------------------
    publicWebshopList: PublicShopSearchResult[]
    //---- PUBLIC - WEBSHOP STATISTICS ---------------
    shopOrderStatistics: ShopOrderStatistics | null
}

const initialState: WebshopReducer = {
    error: null,
    areLogsLoading: false,
    //---- BASKET RULES --------------
    isBasketRuleLoading: false,
    isBasketRuleInfiniteScrolling: false,
    basketRule: null,
    basketRulesTotalCount: 0,
    basketRuleFiltersInUrl: "",
    basketRuleList: [],
    basketRuleLogs: DEFAULT_LOGS_DATA,
    basketRuleStatistics: DEFAULT_BASKET_RULES_STATISTICS,
    //---- WEBSHOP -------------------
    isWebshopLoading: false,
    isWebshopInfiniteScrolling: false,
    webshop: null,
    displayShop: null,
    webshopTotalCount: 0,
    webshopFiltersInUrl: "",
    webshopList: [],
    webshopLogs: DEFAULT_LOGS_DATA,
    webshopStatistics: DEFAULT_SHOP_STATISTICS,
    isShopCreatingLoading: false,
    //----  WEBSHOP STATISTICS ---------------
    isShopCustomerStatisticsLoading: false,
    shopCustomerStatisticsList: [],
    shopCustomerStatisticsTotalCount: 0,
    currentCustomerStatistic: null,
    //---- WEBSHOP - PRODUCTS --------
    isBundleLoading: false,
    isBundleInfiniteScrolling: false,
    bundleTotalCount: 0,
    bundleList: [],
    bundle: null,
    //---- PUBLIC - WEBSHOP -------------------
    publicWebshopList: [],
    //---- PUBLIC - WEBSHOP STATISTICS ---------------
    shopOrderStatistics: null
};

const WebshopSlice = createSlice({
    name: "webshop",
    initialState,
    extraReducers: (builder) => {
        builder
            //---- BASKET RULES -----------------------------------------------------------------
            .addCase(webshopOperations.createBasketRule.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
            })
            .addCase(webshopOperations.createBasketRule.fulfilled, (state, action) => {
                state.basketRule = action.payload;
                state.isBasketRuleLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.updateBasketRule.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
            })
            .addCase(webshopOperations.updateBasketRule.fulfilled, (state, action) => {
                state.basketRule = action.payload;
                state.isBasketRuleLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getBasketRule.rejected, (state, action) => {
                state.basketRule = initialState.basketRule;
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
            })
            .addCase(webshopOperations.getBasketRule.fulfilled, (state, action) => {
                state.basketRule = action.payload;
                state.isBasketRuleLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.searchBasketRules.rejected, (state, action) => {
                state.basketRuleList = initialState.basketRuleList;
                state.error = action.error.message as string;
                state.isBasketRuleLoading = false;
                state.isBasketRuleInfiniteScrolling = false;
            })
            .addCase(webshopOperations.searchBasketRules.fulfilled, (state, action) => {
                state.basketRuleList = state.isBasketRuleInfiniteScrolling ? state.basketRuleList.concat(action.payload.results) : action.payload.results;
                state.basketRulesTotalCount = action.payload.totalCount;
                state.isBasketRuleLoading = false;
                state.isBasketRuleInfiniteScrolling = false;
            })
            //-----------------------
            .addCase(webshopOperations.enableDisableBasketRule.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //-----------------------
            .addCase(webshopOperations.getBasketRuleLogs.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.basketRuleLogs = initialState.basketRuleLogs;
                state.areLogsLoading = false;
            })
            .addCase(webshopOperations.getBasketRuleLogs.fulfilled, (state, action) => {
                state.basketRuleLogs = action.payload;
                state.areLogsLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getBasketRuleStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.basketRuleStatistics = initialState.basketRuleStatistics;
            })
            .addCase(webshopOperations.getBasketRuleStatistics.fulfilled, (state, action) => {
                state.basketRuleStatistics = action.payload;
            })
            //---- WEBSHOP ----------------------------------------------------------------------
            .addCase(webshopOperations.createShop.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
                state.isShopCreatingLoading = false;
            })
            .addCase(webshopOperations.createShop.fulfilled, (state, action) => {
                state.webshop = action.payload;
                state.isWebshopLoading = false;
                state.isShopCreatingLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.updateShop.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
                state.isShopCreatingLoading = false;
            })
            .addCase(webshopOperations.updateShop.fulfilled, (state, action) => {
                state.webshop = action.payload;
                state.isWebshopLoading = false;
                state.isShopCreatingLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getShop.rejected, (state, action) => {
                state.webshop = initialState.webshop;
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
            })
            .addCase(webshopOperations.getShop.fulfilled, (state, action) => {
                state.webshop = action.payload;
                state.isWebshopLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getDisplayShop.fulfilled, (state, action) => {
                state.displayShop = action.payload;
                state.isWebshopLoading = false;
            })
            .addCase(webshopOperations.getDisplayShop.rejected, (state, action) => {
                state.displayShop = initialState.displayShop;
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.searchShops.rejected, (state, action) => {
                state.webshopList = initialState.webshopList;
                state.error = action.error.message as string;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            .addCase(webshopOperations.searchShops.fulfilled, (state, action) => {
                state.webshopList = state.isWebshopInfiniteScrolling ? state.webshopList.concat(action.payload.results) : action.payload.results;
                state.webshopTotalCount = action.payload.totalCount;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            //-----------------------
            .addCase(webshopOperations.getShopLogs.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.webshopLogs = initialState.webshopLogs;
                state.areLogsLoading = false;
            })
            .addCase(webshopOperations.getShopLogs.fulfilled, (state, action) => {
                state.webshopLogs = action.payload;
                state.areLogsLoading = false;
            })
            //-----------------------
            .addCase(webshopOperations.getShopStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.webshopStatistics = initialState.webshopStatistics;
            })
            .addCase(webshopOperations.getShopStatistics.fulfilled, (state, action) => {
                state.webshopStatistics = action.payload;
            })
            //---- WEBSHOP - BUNDLE -----------------------------------------------------------
            .addCase(webshopOperations.searchShopBundles.rejected, (state, action) => {
                state.bundleList = initialState.bundleList;
                state.error = action.error.message as string;
                state.isBundleLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            .addCase(webshopOperations.searchShopBundles.fulfilled, (state, action) => {
                state.bundleList = state.isBundleInfiniteScrolling ? state.bundleList.concat(action.payload.results) : action.payload.results;
                state.bundleTotalCount = action.payload.totalCount;
                state.isBundleLoading = false;
                state.isBundleInfiniteScrolling = false;
            })
            .addCase(webshopOperations.getShopBundle.rejected, (state, action) => {
                state.bundle = initialState.bundle;
                state.error = action.error.message as string;
                state.isBundleLoading = false;
            })
            .addCase(webshopOperations.getShopBundle.fulfilled, (state, action) => {
                state.bundle = action.payload;
                state.isBundleLoading = false;
            })
            //---- PUBLIC - WEBSHOP ------------
            .addCase(webshopOperations.searchPublicShops.rejected, (state, action) => {
                state.publicWebshopList = initialState.publicWebshopList;
                state.error = action.error.message as string;
                state.webshopTotalCount = state.webshopTotalCount;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            .addCase(webshopOperations.searchPublicShops.fulfilled, (state, action) => {
                state.publicWebshopList = state.isWebshopInfiniteScrolling ? state.publicWebshopList.concat(action.payload.results) : action.payload.results;
                state.webshopTotalCount = action.payload.totalCount;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            //-----------------------
            .addCase(webshopOperations.searchPublicShopsByBundle.rejected, (state, action) => {
                state.publicWebshopList = initialState.publicWebshopList;
                state.error = action.error.message as string;
                state.webshopTotalCount = state.webshopTotalCount;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            .addCase(webshopOperations.searchPublicShopsByBundle.fulfilled, (state, action) => {
                state.publicWebshopList = action.payload;
                state.webshopTotalCount = action.payload.length;
                state.isWebshopLoading = false;
                state.isWebshopInfiniteScrolling = false;
            })
            //---- PUBLIC - WEBSHOP STATISTICS ---------------
            .addCase(webshopOperations.getPublicShopOrderStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.shopOrderStatistics = initialState.shopOrderStatistics;
            })
            .addCase(webshopOperations.getPublicShopOrderStatistics.fulfilled, (state, action) => {
                state.shopOrderStatistics = action.payload;
            })
            .addCase(webshopOperations.getAdminShopOrderStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.shopOrderStatistics = initialState.shopOrderStatistics;
            })
            .addCase(webshopOperations.getAdminShopOrderStatistics.fulfilled, (state, action) => {
                state.shopOrderStatistics = action.payload;
            })
            .addCase(webshopOperations.searchShopOrderCustomers.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.shopCustomerStatisticsList = initialState.shopCustomerStatisticsList;
                state.shopCustomerStatisticsTotalCount = 0;
                state.isShopCustomerStatisticsLoading = false;
            })
            .addCase(webshopOperations.searchShopOrderCustomers.fulfilled, (state, action) => {
                const scroll = action.meta.arg.filters.sequenceToken;

                if (scroll)
                    state.shopCustomerStatisticsList.push(...action.payload.results);
                else
                    state.shopCustomerStatisticsList = action.payload.results;

                state.shopCustomerStatisticsTotalCount = action.payload.totalCount;
                state.isShopCustomerStatisticsLoading = false;
            })
            .addCase(webshopOperations.getAdminShopCustomerOrderStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.shopOrderStatistics = initialState.shopOrderStatistics;
            })
            .addCase(webshopOperations.getAdminShopCustomerOrderStatistics.fulfilled, (state, action) => {
                state.shopOrderStatistics = action.payload;
            })
            //-----------------------
            ;
    },
    reducers: {
        startWebshopLoading(state, action: PayloadAction<boolean | undefined>) {
            state.isWebshopLoading = true;
            if (action.payload) state.isWebshopInfiniteScrolling = true;
        },
        startWebshopBundleLoading(state, action: PayloadAction<boolean>) {
            state.isBundleLoading = true;
            if (action.payload) state.isBundleInfiniteScrolling = true;
        },
        startBasketLoading(state, action: PayloadAction<boolean>) {
            state.isBasketRuleLoading = true;
            if (action.payload) state.isBasketRuleInfiniteScrolling = true;
        },
        startLogsLoading(state) {
            state.areLogsLoading = true;
        },
        startShopCreationLoading(state) {
            state.isShopCreatingLoading = true;
        },
        startCustomerStatisticsLoading(state) {
            state.isShopCustomerStatisticsLoading = true;
        },
        setBundle(state, action: PayloadAction<ShopBundleSearchResult>) {
            state.bundle = action.payload;
        },
        setBasketFiltersInUrl(state, action: PayloadAction<string>) {
            state.basketRuleFiltersInUrl = action.payload;
        },
        setWebshopFiltersInUrl(state, action: PayloadAction<string>) {
            state.webshopFiltersInUrl = action.payload;
        },
        setShopOrderStatistics(state, action: PayloadAction<ShopCustomerStatisticsSearchResult>) {
            state.currentCustomerStatistic = action.payload;
            state.shopOrderStatistics = action.payload;
        },
        resetWebshop(state) {
            state.webshop = null;
        },
        resetBundle(state) {
            state.bundle = null;
        },
        //BASKET DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableBasketSL(state, action: PayloadAction<EnableDisableBasketRuleSliceProps>) {

            const { id, tab, action: payloadAction } = action.payload;

            const index = state.basketRuleList.findIndex((basketRule) => basketRule.id === id);

            state.basketRuleList[index].enabled = payloadAction === "Enable";

            if (tab !== "") state.basketRuleList.splice(index, 1);

            if (payloadAction === "Enable") {
                state.basketRuleStatistics.disabled -= 1;

                state.basketRuleStatistics.enabled += 1;
            } else {
                state.basketRuleStatistics.disabled += 1;

                state.basketRuleStatistics.enabled -= 1;
            }
        },
        //WEBSHOP DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableWebshopSL(state, action: PayloadAction<EnableDisableShopSliceProps>) {

            const { id, tab, action: payloadAction } = action.payload;

            const index = state.webshopList.findIndex((basketRule) => basketRule.id === id);

            state.webshopList[index].enabled = payloadAction === "Enable";

            if (tab !== "") state.webshopList.splice(index, 1);

            if (payloadAction === "Enable") {
                state.webshopStatistics.disabled -= 1;

                state.webshopStatistics.expired += 1;
            } else {
                state.webshopStatistics.disabled += 1;

                state.webshopStatistics.expired -= 1;
            }
        }
    }
});

export const {
    startWebshopLoading,
    startWebshopBundleLoading,
    startBasketLoading,
    startLogsLoading,
    enableDisableBasketSL,
    setBasketFiltersInUrl,
    setWebshopFiltersInUrl,
    enableDisableWebshopSL,
    resetWebshop,
    startShopCreationLoading,
    setBundle,
    startCustomerStatisticsLoading,
    setShopOrderStatistics
} = WebshopSlice.actions;

export default WebshopSlice.reducer;