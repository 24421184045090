import { Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import OrdersList from './OrdersList';

interface OrdersListManagerProps {
    isVendor?: boolean
}

export default function OrdersListManager({ isVendor }: OrdersListManagerProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    //reset to refresh the request when its status changes
    /*     useEffect(() => {
            if (request) dispatch(resetRequest());
        }, [request]); */

    return (
        <Page title={translate(`menu.management.orders.${isVendor ? "myTitle" : "custTitle"}`)}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate(`menu.management.orders.${isVendor ? "myTitle" : "custTitle"}`)}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate('menu.management.orders.list') },
                    ]}
                />

                <OrdersList isVendor={!!isVendor} key={"order.list." + isVendor} />

            </Container>
        </Page>
    );
}