import { AnimatePresence } from 'framer-motion';
import { Box, IconButton, BoxProps } from '@mui/material';
import { varFade } from '../../animate';
import { FileWithPreview } from 'src/@types/media';
import { CropRotate, Delete } from '@mui/icons-material';

export const MediaSliderStyle = {
    "& .thumbnails": {
        minWidth: "160px",
        maxWidth: "160px",
        minHeight: "90px",
        maxHeight: "90px",
        position: 'relative'
    },
    "& .thumbnails-images": {
        borderRadius: "15px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        border: "1px ridge",
        borderColor: (theme: any) => theme.palette.text.main
    }
};

interface ImagesPreviewProps extends BoxProps {
    files: FileWithPreview[],
    onEdit?: (value: number) => void,
    onRemove?: (value: number) => void,
}

export default function ImagesPreview({ files, onEdit, onRemove, sx, ...BoxProps }: ImagesPreviewProps) {

    return (
        <AnimatePresence initial={false}>

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    overflowX: 'auto',
                    py: 1.5,
                    height: 135,
                    gap: 1.5,
                    ...MediaSliderStyle
                }}
            >

                {files.map((file, index) =>
                    <Box
                        key={index}
                        className={"thumbnails"}
                        {...varFade().inUp}
                    >
                        <img
                            alt={file.name}
                            src={file.preview}
                            className={"thumbnails-images"}
                        />

                        <Box sx={{ position: 'absolute', top: 0, width: "100%", height: "100%" }}>
                            {onRemove &&
                                <IconButton
                                    aria-label="delete"
                                    sx={{
                                        cursor: "pointer",
                                        width: "1.35em",
                                        height: "1.35em",
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        border: "1px solid",
                                        borderRadius: "100px",
                                        borderColor: (theme) => theme.palette.text.primary,
                                        color: (theme) => theme.palette.text.primary,
                                        top: '5%',
                                        left: '3%',
                                        '&:hover': {
                                            backgroundColor: (theme) => theme.palette.primary.lighter
                                        }
                                    }}
                                    onClick={() => onRemove(index)}
                                >
                                    <Delete sx={{ width: "0.8em" }} />
                                </IconButton>
                            }

                            {onEdit &&
                                <IconButton
                                    aria-label="edit"
                                    sx={{
                                        cursor: "pointer",
                                        width: "1.35em",
                                        height: "1.35em",
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        border: "1px solid",
                                        borderRadius: "100px",
                                        borderColor: (theme) => theme.palette.text.primary,
                                        color: (theme) => theme.palette.text.primary,
                                        top: '5%',
                                        left: '56.5%',
                                        '&:hover': {
                                            backgroundColor: (theme) => theme.palette.primary.lighter
                                        }
                                    }}
                                    onClick={() => onEdit(index)}
                                >
                                    <CropRotate sx={{ width: "0.8em" }} />
                                </IconButton>
                            }
                        </Box>

                    </Box>
                )}

            </Box>

        </AnimatePresence >
    );
}