import axios, { AxiosResponse } from "axios";
import { Orders } from "./paths";
import { Tag } from "src/@types/commons";
import { CreditNote, DeliveryNote, DocumentFilters, DocumentSearchResult, Invoice, Order, OrderFilters, OrderSearchResult } from "src/@types/orders";
import { LogsFilters, LogsItem } from "src/@types/logs";
import { InfiniteScrollResponse, GenericPagedResponse } from "src/@types/list";

//---- ORDERS -----------------------------------------------------------------

const searchOrders = (options: OrderFilters, isVendor: boolean): Promise<AxiosResponse<InfiniteScrollResponse<OrderSearchResult>>> => {

    const url = isVendor ? Orders.admin.orders.vendor.search() : Orders.admin.orders.customer.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchOrdersTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Orders.admin.orders.searchTags();

    return axios.get(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const detailOrder = (id: string): Promise<AxiosResponse<Order>> => {
    const url = Orders.admin.orders.detail(id);

    return axios.get(url);
};

const searchOrdersLogs = (id: string, filters: LogsFilters): Promise<AxiosResponse<GenericPagedResponse<LogsItem>>> => {
    const url = Orders.admin.orders.searchOrderLogs(id);

    return axios.get<any>(url, { params: filters });
};

//---- DOCUMENTS -----------------------------------------------------------------

const searchDocuments = (options: DocumentFilters, isVendor: boolean): Promise<AxiosResponse<InfiniteScrollResponse<DocumentSearchResult>>> => {

    const url = isVendor ? Orders.admin.orders.vendor.searchDocument() : Orders.admin.orders.customer.searchDocument();

    return axios.get(url, { params: options });
};

const searchDocumentsTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Orders.admin.orders.searchDocumentsTags();

    return axios.get(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const detailInvoice = (id: string): Promise<AxiosResponse<Invoice>> => {
    const url = Orders.admin.orders.invoiceDetail(id);

    return axios.get(url);
};

const detailDeliveryNote = (id: string): Promise<AxiosResponse<DeliveryNote>> => {
    const url = Orders.admin.orders.deliveryNoteDetail(id);

    return axios.get(url);
};

const detailCreditNote = (id: string): Promise<AxiosResponse<CreditNote>> => {
    const url = Orders.admin.orders.creditNoteDetail(id);

    return axios.get(url);
};

const searchDocumentsLogs = (id: string, type: string, filters: LogsFilters): Promise<AxiosResponse<GenericPagedResponse<LogsItem>>> => {
    let url = "";

    switch (type) {
        case "Invoice":
            url = Orders.admin.orders.searchInvoiceLogs(id);
            break;
        case "CreditNote":
            url = Orders.admin.orders.searchCreditNoteLogs(id);
            break;
        case "DeliveryNote":
            url = Orders.admin.orders.searchDeliveryNoteLogs(id);
            break;
    }

    return axios.get<any>(url, { params: filters });
};

export {
    searchOrders,
    searchOrdersTags,
    searchDocumentsTags,
    detailOrder,
    searchDocuments,
    detailDeliveryNote,
    detailInvoice,
    searchOrdersLogs,
    searchDocumentsLogs,
    detailCreditNote
};