import { ArrowForwardIos, Close, ExpandMore, InsertDriveFile } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, Drawer, IconButton, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { CreditNote, DeliveryNote, Invoice } from "src/@types/orders";
import DateZone from "src/appComponents/DateZone";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import DocumentPrint from "src/sections/@dashboard/documents/detail/DocumentPrint";

type Document = Invoice | DeliveryNote | CreditNote

interface DocumentsDetailProps {
    documents: Document[],
    sx?: SxProps<Theme> | undefined
    isInvoices?: boolean
}

export default function RequestDocumentsDetail({ documents, sx, isInvoices }: DocumentsDetailProps) {

    const { translate } = useLocales();

    const [expanded, setExpanded] = useState(documents.length === 1);

    const isDesktop = useResponsive('up', 'md');

    const [selectedDoc, setSelectedDoc] = useState<Document>();

    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <Card sx={{ "& .MuiAccordionSummary-root": { cursor: documents.length > 1 ? 'pointer' : "default !important" }, ...sx }}>

            <Drawer PaperProps={{ sx: { width: '80%' } }} open={openDrawer} onClose={() => setOpenDrawer(false)} anchor='right' >
                <Box sx={{ overflow: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2, alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                            {`${translate('documents.detail.title')}`}
                        </Typography>
                        <IconButton onClick={() => setOpenDrawer(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        <DocumentPrint document={selectedDoc!} type={isInvoices ? "Invoice" : "DeliveryNote"} />
                    </Box>
                </Box>
            </Drawer>

            <Accordion
                disableGutters
                square
                expanded={expanded}
            >
                <AccordionSummary
                    id={"spare-sparts-accordion"}
                    expandIcon={(documents.length > 1) && <ExpandMore sx={{ pointerEvents: 'auto' }} />}
                    onClick={() => { if (documents.length > 1) setExpanded(prev => !prev); }}
                    sx={{ pr: 2 }}
                >
                    <InsertDriveFile />
                    <Typography
                        variant="h6"
                        sx={{ color: 'text.primary', fontWeight: '600', ml: 2 }}
                    >
                        {`${isInvoices ? translate('request.detail.invoiceListInDetail') : translate('request.detail.deliveryNoteListInDetail')}`}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ pt: 0, pb: 1, pr: 1, pl: 4 }}>

                    {documents.length > 0 &&
                        <>
                            {
                                documents.map((document, index) =>
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setSelectedDoc(document);
                                            setOpenDrawer(true);
                                        }}
                                    >
                                        <Box

                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: isDesktop ?
                                                    `${documents.length > 1 ? "3%" : ""} 15% 17.5% 20% 10%` :
                                                    `${documents.length > 1 ? "10%" : ""} 45% 45% 50% 35%`,
                                                flexDirection: 'row',
                                                width: '100%',
                                                overflowX: "auto",
                                                alignItems: 'center'
                                            }}
                                        >
                                            {documents.length > 1 &&
                                                <Stack sx={{ m: 2, mr: 0 }}>
                                                    <Typography variant="overline">{index + 1}</Typography>
                                                </Stack>
                                            }

                                            <Stack sx={{ m: 2 }}>
                                                <Typography variant="overline">{`${translate('documents.filter.type')}`}</Typography>
                                                <Typography variant="body2">{`${translate(`documents.types.${isInvoices ? 'invoice' : 'deliveryNote'}`)}`}</Typography>
                                            </Stack>
                                            <Stack sx={{ m: 2 }}>
                                                <Typography variant="overline">{`${translate('documents.detail.documentNumber')}`}</Typography>
                                                <Typography variant="body2">{document.externalId}</Typography>
                                            </Stack>
                                            <Stack sx={{ m: 2 }}>
                                                <Typography variant="overline">{`${translate('documents.detail.documentDate')}`}</Typography>
                                                <DateZone date={document.createdOn} noSeconds />
                                            </Stack>

                                        </Box>
                                        <Stack sx={{ ml: 'auto', display: 'flex', alignItems: 'center', pr: 3 }}>
                                            <ArrowForwardIos />
                                        </Stack>
                                    </Box>
                                )
                            }
                        </>
                    }
                </AccordionDetails>
            </Accordion>
        </Card >
    );
}