import { Typography, Box } from "@mui/material";
import { useState } from "react";
import { FileWithSection } from "src/@types/media";
import Upload from "src/components/upload/Upload";
import { useLocales } from "src/locales";
import ImagesPreview from "./preview/ImagesPreview";

interface UploadForContainerProps {
    files: FileWithSection[];
    setFiles: (files: FileWithSection[]) => void;
}

export default function UploadForContainer({ files, setFiles }: UploadForContainerProps) {

    const [url, setUrl] = useState("");

    const { translate } = useLocales();

    return (
        <Box>
            {files.length === 0 ?
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{
                        color: '#6D6D6D',
                        fontSize: 12,
                        fontFamily: 'Public Sans',
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        wordWrap: 'break-word',
                        mb: 3
                    }}
                    >
                        {`${translate('request.addPicture')}`}
                    </Typography>

                    <Upload
                        onDropAccepted={(files) => {
                            setFiles(files);
                            setUrl(URL.createObjectURL(files[0]));
                        }}
                        files={files}
                    />

                </Box>
                :
                <Box>
                    <ImagesPreview
                        files={files.map(f => ({ ...f, preview: url }))}
                        onRemove={() => {
                            setFiles([]);
                            URL.revokeObjectURL(url);
                        }}
                    />
                </Box>

            }
        </Box>);
}