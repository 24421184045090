import { createAsyncThunk } from "@reduxjs/toolkit";
import { EnableDisableGroupSliceProps, GroupCategory, GroupFilters, NewGroup } from "src/@types/group";
import * as groupService from '../../services/groupService';
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import { startLoading, startMembersLoading, startLogsLoading, enableDisableGroupSL, startOrganizationsLoading, startWhitelistLoading, startBlacklistLoading, setOrganizationBlackHasFilter, setUserBlackHasFilter, setOrganizationWhiteHasFilter, setUserWhiteHasFilter } from "./group-slices";
import { UserFilters } from "src/@types/user";
import { OrganizationFilters } from "src/@types/organizations";

const createGroup = createAsyncThunk(
    'group/createGroup',
    async (group: NewGroup, { dispatch, rejectWithValue }) => {
        try {
            const { data, status } = await groupService.createGroup(group);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateGroup = createAsyncThunk(
    'group/updateGroup',
    async (params: { group: NewGroup, id: string }, { dispatch, rejectWithValue }) => {
        try {
            const { group, id } = params;

            const { data, status } = await groupService.updateGroup(group, id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getGroup = createAsyncThunk(
    'group/getGroup',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await groupService.detailGroup(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroups = createAsyncThunk(
    'group/searchGroups',
    async (options: { filters: GroupFilters, check: boolean }, { dispatch, rejectWithValue }) => {

        let { filters, check } = options;

        dispatch(startLoading(check));
        try {
            const { data } = await groupService.searchGroups(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getGroupLogs = createAsyncThunk(
    'group/getGroupLogs',
    async (params: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await groupService.searchGroupLogs(params.id, nullifyEmptyKeys(params.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableDisableGroup = createAsyncThunk(
    'group/enableDisableGroup',
    async (options: EnableDisableGroupSliceProps, { dispatch, rejectWithValue }) => {

        try {
            const { data, status } = options.action === 'Enable' ?
                await groupService.enableGroup(options.id) :
                await groupService.disableGroup(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableGroupSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupMembers = createAsyncThunk(
    'group/searchGroupMembers',
    async (options: { filters: UserFilters, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check } = options;

        dispatch(startMembersLoading(check));
        try {
            const { data } = await groupService.searchGroupMembers(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupMembersBlackWhite = createAsyncThunk(
    'group/searchGroupMembersBlackWhite',
    async (options: { filters: UserFilters, isWhiteList: boolean, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check, isWhiteList } = options;

        if (isWhiteList) dispatch(startWhitelistLoading(check));
        else dispatch(startBlacklistLoading(check));

        try {
            if (isWhiteList) dispatch(setUserWhiteHasFilter(!!filters.all));
            else dispatch(setUserBlackHasFilter(!!filters.all));

            const { data } = await groupService.searchGroupMembers(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupOrganizations = createAsyncThunk(
    'group/searchGroupOrganizations',
    async (options: { filters: OrganizationFilters, check: boolean, isVendor: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check, isVendor } = options;

        dispatch(startOrganizationsLoading(check));
        try {
            const { data } = await groupService.searchGroupOrganizations(filters, isVendor);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupOrganizationsBlackWhite = createAsyncThunk(
    'group/searchGroupOrganizationsBlackWhite',
    async (params: { filters: OrganizationFilters, type: GroupCategory, isWhiteList: boolean, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, type, isWhiteList, check } = params;

        if (isWhiteList) dispatch(startWhitelistLoading(check));
        else dispatch(startBlacklistLoading(check));

        try {
            if (isWhiteList) dispatch(setOrganizationWhiteHasFilter(!!filters.all));
            else dispatch(setOrganizationBlackHasFilter(!!filters.all));

            const { data } = await (groupService.searchGroupOrganizations(filters, type === "Organization"));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getStatistics = createAsyncThunk(
    'group/getStatistics',
    async (options: GroupFilters, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await groupService.statistics(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const groupOperations = {
    createGroup,
    updateGroup,
    getGroup,
    searchGroups,
    searchGroupMembers,
    getGroupLogs,
    enableDisableGroup,
    searchGroupOrganizations,
    getStatistics,
    searchGroupOrganizationsBlackWhite,
    searchGroupMembersBlackWhite
};

export default groupOperations;
