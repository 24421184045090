import { Box, Rating, Tooltip } from '@mui/material';
import SvgColor from 'src/components/svg-color';

interface ScoreProgressIconProps {
    progress: number
}

export default function ScoreProgressIcon({ progress }: ScoreProgressIconProps) {

    const iconComponent = <SvgColor src={'/assets/icons/utils/ic_vertical_line.svg'} sx={{ mr: -1.75 }} />;

    return (
        <Tooltip title={"Score: " + progress} arrow>
            <Box sx={{ display: "flex" }}>
                <Rating
                    value={progress}
                    size={'large'}
                    icon={iconComponent}
                    emptyIcon={iconComponent}
                    readOnly
                    sx={{ ml: -1.5, '& .MuiRating-iconFilled': { color: (theme) => theme.palette.text.primary } }}
                />
            </Box>
        </Tooltip>
    );
}
