import axios, { AxiosResponse } from "axios";
import { Shop } from "./paths";
import { DiscountMatrixFilters, DiscountMatrixRowsFilters, DiscountMatrixRowsSearchResult, DiscountMatrixSearchResult } from "src/@types/discountMatrix";
import { InfiniteScrollResponse } from "src/@types/list";

const searchDiscountMatrixes = (options: DiscountMatrixFilters): Promise<AxiosResponse<InfiniteScrollResponse<DiscountMatrixSearchResult>>> => {
    const url = Shop.admin.discountMatrix.list();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchDiscountMatrixRows = (options: DiscountMatrixRowsFilters, id: string): Promise<AxiosResponse<InfiniteScrollResponse<DiscountMatrixRowsSearchResult>>> => {
    const url = Shop.admin.discountMatrix.detailRows(id);

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const enable = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.discountMatrix.enable(id);

    return axios.patch(url);

};

const disable = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.discountMatrix.disable(id);

    return axios.patch(url);

};

const rename = (id: string, name: string): Promise<AxiosResponse> => {
    const url = Shop.admin.discountMatrix.rename(id);

    return axios.patch(url, { name });
};

export {
    searchDiscountMatrixes,
    searchDiscountMatrixRows,
    enable,
    disable,
    rename
};