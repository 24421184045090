import { HouseSiding, Label, Payments, ShoppingBasket } from "@mui/icons-material";
import { Box, Button, Card, Divider, Grid, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Alert, Request, WarrantyApprove } from "src/@types/request";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import PaymentsIcon from '@mui/icons-material/Payments';
import WarrantyForm from "./BacklogWarrantyConfirm";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";
import useTenant from "src/appHooks/useTenant";
import { FileWithSection } from "src/@types/media";

interface BacklogConfirmRequestProps {
    request: Request,
    setConfirmed: (val: boolean) => void,
    handleSubmit: (
        note: string,
        customFields: Record<string, string>,
        fees: {
            repairFee: number,
            returnFee: number,
            surveillanceFee: number,
            invoiceDataFee: number
        },
        warrantyObj?: WarrantyApprove,
        file?: FileWithSection[]
    ) => void,
    destination: string
    onChangeDestination: (val: string) => void,
    fees: Alert,
    isAr?: boolean
}

export default function BacklogConfirmRequest({
    request,
    setConfirmed,
    handleSubmit,
    destination,
    onChangeDestination,
    fees,
    isAr
}: BacklogConfirmRequestProps) {

    const { translate, currentLang } = useLocales();

    const isDesktop = useResponsive("up", "sm");

    const [icar, setIcar] = useState("");

    const [sap, setSap] = useState("");

    const [disabled, setDisabled] = useState(true);

    const [icarError, setIcarError] = useState("");

    const [sapError, setSapError] = useState("");

    const [returnFee, setReturnFee] = useState(fees.frequencyFee);

    const [surveillanceFee, setSurveillanceFee] = useState(0);

    const [invoiceDataFee, setInvoiceDataFee] = useState(fees.invoiceFee);

    const [repairFee, setRepairFee] = useState(0);

    const totalFee = repairFee - (invoiceDataFee + surveillanceFee + returnFee);

    const plusOrMinus = totalFee > 0 ? "+" : "";

    const [warrantyObj, setWarrantyObj] = useState<WarrantyApprove>();

    const [file, setFile] = useState<FileWithSection[]>([]);

    const { customFields } = useTenant();

    const customFieldName = useCallback((id: string) => {

        return getCustomFieldLabel(customFields, id, currentLang);

    }, [currentLang, customFields]);

    const handleChangeFee = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let { value, name } = e.target;

        if (value.length === 0)
            value = "0";

        switch (name) {
            case "returnFee":
                setReturnFee(prev => !isNaN(+value) && !(+value > 100 || +value < 0) ? Math.round(+value) : prev);
                break;
            case "surveillanceFee":
                setSurveillanceFee(prev => !isNaN(+value) && !(+value > 100 || +value < 0) ? Math.round(+value) : prev);
                break;
            case "invoiceDataFee":
                setInvoiceDataFee(prev => !isNaN(+value) && !(+value > 100 || +value < 0) ? Math.round(+value) : prev);
                break;
            case "repairFee":
                setRepairFee(prev => !isNaN(+value) && !(+value > 100 || +value < 0) ? Math.round(+value) : prev);
                break;
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.name === "stellantis-europe-returnrequestapprover-icar-request")
            setIcar(e.target.value);
        else if (e.target.name === "stellantis-europe-returnrequestapprover-sapcsps-code")
            setSap(e.target.value);
    };

    const handleError = (e: any) => {
        if (e.target.name === "stellantis-europe-returnrequestapprover-icar-request")
            if (icar.length === 0)
                setIcarError(translate("commons.validation.requiredField").toString());
            else
                setIcarError("");

        else if (e.target.name === "stellantis-europe-returnrequestapprover-sapcsps-code")
            if (sap.length === 0)
                setSapError(translate("commons.validation.requiredField").toString());
            else
                setSapError("");

    };

    const handleSub = () => {

        let customField: Record<string, string> = {};

        if (request.requestType === "Core") {
            handleSubmit("", customField, { repairFee, returnFee, invoiceDataFee, surveillanceFee });

            return;
        }

        if (destination === "BackInStock")
            customField["stellantis-europe-returnrequestapprover-icar-request"] = icar;

        else if (destination === "ReturnToSupplier")
            customField = {
                "stellantis-europe-returnrequestapprover-icar-request": icar,
                "stellantis-europe-returnrequestapprover-sapcsps-code": sap
            };

        if (destination === "E") {
            handleSubmit("", {}, { repairFee, returnFee, invoiceDataFee, surveillanceFee }, warrantyObj, file);

            return;
        }

        handleSubmit("", customField, { repairFee, returnFee, invoiceDataFee, surveillanceFee });
    };

    const handleWarranty = (val: any) => {
        setWarrantyObj(val);
    };

    useEffect(() => {

        if (request.requestType === "Core" || request.requestType === "WrongShipment")
            setDisabled(false);
        else if (destination === "ReturnToSupplier" && (!icar || !sap))
            setDisabled(true);
        else if (destination === "BackInStock" && !icar)
            setDisabled(true);
        else if (destination === "E") {
            let val = file.length !== 1 || !warrantyObj;

            if (warrantyObj)
                val = val || !warrantyObj.invoice || !warrantyObj.invoiceDate || warrantyObj.approvedProducts.length === 0;

            setDisabled(val);
        }
        else if (request.requestType === "Warranty" && isAr) {
            setDisabled(false);
        }
        else if (!destination)
            setDisabled(true);
        else
            setDisabled(false);

    }, [destination, file.length, icar, isAr, request, sap, warrantyObj]);

    useEffect(() => {
        setIcar("");
        setSap("");
    }, [destination]);

    const TOGGLE_BUTTONS = [
        {
            value: 'ReturnToSupplier',
            label: `${translate('request.retSupplier')}`,
            icon: <HouseSiding />
        },
        ...(request.requestType === "NewPart" ? [{
            value: 'BackInStock',
            label: `${translate('request.retStock')}`,
            icon: <ShoppingBasket />
        }] : []),
        ...(request.requestType === "Quality" ? [
            {
                value: 'Scrapping',
                label: `${translate('request.scrapping')}`,
                icon: <ShoppingBasket />
            },
            ...(request.requestReason === "OriginalPackagingContainsWrongParts" ? [{
                value: 'ChangeReference',
                label: `${translate('request.changeReference')}`,
                icon: <Label />
            }] : []),
            {
                value: 'RepairFee',
                label: `${translate('request.repFee')}`,
                icon: <Payments />
            }] : []),
        ...(request.requestType === "DamagedPart" ? [{

            value: 'Scrapping',
            label: `${translate('request.scrapping')}`,
            icon: <ShoppingBasket />
        },
        {
            value: 'RepairFee',
            label: `${translate('request.repFee')}`,
            icon: <Payments />
        }] : [])];

    const WARRANTY_BUTTONS = [
        {
            value: 'T',
            label: `${translate('request.detail.statusT')}`,
            icon: <HouseSiding />
        },
        {
            value: 'E',
            label: `${translate('request.detail.statusE')}`,
            icon: <ShoppingBasket />
        },
        {
            value: 'N',
            label: `${translate('request.detail.statusN')}`,
            icon: <PaymentsIcon />
        },
        // {
        //     value: 'A',
        //     label: `${translate('request.detail.statusA')}`,
        //     icon: <DangerousIcon />
        // },
    ];

    const feeBox = (
        <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${destination === "RepairFee" ? 2 : 3}, 1fr)`, gap: 3 }} >
            {destination === "RepairFee" &&
                <TextField
                    label={`${translate('request.repairFee')}`}
                    name={"repairFee"}
                    value={repairFee + ""}
                    onChange={(e) => handleChangeFee(e)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{'%'}</InputAdornment>
                    }}
                />
            }
            <TextField
                label={`${translate('request.retFee')}`}
                name="returnFee"
                value={returnFee + ""}
                onChange={(e) => handleChangeFee(e)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{'%'}</InputAdornment>
                }}
            />
            <TextField
                label={`${translate('request.survFee')}`}
                name="surveillanceFee"
                value={surveillanceFee + ""}
                onChange={(e) => handleChangeFee(e)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{'%'}</InputAdornment>
                }}
            />

            <TextField
                label={`${translate('request.dateFee')}`}
                name="invoiceDataFee"
                value={invoiceDataFee + ""}
                onChange={(e) => handleChangeFee(e)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{'%'}</InputAdornment>
                }}
            />
        </Box>
    );

    if (request.requestType === "Warranty" && !isAr) {

        return (
            <Card sx={{ p: 3, mt: 5 }}>

                <ToggleButtonGroup
                    sx={{
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        display: 'grid',
                        border: 'none',
                        m: 2, gap: 3
                    }}
                    value={destination}
                    onChange={(_, v) => { onChangeDestination(v); }}
                    exclusive
                    size="large"
                    color="primary"
                >
                    {WARRANTY_BUTTONS.map((button, index) =>
                        <ToggleButton
                            key={index}
                            value={button.value}
                            sx={{
                                flex: '1 0 0',
                                p: '32px 27px',
                                outlineWidth: '1px',
                                outlineStyle: 'solid',
                                outlineColor: destination === 'stock' ? 'inherit' : "#E2E2E2"
                            }}
                        >
                            <Grid item xs={4}>
                                <Stack sx={{ alignItems: 'center' }}>
                                    {button.icon}
                                    <Typography sx={{ mt: 1 }} variant="body2">
                                        {button.label}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </ToggleButton>)}
                </ToggleButtonGroup>

                {destination === "E" && <WarrantyForm request={request} onChange={handleWarranty} changeFile={setFile} />}

                {destination === "A" &&
                    <Box sx={{ p: 3 }}>
                        <TextField
                            fullWidth
                            multiline
                            label={'add note'}
                            minRows={3}
                        />
                    </Box>}
                <Box sx={{ my: 2, justifyContent: 'space-between', ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'default' : 'column-reverse' }}>

                    <Button variant="soft" sx={{ borderRadius: '100px' }} onClick={() => setConfirmed(false)}>
                        {`${translate('request.goBackReq')}`}
                    </Button>

                    <Button
                        variant="contained"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => handleSub()}
                        disabled={disabled}
                    >
                        {`${translate('commons.confirm')}`}
                    </Button>

                </Box>
            </Card>
        );
    }

    return (
        <Box>
            {request.requestType === "Core" || request.requestType === "WrongShipment" || request.requestType === "Warranty" ?
                <Card sx={{ p: 3, mt: 5 }}>
                    {request.requestType !== "Core" && request.requestType !== "WrongShipment" &&
                        <Box>
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 2 }}>{`${translate('request.selectFee')}`}</Typography>
                                {feeBox}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                                <Typography variant="body2" color={totalFee >= 100 ? "error" : undefined}>
                                    {`${plusOrMinus}${totalFee}% ${translate('request.messages.disc')}`}
                                </Typography>
                            </Box>
                            <Divider sx={{ mt: 2 }} />
                        </Box>}

                    <Box sx={{ my: 2, justifyContent: 'space-between', ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'default' : 'column-reverse' }}>

                        <Button variant="soft" sx={{ borderRadius: '100px' }} onClick={() => setConfirmed(false)}>
                            {`${translate('request.goBackReq')}`}
                        </Button>

                        <Button
                            variant="contained"
                            sx={{ borderRadius: '100px' }}
                            onClick={() => handleSub()}
                            disabled={disabled || totalFee >= 100}
                        >
                            {`${translate('commons.confirm')}`}
                        </Button>

                    </Box>
                </Card>
                :
                <Card sx={{ p: 3, mt: 5 }}>
                    <Box>
                        <Typography variant="h6">{`${translate('request.partDest')}`}</Typography>

                        <ToggleButtonGroup sx={{ gridTemplateColumns: `repeat(${request.requestReason === "OriginalPackagingContainsWrongParts" ? 4 : 3}, 1fr)`, display: 'grid', border: 'none', m: 2, gap: 3 }}
                            value={destination} onChange={(_, v) => { onChangeDestination(v); }} exclusive size="large" color="primary">

                            {TOGGLE_BUTTONS.map((button, index) =>
                                <ToggleButton
                                    key={index}
                                    value={button.value}
                                    sx={{
                                        flex: '1 0 0',
                                        p: '32px 27px',
                                        outlineWidth: '1px',
                                        outlineStyle: 'solid',
                                        outlineColor: destination === 'stock' ? 'inherit' : "#E2E2E2"
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <Stack sx={{ alignItems: 'center' }}>
                                            {button.icon}
                                            <Typography sx={{ mt: 1 }} variant="body2">
                                                {button.label}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                        {
                            destination === 'ReturnToSupplier' &&
                            <Box>
                                <Typography variant="subtitle1">{`${translate('commons.moreDetail')}`}</Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 2 }}>

                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 3 }} >
                                        <TextField
                                            required
                                            label={customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                                            error={!!icarError}
                                            onBlur={(e) => handleError(e)}
                                            value={icar}
                                            onChange={(e) => handleChange(e)}
                                            helperText={icarError}
                                            name="stellantis-europe-returnrequestapprover-icar-request"

                                        />
                                        <TextField
                                            required
                                            label={customFieldName("stellantis-europe-returnrequestapprover-sapcsps-code")}
                                            value={sap}
                                            onBlur={(e) => handleError(e)}
                                            onChange={(e) => handleChange(e)}
                                            error={!!sapError}
                                            helperText={sapError}
                                            name="stellantis-europe-returnrequestapprover-sapcsps-code"

                                        />
                                    </Box>
                                    {feeBox}
                                </Box>
                            </Box>
                        }
                        {
                            destination === 'BackInStock' &&
                            <Box>
                                <Typography variant="subtitle1">{`${translate('commons.moreDetail')}`}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 2 }}>

                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3 }} >
                                        <TextField
                                            required
                                            label={customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                                            error={!!icarError}
                                            helperText={icarError}
                                            onBlur={(e) => handleError(e)}
                                            value={icar}
                                            onChange={(e) => handleChange(e)}
                                            name="stellantis-europe-returnrequestapprover-icar-request"

                                        />
                                    </Box>

                                    {feeBox}
                                </Box>
                            </Box>
                        }
                        {
                            (destination === "Scrapping" || destination === "ChangeReference") &&
                            <Box>
                                <Typography variant="subtitle1">{`${translate('commons.moreDetail')}`}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 2 }}>

                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3 }} >
                                        <TextField
                                            required
                                            label={customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                                            error={!!icarError}
                                            helperText={icarError}
                                            onBlur={(e) => handleError(e)}
                                            value={icar}
                                            onChange={(e) => handleChange(e)}
                                            name="stellantis-europe-returnrequestapprover-icar-request"

                                        />
                                    </Box>
                                    {feeBox}
                                </Box>
                            </Box>
                        }
                        {
                            destination === "RepairFee" &&
                            <Box>
                                <Typography variant="subtitle1">{`${translate('commons.moreDetail')}`}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 2 }}>

                                    {feeBox}
                                </Box>
                            </Box>
                        }

                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Typography variant="body2" color={totalFee >= 100 ? "error" : undefined}>
                                {`${plusOrMinus}${totalFee}% ${translate('request.messages.disc')}`}
                            </Typography>
                        </Box>

                        <Divider sx={{ mt: 2 }} />

                        <Box sx={{ my: 2, justifyContent: 'space-between', ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'default' : 'column-reverse' }}>

                            <Button variant="soft" sx={{ borderRadius: '100px' }} onClick={() => setConfirmed(false)}>
                                {`${translate('request.goBackReq')}`}
                            </Button>

                            <Button
                                variant="contained"
                                sx={{ borderRadius: '100px' }}
                                onClick={() => handleSub()}
                                disabled={disabled || totalFee >= 100}
                            >
                                {`${translate('commons.confirm')}`}
                            </Button>

                        </Box>
                    </Box>
                </Card>
            }
        </Box>
    );
}
