import { Container } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { organizationsOperations } from "src/redux/organizations";
import { dispatch, RootState } from "src/redux/store";
import { webshopOperations } from "src/redux/webshop";
import { PATH_DASHBOARD } from "src/routes/paths";
import PublicStatisticsDetail from "src/sections/@dashboard/webshop/detail/PublicStatisticDetail";

export default function CustomerWebshopStatistics() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { webshopId, customerId } = useParams();

    const { displayShop } = useSelector((state: RootState) => state.webshop);

    const { organization } = useSelector((state: RootState) => state.organizations);

    useEffect(() => {
        if (webshopId && webshopId !== displayShop?.id)
            dispatch(webshopOperations.getDisplayShop({ id: webshopId })).unwrap();
    }, [webshopId, displayShop]);

    useEffect(() => {
        if (customerId && customerId !== organization?.id)
            dispatch(organizationsOperations.getGeneric(customerId));
    }, [customerId, organization?.id]);

    const displayName = organization?.externalId ? organization?.externalId + " - " + organization?.name : organization?.name;

    return (
        <Page title={translate("webshop.detail.title")}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={translate("webshop.detail.title")}
                    links={[
                        { name: translate("commons.home"), href: PATH_DASHBOARD.root },
                        { name: translate('menu.management.webshop.public.title'), href: PATH_DASHBOARD.webshop.list },
                        { name: displayShop?.title || "", href: PATH_DASHBOARD.webshop.detail(webshopId ?? "") },
                        { name: displayName || "" },
                    ]}
                />
                <PublicStatisticsDetail isVendor customerId={customerId ?? ""} shopId={webshopId ?? ""} />
            </Container>
        </Page>
    );
}