import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { DEFAULT_PRICE_AMOUNT, PriceAmount, PricePercentage } from 'src/@types/currency';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { getPrice } from 'src/utils/currency';

interface ProductPriceLabelProps {
    grossAmount: PriceAmount,
    unitDiscountedAmount?: PriceAmount,
    discountPercentage?: PricePercentage,
    translate: (text: unknown, options?: any) => string,
    priceTextVariant?: Variant,
    discountTextVariant?: Variant
}

export default function ProductPriceLabel({
    translate,
    grossAmount,
    unitDiscountedAmount,
    discountPercentage,
    priceTextVariant = "subtitle1",
    discountTextVariant = 'subtitle2'
}: ProductPriceLabelProps) {

    const discount = getPrice(discountPercentage);

    const isDiscounted = discount > 0;

    return (
        <>
            <Box sx={{ display: "flex", gap: 1 }}>
                <CurrencyAdapter
                    value={grossAmount}
                    variant={priceTextVariant}
                    color={isDiscounted ? 'textSecondary' : 'textPrimary'}
                    style={isDiscounted ? { textDecoration: 'line-through' } : {}}
                />

                {isDiscounted &&
                    <CurrencyAdapter
                        value={unitDiscountedAmount ?? DEFAULT_PRICE_AMOUNT}
                        variant={priceTextVariant}
                    />
                }
            </Box>

            {!!discount &&
                <Typography variant={discountTextVariant} noWrap sx={{ mt: -1.5 }}>
                    {translate("webshop.detail.products.saving") + " " + (Math.round(discount * 100) / 100) + "%"}
                </Typography>
            }

        </>
    );
}