import { Box, Typography } from '@mui/material';
import { ShopBundleAvailability } from 'src/@types/webshop';
import IncrementerButton from 'src/components/custom-input/IncrementerButton';

interface CartAvailabilityIncrementerProps {
    translate: (text: unknown, options?: any) => string,
    bundleId: string,
    availability: ShopBundleAvailability,
    quantitiesRecord: Record<string, number>,
    handleChangeQuantity: (id: string, action: "increase" | "decrease") => void,
    getQuantityInBasketPrefilled: (bundleId: string) => number,
    isCardView?: boolean
}

export default function CartAvailabilityIncrementer({
    translate,
    bundleId,
    availability,
    quantitiesRecord,
    handleChangeQuantity,
    getQuantityInBasketPrefilled,
    isCardView = false
}: CartAvailabilityIncrementerProps) {

    const { availableQuantity } = availability;

    const quantity: number = quantitiesRecord[bundleId] ?? 0;

    const quantityInCart: number = getQuantityInBasketPrefilled(bundleId);

    const labelComponent: JSX.Element = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: (isCardView ? 1 : 0),
                alignItems: (isCardView ? "flex-end" : "flex-start")
            }}
        >
            <Typography variant={"caption"}>
                {translate(`statuses.available`) + ": " + availableQuantity}
            </Typography>
            {quantityInCart > 0 &&
                <Typography variant={'caption'}>
                    {"(" + translate(`webshop.detail.products.inCart`) + ": " + quantityInCart + ")"}
                </Typography>
            }
        </Box>
    );

    return (
        <Box
            sx={{
                display: (isCardView ? "flex" : "grid"),
                flexDirection: 'column',
                gridTemplateColumns: 'repeat(2, 1fr)',
                alignItems: (isCardView ? "flex-end" : "center")
            }}
        >

            {!isCardView && labelComponent}

            <Box sx={{ display: "flex", height: "100%", alignItems: "center", gap: 10 }}>
                {isCardView &&
                    <Typography variant={"h6"}>
                        {translate(`commons.quantity`)}
                    </Typography>
                }

                <IncrementerButton
                    quantity={quantity}
                    disabledIncrease={quantity === availableQuantity || availableQuantity === 0}
                    disabledDecrease={quantity === 0 || availableQuantity === 0}
                    onIncrease={() => handleChangeQuantity(bundleId, 'increase')}
                    onDecrease={() => handleChangeQuantity(bundleId, 'decrease')}
                    sx={{ py: 1.5, height: (isCardView ? "3vh" : "42%"), my: 0 }}
                />
            </Box>

            {isCardView && labelComponent}

        </Box>
    );
}