import { Box, Button, Card, CircularProgress, Container, FormControlLabel, Stack, Switch, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomField, CustomFieldSearchFilters } from "src/@types/customField";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";
import { TableHeadCustom, TableNoData } from "src/components/table";
import useTable from "src/appHooks/useTable";
import { disable, enable, search } from "src/services/customFieldServices";
import { scrollBarStyle } from "src/components/scrollbar/Scrollbar";
import { useSnackbar } from "notistack";
import CustomFieldTableRow from "./CustomFieldTableRow";
import CustomFieldTableToolbar from "./CustomFieldTableToolbar";
import useTenant from "src/appHooks/useTenant";
import { getEntities } from "./CustomFieldCreation";
import { GenericPagedResponse } from "src/@types/list";

export default function CustomFieldList() {

    const { translate, currentLang } = useLocales();

    const tenant = useTenant();

    const { themeStretch } = useSettingsContext();

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState<GenericPagedResponse<CustomField>>();

    const [list, setList] = useState<CustomField[]>([]);

    const [update, setUpdate] = useState(false);

    const { page, rowsPerPage, dense, onChangeDense, onChangePage, onChangeRowsPerPage } = useTable();

    const TABLE_HEAD = [
        { id: 'name', label: `${translate('commons.name')}`, align: 'left' },
        { id: 'id', label: `${translate('customField.form.id')}`, align: 'left' },
        { id: 'dataCreation', label: `${translate('commons.creationDate')}`, align: 'left' },
        { id: 'type', label: `${translate('commons.type')}` },
        { id: 'entityType', label: `${translate('customField.form.entity')}` },
        { id: 'enabled', label: `${translate('commons.enabled')}`, align: 'center' },
        { id: '', align: 'right' }];

    const getAllEntityType = (): CustomFieldSearchFilters[] => {
        const arr: CustomFieldSearchFilters[] = [{ key: 'all', label: `${translate('commons.all')}` }];

        getEntities(tenant).forEach(entity => {
            arr.push({ key: entity, label: `${translate(`customField.entity.${entity[0].toLowerCase() + entity.slice(1)}`)}` });
        });

        return arr;
    };

    const filterList: CustomFieldSearchFilters[] = [
        ...getAllEntityType()
    ];

    const [filterField, setFilterField] = useState('all');

    useEffect(() => {
        search({ pageIndex: page, pageSize: rowsPerPage, entityType: (filterField === "all" ? "" : filterField) }).then(
            response => {
                setList(response.data.items);
                setData(response.data);
            }
        ).catch(
            e => { console.error(e); }
        );
    }, [page, rowsPerPage, update, filterField, currentLang]);

    useEffect(() => {
        if (list)
            setLoading(false);
    }, [list]);

    const handleEnable = async (id: string) => {
        try {
            await enable(id);
            enqueueSnackbar(`${translate('commons.enabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });

        } catch (e) {
            console.error(e);
            enqueueSnackbar(`${translate('commons.requestFailed')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        setUpdate(!update);
    };

    const handleDisable = async (id: string) => {
        try {
            await disable(id);
            enqueueSnackbar(`${translate('commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`${translate('commons.requestFailed')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        setUpdate(!update);
    };

    const handleEdit = (id: string) => {
        navigate(PATH_DASHBOARD.tenant.CustomFieldEdit(id));
    };

    const handleDetail = (id: string) => {
        navigate(PATH_DASHBOARD.tenant.CustomFieldDetail(id));
    };

    return (
        <Page title={`${translate('customField.list')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('customField.list')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.customField')}` },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            component={Link}
                            to={PATH_DASHBOARD.tenant.newCustomField}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                        >
                            {`${translate('customField.form.title.new')}`}
                        </Button>
                    }
                />

                <Card>
                    <CustomFieldTableToolbar
                        filterField={filterField}
                        setFilterField={setFilterField}
                        optionsFields={filterList}
                    />

                    <TableContainer sx={[{ minWidth: 800, maxHeight: 600, position: 'relative', height: dense ? '' : rowsPerPage <= 5 ? 400 : 500 }, scrollBarStyle]}>
                        <Table stickyHeader size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom
                                headLabel={TABLE_HEAD}
                                rowCount={list.length}
                            />
                            {isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    height: 1,
                                                    textAlign: 'center',
                                                    p: (theme) => theme.spacing(8, 2),
                                                }}
                                            >
                                                <CircularProgress />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>)
                                : (
                                    <TableBody>
                                        {list?.map((field: CustomField) => (
                                            <CustomFieldTableRow
                                                key={field.id}
                                                row={field}
                                                onEditRow={() => handleEdit(field.id)}
                                                onDetailsRow={() => handleDetail(field.id)}
                                                onEnableRow={() => handleEnable(field.id)}
                                                onDisableRow={() => handleDisable(field.id)}
                                            />
                                        ))}
                                        <TableNoData isNotFound={list.length === 0} />
                                    </TableBody>
                                )
                            }
                        </Table>
                    </TableContainer>
                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={data?.totalCount ? data.totalCount : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                        />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label={`${translate('commons.dense')}`}
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page >
    );
}