import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategoriesSearchResult, DEFAULT_SPARE_PARTS_STATISTICS, SparePart, SparePartSearchResult, SparePartsStatistics } from "src/@types/spareParts";
import sparePartsOperations from "./spareParts-operations";
import { ErrorResponse } from "src/@types/commons";
import { GenericPagedResponse } from "src/@types/list";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";

interface SparePartReducer {
    /* COMMONS */
    error: ErrorResponse,
    isLoading: boolean,
    filtersInUrl: string,
    /* SPARE PARTS LIST */
    sparePartsList: SparePartSearchResult[],
    statistics: SparePartsStatistics,
    totalCount: number,
    isInfiniteScrolling: boolean,
    /* SPARE PART */
    sparePart: SparePart | null,
    /* LOGS */
    areLogsLoading: boolean,
    sparePartLogs: GenericPagedResponse<LogsItem>,
    /* POWERSEARCH */
    sparePartsPowerSearch: SparePartSearchResult[],
    isSparePartPowerSearchLoading: boolean,
    /* CATEGORIES */
    categoriesList: CategoriesSearchResult[],
    categoriesTotalCount: number
};

const initialState: SparePartReducer = {
    /* COMMONS */
    error: null,
    isLoading: false,
    filtersInUrl: "",
    /* SPARE PARTS LIST */
    sparePartsList: [],
    statistics: DEFAULT_SPARE_PARTS_STATISTICS,
    totalCount: 0,
    isInfiniteScrolling: false,
    /* SPARE PART */
    sparePart: null,
    /* LOGS */
    areLogsLoading: false,
    sparePartLogs: DEFAULT_LOGS_DATA,
    /* POWERSEARCH */
    sparePartsPowerSearch: [],
    isSparePartPowerSearchLoading: false,
    /* CATEGORIES */
    categoriesList: [],
    categoriesTotalCount: 0
};

const SparePartsSlice = createSlice({
    name: 'sparePart',
    initialState,
    extraReducers: (builder) => {
        builder
            //---------------------------------------
            .addCase(sparePartsOperations.getSparePart.rejected, (state, action) => {
                state.sparePart = initialState.sparePart;
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(sparePartsOperations.getSparePart.fulfilled, (state, action) => {
                state.sparePart = action.payload;
                state.isLoading = false;
            })
            //---------------------------------------
            .addCase(sparePartsOperations.searchSpareParts.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.sparePartsList = initialState.sparePartsList;
                state.totalCount = initialState.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            .addCase(sparePartsOperations.searchSpareParts.fulfilled, (state, action) => {
                state.sparePartsList = state.isInfiniteScrolling ? state.sparePartsList.concat(action.payload.results) : action.payload.results;
                state.totalCount = action.payload.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            //---------------------------------------
            .addCase(sparePartsOperations.searchStatistics.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.isLoading = false;
            })
            .addCase(sparePartsOperations.searchStatistics.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            })
            //---------------------------------------
            .addCase(sparePartsOperations.getSparePartLogs.rejected, (state, action) => {
                state.areLogsLoading = false;
                state.error = action.error.message as string;
                state.sparePartLogs = initialState.sparePartLogs;
            })
            .addCase(sparePartsOperations.getSparePartLogs.fulfilled, (state, action) => {
                state.areLogsLoading = false;
                state.sparePartLogs = action.payload;
            })
            //---------------------------------------
            .addCase(sparePartsOperations.sparePartsPowerSearch.rejected, (state, action) => {
                state.isSparePartPowerSearchLoading = false;
                state.sparePartsPowerSearch = initialState.sparePartsPowerSearch;
                state.error = action.error.message as string;
            })
            .addCase(sparePartsOperations.sparePartsPowerSearch.fulfilled, (state, action) => {
                state.isSparePartPowerSearchLoading = false;
                state.sparePartsPowerSearch = action.payload.results;
            })
            //---------------------------------------
            .addCase(sparePartsOperations.searchRulesCategories.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.categoriesList = initialState.categoriesList;
                state.categoriesTotalCount = initialState.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            .addCase(sparePartsOperations.searchRulesCategories.fulfilled, (state, action) => {
                state.categoriesList = state.isInfiniteScrolling ? state.categoriesList.concat(action.payload.results) : action.payload.results;
                state.categoriesTotalCount = action.payload.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            //---------------------------------------
            .addCase(sparePartsOperations.searchRulesVIN.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.sparePartsList = initialState.sparePartsList;
                state.totalCount = initialState.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            .addCase(sparePartsOperations.searchRulesVIN.fulfilled, (state, action) => {
                state.sparePartsList = initialState.sparePartsList;
                state.totalCount = initialState.totalCount;
                state.isLoading = false;
                state.isInfiniteScrolling = false;
            })
            //---------------------------------------
            ;
    },
    reducers: {
        // START LOADING
        startLoading(state, action: PayloadAction<boolean | undefined>) {
            state.isLoading = true;
            if (action.payload) state.isInfiniteScrolling = true;
        },
        startLogsLoading(state) {
            state.areLogsLoading = true;
        },
        startPowerSearchLoading(state) {
            state.isSparePartPowerSearchLoading = true;
        },
        // SPARE PART
        resetSparePart(state) {
            state.sparePart = null;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        }
    }
});

export const {
    startLoading,
    resetSparePart,
    startLogsLoading,
    startPowerSearchLoading,
    setFiltersInUrl
} = SparePartsSlice.actions;

export default SparePartsSlice.reducer;
