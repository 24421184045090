import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Box, Container } from "@mui/material";
import DriverBarcodeScan from "./steps/DriverBarcodeScan";
import { useSettingsContext } from "src/components/settings";

interface DriverConfirmProps {
    customer?: boolean
};

export default function DriverConfirm({ customer }: DriverConfirmProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    return (
        <Page title={`${translate("request.request")}: ${translate("request.form.title")}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.request.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.request.title')}`, href: PATH_DASHBOARD.request.carrier.list },
                        { name: `${translate("returns.newReturn")}` }
                    ]} />

                <Box>
                    <DriverBarcodeScan customer={!!customer} carrier={!customer} />
                </Box>
            </Container>
        </Page>
    );

}