import { createSlice } from "@reduxjs/toolkit";
import { Basket } from "src/@types/basket";
import { ErrorResponse } from "src/@types/commons";
import basketOperations from "./basket-operations";
import { PublicShopSearchResult } from "src/@types/webshop";

interface BasketReducer {
    error: ErrorResponse,
    basket: Basket | null,
    isBasketLoading: boolean,
    isChekoutLoading: boolean,
    basketShops: PublicShopSearchResult[]
}

const initialState: BasketReducer = {
    error: null,
    basket: null,
    isBasketLoading: false,
    isChekoutLoading: false,
    basketShops: []
};

const BasketSlice = createSlice({
    name: "basket",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(basketOperations.detailPublicBasket.fulfilled, (state, action) => {
                state.basket = action.payload;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.detailPublicBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.basket = initialState.basket;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.addItemPublicBasket.fulfilled, (state, action) => {
                state.basket = action.payload;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.addItemPublicBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.removeItemPublicBasket.fulfilled, (state, action) => {
                state.basket = action.payload;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.removeItemPublicBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.checkoutPublicBasket.fulfilled, (state) => {
                state.isChekoutLoading = false;
                state.basket = initialState.basket;
            })
            .addCase(basketOperations.checkoutPublicBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isChekoutLoading = false;
            })
            .addCase(basketOperations.getShopsBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.basketShops = initialState.basketShops;
            })
            .addCase(basketOperations.getShopsBasket.fulfilled, (state, action) => {
                state.basketShops = action.payload;
            })
            ;
    },
    reducers: {
        startLoading(state) {
            state.isBasketLoading = true;
        },
        resetBasket(state) {
            state.basket = null;
        },
        startCheckoutLoading(state) {
            state.isChekoutLoading = true;
        }
    }
});

export const {
    startLoading,
    resetBasket,
    startCheckoutLoading
} = BasketSlice.actions;

export default BasketSlice.reducer;