import { Card, Typography, Divider, Box, Button } from "@mui/material";
import MediaSlider from "src/components/mediaSlider/MediaSlider";
import ApprovedProductsDetails from "src/components/request-utils/ApprovedProductsDetails";
import DeliverToFrom from "src/components/request-utils/DeliverToFrom";
import MoreDetails from "src/components/request-utils/MoreDetails";
import { useLocales } from "src/locales";
import { Media, Request } from "src/@types/request";
import { MutableRefObject, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import useResponsive from "src/hooks/useResponsive";
import { requestOperations } from "src/redux/request";
import { RootState, dispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import PrintIcon from '@mui/icons-material/Print';
import { DeliveryNote, Invoice } from "src/@types/orders";
import MediaList from "src/components/mediaList/MediaList";
import { brandOperations } from "src/redux/brand";
import RequestDocumentsDetail from "src/components/request-utils/RequestDocumentsDetail";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

interface RefusedNotesAlertProps {
    request: Request,
    setOpenConfirm: (value: boolean) => void,
    setOpenClaim: (value: boolean) => void,
    componentRef: MutableRefObject<null>,
    invoices: Invoice[],
    deliveryNotes: DeliveryNote[]
}

const PRINT_STATUSES = ["AvailableForPrinting", "Printed", "ReadyForPickUp"];

export function CustomerDeliveryDetail({ request, setOpenConfirm, setOpenClaim, componentRef, deliveryNotes, invoices }: RefusedNotesAlertProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive("up", "sm");

    const { publicBrandList } = useSelector((state: RootState) => state.brand);

    useEffect(() => {
        if (!publicBrandList) dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    const isMediaImage = useMemo(() => request.hasMedia && request.requestType !== "Warranty", [request]);

    const showApprovedItems = useMemo(() => {

        const differentQuantities = !!(request.items.find((item) => item.approvedQuantity !== item.requiredQuantity));

        return request.approverNotes && request.items.length > 0 && differentQuantities;
    }, [request]);

    const getFileLabel = useCallback((label: string) => {
        switch (label) {
            case "firstInvoice": return `${translate('request.form.firstInvoice')}`;
            case "secondInvoice": return `${translate('request.form.secondInvoice')}`;
            case "vehicleInfo": return `${translate('request.form.vehicleInfo')}`;
            case "firstRepairInvoice": return `${translate('request.form.invoiceRepair')}`;
            case "secondRepairInvoice": return `${translate('request.form.invoiceSecondRepair')}`;
            case "maddDoc": return `${translate('request.detail.maddLabel')}`;
        }

        return label;
    }, [translate]);

    const isAR = useMemo(() => request.requestType === "Warranty" && request.warranty && request.warranty.invoices.length === 1, [request]);

    const mediaFromFile = useMemo(() => {
        if (request.media && request.warranty) {
            return {
                media: request.media.map((file, index) => {
                    return {
                        section: getFileLabel(file.section || ""),
                        mediaType: file.mediaType,
                        fileInfo: file.fileInfo,
                        uploadDateInfo: isAR ? request.warranty?.invoices[index]?.date || "" : ""
                    } as Media;
                }),
                mediaData: request.media.map((file, index) => index === 2 ? (request.warranty!.vehicle!.brand + " " + request.warranty!.vehicle!.model) : request.warranty?.invoices[(index > 2 ? index - 1 : index)]?.number || "")
            };
        }

        return null;

    }, [request, getFileLabel, isAR]);

    return (
        <Box>
            {invoices.length > 0 &&
                <RequestDocumentsDetail isInvoices documents={invoices} sx={{ my: 2 }} />
            }
            {deliveryNotes.length > 0 &&
                <RequestDocumentsDetail documents={deliveryNotes} sx={{ my: 2 }} />
            }

            {(!request.acceptNotes?.partConditionAccepted && request.hasMedia && isMediaImage) &&
                <>
                    <Typography
                        display="flex"
                        variant="h6"
                        sx={{
                            color: 'text.primary',
                            fontWeight: '600',
                            p: 3, pb: 1
                        }}
                    >
                        {`${translate('request.anomalies.detail.badCondition')}`}
                    </Typography>
                    <Box sx={{ p: 3, pt: 0 }}>
                        <MediaSlider images={request.media} />
                    </Box>
                </>
            }

            <Card sx={{ mt: 2, p: 2 }}>

                <MoreDetails request={request} />

                {(request.hasMedia && !isMediaImage && mediaFromFile && mediaFromFile.mediaData) &&
                    <Box sx={{ mt: 3, p: 3, pt: 0, pb: isDesktop ? 3 : 0 }}>
                        <MediaList
                            media={mediaFromFile.media}
                            mediaData={mediaFromFile.mediaData}
                        />
                    </Box>
                }

                {!request.deliveryOrganization &&
                    <Box sx={{ mt: 2 }}>

                        <Divider />

                        <DetailsButtons
                            request={request}
                            setOpenClaim={setOpenClaim}
                            setOpenConfirm={setOpenConfirm}
                            componentRef={componentRef}
                        />

                    </Box >
                }
            </Card>

            {request.deliveryOrganization &&
                <Card sx={{ mt: 2 }}>

                    <DeliverToFrom
                        deliveryOrganization={{
                            name: request.deliveryOrganization.name,
                            address: request.deliveryOrganization.address,
                            contact: request.deliveryOrganization.contact?.businessPhone || ""
                        }}
                        vendor={{
                            name: request.vendor.name,
                            address: request.vendor.address,
                            contact: request.vendor.contact?.businessPhone || ""
                        }}
                    />

                    {showApprovedItems &&
                        <ApprovedProductsDetails
                            approvedProducts={request.items}
                            hideFirstDivider
                            sx={{ px: 3, pb: 2, pt: 0 }}
                        />
                    }

                    <Divider />

                    <DetailsButtons
                        request={request}
                        setOpenClaim={setOpenClaim}
                        setOpenConfirm={setOpenConfirm}
                        componentRef={componentRef}
                    />

                </Card >
            }
        </Box>
    );
}

//-----------------------------------------------------------------------------------------

export const createReturnLabelName = (request: Request): string => {
    const {
        vendor,
        customer,
        requestType,
        prettyName
    } = request;

    const hubShortage = vendor.customFields["stellantis-europe-hub-code-power-return"];

    const requestTypeCode = () => {
        switch (requestType) {
            case "NewPart": return "N";
            case "DamagedPart": return "D";
            case "WrongShipment": return "S";
            case "Core": return "C";
            case "Quality": return "Q";
            case "Warranty": return "W";
            default: return "";
        }
    };

    const dateTimeDownload = new Date().toISOString().replace(/[-:T]/g, "").slice(0, 12);

    return `${hubShortage}_${customer.externalId}_${requestTypeCode()}_${prettyName}_${dateTimeDownload}`;
};

interface DetailsButtonsProps {
    request: Request,
    setOpenConfirm: (value: boolean) => void,
    setOpenClaim: (value: boolean) => void,
    componentRef: RefObject<Element | Text>
};

function DetailsButtons({ request, setOpenConfirm, setOpenClaim, componentRef }: DetailsButtonsProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive("up", "sm");

    const navigate = useNavigate();

    const { filtersInUrl } = useSelector((state: RootState) => state.request);

    const [printed, setPrinted] = useState(false);

    const originalDocName: string = document.title;

    const Print = useReactToPrint({
        contentRef: componentRef,
        documentTitle: createReturnLabelName(request),
        onBeforePrint: async () => {
            document.title = createReturnLabelName(request);
        },
        onAfterPrint: async () => {
            if (!printed && request.status === "AvailableForPrinting") {
                await dispatch(requestOperations.printedRequest({ id: request.id, customFields: {}, note: "AvailableForPrinting" })).unwrap();
                setPrinted(true);
                dispatch(setSuccessMessage({ text: "", returnTo: PATH_DASHBOARD.request.customer.list, callback: () => dispatch(requestOperations.getRequest({ id: request.id })) }));
            }
            document.title = originalDocName;
        }
    });

    const handleArrived = async () => {
        await dispatch(requestOperations.returnToCustomerOp({ id: request.id, customFields: {}, note: request.refusedNotes?.note || "" })).unwrap();
        dispatch(setSuccessMessage({ text: "", returnTo: PATH_DASHBOARD.request.customer.list, callback: () => dispatch(requestOperations.getRequest({ id: request.id })) }));
    };

    const handleRestore = async () => {
        await dispatch(requestOperations.restoreLabel({ id: request.id, note: null, customFields: {} })).unwrap();
        dispatch(setSuccessMessage({ text: "", returnTo: PATH_DASHBOARD.request.customer.list, callback: () => dispatch(requestOperations.getRequest({ id: request.id })) }));
    };

    return (
        <Box
            sx={{
                justifyContent: 'space-between',
                ml: isDesktop ? 'auto' : 0,
                display: 'flex',
                gap: 1,
                flexDirection: isDesktop ? 'default' : 'column-reverse',
                p: 3
            }}>

            <Button
                variant="soft"
                sx={{ borderRadius: '100px' }}
                onClick={() => navigate(PATH_DASHBOARD.request.customer.list + filtersInUrl, { replace: true })}
            >
                {`${translate('request.goBack')}`}
            </Button>

            <Box sx={{ display: 'flex', gap: 2 }}>

                {request.isDeletable &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerEnableDisable]}>
                        <Button variant="outlined" sx={{ borderRadius: '100px' }} color="error" onClick={() => setOpenConfirm(true)}>
                            {`${translate('request.delete')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {PRINT_STATUSES.includes(request.status) &&
                    <Button variant="contained" sx={{ borderRadius: '100px' }} startIcon={<PrintIcon />} onClick={() => Print()}>
                        {`${translate('request.print')}`}
                    </Button>
                }
                {
                    request.status === "LabelExpired" &&
                    <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={() => handleRestore()}>
                        {`${translate('request.detail.requestRestore')}`}
                    </Button>
                }
                {
                    request.status === "RefusedPickedUp" &&
                    <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={() => handleArrived()}>
                        {`${translate('request.messages.confirmArrived')}`}
                    </Button>
                }
                {
                    ((request.status === "PickedUp" || request.status === "ReadyForPickUp" || request.status === "Accepted") && !request.customerClaimNotes) &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerOpenClaim]}>
                        <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={() => setOpenClaim(true)}>
                            {`${translate('request.messages.claimSubmit')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

            </Box>

        </Box>
    );
}