import Container from '@mui/material/Container';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { LogsFilters } from 'src/@types/logs';
import GenericLogsList from 'src/appComponents/logs/GenericLogsList';
import { webshopOperations } from 'src/redux/webshop';

const LABELS: string[] = [
    "ShopCreated",
    "ShopUpdated",
    "ShopMediaAdded",
    "ShopMediaRemoved",
    "ShopMediaUpdated",
    "ShopDisabled",
    "ShopEnabled",
    "ShopPublished",
    "ShopUnPublished",
    "ShopInPreview",
];

export default function AdminWebshopLogs() {

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const { areLogsLoading, webshopLogs, webshop } = useSelector((state: RootState) => state.webshop);

    const { webshopId } = useParams();

    const fetchLogs = useCallback((searchFilters: LogsFilters) => {
        dispatch(webshopOperations.getShopLogs({ id: webshopId!, filters: searchFilters }));
    }, [webshopId]);

    useEffect(() => {
        if ((!webshop && webshopId) || (webshop && webshopId && webshop.id !== webshopId)) dispatch(webshopOperations.getShop(webshopId));
    }, [webshopId, currentLang, webshop]);

    return (
        <Page title={translate('webshop.logs.title')}>

            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>

                <HeaderBreadcrumbs
                    heading={translate('webshop.logs.title')}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate('menu.management.webshop.admin.title'), href: PATH_DASHBOARD.webshop.list },
                        { name: webshop?.title, href: PATH_DASHBOARD.webshop.detail(webshopId!) },
                        { name: translate('commons.logs') }
                    ]}
                />

                <GenericLogsList
                    labels={LABELS}
                    logsData={{ ...webshopLogs, items: webshopLogs.items.map((item, index) => ({ ...item, id: index })) }}
                    searchFunc={fetchLogs}
                    isLoading={areLogsLoading}
                    localesPath={"webshop"}
                />

            </Container>
        </Page>
    );
}