import { Box, Button, Card, Modal, Typography } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";

interface ModalType {
    isOpen: boolean,
    toggle: () => void,
    handleSave: () => Promise<void>,
    saveText: string
}

export default function SaveModal({ isOpen, toggle, handleSave, saveText }: ModalType) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'lg');

    return (
        <Modal
            open={isOpen}
            onClose={toggle}
        >
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '25vw' : '70vw',
                    p: isDesktop ? 4 : 2
                }}
            >
                <Box sx={{ textAlign: "center" }}>

                    <Typography variant="h3">
                        {translate('commons.save')}
                    </Typography>

                    <Typography variant="body1" sx={{ my: 6 }}>
                        {saveText}
                    </Typography>

                    <Box sx={{ mx: 2, display: "flex", justifyContent: "space-between" }}>
                        <Button
                            variant={"soft"}
                            size={isDesktop ? "small" : "medium"}
                            onClick={toggle}
                            sx={{ borderRadius: "100px" }}
                        >
                            {translate("commons.cancel")}
                        </Button>
                        <Button
                            variant={"contained"}
                            size={isDesktop ? "small" : "medium"}
                            onClick={handleSave}
                            sx={{ borderRadius: "100px" }}
                        >
                            {translate("commons.save")}
                        </Button>
                    </Box>

                </Box>
            </Card>
        </Modal>
    );
}
