import { createAsyncThunk } from "@reduxjs/toolkit";
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import * as reportsService from '../../services/reportsService';
import { ExecutionFilter, NewReportRecurring, NewReportScheduled, ReportFilters, ReportType, ReportTypeData } from "src/@types/report";
import { startColumnLoading, startExecutionLoading, startLoading } from "./reports-slices";

interface BaseType { isScheduled: boolean, type: ReportTypeData, category: string };

interface CreateType extends BaseType { report: NewReportScheduled | NewReportRecurring };

interface GetType extends BaseType { id: string };

interface SearchType { isScheduled: boolean, filters: ReportFilters };

interface EditType { report: ReportType, id: string, type: ReportTypeData };

interface EnableDisableType { id: string, type: ReportTypeData };

const createReport = createAsyncThunk(
    'report/createReport',
    async (options: CreateType, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { report, isScheduled, type, category } = options;

            const { status } = await reportsService.create(nullifyEmptyKeys(report), isScheduled, type, category);

            if (status > 400) {
                throw new Error('Something went wrong');
            }

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getReport = createAsyncThunk(
    'report/getReport',
    async (options: GetType, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { id, isScheduled, type, category } = options;

            const { data } = await reportsService.detail(id, isScheduled, type, category);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getColumns = createAsyncThunk(
    'report/getColumns',
    async (type: ReportTypeData, { rejectWithValue, dispatch }) => {
        dispatch(startColumnLoading());
        try {
            const { data } = await reportsService.columns(type);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        }
        catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getTemplates = createAsyncThunk(
    'report/getTemplates',
    async (type: ReportTypeData, { rejectWithValue }) => {
        try {
            const { data, status } = await reportsService.templates(type);

            if (!data && status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        }
        catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const editReport = createAsyncThunk(
    'report/editReport',
    async (options: EditType, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { report, id, type } = options;

            const { status } = await reportsService.edit(nullifyEmptyKeys(report), id, type);

            if (status > 400) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const enableReport = createAsyncThunk(
    'report/enableReport',
    async (options: EnableDisableType, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { id, type } = options;

            const response = await reportsService.enable(id, type);

            if (response.status < 200 || response.status >= 300)
                throw new Error(`Error enabling report: '${id}'`);

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const disableReport = createAsyncThunk(
    'report/disableReport',
    async (options: EnableDisableType, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { id, type } = options;

            const response = await reportsService.disable(id, type);

            if (response.status < 200 || response.status >= 300)
                throw new Error(`Error disabling report: '${id}'`);

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchReports = createAsyncThunk(
    'report/searchReports',
    async (options: SearchType, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { filters, isScheduled } = options;

            const { data } = await reportsService.search(nullifyEmptyKeys(filters), isScheduled);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchExecution = createAsyncThunk(
    'report/searchExecution',
    async (options: { filter: ExecutionFilter, id: string }, { rejectWithValue, dispatch }) => {
        dispatch(startExecutionLoading());
        try {
            const { filter, id } = options;

            const { data } = await reportsService.searchExecution(filter, id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            const formattedItems = data.items.map((data, index) => {
                return { ...data, tabIndex: index };
            });

            return { ...data, items: formattedItems };
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const reportsOperations = {
    createReport,
    getReport,
    editReport,
    enableReport,
    disableReport,
    searchReports,
    getColumns,
    searchExecution,
    getTemplates
};

export default reportsOperations;
