import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ordersOperations from "./orders-operations";
import { CreditNote, DeliveryNote, DocumentSearchResult, Invoice, Order, OrderSearchResult } from "src/@types/orders";
import { ErrorResponse } from "src/@types/commons";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";
import { GenericPagedResponse } from "src/@types/list";

interface OrdersReducer {
    error: ErrorResponse,
    //-------------------------------------- ORDER
    isOrderLoading: boolean,
    orderList: OrderSearchResult[],
    totalCount: number,
    order: Order | null,
    orderPowersearch: OrderSearchResult[],
    isOrderPowersearchLoading: boolean,
    isOrderLogsLoading: boolean,
    ordersLogs: GenericPagedResponse<LogsItem>,
    isOrderInfiniteScrolling: boolean,
    ordersFiltersInUrl: string,
    //-------------------------------------- DOCUMENT
    isDocumentLoading: boolean,
    documentList: DocumentSearchResult[],
    documentTotalCount: number,
    invoice: Invoice | null,
    deliveryNote: DeliveryNote | null,
    creditNote: CreditNote | null,
    isDocsLogsLoading: boolean,
    documentsLogs: GenericPagedResponse<LogsItem>,
    isDocInfiniteScrolling: boolean,
    documentsFiltersInUrl: string,
};

const initialState: OrdersReducer = {
    error: null,
    //-------------------------------------- ORDER
    isOrderLoading: false,
    orderList: [],
    totalCount: 0,
    order: null,
    orderPowersearch: [],
    isOrderPowersearchLoading: false,
    isOrderLogsLoading: false,
    ordersLogs: DEFAULT_LOGS_DATA,
    isOrderInfiniteScrolling: false,
    ordersFiltersInUrl: "",
    //-------------------------------------- DOCUMENT
    isDocumentLoading: false,
    documentList: [],
    documentTotalCount: 0,
    invoice: null,
    deliveryNote: null,
    creditNote: null,
    isDocsLogsLoading: false,
    documentsLogs: DEFAULT_LOGS_DATA,
    isDocInfiniteScrolling: false,
    documentsFiltersInUrl: ""
};

const OrdersSlice = createSlice({
    name: 'order',
    initialState,
    extraReducers: (builder) => {
        builder
            //-----------------------
            .addCase(ordersOperations.getOrder.rejected, (state, action) => {
                state.order = initialState.order;
                state.error = action.error.message as string;
                state.isOrderLoading = false;
            })
            .addCase(ordersOperations.getOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.isOrderLoading = false;
            })
            //-----------------------
            .addCase(ordersOperations.searchOrders.rejected, (state, action) => {
                state.orderList = initialState.orderList;
                state.totalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isOrderLoading = false;
                state.isOrderInfiniteScrolling = false;
            })
            .addCase(ordersOperations.searchOrders.fulfilled, (state, action) => {
                state.totalCount = action.payload.totalCount;
                state.orderList = state.isOrderInfiniteScrolling ? state.orderList.concat(action.payload.results) : action.payload.results;
                state.isOrderInfiniteScrolling = false;
                state.isOrderLoading = false;
            })
            //-----------------------
            .addCase(ordersOperations.searchDocuments.rejected, (state, action) => {
                state.documentList = initialState.documentList;
                state.documentTotalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isDocumentLoading = false;
                state.isDocInfiniteScrolling = false;
            })
            .addCase(ordersOperations.searchDocuments.fulfilled, (state, action) => {
                state.documentTotalCount = action.payload.totalCount;
                state.documentList = state.isDocInfiniteScrolling ? state.documentList.concat(action.payload.results) : action.payload.results;
                state.isDocInfiniteScrolling = false;
                state.isDocumentLoading = false;
            })
            //-----------------------
            .addCase(ordersOperations.searchOrdersPowerSearch.rejected, (state, action) => {
                state.orderPowersearch = initialState.orderPowersearch;
                state.isOrderPowersearchLoading = initialState.isOrderPowersearchLoading;
                state.error = action.error.message as string;
            })
            .addCase(ordersOperations.searchOrdersPowerSearch.fulfilled, (state, action) => {
                state.orderPowersearch = action.payload.results;
                state.isOrderPowersearchLoading = false;
            })
            //-----------------------
            .addCase(ordersOperations.getDeliveryNote.rejected, (state, action) => {
                state.deliveryNote = initialState.deliveryNote;
                state.isDocumentLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(ordersOperations.getDeliveryNote.fulfilled, (state, action) => {
                state.deliveryNote = action.payload;
                state.isDocumentLoading = false;
            })
            //-----------------------
            .addCase(ordersOperations.getInvoice.rejected, (state, action) => {
                state.invoice = initialState.invoice;
                state.isDocumentLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(ordersOperations.getInvoice.fulfilled, (state, action) => {
                state.invoice = action.payload;
                state.isDocumentLoading = false;
            })
            //-----------------------
            .addCase(ordersOperations.getCreditNote.fulfilled, (state, action) => {
                state.creditNote = action.payload;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.getCreditNote.rejected, (state, action) => {
                state.creditNote = initialState.creditNote;
                state.isDocumentLoading = false;
                state.error = action.error.message as string;
            })
            //-----------------------
            .addCase(ordersOperations.getOrdersLogs.rejected, (state, action) => {
                state.isOrderLogsLoading = false;
                state.error = action.error.message as string;
                state.ordersLogs = initialState.ordersLogs;
            })
            .addCase(ordersOperations.getOrdersLogs.fulfilled, (state, action) => {
                state.isOrderLogsLoading = false;
                state.ordersLogs = action.payload;
            })
            //-----------------------
            .addCase(ordersOperations.getDocsLogs.rejected, (state, action) => {
                state.isDocsLogsLoading = false;
                state.error = action.error.message as string;
                state.documentsLogs = initialState.documentsLogs;
            })
            .addCase(ordersOperations.getDocsLogs.fulfilled, (state, action) => {
                state.isDocsLogsLoading = false;
                state.documentsLogs = action.payload;
            })
            ;
    },
    reducers: {
        //-------------------------------------- ORDER
        startOrderLoading(state, action: PayloadAction<boolean>) {
            state.isOrderLoading = true;
            if (action.payload) state.isOrderInfiniteScrolling = true;
        },
        startOrderLogsLoading(state) {
            state.isOrderLogsLoading = true;
        },
        startPowerSearchLoading(state) {
            state.isOrderPowersearchLoading = true;
        },
        setOrdersFiltersInUrl(state, action: PayloadAction<string>) {
            state.ordersFiltersInUrl = action.payload;
        },
        //-------------------------------------- DOCUMENT
        startDocumentLoading(state, action: PayloadAction<boolean>) {
            state.isDocumentLoading = true;
            if (action.payload) state.isDocInfiniteScrolling = true;
        },
        startDocLogsLoading(state) {
            state.isDocsLogsLoading = true;
        },
        setDocumentsFiltersInUrl(state, action: PayloadAction<string>) {
            state.documentsFiltersInUrl = action.payload;
        }
    }
});

export const {
    startOrderLoading,
    startDocumentLoading,
    startPowerSearchLoading,
    startDocLogsLoading,
    startOrderLogsLoading,
    setDocumentsFiltersInUrl,
    setOrdersFiltersInUrl
} = OrdersSlice.actions;

export default OrdersSlice.reducer;