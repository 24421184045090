import { Button, Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import OrganizationList from './OrganizationList';
import { resetOrganization } from 'src/redux/organizations/organizations-slices';

interface OrganizationListProps {
    isVendor?: boolean
}

export default function OrganizationListManager({ isVendor }: OrganizationListProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { request } = useSelector((state: RootState) => state.request);

    //reset to refresh the request when its status changes
    useEffect(() => {
        if (request) dispatch(resetOrganization());
    }, [request]);

    return (
        <Page title={translate(`menu.management.organizations.${isVendor ? "myTitle" : "cusTitle"}`)}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate(`menu.management.organizations.${isVendor ? "myTitle" : "cusTitle"}`)}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate('commons.list') },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            sx={{ borderRadius: "100px" }}
                            onClick={() => navigate(PATH_DASHBOARD.organizations.customer.new)}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                        >
                            {translate('organization.form.title.new')}
                        </Button>
                    }
                />

                <OrganizationList isVendor={!!isVendor} key={"organization.list." + isVendor} />

            </Container>

        </Page>
    );
}