import { Box, Button, Card, Divider } from "@mui/material";
import { useLocales } from "src/locales";
import WarehouseNewArticle from "./warehouseRequestTypes/WarehouseNewArticle";
import WarehouseUsedArticle from "./warehouseRequestTypes/WarehouseUsedArticle";
import { useMemo, useState } from "react";
import { RejectOptions, Request, RequestType, WarehouseAcceptOptions } from "src/@types/request";
import { dispatch } from "src/redux/store";
import { requestOperations } from "src/redux/request";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import WarehouseDamaged from "./warehouseRequestTypes/WarehouseDamaged";
import WarehouseWarranty from "./warehouseRequestTypes/WarehouseWarranty";
import useResponsive from "src/hooks/useResponsive";
import { handleUpload } from "src/appUtils/UploadHandler";
import { containerOperations } from "src/redux/container";
import { FileWithSection } from "src/@types/media";

interface WarehouseRequestConditionsProps {
    handlePreviousStep: VoidFunction,
    type: string,
    setOpen: VoidFunction,
    request: Request | null
}

export default function WarehouseRequestConditions({ handlePreviousStep, type, setOpen, request }: WarehouseRequestConditionsProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const [anomaly, setAnomaly] = useState(false);

    const [note, setNote] = useState("");

    const [reasonCode, setReasonCode] = useState<string | null>(null);

    const [container, setContainer] = useState<string | null>(null);

    const [files, setFiles] = useState<FileWithSection[]>([]);

    const [isSendingLoading, setIsSendingLoading] = useState(false);

    const [flags, setFlags] = useState({ reasonAccepted: true, partConditionAccepted: true, administrativeCheckAccepted: true, partAccepted: true });

    const disabled = useMemo(() => {
        if (request) {
            switch (request.requestType) {
                case "NewPart":
                    if (request.approverNotes!.approvedType === "ReturnToSupplier" && !container)
                        return true;

                    return false;
                case "Core":
                    return !container;

                case "DamagedPart":
                    return (!container && (request.approverNotes?.approvedType === "ReturnToSupplier" || request.approverNotes?.approvedType === "Scrapping"));

                case "Quality":
                    return (!container && (request.approverNotes?.approvedType === "ReturnToSupplier" || request.approverNotes?.approvedType === "Scrapping"));

                case "Warranty":
                    return !container;

                case "WrongShipment":
                    return false;
            }
        }

        return false;
    }, [container, request]);

    const handleFlags = (flags: any) => {
        if (Object.entries(flags).some((v) => !v[1])) {
            setAnomaly(true);
        }
        else {
            setAnomaly(false);
        }

        setFlags(flags);
    };

    const getType = () => {
        switch (type as RequestType) {
            case "NewPart":
                return <WarehouseNewArticle changeFile={(val) => setFiles(val)} images={files} handleFlag={handleFlags} changeNote={setNote} changeContainer={setContainer} request={request} />;
            case "Core":
                return <WarehouseUsedArticle changeFile={(val) => setFiles(val)} images={files} changeReasonCode={setReasonCode} handleFlag={handleFlags} changeNote={setNote} changeContainer={setContainer} request={request} />;
            case "DamagedPart":
                return <WarehouseDamaged changeFile={(val) => setFiles(val)} images={files} changeReasonCode={setReasonCode} handleFlag={handleFlags} changeNote={setNote} changeContainer={setContainer} request={request} />;
            case "Quality":
                return <WarehouseDamaged changeFile={(val) => setFiles(val)} images={files} changeReasonCode={setReasonCode} handleFlag={handleFlags} changeNote={setNote} changeContainer={setContainer} request={request} />;
            case "Warranty":
                return <WarehouseWarranty changeFile={(val) => setFiles(val)} images={files} changeContainer={setContainer} changeNote={setNote} />;

            default:
                return <WarehouseNewArticle changeFile={(val) => setFiles(val)} images={files} handleFlag={handleFlags} changeNote={setNote} changeContainer={setContainer} request={request} />;
        }

    };

    const getButton = () => {

        if (anomaly)
            return <Button variant="contained" onClick={handleSubmit}>{`${translate('request.openAnomaly')}`}</Button>;

        return <Button variant="contained" disabled={disabled} onClick={handleSubmit}>{`${translate('request.sendReport')}`}</Button>;

    };

    const handleSubmit = async () => {
        if (request?.id) {
            let options: WarehouseAcceptOptions = {
                note: note,
                id: request.id,
                customFields: {},
                partConditionMedia: [],
                containerId: container,
                reasonCode: reasonCode,
                ...flags
            };

            if (files.length > 0) {
                setIsSendingLoading(true);
                await handleUpload(files, request.id, "ReturnRequest", setIsSendingLoading);
            }

            await dispatch(requestOperations.warehouseAcceptRequest(options)).unwrap(); //rejectRequestWarehouse

            if (container)
                await dispatch(containerOperations.addRequest({ containerId: container, requestId: request.id })).unwrap();

            setOpen();
            setFiles([]);
        }
    };

    const handleRefuse = async () => {
        if (request) {
            let options: RejectOptions = {
                note: note,
                id: request.id,
                customFields: {}
            };

            await dispatch(requestOperations.rejectRequestWarehouse(options)).unwrap();
            setOpen();
            setFiles([]);
        }
    };

    return (
        <Box>
            {isSendingLoading ? <LoadingScreen /> :

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <Card sx={{ my: 2 }}>

                        {getType()}

                        <Divider sx={{ mt: 3 }} />

                        <Box
                            sx={{
                                display: 'flex',
                                px: 7,
                                py: 3,
                                gap: 2,
                                flexDirection: isDesktop ? 'row' : 'column',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button
                                variant="soft"
                                onClick={handlePreviousStep}
                            >
                                {`${translate('commons.goBack')}`}
                            </Button>

                            {(request && request.requestType === "Warranty") &&
                                < Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleRefuse}
                                >
                                    {`${translate('commons.refuse')}`}
                                </Button>
                            }

                            {getButton()}

                        </Box>
                    </Card>
                </Box >
            }
        </Box >
    );
}