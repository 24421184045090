import { ChevronLeft, ShoppingCart } from "@mui/icons-material";
import { alpha, Badge, Box, Button, Card, Divider, Drawer, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { bgBlur } from "src/utils/cssStyles";
import CartShopInfo from "./CartShopInfo";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { basketOperations } from "src/redux/basket";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import { useLocales } from "src/locales";
import { resetBasket } from "src/redux/basket/basket-slices";
import Timer from "src/appComponents/Timer";
import Label from "src/components/label";

export default function CartShopPopover() {

    const [openPopover, setOpenPopover] = useState(false);

    const { basket, basketShops } = useSelector((state: RootState) => state.basket);

    const totalItems = useMemo(() => {
        let total = 0;

        basket?.items.forEach(s => {
            total += s.price.quantity;
        });

        return total;
    }, [basket]);

    useEffect(() => {
        if (!basket)
            dispatch(basketOperations.detailPublicBasket());
    }, [basket]);

    useEffect(() => {
        const shopsIds = basket?.items.map(i => i.shopId);

        const missingIds = shopsIds?.filter((id) => !basketShops.some((item) => item.id === id));

        if (missingIds && missingIds?.length > 0) {
            dispatch(basketOperations.getShopsBasket(shopsIds ?? []));
        }
    }, [basket?.items, basketShops]);

    return (
        <>
            <Card sx={{ cursor: 'pointer', width: basket?.isExpired === false ? '8rem' : 'auto' }} onClick={() => setOpenPopover(true)}>
                <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
                    <Badge badgeContent={totalItems} color="error">
                        <ShoppingCart />
                    </Badge>
                    {basket?.isExpired === false && <Box sx={{ width: '100%' }}>
                        <Label
                            color="default"
                            sx={{ width: 'fit-content' }}
                        >
                            <Timer
                                showNegative
                                variant="overline"
                                expiresAt={basket.expiresAt}
                                expireCallback={() => dispatch(resetBasket())}
                            />
                        </Label>
                    </Box>}
                </Box>
            </Card>
            <CartLight openPopover={openPopover} setOpenPopover={setOpenPopover} />
        </>
    );
}

interface CartLightProps {
    openPopover: boolean;
    setOpenPopover: (e: boolean) => void;
}

function CartLight({ openPopover, setOpenPopover }: CartLightProps) {

    const { translate } = useLocales();

    const theme = useTheme();

    const navigate = useNavigate();

    const { basket } = useSelector((state: RootState) => state.basket);

    return (
        <Drawer
            anchor="right"
            open={openPopover}
            onClose={() => setOpenPopover(false)}
            slotProps={{ backdrop: { invisible: true } }}
            PaperProps={{
                sx: {
                    bgcolor: 'transparent',
                    ...bgBlur({ color: theme.palette.background.default, opacity: 0.85 }),
                    width: '55vw',
                    boxShadow: (theme) =>
                        `-24px 12px 40px 0 ${alpha(
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[500]
                                : theme.palette.common.black,
                            0.16
                        )}`,
                },
            }}
        >
            <Box sx={{ py: 3, overflowY: 'auto' }}>
                {basket?.items.map((shop) => <CartShopInfo key={shop.shopId} shop={shop} />)}
            </Box>

            {!basket?.items.length &&
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: 2, m: 'auto', flexDirection: 'column' }}>
                    <ShoppingCart sx={{ width: '100%', height: '8vh' }} />
                    <Typography variant="h5">
                        {translate('basket.messages.emptyCart')}
                    </Typography>
                </Box>
            }

            <Box sx={{ bgcolor: (theme) => theme.palette.background.neutral, p: 2, mt: 'auto' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate('basket.detail.subTotal')}</Typography>
                        {basket &&
                            <CurrencyAdapter value={basket.price.grossAmount} excludeVatAsterisk />
                        }
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate('basket.detail.totalDiscount')}</Typography>
                        {basket &&
                            <CurrencyAdapter value={basket.price.discountAmount} isNegative excludeVatAsterisk />
                        }
                    </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="subtitle1" >{translate("documents.documentHeaders.total")}</Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignItems: 'end' }}>
                        {basket &&
                            <CurrencyAdapter variant="subtitle1" value={basket.price.netAmount} excludeVatAsterisk />
                        }
                        <Typography variant="body2">
                            {translate('basket.messages.vatIncluded')}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button
                        variant="text"
                        sx={{ color: 'inherit' }}
                        startIcon={<ChevronLeft />}
                        onClick={() => setOpenPopover(false)}
                    >
                        {translate('commons.close')}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '100px' }}
                        disabled={!basket?.items.length}
                        onClick={() => navigate(PATH_DASHBOARD.webshopPublic.checkout)}
                    >
                        {translate("basket.checkout.title")}
                    </Button>

                </Box>
            </Box>
        </Drawer >
    );
}

