import { Box, Button, Card, Container, Divider, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography, useTheme } from "@mui/material";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { ChevronLeft, ShoppingCart } from "@mui/icons-material";
import CartShopInfo from "src/sections/@dashboard/webshop/publicShop/CartShopInfo";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { useEffect, useState } from "react";
import { basketOperations } from "src/redux/basket";
import { Basket, CheckoutParams, DEFAULT_CHECKOUT_PARAMS, DeliveryTypesArr } from "src/@types/basket";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import { getPrice } from "src/utils/currency";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import LoadingScreen from "src/appComponents/loading-screen";
import { LoadingButton } from "@mui/lab";
import { FormikProps, useFormik } from "formik";
import * as Yup from 'yup';
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { hasPermissions } from "src/utils/user";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import { AltInfiniteAutocomplete } from "src/appComponents/InfiniteAutocomplete";
import { OrganizationSearchResult } from "src/@types/organizations";
import { organizationsOperations } from "src/redux/organizations";
import { DeliveryAddressAutocomplete } from "src/utils/requestUtils";
import Timer from "src/appComponents/Timer";
import { resetBasket } from "src/redux/basket/basket-slices";
import { InfoIcon } from "src/theme/overrides/CustomIcons";

const schema = Yup.object<CheckoutParams>().shape({
    customerOrderReference: Yup.string().trim().required("commons.validation.requiredField").max(17, "basket.messages.orderReferenceMsg"),
    deliveryOptions: Yup.object({
        deliveryAddressId: Yup.string().trim().required("commons.validation.requiredField"),
        deliveryType: Yup.string().trim().required("commons.validation.requiredField").oneOf(DeliveryTypesArr, "commons.validation.requiredField"),
    })
});

export default function WebshopCheckout() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { basket, isBasketLoading } = useSelector((state: RootState) => state.basket);

    const { organization } = useUserOrganizationContext();

    const isVendor = hasPermissions([OrganizationPermissionTypes.WebShop_Admin_View], organization.roles);

    useEffect(() => {
        if (!basket)
            dispatch(basketOperations.detailPublicBasket());

    }, [basket]);

    useEffect(() => {
        if (basket?.items.length) {
            dispatch(basketOperations.getShopsBasket(basket.items.map(item => item.shopId)));
        }
    }, [basket]);

    const handleSubmit = async (options: CheckoutParams) => {
        await dispatch(basketOperations.checkoutPublicBasket(options)).unwrap();

        dispatch(setSuccessMessage({ text: translate('basket.messages.checkoutSuccess') }));
        formik.resetForm();
    };

    const formik = useFormik<CheckoutParams>({
        validationSchema: schema,
        validateOnMount: true,
        initialValues: DEFAULT_CHECKOUT_PARAMS,
        onSubmit: handleSubmit
    });

    return (
        <Page title={translate("menu.management.webshop.checkout.title")}>
            {isBasketLoading ?
                <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={translate("menu.management.webshop.checkout.title")}
                        links={[
                            { name: translate("commons.home"), href: PATH_DASHBOARD.root },
                            { name: `${translate("commons.list")}`, href: PATH_DASHBOARD.webshopPublic.list },
                            { name: translate("menu.management.webshop.checkout.title") },
                        ]}
                    />
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={8}>
                            <OrderItems basket={basket} formik={formik} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <OrderSummary basket={basket} formik={formik} isVendor={isVendor} />
                        </Grid>
                    </Grid>
                </Container>
            }
        </Page>
    );
}

function OrderItems({ basket, formik }: { basket: Basket | null, formik: FormikProps<CheckoutParams> }) {

    const navigate = useNavigate();

    const { translate } = useLocales();

    const theme = useTheme();

    const [color, setColor] = useState(theme.palette.success);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ width: '100%', bgcolor: color.light, p: 2, borderRadius: '12px', display: 'flex', alignItems: 'center', alignContent: 'center', gap: 3 }}>
                <InfoIcon sx={{ ml: 1, color: color.darker }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6">
                        {translate('basket.messages.expires')}
                    </Typography>
                    <Timer
                        variant="h6"
                        fontWeight={"bold"}
                        sx={{ ml: 1 }}
                        expiresAt={basket?.expiresAt ?? ""}
                        duration={basket?.durationInSeconds}
                        expireCallback={() => {
                            dispatch(resetBasket());
                            navigate(PATH_DASHBOARD.webshopPublic.list);
                        }}
                        halfTimeCallback={() => setColor(theme.palette.warning)}
                        lowTimeCallback={() => setColor(theme.palette.error)}
                    />
                </Box>
            </Box>
            <Card sx={{ p: 2 }}>
                <TextField
                    value={formik.values.customerOrderReference}
                    name="customerOrderReference"
                    required
                    onChange={(e) => {
                        if (!isNaN(+e.target.value))
                            formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    label={translate('basket.checkout.customerOrderRef')}
                    error={!!formik.errors.customerOrderReference && formik.touched.customerOrderReference}
                    helperText={
                        (!!formik.errors.customerOrderReference && formik.touched.customerOrderReference)
                        && translate(formik.errors.customerOrderReference)
                    }

                    fullWidth
                />
            </Card>
            {!basket?.items.length &&
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: 2, m: 'auto', flexDirection: 'column', my: 10 }}>
                    <ShoppingCart sx={{ width: '100%', height: '8vh' }} />
                    <Typography variant="h5">
                        {translate('basket.messages.emptyCart')}
                    </Typography>

                </Box>
            }
            {basket?.items.map((shop) =>
                <Card key={shop.shopId}>
                    <CartShopInfo shop={shop} showTerms />
                </Card>
            )}

            <Box>
                <Button
                    onClick={() => navigate(PATH_DASHBOARD.webshopPublic.list)}
                    variant="text"
                    color="inherit"
                    startIcon={<ChevronLeft />}
                >
                    {translate('basket.checkout.continueShopping')}
                </Button>
            </Box>

        </Box>
    );
}

function OrderSummary({ basket, formik, isVendor }: { basket: Basket | null, formik: FormikProps<CheckoutParams>, isVendor: boolean }) {

    const { translate } = useLocales();

    const { isChekoutLoading } = useSelector((state: RootState) => state.basket);

    const { organization } = useUserOrganizationContext();

    const { isSearchLoading } = useSelector((state: RootState) => state.organizations);

    const handleChangeCustomer = (org: OrganizationSearchResult | null) => {

        formik.setFieldValue('customerId', org?.id ?? null).then(() => {
            formik.setFieldTouched('customerId', true);
            formik.setFieldValue("deliveryOptions.deliveryAddressId", null, true);
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, position: 'sticky', top: 40 }}>
            <Card sx={{ p: 2 }}>

                <Typography variant="h6" sx={{ mb: 3 }}>{translate('basket.checkout.summary')}</Typography>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {translate("basket.checkout.totalGross")}
                        </Typography>
                        {basket &&
                            <CurrencyAdapter
                                value={basket.price.grossAmount}
                                excludeVatAsterisk
                            />
                        }
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {translate("basket.checkout.totalRegularDiscount")}
                        </Typography>
                        {basket &&
                            <CurrencyAdapter
                                value={basket.price.regularDiscountAmount}
                                isNegative
                                excludeVatAsterisk
                            />
                        }
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {translate("basket.checkout.totalAdditionalDiscount")}
                        </Typography>
                        {basket &&
                            <CurrencyAdapter
                                value={basket.price.additionalDiscountAmount}
                                isNegative
                                excludeVatAsterisk
                            />
                        }
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {translate("basket.detail.totalDiscount")}
                        </Typography>
                        {basket &&
                            <CurrencyAdapter
                                value={basket.price.discountAmount}
                                isNegative
                                excludeVatAsterisk
                            />
                        }
                    </Box>

                </Box>
                <Divider sx={{ my: 2 }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="subtitle1" >{translate("documents.documentHeaders.total")}</Typography>
                    {basket &&
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>

                            <CurrencyAdapter
                                variant="subtitle1"
                                value={basket.price.netAmount}
                                excludeVatAsterisk
                            />

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <CurrencyAdapter
                                    color={'text.secondary'}
                                    sx={{ textDecoration: 'line-through' }}
                                    value={basket.price.grossAmount}
                                    excludeVatAsterisk
                                />
                                <Typography
                                    variant="body2"
                                    color={'text.secondary'}>
                                    (-{getPrice(basket.price.discountPercentage as any)}%)
                                </Typography>
                            </Box>

                            <Typography sx={{ mt: 1 }} variant="body2">
                                ({translate('basket.messages.vatIncluded')})
                            </Typography>
                        </Box>
                    }
                </Box>

            </Card>

            <Card sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 3 }}>{translate("basket.checkout.deliveryOptions")}</Typography>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                    {isVendor &&
                        <AltInfiniteAutocomplete<OrganizationSearchResult>
                            searchCallBack={(params) => dispatch(organizationsOperations.searchOrganizations({ filters: { ...params, status: 'Enabled' }, check: !!params.sequenceToken, isVendor: false })).unwrap()}
                            getOptionLabel={(option) => `${option?.externalId ?? ""} ${option.name}`}
                            filterOptions={(options) => options}
                            loading={isSearchLoading}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            autoHighlight
                            onChange={(_, val) => handleChangeCustomer(val)}
                            renderInput={(params) => <TextField
                                {...params}
                                label={translate('basket.checkout.selectCustomer')}
                                required

                            />}
                            renderOption={(props, option) =>
                                <MenuItem {...props} key={option.id}>
                                    {`${option?.externalId ?? ""} ${option.name}`}
                                </MenuItem>
                            }
                        />
                    }
                    {
                        isVendor && formik.values.customerId &&
                        <DeliveryAddressAutocomplete
                            required
                            key={formik.values.customerId}
                            isVendor={true}
                            onChange={(_, v) => {
                                formik.setFieldValue("deliveryOptions.deliveryAddressId", v[0], true)
                                    .then(() => formik.setFieldTouched("deliveryOptions.deliveryAddressId", true));
                            }}
                            organization={formik.values.customerId}
                        />
                    }
                    {!isVendor &&
                        <DeliveryAddressAutocomplete
                            required
                            isVendor={false}
                            onChange={(_, v) => {
                                formik.setFieldValue("deliveryOptions.deliveryAddressId", v[0], true)
                                    .then(() => formik.setFieldTouched("deliveryOptions.deliveryAddressId", true));
                            }}
                            organization={organization.organizationId}
                        />}
                </Box>
                <Divider sx={{ my: 3 }} />
                <RadioGroup
                    name="deliveryOptions.deliveryType"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deliveryOptions.deliveryType}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                >
                    <FormControlLabel value="Standard" control={<Radio />} label={
                        <Box>
                            <Typography variant="subtitle2">
                                {translate('basket.checkout.standardDelivery')}
                            </Typography>
                        </Box>
                    }
                    />
                    <FormControlLabel value="Fast" control={<Radio />} label={
                        <Box>
                            <Typography variant="subtitle2">
                                {translate('basket.checkout.fastDelivery')}
                            </Typography>
                        </Box>}
                    />
                </RadioGroup>
            </Card>
            <Typography variant="subtitle2">
                {translate('basket.messages.termsAccept')}
            </Typography>
            <LoadingButton
                loading={isChekoutLoading}
                disabled={!formik.isValid || !basket}
                onClick={() => formik.handleSubmit()}
                variant="contained"
                color="primary"
                sx={{ borderRadius: 100 }}
            >
                {translate("basket.checkout.complete")}
            </LoadingButton>

        </Box>
    );
}

