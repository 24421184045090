import { Box, Card, Divider, Tab, Tabs, Typography, alpha, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CustomField } from "src/@types/customField";
import { Order } from "src/@types/orders";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import useCurrency from "src/appHooks/useCurrency";
import useTenant from "src/appHooks/useTenant";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";

interface OrderGeneralDetailsProps {
    order: Order
}

export default function OrderGeneralDetails({ order }: OrderGeneralDetailsProps) {

    const theme = useTheme();

    const isDesktop = useResponsive('up', 'lg');

    const { customFields } = useTenant();

    const [currentTab, setCurrentTab] = useState(0);

    const [parsedFields, setParsedFields] = useState<CustomField[]>([]);

    const [sectionList, setSectionList] = useState<string[]>([]);

    const { translate, currentLang } = useLocales();

    const { currency: currentCurrency } = useSettingsContext();

    const { rates } = useCurrency();

    useEffect(() => {
        let fields = customFields.filter(field =>
            field.enabled &&
            field.entityType === 'Order' &&
            field.visibility !== 'never' &&
            (field.visibility === 'always' || !!(order.customFields && order.customFields[field.id])));

        let sectionList = Array.from(new Set(fields.filter(f => !['OtherInfo'].includes(f.section.toLowerCase())).map(f => f.section)));
        setSectionList(sectionList);
        setParsedFields(fields);

    }, [customFields, order.customFields]);

    const getLang = useCallback((field: CustomField) => {

        let tmp = field.name.find((f) => f.culture === currentLang.value);

        return tmp ? tmp.text : field.name[0].text;
    }, [currentLang.value]);

    const TABS = useMemo(() => [
        <Tab
            key={0}
            value={0}
            sx={{
                justifyContent: 'flex-start',
                py: '13px',
                px: 2,
                m: 1,
                borderRadius: '8px',
                color: currentTab === 0 ? 'primary' : '',
                backgroundColor: currentTab === 0 ? 'primary.lighter' : '',
                '&:not(:last-of-type)': { mr: 1 },
                "&.Mui-selected": {
                    color: "primary.main"
                }
            }}
            label={`${translate('commons.moreDetail')}`}
        />,
        <Tab
            key={1}
            value={1}
            sx={{
                justifyContent: 'flex-start',
                py: '13px',
                px: 2,
                m: 1,
                borderRadius: '8px',
                color: currentTab === 1 ? 'primary' : '',
                backgroundColor: currentTab === 1 ? 'primary.lighter' : '',
                '&:not(:last-of-type)': { mr: 1 },
                "&.Mui-selected": {
                    color: "primary.main"
                }
            }}
            label={`${translate('orders.detail.amounts')}`}
        />
    ].concat(sectionList.map((b, ind) => (
        <Tab
            key={ind}
            sx={{
                justifyContent: 'flex-start',
                py: '13px',
                px: 2,
                m: 1,
                borderRadius: '8px',
                backgroundColor: currentTab === ind + 2 ? 'primary.lighter' : '',
                '&:not(:last-of-type)': { mr: 1 },
                "&.Mui-selected": {
                    color: "primary.main"
                }
            }}
            id={(ind + 4).toString()}
            label={b === 'OtherInfo' ? `${translate('customField.section.otherInfo')}` : b} />))

    ), [currentTab, sectionList, translate]);

    const TABS_GRID = useMemo(() => [
        <Box key={0} sx={{ p: 3, bgcolor: 'background.paper', width: '100%' }}>
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('commons.moreDetail')}`}</Typography>
            <Typography variant="overline">{`${translate('commons.vendor')}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText label={`${translate('orders.detail.name')}`} value={order.vendor.name || '—'} />
                <GridLabeledText label={`${translate('commons.code')}`} value={order.vendor.externalId || '—'} />
                <GridLabeledText label={`${translate('commons.type')}`} value={order.vendor.type || '—'} />
                <GridLabeledText label={`${translate('orders.vat')}`} value={order.vendor.vat || '—'} />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="overline">{`${translate('orders.detail.vendorAddress')}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText label={`${translate('commons.address')}`} value={order.vendor.address.address || '—'} />
                <GridLabeledText label={`${translate('organization.detail.city')}`} value={order.vendor.address.city || '—'} />
                <GridLabeledText label={`${translate('organization.detail.country')}`} value={order.vendor.address.country || '—'} />
                <GridLabeledText label={`${translate('organization.detail.district')}`} value={order.vendor.address.district || '—'} />
                <GridLabeledText label={`${translate('organization.detail.zipcode')}`} value={order.vendor.address.zipCode || '—'} />
            </Box>

            <Divider sx={{ my: 2 }} />
            <Typography variant="overline">{`${translate('commons.customer')}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText label={`${translate('orders.detail.name')}`} value={order.customer.name || '—'} />
                <GridLabeledText label={`${translate('commons.code')}`} value={order.customer.externalId || '—'} />
                <GridLabeledText label={`${translate('commons.type')}`} value={order.customer.type || '—'} />
                <GridLabeledText label={`${translate('orders.vat')}`} value={order.customer.vat || '—'} />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="overline">{`${translate('orders.detail.customerAddress')}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText label={`${translate('commons.address')}`} value={order.customer.address.address || '—'} />
                <GridLabeledText label={`${translate('organization.detail.city')}`} value={order.customer.address.city || '—'} />
                <GridLabeledText label={`${translate('organization.detail.country')}`} value={order.customer.address.country || '—'} />
                <GridLabeledText label={`${translate('organization.detail.district')}`} value={order.customer.address.district || '—'} />
                <GridLabeledText label={`${translate('organization.detail.zipcode')}`} value={order.customer.address.zipCode || '—'} />
            </Box>
            {parsedFields.length > 0 && <Divider sx={{ my: 2 }} />}
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                {parsedFields.filter(x => x.section.toLowerCase() === 'general').map(field =>
                    <GridLabeledText key={field.id} label={getLang(field)!} value={order.customFields && order.customFields[field.id] || '—'} />)}
            </Box>

        </Box>,

        <Box sx={{ p: 3, bgcolor: 'background.paper', width: '100%' }} key={1}>
            <Typography sx={{ mb: 2 }} variant="h6">{`${translate('orders.detail.amounts')}`}</Typography>
            <Typography variant="overline">{`${translate('orders.detail.grossAmount')}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('orders.detail.withoutVat')}`}
                    value={{
                        withoutVat: order.grossAmount.withoutVat,
                        currentCurrency: order.grossAmount.currentCurrency
                    }}
                    isCurrency
                />
                <GridLabeledText
                    label={`${translate('orders.detail.withVat')}`}
                    value={{
                        withVat: order.grossAmount.withVat,
                        currentCurrency: order.grossAmount.currentCurrency
                    }}
                    isCurrency
                />
            </Box>
            <Divider sx={{ my: 2 }} />

            <Typography variant="overline">{`${translate('orders.detail.netAmount')}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('orders.detail.withoutVat')}`}
                    value={{
                        withoutVat: order.netAmount.withoutVat,
                        currentCurrency: order.netAmount.currentCurrency
                    }}
                    isCurrency
                />
                <GridLabeledText
                    label={`${translate('orders.detail.withVat')}`}
                    value={{
                        withVat: order.netAmount.withVat,
                        currentCurrency: order.netAmount.currentCurrency
                    }}
                    isCurrency
                />

            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="overline">{`${translate('orders.detail.totalDiscountAmount')}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('orders.detail.withoutVat')}`}
                    value={{
                        withoutVat: order.totalDiscountAmount.withoutVat,
                        currentCurrency: order.totalDiscountAmount.currentCurrency
                    }}
                    isCurrency
                />
                <GridLabeledText
                    label={`${translate('orders.detail.withVat')}`}
                    value={{
                        withVat: order.totalDiscountAmount.withVat,
                        currentCurrency: order.totalDiscountAmount.currentCurrency
                    }}
                    isCurrency
                />
            </Box>
        </Box>
    ].concat(sectionList.map((section, ind) => {
        let fields = parsedFields.filter(f => f.section === section);

        return (
            <Box key={ind} sx={{ p: 3, bgcolor: 'background.paper', width: '100%' }}>
                <Typography variant="h6">{section === 'OtherInfo' ? `${translate('customField.section.otherInfo')}` : section}</Typography>
                <Box
                    sx={{
                        mt: 1,
                        display: 'grid',
                        columnGap: 8,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                >
                    {fields.map(
                        field => (
                            <GridLabeledText key={field.id} label={getLang(field)!} value={order.customFields && order.customFields[field.id] || '—'} />
                        )
                    )}

                </Box>
            </Box>
        );
    })), [currentCurrency, getLang, order, parsedFields, rates, translate, isDesktop, sectionList]);

    return (
        <Card sx={{ flexGrow: 1, display: 'flex' }}>
            <Tabs
                orientation='vertical'
                value={currentTab}
                onChange={(_, newVal) => setCurrentTab(newVal)}
                TabIndicatorProps={{
                    style: { display: 'none' }
                }}
                sx={{
                    bgcolor: alpha(theme.palette.grey[500], 0.12),
                    minWidth: "20%",
                    maxHeight: "20%"
                }}
            >
                {TABS.map((tab) => tab)}
            </Tabs>
            {TABS_GRID[currentTab]}
        </Card >);
}