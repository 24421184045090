import { Modal, Card, Typography, TextField, Button, Box, InputAdornment } from "@mui/material";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { MinimumAmount, Period, Rule } from "src/@types/rules";
import useCurrency from "src/appHooks/useCurrency";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import rulesOperations from "src/redux/rules/rules-operations";
import { dispatch } from "src/redux/store";
import { convert } from "src/utils/currency";

interface RulesEditModalProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    isPeriod: boolean,
    rule: Rule
}

export default function RulesEditModal({ isOpen, setIsOpen, isPeriod, rule }: RulesEditModalProps) {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const { currency: currentCurrency } = useSettingsContext();

    const { rates } = useCurrency();

    const [requestPeriod, setRequestPeriod] = useState<Period>(rule.period);

    const initialPartValue: MinimumAmount = useMemo(() => {
        return {
            withoutVat: currentCurrency.label !== rule.minimumAmount.currentCurrency ? Math.round((convert(rule.minimumAmount.withoutVat, rule.minimumAmount.currentCurrency, currentCurrency.label, rates) + Number.EPSILON) * 100) / 100 : rule.minimumAmount.withoutVat,
            withVat: currentCurrency.label !== rule.minimumAmount.currentCurrency ? Math.round((convert(rule.minimumAmount.withVat, rule.minimumAmount.currentCurrency, currentCurrency.label, rates) + Number.EPSILON) * 100) / 100 : rule.minimumAmount.withVat,
            currentCurrency: currentCurrency.label
        };
    }, [currentCurrency, rates, rule]);

    const [partValue, setPartValue] = useState<MinimumAmount>(initialPartValue);

    useEffect(() => {
        if (JSON.stringify(initialPartValue) !== JSON.stringify(partValue)) setPartValue(initialPartValue);
    }, [initialPartValue]);

    const [isError, setIsError] = useState<boolean>(false);

    const handleChangeNumeric = (value: string, field: string) => {

        const numericValue = Number(value);

        if (isPeriod) {
            if (/^[0-9]*$/.test(value)) {
                const requestPeriodCopy = cloneDeep(requestPeriod);

                requestPeriodCopy[field] = numericValue;

                setRequestPeriod(requestPeriodCopy);

                if (numericValue < 0 || numericValue > 1000) setIsError(true);
                else if (isError) {
                    const checkError = Object.keys(requestPeriod).find((fieldCheck) => (field === fieldCheck) ? (numericValue < 0 || numericValue > 1000) : requestPeriod[fieldCheck] < 0 || requestPeriod[fieldCheck] > 1000);

                    if (!checkError) setIsError(false);
                }
            }
        } else {
            setPartValue({ ...partValue, withoutVat: numericValue });
        }
    };

    const handleCancel = () => {
        setIsOpen(false);
        setIsError(false);
        if (isPeriod) setRequestPeriod(rule.period);
        else setPartValue(initialPartValue);
    };

    const handleSave = async () => {

        if (isPeriod) await dispatch(rulesOperations.updateRulePeriod(requestPeriod)).unwrap();
        await dispatch(rulesOperations.updateRuleMinimunAmount(partValue)).unwrap();

        setIsOpen(false);
    };

    return (
        <Modal open={isOpen}>
            <Card sx={{
                position: 'absolute',
                top: '50%', left: '50%',
                transform: 'translate(-50%, -50%)',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>

                    <Typography variant="h6" textAlign={'center'}>
                        {`${translate("commons.edit")} - ${translate(`rules.list.tableContent.name.${isPeriod ? "returnRequestPeriod" : "partValue"}`)}`}
                    </Typography>
                    {isPeriod &&
                        <Typography variant="subtitle2" textAlign={'center'}>
                            {`${translate("rules.form.edit.periodDetailMin")}, ${translate("rules.form.edit.periodDetailMax")}`}
                        </Typography>
                    }
                </Box>

                {isPeriod ?
                    <>
                        <PeriodEdit
                            label={"rules.list.rows.periodNewParts"}
                            value={requestPeriod.newPartInDays}
                            field={"newPartInDays"}
                            handleChangeNumeric={handleChangeNumeric}
                        />

                        <PeriodEdit
                            label={"rules.list.rows.periodCore"}
                            value={requestPeriod.corePartInDays}
                            field={"corePartInDays"}
                            handleChangeNumeric={handleChangeNumeric}
                        />

                        <PeriodEdit
                            label={"rules.list.rows.periodQuality"}
                            value={requestPeriod.qualityPartInDays}
                            field={"qualityPartInDays"}
                            handleChangeNumeric={handleChangeNumeric}
                        />

                        <PeriodEdit
                            label={"rules.list.rows.periodDamaged"}
                            value={requestPeriod.damagePartInDays}
                            field={"damagePartInDays"}
                            handleChangeNumeric={handleChangeNumeric}
                        />
                    </>
                    :
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                        <Typography>{`>`}</Typography>
                        <TextField
                            type={'number'}
                            value={partValue.withoutVat}
                            onChange={(e) => handleChangeNumeric(e.target.value, "")}
                            sx={{ my: 2 }}
                            fullWidth
                            variant={"outlined"}
                            placeholder={translate(`request.insertNote`)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{currentCurrency.symbol}</InputAdornment>
                            }}
                        />
                    </Box>
                }

                <Box
                    sx={{
                        mt: 2,
                        justifyContent: 'space-between',
                        ml: isDesktop ? 'auto' : 0,
                        display: 'flex', gap: 1,
                        flexDirection: isDesktop ? 'default' : 'column-reverse'
                    }}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ borderRadius: '100px' }}
                        onClick={handleCancel}
                    >
                        {translate(`commons.cancel`)}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: '100px' }}
                        onClick={handleSave}
                        disabled={isError}
                    >
                        {translate('commons.justSave')}
                    </Button>

                </Box>
            </Card>
        </Modal >
    );
}

interface PeriodEditProps {
    label: string,
    value: number,
    field: string,
    handleChangeNumeric: (value: string, field: string) => void
}

function PeriodEdit({ label, value, field, handleChangeNumeric }: PeriodEditProps) {

    const { translate } = useLocales();

    return (
        <>
            <Box sx={{ display: "grid", gridTemplateColumns: "47.5% 32.5% 25%", alignItems: "center" }}>
                <Typography>
                    {`${translate(label)} <=`}
                </Typography>
                <Box sx={{ display: "flex", my: 2, width: 80 }}>
                    <TextField
                        value={value}
                        onChange={(e) => handleChangeNumeric(e.target.value, field)}
                        placeholder={`${translate(`commons.days`)}`}
                        error={value > 1000}
                    />
                </Box>
                <Typography>
                    {`${translate("commons.days")}`}
                </Typography>
            </Box>
            {value > 1000 &&
                <Typography textAlign={'center'} color={"error"} fontSize={"0.75rem"} sx={{ mt: -1.5, ml: 8 }}>
                    {`${translate("rules.form.edit.periodErrorMax")}`}
                </Typography>
            }
        </>
    );
}