import { Box, Button, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocales } from "src/locales";
import { Request, RequestType } from "src/@types/request";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { containerOperations } from "src/redux/container";
import { ContainerTemplateSearchResult } from "src/@types/container";
import { PATH_DASHBOARD } from "src/routes/paths";
import AddIcon from '@mui/icons-material/Add';
import { FileWithSection } from "src/@types/media";
import InfiniteAutocomplete from "src/appComponents/InfiniteAutocomplete";
import UploadForContainer from "src/components/upload/UploadForContainers";

interface WarehouseNewArticleProps {
    handleFlag: (flags: any) => void,
    changeNote: (val: string) => void,
    changeContainer: (val: string | null) => void,
    changeFile: (val: File[]) => void,
    request: Request | null,
    images: FileWithSection[]
}

const typographySubtitle = {
    color: '#6D6D6D',
    fontSize: 12,
    fontFamily: 'Public Sans',
    fontWeight: '700',
    textTransform: 'uppercase',
    wordWrap: 'break-word'
};

export default function WarehouseNewArticle({ handleFlag, changeNote, changeContainer, request, images, changeFile }: WarehouseNewArticleProps) {

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    const [files, setFiles] = useState<FileWithSection[]>(images);

    useEffect(() => {
        changeFile(files);
    }, [changeFile, files]);

    const handleContainer = (container: ContainerTemplateSearchResult | null) => {
        changeContainer(container?.id || null);
    };

    const [flags, setFlags] = useState({ reasonAccepted: true, partConditionAccepted: true, administrativeCheckAccepted: true, partAccepted: true });

    const handleFlags = (e: ChangeEvent<HTMLInputElement>) => {

        switch (e.target.name) {
            case "reasonCode":
                setFlags((prev) => ({ ...prev, reasonAccepted: e.target.checked }));
                break;
            case "goodCondition":
                setFlags((prev) => ({ ...prev, partConditionAccepted: e.target.checked }));
                break;
            case "adminCorrect":
                setFlags((prev) => ({ ...prev, administrativeCheckAccepted: e.target.checked }));
                break;
            case "correctPart":
                setFlags((prev) => ({ ...prev, partAccepted: e.target.checked }));
                break;

        }
    };

    useEffect(() => {
        handleFlag(flags);
    }, [flags, handleFlag]);

    return (
        <Box sx={{ m: 3 }}>
            <Typography sx={typographySubtitle}>{`${translate('request.checkCondition')}`}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                my: 2,
            }}
            >
                <FormControlLabel
                    label={`${translate('request.correctPart')}`}
                    control={<Switch checked={flags.partAccepted} name="correctPart" onChange={handleFlags} />}
                />
                <FormControlLabel
                    label={`${translate('request.correctCode')}`}
                    control={<Switch checked={flags.reasonAccepted} name="reasonCode" onChange={handleFlags} />}
                />
                <FormControlLabel
                    label={`${translate('request.goodCondition')}`}
                    control={<Switch checked={flags.partConditionAccepted} name="goodCondition" onChange={handleFlags} />}
                />
                <FormControlLabel
                    label={`${translate(`request.adminCorrect`)}`}
                    control={<Switch checked={flags.administrativeCheckAccepted} name="adminCorrect" onChange={handleFlags} />}
                />

            </Box>
            <UploadForContainer files={files} setFiles={(files) => {
                setFiles(files);
                changeFile(files);
            }} />

            {request && request.approverNotes!.approvedType === "ReturnToSupplier" &&
                <ContainerSection onContainerChange={handleContainer} contentTypes={request.requestType} required />
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 3 }}>
                <Typography sx={typographySubtitle}>{`${translate(`commons.note`)}`}</Typography>
                <TextField
                    name="description"
                    variant="outlined"
                    value={note}
                    onChange={(e) => {
                        setNote(e.target.value);
                        changeNote(e.target.value);
                    }}
                    rows={4}
                    multiline
                    fullWidth
                    type="text"
                    placeholder={`${translate(`commons.insertDesc`)}`}
                    sx={{
                        width: '100%',
                    }}
                />
            </Box>

        </Box >);
}

interface ContainerSectionProps {
    onContainerChange: (container: ContainerTemplateSearchResult | null) => void,
    contentTypes?: RequestType,
    required?: boolean
}

export function ContainerSection({ onContainerChange, contentTypes, required }: ContainerSectionProps) {

    const { translate } = useLocales();

    const { isLoading, } = useSelector((state: RootState) => state.container);

    const [error, setError] = useState("");

    const [reload, setReload] = useState(0);

    const handleNewContainer = () => {
        window.open(PATH_DASHBOARD.containers.templates.list, "_blank");
        setReload(prev => ++prev);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>

                <Typography sx={typographySubtitle}>
                    {translate(`request.selectContainer`)}
                </Typography>

                <Button
                    sx={{ ml: 'auto' }}
                    variant={"text"}
                    startIcon={<AddIcon />}
                    onClick={() => handleNewContainer()}
                >
                    {`${translate('containers.addContainer')}`}
                </Button>
            </Box>

            <InfiniteAutocomplete
                loading={isLoading}
                key={reload}
                searchCallBack={(params) => dispatch(containerOperations.searchContainer({
                    filters: {
                        pageIndex: params.pageIndex,
                        pageSize: params.pageSize,
                        status: 'Open',
                        contentTypes: contentTypes ? [contentTypes] : undefined,
                        name: params.all || undefined
                    }, isIstance: false
                })).unwrap()}
                renderInput={(params) =>
                    <TextField
                        error={!!error}
                        helperText={!!error && error}
                        {...params}
                        label={`${translate(`request.containerCode`)}`}
                        required={required}

                    />
                }
                getOptionLabel={(option: ContainerTemplateSearchResult) =>
                    typeof option !== 'string' ? option.name : ''
                }
                onChange={(_, value) => {
                    onContainerChange(value);

                    if (!value)
                        setError(`${translate('commons.validation.requiredField')}`);
                    else
                        setError('');
                }}
                filterOptions={options => options}
                isOptionEqualToValue={(option, value) => option.id === value.id}

                renderOption={(props, option) => {
                    return (
                        <li
                            {...props}
                            key={option.id}
                        >
                            <Typography>
                                {option.name}
                            </Typography>
                        </li>
                    );
                }}
            />

        </Box>
    );
}
