import { Typography, TypographyProps } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

const calculatePercentage = (expiresAt: string, durationInSeconds: number) => {
    const remainingTime = Date.parse(expiresAt) - Date.parse(new Date().toISOString());

    const totalDuration = durationInSeconds * 1000;

    return (remainingTime / totalDuration) * 100;
};

interface TimerProps extends TypographyProps {
    expiresAt: string,
    duration?: number,
    showNegative?: boolean,
    expireCallback?: () => void
    halfTimeCallback?: () => void,
    lowTimeCallback?: () => void
}

export default function Timer({ showNegative, duration, expiresAt, halfTimeCallback, lowTimeCallback, expireCallback, ...props }: TimerProps) {
    const [timer, setTimer] = useState("00:00");

    const [halfTimePassed, setHalfTimePassed] = useState(false);

    const [lowTimePassed, setLowTimePassed] = useState(false);

    const Ref = useRef<number | null>(null);

    const getTimeRemaining = (e: string) => {
        const total = Date.parse(e) - Date.parse(new Date().toISOString());

        const seconds = Math.floor((total / 1000) % 60);

        const minutes = Math.floor(
            (total / 1000 / 60) % 60
        );

        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = useCallback((e: string) => {
        let { total, minutes, seconds } = getTimeRemaining(e);

        if (duration) {
            const percentage = calculatePercentage(expiresAt, duration);

            // Notifica al 50% del tempo rimanente
            if (!halfTimePassed && percentage <= 50) {

                halfTimeCallback?.();

                setHalfTimePassed(true);
            }

            if (!lowTimePassed && percentage <= 20) {
                lowTimeCallback?.();

                setLowTimePassed(true);
            }
        }

        if (total >= 0) {
            setTimer(
                `${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds}`
            );
        } else {
            setTimer("00:00");
            if (Ref.current) window.clearInterval(Ref.current);
            expireCallback?.();
        }

    }, [duration, expireCallback, expiresAt, halfTimeCallback, halfTimePassed, lowTimeCallback, lowTimePassed]);

    useEffect(() => {

        if (Ref.current) window.clearInterval(Ref.current);

        const id = window.setInterval(() => startTimer(expiresAt), 1000);

        Ref.current = id;
    }, [expiresAt, startTimer]);

    return (
        <Typography
            {...props}
        >
            {showNegative && "-"}{timer}
        </Typography>
    );
}