import { Avatar, Box, Card, Chip, Divider, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NewGroup, WhiteBlackListDisplay } from "src/@types/group";
import { OrganizationSearchResult } from "src/@types/organizations";
import StepButtons from "src/components/buttons/StepButtons";
import { useLocales } from "src/locales";
import { organizationsOperations } from "src/redux/organizations";
import { dispatch, RootState } from "src/redux/store";
import { usersOperations } from "src/redux/users";
import { UserSearchResult } from "src/@types/user";
import { Business, DeleteForever } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import useTable from "src/appHooks/useTable";
import useResponsive from "src/hooks/useResponsive";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { noData } from "src/components/empty-content/EmptyContent";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import DateZone from "src/appComponents/DateZone";
import { GreyCounter } from "../../webshop/newWebshop/WebshopUtilComponents";
import { AltInfiniteAutocomplete } from "src/appComponents/InfiniteAutocomplete";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GenericListFooter from "src/utils/list/utils/GenericListFooter";

interface WhiteBlacklistStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    state: NewGroup,
    onSubmit: (list: WhiteBlackListDisplay, black?: boolean) => void,
    blackList?: boolean,
    list: WhiteBlackListDisplay
}

export default function WhiteBlacklistStep({ changeStep, onSubmit, state, blackList, list }: WhiteBlacklistStepProps) {

    const { translate } = useLocales();

    const [selectSearch, setSelectSearch] = useState<"Users" | "Organizations">("Users");

    const { isLoading: isLoading } = useSelector((state: RootState) => state.organizations);

    const { isLoading: isUserLoading, } = useSelector((state: RootState) => state.user);

    const [blackWhiteList, setBlackWhiteList] = useState<WhiteBlackListDisplay>(list);

    const handleSave = () => {

        onSubmit(blackWhiteList, blackList);
    };

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 3 }}>
                    <Typography variant='h6'>
                        {translate(blackList ? "groups.form.selectBlacklist" : "groups.form.selectWhitelist")}
                    </Typography>
                    {state?.groupType && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Business />
                        <Typography variant="subtitle1">
                            {translate(state.groupType === "Customer" ? "commons.customer_plural" : "commons.organizations")}
                        </Typography>
                    </Box>}
                </Box>
                <Box sx={{ justifyContent: 'center', display: 'flex', pb: 0, my: 3, gap: 2 }}>
                    <TextField
                        sx={{ width: '30%' }}
                        label={translate('commons.search')}
                        select
                        value={selectSearch}
                        onChange={(e) => setSelectSearch(e.target.value as ("Users" | "Organizations"))}
                    >
                        <MenuItem value="Users">
                            {translate('commons.users')}
                        </MenuItem>
                        <MenuItem value="Organizations">
                            {translate('commons.organizations')}
                        </MenuItem>
                    </TextField>
                    {selectSearch === "Users" &&
                        <AltInfiniteAutocomplete<UserSearchResult>
                            fullWidth
                            defaultSortField="lastLogin"
                            searchCallBack={(params) => dispatch(usersOperations.searchUsers({ filters: { ...params, status: 'Enabled' }, check: !!params.sequenceToken })).unwrap()}
                            id="userSelection"
                            filterOptions={(options) => options}
                            disabled={blackWhiteList.users.length >= 100}
                            loading={isUserLoading}
                            onChange={(_, v) => {
                                if (v && !blackWhiteList.users.find(el => el.id === v.id))
                                    setBlackWhiteList(p => ({ ...p, users: p.users.concat([v]) }));
                            }}
                            getOptionLabel={(option) => option.firstName + " " + option.lastName}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    <Typography sx={{ mr: 2 }}>{option.firstName + " " + option.lastName}</Typography>
                                    <Chip label={option.username} />
                                </li>
                            }
                            renderInput={(params) => (<TextField
                                {...params}
                                label={`${translate('user.form.searchIdName')}`}
                                helperText={blackWhiteList.users.length >= 100 && translate('groups.messages.maxNumber')}
                            />)}
                        />}
                    {selectSearch === "Organizations" &&
                        <AltInfiniteAutocomplete<OrganizationSearchResult>
                            fullWidth
                            id="organizationSelection"
                            disabled={blackWhiteList.organizations.length >= 100}
                            searchCallBack={(params) => dispatch(organizationsOperations.searchOrganizations({
                                filters: {
                                    ...params,
                                    status: 'Enabled',
                                },
                                check: !!params.sequenceToken,
                                isVendor: state.groupType === "Organization"
                            })).unwrap()}
                            loading={isLoading}
                            onChange={(_, v) => {
                                if (v && !blackWhiteList.organizations.find(el => el.id === v.id))
                                    setBlackWhiteList(p => ({ ...p, organizations: p.organizations.concat([v]) }));
                            }}
                            getOptionLabel={(option) => option?.externalId + " " + option.name}
                            filterOptions={(options) => options}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    <Typography sx={{ mr: 2 }}>{option.externalId ? option.externalId + " - " : ""}{option.name}</Typography>
                                    <Chip label={option.type} />
                                </li>
                            }
                            renderInput={(params) => (<TextField
                                {...params}
                                label={`${translate('user.form.searchIdName')}`}
                                helperText={blackWhiteList.organizations.length >= 100 && translate('groups.messages.maxNumber')}

                            />)}
                        />
                    }
                </Box>
            </Box>
            <Box sx={{ mb: 3 }}>
                {["Users", "Organizations"].map((type) => {

                    const list = type === "Users" ? blackWhiteList.users : blackWhiteList.organizations;

                    if (selectSearch === type)
                        return (

                            <Box key={type}>
                                {!list.length ?
                                    <GreyCounter label={translate('groups.messages.noSelected')} />
                                    :
                                    <WhiteBlackList type={selectSearch} list={blackWhiteList} setList={(v) => setBlackWhiteList(v)} />
                                }
                            </Box>

                        );
                })}
            </Box>
            <StepButtons changeStep={changeStep} onSave={() => handleSave()} />
        </Card>
    );
}

interface WhiteBlackListProps {
    list: WhiteBlackListDisplay,
    type: "Users" | "Organizations",
    setList?: (list: WhiteBlackListDisplay) => void,
    isLoading?: boolean,
    totalCount?: number,
    startDense?: boolean
}

export function WhiteBlackList({ list, setList, type, isLoading, totalCount, startDense }: WhiteBlackListProps) {

    const { translate } = useLocales();

    const { dense, onChangeDense } = useTable({ defaultDense: startDense });

    const isDesktop = useResponsive('up', 'lg');

    const handleDelete = useCallback((id: string) => {

        const returnList: WhiteBlackListDisplay = {
            organizations: type === "Organizations" ? list.organizations.filter(el => el.id !== id) : list.organizations,
            users: type === "Users" ? list.users.filter(el => el.id !== id) : list.users,
        };

        if (setList)
            setList(returnList);
    }, [list, setList, type]);

    const COLUMNS_USERS: GridColDef<UserSearchResult>[] = useMemo(() => [
        {
            field: 'firstName',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    obj.row.firstName ?
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Avatar
                                alt={obj.row?.firstName + " " + obj.row?.lastName}
                                src={obj.row?.pictureUrl}
                                sx={{ mr: 2, width: dense ? 24 : 40, height: dense ? 24 : 40 }}
                            />
                            <Typography variant="subtitle2" noWrap>
                                {obj.row?.firstName + " " + obj.row?.lastName}
                            </Typography>
                        </Box>
                        :
                        "—"

                );
            }
        },
        {
            field: 'username',
            headerName: translate('commons.username'),
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'lastLogin',
            headerName: translate('user.list.tableHeaders.lastLogin'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    obj.row?.lastLogin ?
                        <DateZone
                            date={obj.row?.lastLogin}
                            shortMonth
                            noSeconds
                        />
                        :
                        "—"
                );
            }
        },
        {
            field: 'email',
            headerName: translate('commons.email'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined
        },
        ...(setList ? [
            {
                field: 'del',
                headerName: '',
                flex: isDesktop ? 0.25 : undefined,
                minWidth: !isDesktop ? 155 : undefined,
                renderCell: (params: any) =>
                    <IconButton onClick={() => handleDelete(params.id.toString())}>
                        <DeleteForever />
                    </IconButton>
            }
        ] : [])
    ], [dense, handleDelete, isDesktop, setList, translate]);

    const COLUMNS_ORGS: GridColDef<OrganizationSearchResult>[] = useMemo(() => [
        {
            field: 'externalId',
            headerName: translate('commons.code'),
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 100 : undefined
        },
        {
            field: 'name',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1.3 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'type',
            headerName: translate('organization.list.table.type'),
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 150 : undefined
        },
        {
            field: 'credit',
            headerName: translate('organization.list.table.credit'),
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 150 : undefined,
            renderCell: (obj) => <CurrencyAdapter value={obj.row.creditLimit} sx={{ pl: 1 }} />
        },
        ...(setList ? [
            {
                field: 'del',
                headerName: '',
                flex: isDesktop ? 0.25 : undefined,
                minWidth: !isDesktop ? 155 : undefined,
                renderCell: (params: any) =>
                    <IconButton onClick={() => handleDelete(params.id.toString())}>
                        <DeleteForever />
                    </IconButton>
            }
        ] : [])
    ], [handleDelete, isDesktop, setList, translate]);

    const getHeight = () => {
        let height: string | number = "auto";

        const { length } = (type === 'Users' ? list.users : list.organizations);

        //if (!totalCount) return 120 + 56 * length;

        if (!dense || length === 0) height = isDesktop ? 373 : 422;

        return height;
    };

    const getMaxHeight = () => {
        if (!totalCount) return "auto";

        return isDesktop ? 373 : 422;
    };

    return (
        <DataGridPro
            rowCount={100}
            rows={(type === 'Users' ? list.users : list.organizations) as any}
            columns={(type === 'Users' ? COLUMNS_USERS : COLUMNS_ORGS) as any}
            sortingMode={"server"}
            paginationMode={"server"}
            disableColumnResize
            density={dense ? 'compact' : 'standard'}
            disableColumnMenu
            disableRowSelectionOnClick
            loading={isLoading}
            slots={{
                noRowsOverlay: noData,
                noResultsOverlay: noData,
                footer: () => (
                    <>
                        {(type === 'Users' ? list.users : list.organizations).length === 0 && <Divider />}
                        <GenericListFooter
                            dense={dense}
                            onChangeDense={onChangeDense}
                            totalCount={totalCount ? totalCount : (type === 'Users' ? list.users : list.organizations).length}
                        />
                    </>
                )
            }}
            sx={{
                ...DataGridStyle,
                cursor: "default",
                height: getHeight(),
                maxHeight: getMaxHeight()
            }}
        />
    );
}