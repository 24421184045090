import { Card, TablePagination, FormControlLabel, Switch, Box, Typography, MenuItem } from "@mui/material";
import { GridSortModel, GridColDef, DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { ExecutionFilter, ExecutionSearchResult } from "src/@types/report";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import DateZone from "src/appComponents/DateZone";
import Label from "src/components/label";
import { reportsOperations } from "src/redux/reports";
import { useParams } from "react-router";
import TableMoreMenu from "src/appComponents/TableMoreMenu";

export default function ReportExecutionDetail() {

    const { translate } = useLocales();

    const { executions, isExecutionLoading } = useSelector((state: RootState) => state.reports);

    const { id } = useParams();

    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        onChangePage,
        onChangeDense,
        dense,
        onChangeRowsPerPage,
        setOrder,
        setOrderBy,
    } = useTable();

    const [options, setOptions] = useState<ExecutionFilter>({
        pageIndex: page,
        pageSize: rowsPerPage,
        sortAscending: order === 'desc' ? true : false,
        sortField: orderBy
    });

    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [currentRow, setCurrentRow] = useState<any>(null);

    const isDesktop = useResponsive("up", "md");

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const getHeight = () => {
        return rowsPerPage === 5 ? 380 : 650;
    };

    useEffect(() => {
        setOptions({
            pageIndex: page,
            pageSize: rowsPerPage,
            sortAscending: order === 'desc' ? true : false,
            sortField: orderBy || undefined
        });
    }, [order, orderBy, page, rowsPerPage]);

    useEffect(() => {
        if (id)
            dispatch(reportsOperations.searchExecution({ filter: options, id }));
    }, [id, options]);

    const columns: GridColDef<ExecutionSearchResult>[] = useMemo(() => [
        {
            field: 'tabIndex',
            headerName: `${translate('#')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            sortable: false,
            renderCell: (obj) => <Typography>{(page * rowsPerPage) + obj.row.tabIndex + 1}</Typography>
        },
        {
            field: 'lastExecution',
            headerName: `${translate('report.tableHeaders.lastExecution')}`,
            flex: isDesktop ? 1.1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            sortable: true,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.lastExecution)}
                        shortMonth
                    />
                );
            }
        },
        {
            field: 'hasError',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={obj.row.hasError ? 'error' : 'success'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {obj.row.hasError ? `${translate("commons.error")}` : `${translate("commons.success")}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 80 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={(e) => setOpenMenuActions(e.currentTarget)}
                        handleCloseMenu={() => setOpenMenuActions(null)}
                        object={obj}
                        currentRow={currentRow}
                    />
                );
            }
        }
    ], [currentRow, isDesktop, openMenu, page, rowsPerPage, translate]);

    return (
        <Card sx={{ mb: 3 }}>

            <DataGrid
                rows={executions.items}
                loading={isExecutionLoading}
                columns={columns}
                getRowId={(row) => row.tabIndex}
                disableColumnResize
                pagination
                paginationModel={{
                    page: page,
                    pageSize: rowsPerPage
                }}
                density={dense ? 'compact' : 'standard'}
                sortingMode={"server"}
                onSortModelChange={handleSort}
                onCellClick={(param) => setCurrentRow(param)}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{ position: 'relative' }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                component="div"
                                count={executions.totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`} />

                            <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label={`${translate('commons.dense')}`}
                                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                        </Box>)
                }}
                disableColumnMenu
                pageSizeOptions={[5, 10, 15, 30]}
                disableRowSelectionOnClick
                sx={{
                    ...DataGridStyle,
                    height: getHeight(),
                    maxHeight: 640
                }}
            />
        </Card>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void
    object: any,
    currentRow: any
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <MenuItem
                        onClick={() => window.open(object.row.downloadUri, '_blank')}
                    >
                        {`${translate("report.detail.download")}`}
                    </MenuItem>
                </>
            }
        />
    );
}