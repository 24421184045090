import { useLocales } from 'src/locales';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DeleteForever } from '@mui/icons-material';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { sparePartsOperations } from 'src/redux/spareParts';
import { SparePartSearchResult } from 'src/@types/spareParts';
import { rulesOperations } from 'src/redux/rules';
import { getPrice } from 'src/utils/currency';
import { PartSummaryComponentProps } from 'src/@types/rules';
import sparePartsServices from 'src/services/sparePartsServices';
import GenericPartViewEdit from './GenericPartViewEdit';
import LoadingScreen from 'src/appComponents/loading-screen/LoadingScreen';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';

export default function VinBlacklist() {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const { rule } = useSelector((state: RootState) => state.rules);

    const { isLoading, sparePartsList, totalCount } = useSelector((state: RootState) => state.spareParts);

    const COLUMNS: GridColDef<SparePartSearchResult>[] = [];

    return (!rule) ? (<LoadingScreen />) : (
        <GenericPartViewEdit
            defaultItems={[]}
            successMessage={translate('rules.messages.saveText.partBlacklist')}
            saveModalText={translate('rules.messages.saveConfirmPartRef')}
            clearModalText={"clearPartref"}
            title={translate(`rules.list.tableContent.name.partVin`)}
            addItemText={translate("rules.form.edit.addPartRef")}
            searchService={sparePartsServices.search}
            searchOperation={sparePartsOperations.searchRulesVIN}
            updateOperation={rulesOperations.updateRuleParts}
            columns={COLUMNS}
            itemsList={sparePartsList}
            totalCount={totalCount}
            isLoading={isLoading}
            PartSummaryComponent={PartSummary}
        />
    );
}

function PartSummary({ item, deleteItem, isLastItem, key, translate, isSM }: PartSummaryComponentProps<SparePartSearchResult>) {

    return (
        <Box key={key}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    position: "relative"
                }}
            >
                <Grid container gap={0.5} sx={{ mx: 2, width: '100%' }}>

                    <Grid item xs={5} sm={5} md={2}>
                        <Stack sx={{ m: { xs: 1, md: 2 } }}>
                            <Typography variant="overline">
                                {translate('spareParts.list.tableHeaders.partNr')}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {item.externalId || '—'}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={5} sm={5} md={2}>
                        <Stack sx={{ m: { xs: 1, md: 2 } }}>
                            <Typography variant="overline">
                                {translate('spareParts.list.tableHeaders.partDesc')}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {item.name || '—'}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={11} sm={5} md={4}>
                        <Stack sx={{ m: { xs: 1, md: 2 } }}>
                            <Typography variant="overline">
                                {translate('spareParts.detail.family')}
                            </Typography>

                            {isSM ?
                                <>
                                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                                        <b>{item.family.code}</b>
                                    </Typography>
                                    <Typography variant="body2">
                                        {item.family.description || '—'}
                                    </Typography>
                                </>
                                :
                                <Typography variant="body2" sx={{ mt: 0.5 }}>
                                    <b>{item.family.code}</b>{" - " + (item.family.description || '—')}
                                </Typography>
                            }

                        </Stack>
                    </Grid>

                    <Grid item xs={5} sm={3} md={1.5}>
                        <Stack sx={{ m: { xs: 1, md: 2 } }}>

                            <Typography variant="overline" sx={{ mb: 0.5 }}>
                                {translate('vehicle.commercialData.form.sellingPrice')}
                            </Typography>

                            <CurrencyAdapter value={item.sellAmount} />

                        </Stack>
                    </Grid>

                </Grid>

                <Button
                    size={"medium"}
                    startIcon={<DeleteForever sx={{ ml: 1.5 }} />}
                    onClick={() => deleteItem(item.id)}
                    sx={{
                        position: "absolute",
                        right: { xs: "2%", md: "3%" },
                        top: { xs: "2%", md: "25%" }
                    }}
                />

            </Box>

            {(!isLastItem) && <Divider sx={{ mx: 2 }} />}
        </Box>
    );
}