import axios, { AxiosResponse } from 'axios';
import { Core, Orders } from './paths';
import { OrganizationFilters, OrganizationSearchResult, OrganizationStatistics, GenericOrganization, GenericOrganizationForEdit, OrganizationSurveillance } from 'src/@types/organizations';
import { Tag } from 'src/@types/commons';
import { GenericPagedResponse, InfiniteScrollResponse } from 'src/@types/list';

const getNonEditables = (): Promise<AxiosResponse> => {
    const url = Core.admin.organizations.nonEditables();

    return axios.get(url);
};

const searchStatistics = (filters: OrganizationFilters, isVendor: boolean): Promise<AxiosResponse<OrganizationStatistics>> => {

    const url = isVendor ? Core.admin.organizations.vendor.statistic() : Core.admin.organizations.customer.statistic();

    return axios.get<OrganizationStatistics>(url, { params: filters });
};

const search = (filters: OrganizationFilters, isVendor: boolean): Promise<AxiosResponse<InfiniteScrollResponse<OrganizationSearchResult>>> => {

    const url = isVendor ? Core.admin.organizations.vendor.search() : Core.admin.organizations.customer.search();

    return axios.get<InfiniteScrollResponse<OrganizationSearchResult>>(url, { params: filters });
};

const searchTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {

    const url = Core.admin.organizations.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const getOwners = (id: string): Promise<AxiosResponse<GenericOrganization[]>> => {
    const url = Core.admin.organizations.getOwners(id);

    return axios.get(url);
};

const detailGeneric = (id: string): Promise<AxiosResponse<GenericOrganization>> => {
    const url = Core.admin.organizations.detailGeneric(id);

    return axios.get(url);
};

const detailEdit = (id: string): Promise<AxiosResponse<GenericOrganizationForEdit>> => {

    const url = Core.admin.organizations.detailEdit(id);

    return axios.get(url);

};

const createGeneric = (organization: GenericOrganization): Promise<AxiosResponse> => {
    const url = Core.admin.organizations.createGeneric();

    return axios.post(url, organization);
};

const updateGeneric = (organization: GenericOrganization & { id: string }): Promise<AxiosResponse> => {
    const url = Core.admin.organizations.updateGeneric();

    return axios.put(url, organization);
};

const detailMultiple = (ids: string[]): Promise<AxiosResponse<GenericOrganization[]>> => {
    const url = Core.admin.organizations.detailMultiple();

    return axios.get(url, { params: { ids: ids }, paramsSerializer: { indexes: null } });
};

const searchLogs = (id: string, filters: any): Promise<AxiosResponse<GenericPagedResponse<any>>> => {
    const url = Core.admin.organizations.searchLogs(id);

    return axios.get<any>(url, { params: filters });
};

const searchSurveillance = (id: string): Promise<AxiosResponse<OrganizationSurveillance>> => {
    const url = Orders.admin.returns.customerSurveillance(id);

    return axios.get(url);
};

const defaultExport = {
    getNonEditables,
    detailGeneric,
    createGeneric,
    updateGeneric,
    detailMultiple,
    searchLogs,
    search,
    searchStatistics,
    searchTags,
    getOwners,
    detailEdit,
    searchSurveillance
};

export default defaultExport;
