import { Divider } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { ShopBundleSearchResult } from 'src/@types/webshop';
import MenuPopover from 'src/components/menu-popover';
import AddToCartButton from './AddToCartButton';
import CartAvailabilityIncrementer from './CartAvailabilityIncrementer';

type AddToCartMenuProps<T> = {
    openMenu: HTMLElement | null,
    handleCloseMenu: () => void,
    translate: (text: unknown, options?: any) => string,
    bundle: ShopBundleSearchResult,
    quantitiesRecord: Record<string, number>,
    addedQuantitiesRecord: Record<string, number>,
    handleChangeQuantity: (id: string, action: "increase" | "decrease") => void,
    handleAddToCart: (bundleId: string, quantity: number) => Promise<void>
    getQuantityInBasketPrefilled: (bundleId: string) => number,
    actualCardItem: T | null
};

export default function AddToCartMenu<T extends GridValidRowModel>({
    openMenu,
    handleCloseMenu,
    translate,
    bundle,
    quantitiesRecord,
    addedQuantitiesRecord,
    handleChangeQuantity,
    getQuantityInBasketPrefilled,
    actualCardItem,
    handleAddToCart
}: AddToCartMenuProps<T>) {

    const { bundleId, availability } = bundle;

    const quantity: number = quantitiesRecord[bundleId] ?? 0;

    const addedQuantityToCart: number = addedQuantitiesRecord[bundleId] ?? 0;

    return ((!!actualCardItem && bundle.bundleId === actualCardItem.bundleId) &&
        <MenuPopover
            open={openMenu}
            anchorEl={openMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            arrow={"right-top"}
            sx={{
                mt: -1,
                minWidth: 160,
                p: 2,
                '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                    '& svg': { mr: 2, width: 20, height: 20 }
                }
            }}
        >
            <CartAvailabilityIncrementer
                translate={translate}
                bundleId={bundleId}
                availability={availability}
                quantitiesRecord={quantitiesRecord}
                handleChangeQuantity={handleChangeQuantity}
                getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                isCardView
            />

            <Divider sx={{ borderStyle: 'dashed', my: 1 }} />

            <AddToCartButton
                addedQuantityToCart={addedQuantityToCart}
                bundleId={bundleId}
                handleAddToCart={handleAddToCart}
                quantity={quantity}
                translate={translate}
                isCardView
            />
        </MenuPopover>
    );
}