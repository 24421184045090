import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../appLayouts/LogoOnlyLayout';
import LoadingScreen from 'src/appComponents/loading-screen';
import AuthGuard from '../guards/AuthGuard';
import LoginLayout from 'src/layouts/login';
import OrganizationRolesList from 'src/pages/dashboard/role/OrganizationRoles/OrganizationRolesList';
import NewOrganizationRole from 'src/pages/dashboard/role/OrganizationRoles/NewOrganizationRole';
import OrganizationRoleDetail from 'src/pages/dashboard/role/OrganizationRoles/OrganizationRoleDetail';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import GodGuard from 'src/guards/GodGuard';
import MatrixList from 'src/sections/@dashboard/matrix/MatrixList';
import CustomFieldCreation from 'src/pages/dashboard/tenantManagement/CustomFieldCreation';
import CustomFieldList from 'src/pages/dashboard/tenantManagement/CustomFieldList';
import CustomFieldDetail from 'src/pages/dashboard/tenantManagement/CustomFieldDetail';
import CustomFieldEdit from 'src/pages/dashboard/tenantManagement/CustomFieldEdit';
import MatrixCreateReset from 'src/pages/dashboard/matrix/MatrixCreateReset';
import LogisticsView from 'src/sections/@dashboard/vehicle/logistics/LogisticsView';
import LogisticsNewEdit from 'src/sections/@dashboard/vehicle/logistics/LogisticsNewEdit';
import LogisticsLogs from 'src/sections/@dashboard/vehicle/logistics/LogisticsLogs';
import OrganizationListManager from 'src/pages/dashboard/organizations/OrganizationListManager';
import EmailList from 'src/pages/dashboard/tenantManagement/EmailList';
import EmailDetails from 'src/pages/dashboard/tenantManagement/EmailDetails';
import NewEditBrand from 'src/pages/dashboard/brands/NewEditBrand';
import BrandList from 'src/pages/dashboard/brands/BrandList';
import BrandDetail from 'src/pages/dashboard/brands/BrandDetail';
import OrganizationDetailPage from 'src/sections/@dashboard/organizations/detail/OrganizationDetailPage';
import NewOrganizationRouter from 'src/pages/dashboard/organizations/NewOrganizationRouter';
import NewEditOrganization from 'src/pages/dashboard/organizations/NewEditOrganization';
import RequestListManager from 'src/pages/dashboard/requests/RequestListManager';
import NewRequest from 'src/pages/dashboard/requests/NewRequest';
import RequestDetail from 'src/pages/dashboard/requests/RequestDetail';
import BacklogRequestDetail from 'src/pages/dashboard/requests/BacklogRequestDetail';
import SparePartsList from 'src/sections/@dashboard/spareParts/SparePartsList';
import SparePartsDetail from 'src/sections/@dashboard/spareParts/SparePartsDetail';
import SparePartsLogs from 'src/sections/@dashboard/spareParts/SparePartsLogs';
import NewBrandRouter from 'src/pages/dashboard/brands/NewBrandRouter';
import OrganizationLogs from 'src/sections/@dashboard/organizations/OrganizationLogs';
import OrganizationRoleLogs from 'src/pages/dashboard/role/OrganizationRoles/OrganizationRoleLogs';
import RequestLogs from 'src/sections/@dashboard/request/RequestLogs';
import WarehouseConfirmRequest from 'src/sections/@dashboard/request/warehouse/WarehouseConfirmRequest';
import AnomalyDetail from 'src/pages/dashboard/requests/AnomalyDetail';
import OrdersLogs from 'src/sections/@dashboard/orders/OrdersLogs';
import { ClusterPermissionTypes, OrganizationPermissionTypes } from 'src/@types/permissions';
import OrganizationSelector from 'src/pages/OrganizationSelector';
import NewAdminRole from 'src/pages/dashboard/role/adminRoles/NewAdminRole';
import AdminRolesList from 'src/pages/dashboard/role/adminRoles/AdminRolesList';
import AdminRoleDetail from 'src/pages/dashboard/role/adminRoles/AdminRoleDetail';
import AdminRoleLogs from 'src/pages/dashboard/role/adminRoles/AdminRoleLogs';
import OrganizationRoleEdit from 'src/pages/dashboard/role/OrganizationRoles/OrganizationRoleEdit';
import AdminRoleEdit from 'src/pages/dashboard/role/adminRoles/AdminRoleEdit';
import AddFromEdit from 'src/sections/@dashboard/user/addFromEdit/AddFromEdit';
import Error403 from 'src/guards/errors/403';
import RulesList from 'src/pages/dashboard/rules/RulesList';
import ContainerList from 'src/pages/dashboard/containers/ContainerList';
import ReportListRedirect from 'src/pages/dashboard/report/ReportListRedirect';
import ReportEditPage from 'src/pages/dashboard/report/ReportEditPage';
import ReportDetail from 'src/pages/dashboard/report/ReportDetail';
import ContainerTemplateDetail from 'src/pages/dashboard/containers/ContainerTemplateDetail';
import ContainerDetail from 'src/pages/dashboard/containers/ContainerDetail';
import ContainerLogs from 'src/pages/dashboard/containers/ContainerLogs';
import AccountPendingActivation from 'src/pages/AccountPendingActivation';
import PendingActivationGuard from 'src/guards/PendingActivationGuard';
import NotificationTemplateList from 'src/pages/dashboard/tenantManagement/NotificationTemplateList';
import NewEditNotificationTemplate from 'src/pages/dashboard/tenantManagement/NewEditNotificationTemplate';
import GroupList from 'src/pages/dashboard/groups/GroupList';
import GroupDetail from 'src/pages/dashboard/groups/GroupDetail';
import Error503Maintenance from 'src/guards/errors/503';
import DocumentDetail from 'src/pages/dashboard/documents/DocumentDetail';
import DocumentLogs from 'src/sections/@dashboard/documents/DocumentLogs';
import OrderDetail from 'src/pages/dashboard/orders/OrderDetail';
import DriverConfirm from 'src/sections/@dashboard/request/driver/DriverConfirm';
import NewEditWebshop from 'src/pages/dashboard/webshop/NewEditWebshop';
import WebshopDetail from 'src/pages/dashboard/webshop/WebshopDetail';
import TermsConditionList from 'src/pages/dashboard/termsConditions/TermsConditionList';
import TermsConditionDetail from 'src/pages/dashboard/termsConditions/TermsCondtionDetail';
import TermsCondtionLogs from 'src/pages/dashboard/termsConditions/TermsConditionLogs';
import NewEditTermsCondition from 'src/pages/dashboard/termsConditions/NewEditTermsCondition';
import NewEditGroup from 'src/pages/dashboard/groups/NewEditGroup';
import GroupLogs from 'src/pages/dashboard/groups/GroupLogs';
import BasketRulesList from 'src/pages/dashboard/basketRules/BasketRulesList';
import BasketRulesLogs from 'src/pages/dashboard/basketRules/BasketRulesLogs';
import NewEditBasketRule from 'src/pages/dashboard/basketRules/NewEditBasketRule';
import WebshopCheckout from 'src/pages/dashboard/webshop/public/WebshopCheckout';
import WebshopListManager from 'src/pages/dashboard/webshop/WebshopListManager';
import PublicProductDetail from 'src/pages/dashboard/webshop/public/PublicProductDetail';
import NewEditDiscountMatrix from 'src/pages/dashboard/discountMatrix/NewEditDiscountMatrix';
import DiscountMatrixList from 'src/pages/dashboard/discountMatrix/DiscountMatrixList';
import DiscountMatrixDetail from 'src/pages/dashboard/discountMatrix/DiscountMatrixDetail';
import OrdersListManager from 'src/pages/dashboard/orders/OrdersListManager';
import DocumentsListManager from 'src/pages/dashboard/documents/DocumentsListManager';
import CustomerWebshopStatistics from 'src/pages/dashboard/webshop/CustomerWebshopStatistics';
import RulesEdit from 'src/sections/@dashboard/rules/edit/RulesEdit';
import AdminWebshopLogs from 'src/pages/dashboard/webshop/admin/AdminWebshopLogs';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element:
        <AuthGuard>
          <PendingActivationGuard />
        </AuthGuard>,
    },
    {
      path: '/selector',
      element: (
        <AuthGuard>
          <OrganizationSelector />
        </AuthGuard>
      )
    },
    {
      path: '/contactAdmin',
      element: <>
        <LogoOnlyLayout />
        <AccountPendingActivation contactAdmin />
      </>
    },
    {
      path: '/pendingActivation',
      element: (
        <AuthGuard>
          <LogoOnlyLayout />
          <AccountPendingActivation />
        </AuthGuard>
      )
    },
    {
      path: '/maintenance',
      element: <>
        <LogoOnlyLayout />
        <Error503Maintenance />
      </>
    },
    {
      path: '/auth/login',
      element: <Farewell />,
    },
    {
      path: '/403',
      element: <Error403 />
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" /> },
        { path: '/dashboard', element: <MatrixDashboard /> },
        {
          path: 'inbox',
          children: [
            { element: <Navigate to="/dashboard/Inbox/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Inbox /> },
            { path: 'label/:customLabel/:mailId', element: <Inbox /> },
            { path: ':systemLabel', element: <Inbox /> },
            { path: ':systemLabel/:mailId', element: <Inbox /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user" replace />, index: true },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.UserView]} >
                  <UserList />
                </PermissionBasedGuard>
            },
            {
              path: 'new', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.UserCreate]} >
                  <NewEditUserRouter />
                </PermissionBasedGuard>
            },
            {
              path: ':userId/edit/:tab', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.UserEdit]} >
                  <NewEditUserRouter />
                </PermissionBasedGuard>
            },
            {
              path: ':userId/add/:addType', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.UserEdit]} >
                  <AddFromEdit />
                </PermissionBasedGuard>
            },
            {
              path: ':userId/selfedit', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.UserEdit]} >
                  <NewEditUserRouter />
                </PermissionBasedGuard>
            },
            {
              path: ':userId/detail/:tab', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.UserView]} >
                  <UserDetail />
                </PermissionBasedGuard>
            }
          ],
        },
        {
          path: 'tenant/role/organization',
          children: [
            {
              element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleView]}>
                  <Navigate to="/dashboard/tenant/role" replace />
                </PermissionBasedGuard>,
              index: true
            },
            {
              path: 'new', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleCreate]}>
                  <NewOrganizationRole />
                </PermissionBasedGuard>
            },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleView]}>
                  <OrganizationRolesList />
                </PermissionBasedGuard>
            },
            {
              path: ':id/edit', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleEdit]}>
                  <OrganizationRoleEdit />
                </PermissionBasedGuard>
            },
            {
              path: ':id/detail', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleView]}>
                  <OrganizationRoleDetail />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleViewLogs]}>
                  <OrganizationRoleLogs />
                </PermissionBasedGuard>
            },
          ]
        },
        {
          path: 'tenant/role/admin',
          children: [
            {
              element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleView]}>
                  <Navigate to="/dashboard/tenant/role" replace />
                </PermissionBasedGuard>,
              index: true
            },
            {
              path: 'new', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleCreate]}>
                  <NewAdminRole />
                </PermissionBasedGuard>
            },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleView]}>
                  <AdminRolesList />
                </PermissionBasedGuard>
            },
            {
              path: ':id/edit', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleEdit]}>
                  <AdminRoleEdit />
                </PermissionBasedGuard>
            },
            {
              path: ':id/detail', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleView]}>
                  <AdminRoleDetail />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs', element:
                <PermissionBasedGuard hasContent={true} permissions={[ClusterPermissionTypes.RoleViewLogs]}>
                  <AdminRoleLogs />
                </PermissionBasedGuard>
            },
          ]
        },
        {
          path: 'tenant',
          children: [
            {
              element:
                <GodGuard hasContent={true}>
                  <Navigate to="/dashboard/tenant" replace />
                </GodGuard>,
              index: true
            },
            { path: 'customField/new', element: <GodGuard hasContent={true}><CustomFieldCreation /></GodGuard> },
            { path: 'customField/list', element: <GodGuard hasContent={true}><CustomFieldList /></GodGuard> },
            { path: 'customField/:id/detail', element: <GodGuard hasContent={true}><CustomFieldDetail /></GodGuard> },
            { path: 'customField/:id/edit', element: <GodGuard hasContent={true}><CustomFieldEdit /></GodGuard> },
            { path: 'systemEmail', element: <EmailList /> },
            { path: 'systemEmail/:id/detail', element: <GodGuard hasContent={true}> <EmailDetails /> </GodGuard> }
          ]
        },
        {
          path: 'vehicle',
          children: [
            { element: <Navigate to="/dashboard/vehicle" replace />, index: true },
            {
              path: 'new', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_Create]} >
                  <VehicleNewEditForm />
                </PermissionBasedGuard>
            },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_View]} >
                  <VehicleList />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/edit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_Edit]} >
                  <VehicleNewEditForm />
                </PermissionBasedGuard>
            },
            { path: ':vehicleId/detail/:page', element: <VehicleDetail /> },
            {
              path: ':vehicleId/detail', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_View]} >
                  <VehicleDetail />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/contractualEdit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_EditContractualData]} >
                  <ContractualDataNewEdit />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/contractualView', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_ViewContractualData]} >
                  <ContractualDataView />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/contractualLogs', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_ViewContractualDataLogs]} >
                  <ContractualDataLogs />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/logisticsEdit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_EditLogisticData]} >
                  <LogisticsNewEdit />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/logisticsView', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_ViewLogisticData]} >
                  <LogisticsView />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/logisticsLogs', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_ViewLogisticDataLogs]} >
                  <LogisticsLogs />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/commercialEdit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_EditCommercialData]} >
                  <CommercialDataNewEdit />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/commercialView', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_ViewCommercialData]} >
                  <CommercialDataView />
                </PermissionBasedGuard>
            },
            {
              path: ':vehicleId/commercialLogs', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_Vehicles_ViewCommercialDataLogs]} >
                  <CommercialDataLogs />
                </PermissionBasedGuard>
            },
          ],
        },
        {
          path: 'termsAndCondition',
          children: [{
            path: 'list',
            element:
              <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.TermsAndCondition_View]}>
                <TermsConditionList />
              </PermissionBasedGuard>
          },
          {
            path: 'new',
            element:
              <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.TermsAndCondition_Create]}>
                <>
                  <NewEditTermsCondition />
                </>
              </PermissionBasedGuard>
          },
          {
            path: ':id/edit',
            element:
              <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.TermsAndCondition_Edit]}>
                <>
                  <NewEditTermsCondition />
                </>
              </PermissionBasedGuard>
          },
          {
            path: ':id/detail/:tab',
            element:
              <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.TermsAndCondition_View]}>
                <TermsConditionDetail />
              </PermissionBasedGuard>
          },
          {
            path: ':id/logs',
            element:
              <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.TermsAndCondition_ViewLogs]}>
                <TermsCondtionLogs />
              </PermissionBasedGuard>
          }
          ]
        },
        {
          path: 'groups',
          children: [
            {
              path: 'list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Group_View]}>
                  <GroupList />
                </PermissionBasedGuard>
            },
            {
              path: 'new',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Group_Create]}>
                  <NewEditGroup />
                </PermissionBasedGuard>,
            },
            {
              path: ":id/edit",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Group_Edit]}>
                  <NewEditGroup />
                </PermissionBasedGuard>
            },
            {
              path: ':id/detail/:tab',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Group_View]} >
                  <GroupDetail />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Group_View]}>
                  <GroupLogs />
                </PermissionBasedGuard>
            }
          ]
        },
        {
          path: "webshopManagement",
          children: [
            {
              path: 'list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_View]}>
                  <WebshopListManager />
                </PermissionBasedGuard>
            },
            {
              path: "new",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_Create]}>
                  <NewEditWebshop />
                </PermissionBasedGuard>
            },
            {
              path: ":webshopId/draft",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_Create]}>
                  <NewEditWebshop />
                </PermissionBasedGuard>
            },
            {
              path: ":id/edit",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_Edit]}>
                  <NewEditWebshop />
                </PermissionBasedGuard>
            },
            {
              path: ":id/detail",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_View]}>
                  <WebshopDetail />
                </PermissionBasedGuard>
            },
            {
              path: ":webshopId/detail/:customerId/statistics",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_View]}>
                  <CustomerWebshopStatistics />
                </PermissionBasedGuard>
            },
            {
              path: ':webshopId/logs', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_ViewLogs]} >
                  <AdminWebshopLogs />
                </PermissionBasedGuard>
            },
          ]
        },
        {
          path: "webshopPublic",
          children: [
            {
              path: 'list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_View]}>
                  <WebshopListManager isPublic />
                </PermissionBasedGuard>
            },
            {
              path: "checkout",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Buy]}>
                  <WebshopCheckout />
                </PermissionBasedGuard>
            },
            {
              path: ":shopId/productDetail/:bundleId",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_View]}>
                  <PublicProductDetail />
                </PermissionBasedGuard>
            },
            {
              path: ":id/detail",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_View]}>
                  <WebshopDetail isPublic />
                </PermissionBasedGuard>
            },
          ]
        },
        {
          path: 'discountMatrix',
          children: [
            {
              path: 'list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_ManageDiscounts]}>
                  <DiscountMatrixList />
                </PermissionBasedGuard>
            },
            {
              path: 'new',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_ManageDiscounts]}>
                  <NewEditDiscountMatrix />
                </PermissionBasedGuard>
            },
            {
              path: ":id/edit",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_ManageDiscounts]}>
                  <NewEditDiscountMatrix />
                </PermissionBasedGuard>
            },
            {
              path: ":id/detail",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Admin_ManageDiscounts]}>
                  <DiscountMatrixDetail />
                </PermissionBasedGuard>
            }
          ]
        },
        {
          path: "basketRules",
          children: [
            {
              path: 'list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_BasketRule_View]}>
                  <BasketRulesList />
                </PermissionBasedGuard>
            },
            {
              path: "new",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_BasketRule_Create]}>
                  <NewEditBasketRule />
                </PermissionBasedGuard>
            },
            {
              path: ":id/edit",
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_BasketRule_Edit]}>
                  <NewEditBasketRule />
                </PermissionBasedGuard>
            },
            {
              path: 'logs',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_BasketRule_ViewLogs]}>
                  <BasketRulesLogs />
                </PermissionBasedGuard>
            },
          ]
        },
        {
          path: 'notificationTemplate',
          children: [{
            path: "list",
            element:
              <PermissionBasedGuard permissions={[ClusterPermissionTypes.Notification_View]}>
                <NotificationTemplateList />
              </PermissionBasedGuard>
          },
          {
            path: "new",
            element:
              <PermissionBasedGuard permissions={[ClusterPermissionTypes.Notification_Create]}>
                <NewEditNotificationTemplate />
              </PermissionBasedGuard>
          },
          {
            path: ":id/edit",
            element:
              <PermissionBasedGuard permissions={[ClusterPermissionTypes.Notification_Edit]}>
                <NewEditNotificationTemplate />
              </PermissionBasedGuard>
          }
          ]
        },
        {
          path: 'matrix',
          children: [
            {
              path: 'templates', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Template_View]} >
                  <MatrixTemplate />
                </PermissionBasedGuard>
            },
            {
              path: 'creationVoid', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Template_Create]} >
                  <MatrixCreateReset />
                </PermissionBasedGuard>
            },
            {
              path: 'creation', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Template_Create]} >
                  <MatrixCreate />
                </PermissionBasedGuard>
            },
            {
              path: ':matrixId/edit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Template_Edit]} >
                  <MatrixCreate />
                </PermissionBasedGuard>
            },
            {
              path: 'upload', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Template_Create]} >
                  <UploadMatrix />
                </PermissionBasedGuard>
            },
            {
              path: ':matrixId/list', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Matrix_View]} >
                  <MatrixList />
                </PermissionBasedGuard>
            },
            {
              path: 'listMain', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Matrix_View]} >
                  <MatrixList />
                </PermissionBasedGuard>
            },

            { path: 'matrixRequests', element: <MatrixRequests /> },
          ]
        },
        {
          path: 'rvMatrix',
          children: [
            { element: <Navigate to="/dashboard" replace />, index: true },
            {
              path: 'managment', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Matrix_View]} >
                  <MatrixMain />
                </PermissionBasedGuard>
            },
            {
              path: ':matrixId/edit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Matrix_Edit]} >
                  <RvMatrixEditPage />
                </PermissionBasedGuard>
            },
            {
              path: ':matrixId/view', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Matrix_View]} >
                  <RvMatrixViewPage />
                </PermissionBasedGuard>
            },
            {
              path: ':matrixId/view/:page', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Matrix_View]} >
                  <RvMatrixViewPage />
                </PermissionBasedGuard>
            },
            {
              path: ':matrixId/summary', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Rv_Matrix_View]}>
                  <RvMatrixSummaryPage />
                </PermissionBasedGuard>
            }
          ]
        },
        {
          path: 'organizations',
          children: [
            {
              path: 'new', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Customer_Create]}>
                  <NewOrganizationRouter />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/edit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Customer_Edit]}>
                  <NewEditOrganization />
                </PermissionBasedGuard>
            },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Customer_View]}>
                  <OrganizationListManager />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/logs', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_ViewLogs]} >
                  <OrganizationLogs isVendor={false} />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/detail', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Customer_View]} >
                  <OrganizationDetailPage isVendor={false} />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/detail/:tab',
              children: [
                {
                  path: '', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Customer_View]} >
                      <OrganizationDetailPage isVendor={false} />
                    </PermissionBasedGuard>
                }
              ]
            }
          ]
        },
        {
          path: 'vendorOrganizations',
          children: [
            {
              path: 'new', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_Create]}>
                  <NewOrganizationRouter />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/edit', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_Edit]}>
                  <NewEditOrganization />
                </PermissionBasedGuard>
            },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_View]}>
                  <OrganizationListManager isVendor />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/logs', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_ViewLogs]} >
                  <OrganizationLogs isVendor={true} />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/detail', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_View]} >
                  <OrganizationDetailPage isVendor />
                </PermissionBasedGuard>
            },
            {
              path: ':type/:id/detail/:tab',
              children: [
                {
                  path: '', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Organization_View]} >
                      <OrganizationDetailPage isVendor />
                    </PermissionBasedGuard>
                }
              ]
            }
          ]
        },
        {
          path: 'brands',
          children: [
            {
              path: 'new', element:
                <GodGuard hasContent={true}>
                  <NewBrandRouter />
                </GodGuard>
            },
            {
              path: 'list', element:
                <GodGuard hasContent={true} >
                  <BrandList />
                </GodGuard>
            },
            {
              path: ':brandId/detail', element:
                <GodGuard hasContent={true} >
                  <BrandDetail />
                </GodGuard>
            },
            {
              path: ':brandId/detail/:tab', element:
                <GodGuard hasContent={true} >
                  <BrandDetail />
                </GodGuard>
            },
            {
              path: ':id/edit', element:
                <GodGuard hasContent={true} >
                  <NewEditBrand />
                </GodGuard>
            }
          ]
        },
        {
          path: 'spareParts',
          children: [
            {
              path: 'list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_SpareParts_View]}>
                  <SparePartsList />
                </PermissionBasedGuard>
            },
            {
              path: ':id/detail',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_SpareParts_View]}>
                  <SparePartsDetail />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Product_SpareParts_ViewLogs]}>
                  <SparePartsLogs />
                </PermissionBasedGuard>
            }
          ]
        },
        {
          path: 'customerRequests',
          children: [
            {
              path: 'new',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerCreate]}>
                  <NewRequest />
                </PermissionBasedGuard>
            },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView]}>
                  <RequestListManager carrier={false} vendor={false} />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerViewLogs]}>
                  <RequestLogs carrier={false} vendor={false} />
                </PermissionBasedGuard>
            },
            {
              path: ':id/detail', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView]}>
                  <RequestDetail />
                </PermissionBasedGuard>
            },
            {
              path: "scan", element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView]}>
                  <DriverConfirm customer />
                </PermissionBasedGuard>
            }

          ]
        },
        {
          path: 'carrierRequests',
          children: [
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView]}>
                  <RequestListManager carrier={true} vendor={false} />
                </PermissionBasedGuard>
            },
            {
              path: 'driver', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp]}>
                  <DriverConfirm />
                </PermissionBasedGuard>
            },
            {
              path: ':id/driver', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp]}>
                  <DriverConfirm />
                </PermissionBasedGuard>
            },
            {
              path: ':idRequest/backlogDetail', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView]}>
                  <BacklogRequestDetail carrier={true} />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorViewLogs, OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerViewLogs, OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView]}>
                  <RequestLogs vendor={false} carrier={true} />
                </PermissionBasedGuard>
            }]
        },
        {
          path: 'vendorRequests',
          children: [
            {
              path: 'new',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorCreate]}>
                  <NewRequest isVendor />
                </PermissionBasedGuard>
            },
            {
              path: 'list', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView]}>
                  <RequestListManager carrier={false} vendor={true} />
                </PermissionBasedGuard>
            },
            {
              path: ':id/detail', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView]}>
                  <RequestDetail />
                </PermissionBasedGuard>
            },
            {
              path: ':idRequest/backlogDetail', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView]}>
                  <BacklogRequestDetail carrier={false} />
                </PermissionBasedGuard>
            },
            {
              path: ':idRequest/backlogDetail/:tab', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView]}>
                  <BacklogRequestDetail carrier={false} />
                </PermissionBasedGuard>
            },
            {
              path: ':idRequest/backlogDetail/:tab/:type/:orgId/detail/:orgTab', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView]}>
                  <BacklogRequestDetail carrier={false} />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorViewLogs]}>
                  <RequestLogs vendor={true} carrier={false} />
                </PermissionBasedGuard>
            },
            {
              path: 'confirm', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorWarehouseValidation]}>
                  <WarehouseConfirmRequest />
                </PermissionBasedGuard>
            },
            {
              path: ':id/confirm', element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorWarehouseValidation]}>
                  <WarehouseConfirmRequest />
                </PermissionBasedGuard>
            },
            {
              path: 'anomalies',
              children: [
                {
                  path: 'list', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly]}>
                      <RequestListManager carrier={false} anomaly={true} vendor={true} />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/:backTo/detail', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly]}>
                      <AnomalyDetail />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/:backTo/detail/:tab', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly]}>
                      <AnomalyDetail />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/:backTo/detail/:tab/:type/:orgId/detail/:orgTab', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly]}>
                      <AnomalyDetail />
                    </PermissionBasedGuard>
                }
              ]
            },
          ]
        },
        {
          path: 'containers',
          children: [
            {
              path: 'templates',
              children: [
                {
                  path: 'list',
                  element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Shipping_Container_Template_View]}>
                      <ContainerList isTemplate />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/detail',
                  element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Shipping_Container_Template_View]}>
                      <ContainerTemplateDetail />
                    </PermissionBasedGuard>
                }
              ]
            },
            {
              path: 'list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Shipping_Container_View]}>
                  <ContainerList />
                </PermissionBasedGuard>
            },
            {
              path: ':id/detail',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Shipping_Container_View]}>
                  <ContainerDetail />
                </PermissionBasedGuard>
            },
            {
              path: ':id/logs',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Shipping_Container_ViewLogs]}>
                  <ContainerLogs />
                </PermissionBasedGuard>
            },
          ]
        },
        {
          path: 'reports',
          children: [
            {
              path: 'recurring',
              children: [
                {
                  path: 'list', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Report_View]} >
                      <ReportListRedirect />
                    </PermissionBasedGuard>
                },
                {
                  path: ':type/:id/detail/:tab', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Report_View]} >
                      <ReportDetail />
                    </PermissionBasedGuard>
                },
                {
                  path: ':type/:id/edit', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Report_View]} >
                      <ReportEditPage isScheduled={false} />
                    </PermissionBasedGuard>
                }
              ]
            },
            {
              path: 'scheduled',
              children: [
                {
                  path: 'list', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Report_View]} >
                      <ReportListRedirect isScheduled />
                    </PermissionBasedGuard>
                },
                {
                  path: ':type/:id/detail/:tab', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Report_View]} >
                      <ReportDetail isScheduled />
                    </PermissionBasedGuard>
                },
                {
                  path: ':type/:id/edit', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.Report_View]} >
                      <ReportEditPage isScheduled={true} />
                    </PermissionBasedGuard>
                }
              ]
            }
          ],
        },
        {
          path: 'inbox',
          children: [
            {
              path: 'list',
              element: <Inbox />
            },
            {
              path: 'list/:section',
              element: <Inbox />
            },
            {
              path: 'list/:section/:messageId',
              element: <Inbox />
            }
          ]
        },
        {
          path: 'rules',
          children: [
            {
              path: ':rulesTab/list',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorView]}>
                  <RulesList />
                </PermissionBasedGuard>
            },
            {
              path: ':rulesTab/:ruleType/edit',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit]}>
                  <RulesEdit />
                </PermissionBasedGuard>

            },
            {
              path: ':rulesTab/edit',
              element:
                <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit]}>
                  <RulesEdit />
                </PermissionBasedGuard>

            }
          ]
        },
        {
          path: 'orders',
          children: [
            {
              path: 'vendorOrders',
              children: [
                {
                  path: 'list', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_VendorView]}>
                      <OrdersListManager isVendor />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/detail', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_VendorView]}>
                      <OrderDetail isVendor />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/logs',
                  element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_VendorViewLogs]}>
                      <OrdersLogs isVendor />
                    </PermissionBasedGuard>
                }
              ]
            },
            {
              path: 'customerOrders',
              children: [
                {
                  path: 'list', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerView]}>
                      <OrdersListManager isVendor={false} />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/detail', element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerView]}>
                      <OrderDetail isVendor={false} />
                    </PermissionBasedGuard>
                },
                {
                  path: ':id/logs',
                  element:
                    <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerViewLogs]}>
                      <OrdersLogs isVendor={false} />
                    </PermissionBasedGuard>
                }
              ]
            },
            {
              path: 'documents',
              children: [
                {
                  path: 'vendorDocuments',
                  children: [
                    {
                      path: 'list', element:
                        <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_VendorView]}>
                          <DocumentsListManager isVendor />
                        </PermissionBasedGuard>
                    },
                    {
                      path: ':type/:id/detail', element:
                        <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_VendorView]}>
                          <DocumentDetail isVendor />
                        </PermissionBasedGuard>
                    },
                    {
                      path: ':type/:id/logs',
                      element:
                        <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_VendorView]}>
                          <DocumentLogs isVendor />
                        </PermissionBasedGuard>
                    }
                  ]
                },
                {
                  path: 'customerDocuments',
                  children: [
                    {
                      path: 'list', element:
                        <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerView]}>
                          <DocumentsListManager isVendor={false} />
                        </PermissionBasedGuard>
                    },
                    {
                      path: ':type/:id/detail', element:
                        <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerView]}>
                          <DocumentDetail isVendor={false} />
                        </PermissionBasedGuard>
                    },
                    {
                      path: ':type/:id/logs',
                      element:
                        <PermissionBasedGuard hasContent={true} permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerViewLogs]}>
                          <DocumentLogs isVendor={false} />
                        </PermissionBasedGuard>
                    }
                  ]
                }
              ]
            }
          ]
        },
      ],
    },
    {
      path: '/login',
      element: <LoginLayout />
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Notification Management
const Inbox = Loadable(lazy(() => import('../pages/dashboard/inbox/Inbox')));

// User Management
const NewEditUserRouter = Loadable(lazy(() => import('../pages/dashboard/user/NewEditUserRouter')));

const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));

const UserDetail = Loadable(lazy(() => import('src/pages/dashboard/user/UserDetail')));

// Vehicle Management 
const VehicleNewEditForm = Loadable(lazy(() => import('../pages/dashboard/vehicle/VehicleNewEditForm')));

const VehicleDetail = Loadable(lazy(() => import('src/pages/dashboard/vehicle/VehicleDetail')));

const ContractualDataNewEdit = Loadable(lazy(() => import('src/sections/@dashboard/vehicle/contractualData/ContractualDataNewEdit')));

const ContractualDataView = Loadable(lazy(() => import('src/sections/@dashboard/vehicle/contractualData/ContractualDataView')));

const ContractualDataLogs = Loadable(lazy(() => import('src/sections/@dashboard/vehicle/contractualData/ContractualDataLogs')));

const VehicleList = Loadable(lazy(() => import('../pages/dashboard/vehicle/VehicleList')));

const CommercialDataNewEdit = Loadable(lazy(() => import('src/sections/@dashboard/vehicle/commercialData/CommercialDataNewEdit')));

const CommercialDataView = Loadable(lazy(() => import('src/sections/@dashboard/vehicle/commercialData/CommercialDataView')));

const CommercialDataLogs = Loadable(lazy(() => import('src/sections/@dashboard/vehicle/commercialData/CommercialDataLogs')));

//Matrix
const MatrixCreate = Loadable(lazy(() => import('src/pages/dashboard/matrix/MatrixCreate')));

const MatrixDashboard = Loadable(lazy(() => import('src/pages/dashboard/matrix/MatrixDashboard')));

const MatrixRequests = Loadable(lazy(() => import('src/pages/dashboard/matrix/MatrixRequests')));

const MatrixMain = Loadable(lazy(() => import('src/sections/@dashboard/matrix/MatrixMain')));

const UploadMatrix = Loadable(lazy(() => import('src/sections/@dashboard/matrix/UploadMatrix')));

const MatrixTemplate = Loadable(lazy(() => import('src/sections/@dashboard/matrix/MatrixTemplate')));

const RvMatrixEditPage = Loadable(lazy(() => import('src/sections/@dashboard/matrix/newEdit/RvMatrixEditPage')));

const RvMatrixViewPage = Loadable(lazy(() => import('src/sections/@dashboard/matrix/newEdit/RvMatrixViewPage')));

const RvMatrixSummaryPage = Loadable(lazy(() => import('src/sections/@dashboard/matrix/rvMatrix/RvMatrixSummary')));

// NOT FOUND
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// FAREWELL PAGE
const Farewell = Loadable(lazy(() => import('../pages/FarewelPage')));
