import { Box, Button, Card, Container, Divider, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import useResponsive from "src/hooks/useResponsive";
import { DeleteForever } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import { RuleTabs, SoftRuleLevel } from "src/@types/rules";
import SaveModal from "src/components/modals/SaveModal";
import { NumericFormat } from "react-number-format";
import { rulesOperations } from "src/redux/rules";
import { RootState, dispatch, useSelector } from "src/redux/store";
import { hasPermissions } from "src/utils/user";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { RulesClearModal } from "../modal/RulesClearModal";

//workaround for NumericFormat bug
type Level = {
    fromInDays: string,
    toInDays: string,
    fee: string
};

export default function InvoiceRulesEdit() {

    const { themeStretch } = useSettingsContext();

    const { organization } = useUserOrganizationContext();

    const { rulesTab } = useParams();

    const { translate } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'sm');

    const { rule } = useSelector((state: RootState) => state.rules);

    const [displayList, setDisplayList] = useState<Level[]>([]);

    useEffect(() => {
        if (rule) {
            setDisplayList(rule.invoice.feesInDays.map((fid) => {
                return {
                    fromInDays: fid.fromInDays.toString(),
                    toInDays: fid.toInDays.toString(),
                    fee: fid.fee.toString()
                };
            }));
        }
    }, [rule]);

    const [isOpenSave, setIsOpenSave] = useState(false);

    const [isOpenClear, setIsOpenClear] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [isRuleEnabled, setIsRuleEnabled] = useState(rule?.invoice?.enabled ?? true);

    const handleDelete = (index: number) => {
        setDisplayList(prev => prev.filter((_, ind) => index !== ind));
    };

    const handleSubmit = async () => {
        setIsOpenSave(false);

        const fees: SoftRuleLevel[] = displayList.map(lv => ({
            fromInDays: +lv.fromInDays,
            toInDays: +lv.toInDays,
            fee: +lv.fee
        }));

        await dispatch(rulesOperations.updateRuleInvoice({ feesInDays: fees, enabled: isRuleEnabled })).unwrap();

        dispatch(setSuccessMessage({ text: "", returnTo: PATH_DASHBOARD.rules.listTab(rulesTab as RuleTabs) }));
    };

    const ref = useRef<HTMLDivElement>(null);

    const handleAdd = () => {
        if (displayList.length < 10)
            setDisplayList(prev =>
                prev.concat([{ fee: "0", fromInDays: "0", toInDays: "0" }])
            );
    };

    const handleChange = (e: any, index: number, val: string) => {

        setDisplayList(prev =>
            prev.map((value, ind) => {
                if (index === ind)
                    return { ...value, [e.target.name]: val };

                return value;
            }));
    };

    useEffect(() => {
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        });
    }, [displayList]);

    const handleClear = () => {
        setDisplayList([]);
        setIsOpenClear(false);
    };

    useEffect(() => {

        let dis = displayList.some(val => {

            if (val.fee.length === 0 || val.fromInDays.length === 0 || val.toInDays.length === 0)
                return true;

            if (+val.fromInDays > +val.toInDays)
                return true;

            return false;
        });

        setDisabled(dis);

    }, [displayList]);

    return (
        <Page title={translate('rules.form.edit.title')}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <SaveModal
                    isOpen={isOpenSave}
                    toggle={() => setIsOpenSave(p => !p)}
                    handleSave={handleSubmit}
                    saveText={translate('rules.messages.saveInvoice')}
                />

                <RulesClearModal
                    isOpen={isOpenClear}
                    toggleOpen={() => setIsOpenClear(p => !p)}
                    submit={handleClear}
                    clearText={"invoiceClear"}
                />

                <Card sx={{ mt: 2 }}>

                    {displayList.length === 0 &&
                        <Box
                            sx={{
                                display: 'flex', gap: 1,
                                flexDirection: 'column'
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography
                                    variant="h5"
                                    sx={{ pt: 3, px: 2.5 }}
                                >
                                    {`${translate(`rules.form.edit.editInvoice`)}`}
                                </Typography>

                                <FormControlLabel
                                    disabled={!hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEnableDisable], organization.roles)}
                                    label={`${translate(`commons.${isRuleEnabled ? "enabled" : "disabled"}`)}`}
                                    control={<Switch checked={isRuleEnabled} onChange={(_, check) => setIsRuleEnabled(check)} />}
                                    sx={{ mr: 3, mt: 2.5 }}
                                />
                            </Box>

                            <Grid container sx={{ px: 3, pt: 2.5, pb: 7.5 }}>

                                <Grid item xs={12} >
                                    <Button
                                        variant="soft"
                                        size={"large"}
                                        fullWidth
                                        sx={{ height: "200%" }}
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAdd()}
                                    >
                                        {`${translate("rules.form.edit.addInvoiceLevel")}`}
                                    </Button>
                                </Grid>

                            </Grid>

                        </Box>
                    }

                    {displayList.length > 0 &&
                        <Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    pt: 3, px: 2.5,
                                    flexDirection: isDesktop ? 'row' : 'column'
                                }}
                            >
                                <Typography variant="h5">
                                    {`${translate(`rules.form.edit.editInvoice`)}`}
                                </Typography>

                                <Box sx={{ ml: "auto" }}>
                                    <FormControlLabel
                                        disabled={!hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEnableDisable], organization.roles)}
                                        label={isRuleEnabled ? `${translate('commons.enabled')}` : `${translate('commons.disabled')}`}
                                        control={<Switch checked={isRuleEnabled} onChange={(_, checked) => setIsRuleEnabled(checked)} />}
                                    />

                                    <Button
                                        variant="text"
                                        size={"medium"}
                                        disabled={displayList.length > 9}
                                        onClick={() => handleAdd()}
                                        startIcon={<AddIcon />}
                                        sx={{ height: "auto", mt: (isDesktop ? 0 : 1.5) }}
                                    >
                                        {`${translate("rules.form.edit.addInvoiceLevel")}`}
                                    </Button>
                                </Box>

                            </Box>

                            <Typography variant="body2" sx={{ px: 2.5, mb: 2 }}>
                                {`${translate(`rules.form.edit.maxRules`)}`}
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: isDesktop ? 0 : 2,
                                    overflow: 'auto',
                                    maxHeight: 500
                                }}
                            >
                                {displayList.map((item, index) =>
                                    <Box key={index + ".summary"}>

                                        <Box key={index} sx={{ m: 2 }}>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <Typography variant="h5" sx={{ ml: 2 }}>{`${translate('rules.form.level')}`} {index}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    mt: 2, gap: 2,
                                                    alignSelf: 'stretch',
                                                    alignItems: 'center'
                                                }}
                                            >

                                                <NumericFormat
                                                    customInput={TextField}
                                                    label={translate('rules.form.daysStart').toString()}
                                                    required
                                                    fullWidth
                                                    error={item.fromInDays.length === 0 || +item.fromInDays > +item.toInDays}
                                                    helperText={(() => {
                                                        if (item.fromInDays.length === 0)
                                                            return translate('rules.validation.empty').toString();
                                                        if (+item.fromInDays > +item.toInDays)
                                                            return translate('rules.validation.dates').toString();

                                                        return undefined;
                                                    })()}
                                                    value={item.fromInDays}
                                                    allowNegative={false}
                                                    name="fromInDays"
                                                    onValueChange={(val, e) => e.event && handleChange(e.event, index, val.value)}

                                                    suffix={` ${translate('rules.form.daysShort')}`}
                                                />
                                                <NumericFormat
                                                    customInput={TextField}
                                                    label={translate('rules.form.daysUntil').toString()}
                                                    error={item.toInDays.length === 0 || +item.fromInDays > +item.toInDays}
                                                    helperText={(() => {
                                                        if (item.toInDays.length === 0)
                                                            return translate('rules.validation.empty').toString();
                                                        if (+item.fromInDays > +item.toInDays)
                                                            return translate('rules.validation.dates').toString();

                                                        return undefined;
                                                    })()}
                                                    required
                                                    fullWidth
                                                    value={item.toInDays + ""}
                                                    name="toInDays"
                                                    allowNegative={false}
                                                    onValueChange={(val, e) => e.event && handleChange(e.event, index, val.value)}

                                                    suffix={` ${translate('rules.form.daysShort')}`}
                                                />
                                                <NumericFormat
                                                    customInput={TextField}
                                                    label={translate('rules.form.fee').toString()}
                                                    required
                                                    fullWidth
                                                    allowNegative={false}
                                                    error={item.fee.length === 0}
                                                    helperText={item.fee.length === 0 ? translate('rules.validation.empty').toString() : undefined}
                                                    value={item.fee + ""}
                                                    isAllowed={(val) => +val.value > 100 || +val.value < 0 ? false : true}
                                                    name="fee"
                                                    onValueChange={(val, e) => e.event && handleChange(e.event, index, val.value)}

                                                    suffix=" % "
                                                />

                                                <Button
                                                    size={"medium"}
                                                    startIcon={<DeleteForever sx={{ ml: 1.5 }} />}
                                                    onClick={() => handleDelete(index)}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                )}
                                <Box ref={ref} />
                            </Box>

                        </Box>
                    }

                    <Divider />

                    {
                        isDesktop ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    px: 3, py: 3, gap: 1,
                                    flexDirection: isDesktop ? 'row' : 'column-reverse'
                                }}
                            >
                                <Button
                                    variant="soft"
                                    size={"medium"}
                                    sx={{ borderRadius: 100 }}
                                    onClick={() => { navigate(PATH_DASHBOARD.rules.listTab("invoice")); }}
                                >
                                    {`${translate("commons.cancel")}`}
                                </Button>

                                <Box
                                    sx={{
                                        ml: 'auto',
                                        display: 'flex',
                                        gap: 1,
                                        flexDirection: 'default'
                                    }}
                                >
                                    {displayList.length > 0 &&
                                        <Button
                                            startIcon={<DeleteForever />}
                                            variant='contained'
                                            color='error'
                                            size={"medium"}
                                            onClick={() => setIsOpenClear(true)}
                                            sx={{ borderRadius: 100 }}
                                        >
                                            {`${translate('commons.empty')}`}
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        size={"medium"}
                                        disabled={disabled}
                                        onClick={() => setIsOpenSave(true)}
                                        sx={{ ml: 1, borderRadius: 100 }}
                                    >
                                        {`${translate("commons.justSave")}`}
                                    </Button>

                                </Box>

                            </Box>
                            :
                            <Box
                                sx={{
                                    display: 'flex',
                                    px: 2, py: 1, gap: 1,
                                    flexDirection: 'column'
                                }}
                            >

                                <Box
                                    sx={{
                                        mx: 2, mt: 1.5,
                                        display: 'flex', gap: 3,
                                    }}
                                >
                                    <Button
                                        variant="soft"
                                        size={"medium"}
                                        sx={{ borderRadius: 100 }}
                                        onClick={() => { navigate(PATH_DASHBOARD.rules.listTab("invoice")); }}
                                        fullWidth
                                    >
                                        {`${translate("commons.cancel")}`}
                                    </Button>
                                    {displayList.length > 0 &&
                                        <Button
                                            startIcon={<DeleteForever />}
                                            variant='contained'
                                            color='error'
                                            size={"medium"}
                                            onClick={() => setIsOpenClear(true)}
                                            sx={{ borderRadius: 100 }}
                                            fullWidth
                                        >
                                            {`${translate('commons.empty')}`}
                                        </Button>
                                    }
                                </Box>

                                <Button
                                    variant="contained"
                                    size={"medium"}
                                    disabled={disabled}
                                    onClick={() => setIsOpenSave(true)}
                                    sx={{ my: 1.5, mx: 2, borderRadius: 100 }}

                                >
                                    {`${translate("commons.justSave")}`}
                                </Button>

                            </Box>
                    }

                </Card>

            </Container>
        </Page >
    );
}