import { Avatar, Box, Card, Divider, MenuItem, Typography } from '@mui/material';
import { useNavigate, } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label, { LabelColor } from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import { SidebarListFilters, StatusFilters, SingleGridElementProps, ToolbarListFilters } from 'src/@types/list';
import { ShopFilters, DEFAULT_SHOP_FILTERS, ShopSearchResult, EnableDisableShopSliceProps } from 'src/@types/webshop';
import { webshopOperations } from 'src/redux/webshop';
import { setWebshopFiltersInUrl } from 'src/redux/webshop/webshop-slices';
import DateZone from 'src/appComponents/DateZone';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { getFirstLowerCase } from 'src/appUtils/string';
import { DEFAULT_SNACKBAR_PROPERTIES } from 'src/@types/commons';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';
import Markdown from 'src/components/markdown';
import { MarkdownStyle } from 'src/sections/@dashboard/inbox/detail/InboxDetail';
import { manageAmountFilters } from 'src/utils/list/utils/functions';
import dayjs from 'dayjs';

export default function AdminWebshopList() {

    const { translate } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isWebshopLoading, webshopList, webshopTotalCount, webshopFiltersInUrl } = useSelector((state: RootState) => state.webshop);

    const fullKeysToDelete: string[] = ["dateField", "min", "max"];

    //---- QUICKFILTERS START ----// 
    const quickFilters: StatusFilters[] = useMemo(() => [
        {
            key: '',
            label: translate('commons.all'),
            color: 'info',
        },
        {
            key: 'published',
            label: translate('statuses.published'),
            color: 'success',
        },
        {
            key: 'created',
            label: translate('statuses.created'),
            color: 'warning',
        },
        {
            key: 'preview',
            label: translate('statuses.preview'),
            color: 'warning',
        },
        {
            key: 'expired',
            label: translate('statuses.expired'),
            color: 'error',
        },
        {
            key: 'disabled',
            label: translate('statuses.disabled'),
            color: 'error',
        },
        {
            key: 'draft',
            label: translate('statuses.draft'),
            color: 'default',
        }
    ], [translate]);
    /*
    const renderQuickFilters = (key: string) => {
        if (webshopStatistics) {
            switch (key) {
                case "":
                    return webshopStatistics.all;
                case "Published":
                    return webshopStatistics.published;
                case "Created":
                    return webshopStatistics.created;
                case "Preview":
                    return webshopStatistics.preview;
                case "Expired":
                    return webshopStatistics.expired;
                case "Disabled":
                    return webshopStatistics.disabled;
                case "Draft":
                    return webshopStatistics.draft;
                default:
                    return 0;
            }
        } else return 0;
    };
    */
    //---- QUICKFILTERS END ----//

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: ShopFilters) =>
        ((filtersToCheck[field] || typeof filtersToCheck[field] === "boolean" || typeof filtersToCheck[field] === "number") && field !== "customer")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_SHOP_FILTERS[field])
        , []);

    //---- EXTRA FUNCTION TO USE BEFORE SEARCH ----//
    const extraSearchFiltersChecks = useCallback((searchFilters: ShopFilters) => {

        if ((searchFilters.from && searchFilters.to) && !searchFilters.dateField) searchFilters.dateField = DEFAULT_SHOP_FILTERS.dateField;

        /*         if (searchFilters.from && searchFilters.to) {
                    searchFilters.from = dayjs(searchFilters.from).startOf('day').toDate();
                    searchFilters.to = dayjs(searchFilters.to).endOf('day').toDate();
                } */

        return searchFilters;
    }, []);

    //---- FUNCTION TO USE TO ADAPT FILTERS BEFORE UPDATING FILTERS IN URL ----//
    const updateInUrlFiltersChecks = useCallback((filters: ShopFilters) => {
        if (filters.minPrice && filters.maxPrice) return manageAmountFilters("minPrice", "maxPrice", filters);

        return filters;
    }, []);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() =>
        [
            { key: 'CreatedOn', label: `${translate('statuses.created')}` },
            { key: 'PreviewDate', label: `${translate('statuses.preview')}` },
            { key: 'StartDate', label: `${translate('webshop.list.columns.publishing.start')}` },
            { key: 'EndDate', label: `${translate('webshop.list.columns.publishing.end')}` }
        ], [translate]);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "partReferenceSection",
            label: translate(`request.partReference`),
            type: "Section"
        },
        {
            name: "partReference",
            label: translate(`webshop.list.filters.partReference`),
            type: "TextField"
        },
        {
            name: "partDescription",
            label: translate(`spareParts.list.tableHeaders.partDesc`),
            type: "TextField"
        },
        {
            name: "supplierFamily",
            label: translate(`spareParts.list.tableHeaders.supplierFamily`),
            type: "TextField"
        },
        {
            name: "price",
            label: translate(`webshop.list.filters.priceList`),
            type: "InputRange"
        }
    ], [translate]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<GridCellParams<ShopSearchResult> | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<ShopSearchResult>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.webshop.detail(params.row.id));
        }
    };

    const handleEdit = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.webshop.edit(id));
    }, [navigate]);

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.webshop.logs(id));
    }, [navigate]);

    const handleArchive = useCallback(async (id: string) => {

        let x = await dispatch(webshopOperations.archiveShop(id));

        if (x.meta.requestStatus !== 'fulfilled') enqueueSnackbar(translate('commons.error'), DEFAULT_SNACKBAR_PROPERTIES);
        else enqueueSnackbar(translate('commons.enabled'), DEFAULT_SNACKBAR_PROPERTIES);

    }, [enqueueSnackbar, translate]);

    const handleEnableDisable = useCallback(async (id: string, action: "Enable" | "Disable") => {

        const options: EnableDisableShopSliceProps = {
            id: id,
            action: action,
            tab: ""
        };

        let x = await dispatch(webshopOperations.enableDisableShop(options));

        if (x.meta.requestStatus !== 'fulfilled') enqueueSnackbar(translate('commons.error'), DEFAULT_SNACKBAR_PROPERTIES);
        else enqueueSnackbar(translate(action === "Enable" ? 'commons.enabled' : 'commons.disabled'), DEFAULT_SNACKBAR_PROPERTIES);

    }, [enqueueSnackbar, translate]);

    const COLUMNS: GridColDef<ShopSearchResult>[] = useMemo(() => [
        {
            field: 'title',
            headerName: `${translate('commons.title')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: true,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.createdOn)}
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'previewDate',
            headerName: `${translate('webshop.list.columns.previewStart')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: true,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.previewDate)}
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'startDate',
            headerName: `${translate('webshop.list.columns.publishing.start')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.startDate)}
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'endDate',
            headerName: `${translate('webshop.list.columns.publishing.end')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.endDate)}
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {

                const status = getFirstLowerCase(obj.row.status);

                return (
                    <Label
                        color={quickFilters.find((filter) => filter.key === status)?.color ?? "default"}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.${status}`)}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleEdit={handleEdit}
                        handleLogs={handleLogs}
                        handleArchive={handleArchive}
                        handleEnableDisable={handleEnableDisable}
                    />
                );
            }
        }
    ], [translate, isDesktop, quickFilters, openMenu, actualRow, handleEdit, handleLogs, handleArchive, handleEnableDisable]);
    //---- HANDLE TABLE END ----//

    return (
        <InfiniteScrollGenericList
            list={webshopList}
            isLoading={isWebshopLoading}
            totalCount={webshopTotalCount}
            defaultFilters={DEFAULT_SHOP_FILTERS}
            //specificStatsKeysToDelete={statsKeysToDelete}
            specificFullKeysToDelete={fullKeysToDelete}
            //quickFilters={quickFilters}
            //renderQuickFilters={renderQuickFilters}
            toolbarFiltersList={toolbarFiltersList}
            //filtersInSidebar={filtersInSidebar}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"Webshop"}
            setActualRow={setActualRow}
            handleCellClick={handleCellClick}
            //setFiltersCallback={setFilters}
            //filterStatus={filterStatus}
            //onChangeFilterStatus={onChangeFilterStatus}
            search={webshopOperations.searchShops}
            //searchStatistics={webshopOperations.getShopStatistics}
            extraSearchFiltersChecks={extraSearchFiltersChecks}
            filtersInUrl={webshopFiltersInUrl}
            setFiltersInUrl={setWebshopFiltersInUrl}
            listDescription={translate('webshop.subtitle.admin')}
            resetList={() => { }}
            datesGeneralFilter={{
                showDates: true,
                //datesNames: ["min", "max"],
                dateSearchBy: ["dateField", "name"]
            }}
            multipleView={{
                renderGridElement: SingleGridElement,
                rowNumber: 2
            }}
            updateInUrlFiltersChecks={updateInUrlFiltersChecks}
        />
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: GridCellParams<ShopSearchResult>,
    currentRow: GridCellParams<ShopSearchResult> | null,
    handleEdit: (id: string) => void,
    handleLogs: (id: string) => void,
    handleArchive: (id: string) => void,
    handleEnableDisable: (id: string, action: "Enable" | "Disable") => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEdit, handleLogs, handleArchive, handleEnableDisable }: OptionsComponentProps) {

    const { translate } = useLocales();

    const { organization } = useUserOrganizationContext();

    const showFirstDivider: boolean =
        hasPermissions([OrganizationPermissionTypes.WebShop_Admin_Edit, OrganizationPermissionTypes.WebShop_Admin_Edit], organization.roles)
        &&
        hasPermissions([OrganizationPermissionTypes.WebShop_Admin_EnableDisable, OrganizationPermissionTypes.WebShop_Admin_Edit], organization.roles);

    const showSecondDivider: boolean =
        hasPermissions([OrganizationPermissionTypes.WebShop_Admin_EnableDisable, OrganizationPermissionTypes.WebShop_Admin_Edit], organization.roles)
        &&
        hasPermissions([OrganizationPermissionTypes.WebShop_Admin_ViewLogs], organization.roles);

    return (
        <TableMoreMenu
            showMenu={!!currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Admin_Edit]}>
                        <MenuItem
                            onClick={() => {
                                handleEdit(object.id.toString());
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.edit")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    {showFirstDivider && <Divider sx={{ my: "0 !important" }} />}

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Admin_EnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                if (handleEnableDisable) {
                                    if (object.row.enabled) handleEnableDisable(object.id.toString(), "Disable");
                                    else handleEnableDisable(object.id.toString(), "Enable");
                                }
                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {translate(`commons.${!object.row.enabled ? "enable" : "disable"}`)}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Admin_Edit]} >
                        <MenuItem
                            onClick={() => {
                                handleArchive(object.id.toString());
                                handleCloseMenu();
                            }}
                            sx={{ color: 'error.main' }}
                        >
                            {translate("webshop.list.actions.archive")}
                        </MenuItem>
                    </PermissionBasedGuard>

                    {showSecondDivider && <Divider sx={{ my: "0 !important" }} />}

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Admin_ViewLogs]} >
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id.toString());
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}

//-----------------------------------------------------------------------------

function SingleGridElement({ key, item, isDesktop, translate, navigate, currentLang }: SingleGridElementProps<ShopSearchResult>) {

    const getAvatarText = (name: string) => {

        if (name.includes("[")) {
            return name.split("]")[1][1];
        }

        return name[0];
    };

    const avatarMediaUrl = item.media?.find((media) => media.section === "profile")?.fileInfo[0].url ?? "";

    const coverMediaUrl = item.media?.find((media) => media.section === "cover")?.fileInfo[0].url ?? "";

    const labelColors: Record<string, string> = {
        published: 'success',
        created: "warning",
        preview: "warning",
        expired: 'error',
        disabled: 'error',
        draft: 'default'
    };

    return (
        <Card
            key={key}
            sx={{
                p: 3,
                transition: "0.5s",
                cursor: 'pointer',
                ':hover': {
                    bgcolor: (theme) => theme.palette.action.selected
                },
                width: "100%",
                height: "260px",
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 1)), url(${coverMediaUrl || "/assets/stellantis-europe/008.webp"})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}
            onClick={() => {
                if (!window.getSelection()?.toString())
                    navigate(PATH_DASHBOARD.webshop.detail(item.id));
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: "space-between", mb: 4.5 }}>
                <Avatar
                    sx={{
                        width: 70,
                        height: 70,
                        bgcolor: (theme) => theme.palette.primary.light,
                        color: (theme) => theme.palette.getContrastText(theme.palette.primary.light),
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: 'common.white'
                    }}
                    src={avatarMediaUrl}
                >
                    {getAvatarText(item.id)}
                </Avatar>

                <Label
                    color={labelColors[getFirstLowerCase(item.status)] as LabelColor}
                    variant={"filled"}
                    sx={{ textTransform: 'uppercase' }}
                >
                    {translate(`statuses.${getFirstLowerCase(item.status)}`)}
                </Label>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: "column", color: "white" }}>

                {item.endDate &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? 'row' : "column",
                            width: '100%',
                            gap: 0.5
                        }}
                    >
                        <Typography variant="caption">
                            {translate('webshop.detail.expiringOn')}
                        </Typography>
                        <DateZone
                            variant="caption"
                            date={new Date(item.endDate)}
                            noSeconds
                            shortMonth
                        />
                    </Box>
                }

                <Typography variant="h5" sx={{ mt: 1, mb: -1 }}>
                    {item.title}
                </Typography>

                <Typography
                    variant="body2"
                    sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2
                    }}
                >
                    <MarkdownStyle>
                        <Markdown children={item.shortDescription ? item.shortDescription : "—"} />
                    </MarkdownStyle>
                </Typography>
            </Box>
        </Card>
    );
}