import { Box, Slider, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { debounce } from "lodash";
import { useCallback, useRef, useState } from "react";
import { useLocales } from "src/locales";

export const getSliderFilterNames = (name: string) => {

    const fieldName = name.charAt(0).toUpperCase() + name.slice(1);

    return ["min" + fieldName, "max" + fieldName];
};

export const clearInput = (value: string) => {
    return Number(value.charAt(0) === "0" ? value.slice(1) : value);
};

interface SidebarSliderProps {
    name: string,
    label: string,
    sidebarValues: any,
    defaultFilterValues: any,
    stepValue: number,
    stepsNumber: number,
    handleChangeValues: (values: any, newVal: any, type: string) => void
};

export default function SidebarSlider({ name, label, sidebarValues, defaultFilterValues, stepValue, stepsNumber, handleChangeValues }: SidebarSliderProps) {

    const { translate } = useLocales();

    const [minLabel, maxLabel] = getSliderFilterNames(name);

    const minValue: number = defaultFilterValues[minLabel];

    const [maxStepField, setMaxStepField] = useState<number>(Math.max(defaultFilterValues[maxLabel], sidebarValues[maxLabel]));

    const [maxStepValue, setMaxStepValue] = useState<number>(Math.max(defaultFilterValues[maxLabel], sidebarValues[maxLabel]));

    const [inputMin, setInputMin] = useState<number>(sidebarValues[minLabel]);

    const [inputMax, setInputMax] = useState<number>(sidebarValues[maxLabel]);

    const stepSkip: number = Math.floor(maxStepValue / (stepValue * stepsNumber));

    const getMarks = useCallback((): { value: number, label: string }[] => {

        const marks = [...Array(Math.floor(maxStepValue / stepValue) + 1)]
            .map((_, index) => {

                const value = index * stepValue;

                return {
                    value,
                    label: index % stepSkip ? '' : value.toString()
                };
            })
            .filter((mark) => mark.label);

        marks.pop();

        return marks;
    }, [maxStepValue, stepSkip, stepValue]);

    const handleInputMinChange = (inputValue: number, maxValue: number) => {

        const value = Math.min(inputValue, maxStepValue - stepValue);

        setInputMin(value);

        validationInputMaxDebounced.current(value, maxValue);

        handleChangeValues(sidebarValues, [value, maxValue], name);
    };

    const handleInputMaxChange = (inputValue: number, minValue: number) => {
        const value = Math.min(inputValue, maxStepValue);

        setInputMax(value);

        validationInputMinDebounced.current(value, minValue);

        handleChangeValues(sidebarValues, [minValue, value], name);
    };

    const handleMaxStepChange = (inputValue: number, maxValue: number, minValue: number) => {
        setMaxStepValue(inputValue);
        if (maxValue > inputValue) handleInputMaxChange(inputValue, minValue);
    };

    const handleMaxStepDebounced = useRef(debounce(handleMaxStepChange, 300));

    //------------ VALIDATION - START ----------------//
    const handleValidationInputMax = (minValue: number, maxValue: number) => {
        if (minValue > maxValue) {
            handleInputMaxChange(minValue + stepValue, minValue);
        }
    };

    const validationInputMaxDebounced = useRef(debounce(handleValidationInputMax, 300));

    const handleValidationInputMin = (maxValue: number, minValue: number) => {

        if (minValue > maxValue) {
            handleInputMinChange(maxValue - stepValue, maxValue);
        }
    };

    const validationInputMinDebounced = useRef(debounce(handleValidationInputMin, 300));
    //------------ VALIDATION - END ----------------//

    const handleSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {

        if (!Array.isArray(newValue)) return;

        const [newMin, newMax] = newValue as [number, number];

        setInputMax(newMax);
        setInputMin(newMin);
        handleChangeValues(sidebarValues, [newMin, newMax], name);
    };

    return (
        <Stack spacing={1} sx={{ pb: 2 }}>
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                {label}
            </Typography>

            <Stack direction="row" spacing={2}>
                <InputRange
                    label={translate('commons.min')}
                    value={inputMin}
                    otherValue={inputMax}
                    onChange={handleInputMinChange}
                />
                <InputRange
                    label={translate('commons.max')}
                    value={inputMax}
                    otherValue={inputMin}
                    onChange={handleInputMaxChange}
                />
            </Stack>

            <Box sx={{ position: "relative" }}>
                <Slider
                    name={name}
                    step={stepValue}
                    min={minValue}
                    max={maxStepValue}
                    marks={getMarks()}
                    getAriaValueText={(value: any) => `${value}`}
                    valueLabelFormat={(value: any) => `${value}`}
                    sx={{ alignSelf: 'center', width: `calc(100% - 20px)` }}
                    valueLabelDisplay={"auto"}
                    value={[inputMin, inputMax]}
                    onChange={handleSliderChange}
                />

                <TextField
                    variant={"standard"}
                    size={"small"}
                    value={maxStepField.toString()}
                    onChange={(e) => {
                        const value = clearInput(e.target.value);

                        setMaxStepField(value);
                        handleMaxStepDebounced.current(value, inputMax, inputMin);
                    }}
                    type={"number"}
                    sx={{ width: 40, position: "absolute", right: -7, top: { xs: "55%", md: "53%" } }}
                    InputProps={{ sx: { fontSize: '14px' } }}
                />
            </Box>

        </Stack>
    );
}

//-------------------------------------------

interface InputRangeProps {
    label: string,
    value: number,
    otherValue: number,
    onChange: (value: number, otherValue: number) => void
};

function InputRange({ label, value, onChange, otherValue }: InputRangeProps) {

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ width: 1 }}>
            <Typography variant="caption" sx={{ flexShrink: 0, fontWeight: 'fontWeightBold', color: 'text.disabled', textTransform: 'capitalize' }}>
                {label}:
            </Typography>
            <TextField
                variant={"outlined"}
                size={"small"}
                value={value.toString()}
                onChange={(e) => onChange(clearInput(e.target.value), otherValue)}
                type={"number"}
                sx={{ width: 80 }}
            />
        </Stack>
    );
}