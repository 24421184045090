import { CurrencyTypes } from "./vehicle";

export interface Rate {
    currency: CurrencyTypes,
    date: Date,
    rate: number
}

export interface RatesContext {
    rates: Rate[],
}

export type PriceAmount = {
    withoutVat?: number,
    withVat?: number,
    currentCurrency: CurrencyTypes
};

export type PricePercentage = {
    withVat: number
    withoutVat: number
    withVatInEuro: number
    withoutVatInEuro: number
}

export const DEFAULT_PRICE_PERCENTAGE: PricePercentage = {
    withoutVat: 0,
    withoutVatInEuro: 0,
    withVat: 0,
    withVatInEuro: 0
};

export const DEFAULT_PRICE_AMOUNT: PriceAmount = {
    withoutVat: 0,
    withVat: 0,
    currentCurrency: CurrencyTypes.EUR
};