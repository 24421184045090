import { Stack } from '@mui/system';
import { useLocales } from 'src/locales';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DeleteForever } from '@mui/icons-material';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { rulesOperations } from 'src/redux/rules';
import { PartSummaryComponentProps } from 'src/@types/rules';
import { CategoriesSearchResult } from 'src/@types/spareParts';
import { sparePartsOperations } from 'src/redux/spareParts';
import sparePartsServices from 'src/services/sparePartsServices';
import LoadingScreen from 'src/appComponents/loading-screen/LoadingScreen';
import GenericPartViewEdit from './GenericPartViewEdit';

export default function PartCategories() {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const { rule } = useSelector((state: RootState) => state.rules);

    const { isLoading, categoriesList, categoriesTotalCount } = useSelector((state: RootState) => state.spareParts);

    const COLUMNS: GridColDef<CategoriesSearchResult>[] = [
        {
            field: 'code',
            headerName: translate('rules.list.tableHeaders.code'),
            flex: isDesktop ? 1.1 : undefined,
            maxWidth: !isDesktop ? 90 : undefined,
            valueGetter: (value: string) => value ? value : '—'
        },
        {
            field: 'description',
            headerName: translate('rules.list.tableHeaders.description'),
            flex: isDesktop ? 2 : undefined,
            minWidth: !isDesktop ? 335 : undefined,
            valueGetter: (value: string) => value ? value : '—'
        },
        {
            field: 'numberOfParts',
            headerName: translate('rules.list.tableHeaders.counter'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 140 : undefined,
            valueGetter: (value: string) => value ? value : '—'
        }
    ];

    return (!rule) ? (<LoadingScreen />) : (
        <GenericPartViewEdit
            defaultItems={rule.partCategory.categories}
            successMessage={translate('rules.messages.saveText.partCategories')}
            saveModalText={translate('rules.messages.saveConfirmPartRef')}
            clearModalText={"clearPartref"}
            title={translate(`rules.form.partCatList`)}
            addItemText={translate("rules.form.addcategory")}
            searchService={sparePartsServices.searchCategories}
            searchOperation={sparePartsOperations.searchRulesCategories}
            updateOperation={rulesOperations.updateRuleCategory}
            columns={COLUMNS}
            itemsList={categoriesList.map((item) => ({ ...item, id: item.code }))}
            totalCount={categoriesTotalCount}
            isLoading={isLoading}
            PartSummaryComponent={PartSummary}
            getRowIdFunction={(row) => row.code}
        />
    );
}

//-------------------------------------------------------------------------

function PartSummary({ item, deleteItem, isLastItem, key, translate, isSM }: PartSummaryComponentProps<CategoriesSearchResult>) {

    const returnCounter =
        <Grid item xs={4} sm={2} md={1.5}>
            <Stack sx={{ m: { xs: 1, md: 2 } }}>
                <Typography variant="overline">
                    {translate('rules.list.tableHeaders.counter')}
                </Typography>
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                    {item?.numberOfParts || '—'}
                </Typography>
            </Stack>
        </Grid>;

    const returnDescription =
        <Grid item xs={12} sm={6} md={4.5}>
            <Stack sx={{ m: { xs: 1, md: 2 } }}>
                <Typography variant="overline">
                    {translate('rules.list.tableHeaders.description')}
                </Typography>
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                    {item?.description || '—'}
                </Typography>
            </Stack>
        </Grid>;

    return (
        <Box key={key}>
            <Box
                key={item.code + ".partFamily.summary"}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    position: "relative"
                }}
            >
                <Grid container gap={0.5} sx={{ mx: 2, width: '100%' }}>

                    <Grid item xs={4} sm={1.5} md={1.5}>
                        <Stack sx={{ m: { xs: 1, md: 2 } }}>
                            <Typography variant="overline">
                                {translate('rules.list.tableHeaders.code')}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {item.code || '—'}
                            </Typography>
                        </Stack>
                    </Grid>

                    {!isSM ? returnCounter : returnDescription}

                    {isSM ? returnCounter : returnDescription}

                </Grid>

                <Button
                    size={"medium"}
                    startIcon={<DeleteForever sx={{ ml: 1.5 }} />}
                    onClick={() => deleteItem(item.code)}
                    sx={{
                        position: "absolute",
                        right: { xs: "1%", md: "3%" },
                        top: { xs: "5%", md: "25%" }
                    }}
                />
            </Box>

            {!isLastItem && <Divider sx={{ mx: 2 }} />}
        </Box>
    );
}