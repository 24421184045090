import { ReactNode, createContext, useMemo } from 'react';
import axios from 'axios';

// hooks
import { useAuth } from '../appHooks/useAuth';
import { useLocales } from 'src/locales';
import { useUserOrganizationContext } from './UserOrganizationContext';
import { dispatch } from 'src/redux/store';
import { setErrorMessage } from 'src/redux/modal/modal-slices';
import { v4 } from 'uuid';

type AxiosContextProviderProps = {
    children: ReactNode;
};

const AxiosContext = createContext(null as any);

const TRUSTED_DOMAINS = [
    process.env.REACT_APP_HOST_API_KEY as string
];

const AxiosProvider = ({ children }: AxiosContextProviderProps) => {
    const { getAccessToken, isAuthenticated } = useAuth();

    const { currentLang } = useLocales();

    const { organizationId } = useUserOrganizationContext();

    useMemo(() => {
        axios.interceptors.request.use(async (config: any) => {

            if (!!config?.url && !TRUSTED_DOMAINS.some(d => config.url?.startsWith(d)))
                return config;

            config.headers = {
                "Content-Type": "application/json",
                "X-Muovyti-Tenant": process.env.REACT_APP_CLIENT_TENANT_ID!,
                "X-Muovyti-Organization": organizationId,
                "X-Idempotency-Key": v4(),
                "Accept-Language": currentLang.locale.code || currentLang.value,
                ...config.headers,
            };

            if (isAuthenticated) {
                const accessToken = await getAccessToken();

                if (accessToken) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${accessToken}`
                    };
                }
            }

            return config;
        });

        axios.interceptors.response.use((response) => response, (error) => {

            if (error.config) {
                const methods = ['post', 'put', 'patch', 'delete'];

                // Controlla se il metodo della richiesta è POST, PUT o PATCH
                if (methods.includes(error.config.method)) {
                    dispatch(setErrorMessage(error.response.status));
                }
            }

            return Promise.reject(error);
        });
    }, [currentLang, getAccessToken, isAuthenticated, organizationId]);

    return (
        <AxiosContext.Provider value={{}}>
            {children}
        </AxiosContext.Provider>
    );
};

export { AxiosProvider };