import { Box, Button, Card, Container, TextField } from "@mui/material";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import useLocales from "src/appHooks/useLocales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useEffect, useReducer, useState } from "react";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { brandOperations } from "src/redux/brand";
import { Brand, DEFAULT_NEW_BRAND, NewBrand } from "src/@types/brand";
import { resetBrand } from "src/redux/brand/brand-slices";
import SaveModal from "src/components/modals/SaveModal";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { useFormik, yupToFormErrors } from "formik";
import { CustomFieldParserFormik } from "src/utils/CustomFieldParser";
import useTenant from "src/appHooks/useTenant";
import { CustomField, CustomFieldTypes, EntityType } from "src/@types/customField";
import { getLocalizedString } from "src/utils/CustomFieldManagment";
import { Lang } from "src/locales";
import dayjs from "dayjs";

type BrandAction = {
    type: 'update' | 'reset'
    payload?: Partial<NewBrand>
};

function reduce(state: NewBrand, action: BrandAction): NewBrand {
    switch (action.type) {
        case 'reset':
            return DEFAULT_NEW_BRAND;
        case 'update':
            return { ...state, ...(action.payload ?? {}) };
        default:
            return state;
    };
}

function getCustomFieldRequiredErrors(
    customFields: CustomField[],
    customFieldValues: Record<string, string>,
    translate: (text: unknown, options?: any) => string,
    currentLang: Lang
) {

    const errors: Record<string, string> = {};

    const requiredFields = customFields.filter(field => field.required);

    if (requiredFields.length > 0) {

        requiredFields.forEach((field) => {

            if (!customFieldValues[field.id])
                Object.assign(errors,
                    {
                        [field.id]: getLocalizedString(field.validationMessage ?? [], currentLang, translate('commons.required'))
                        /*
                        {
                            message: getLocalizedString(field.validationMessage ?? [], currentLang, translate('commons.required')),
                            path: field.id
                        }
                        */
                    }
                );
        });
    }

    return errors;
}

function getCustomFieldSchema(
    customFieldsErrors: Record<string, string>,
    customFields: CustomField[]
) {
    const customFieldSchema = {};

    Object.entries(customFieldsErrors).forEach((error, index) => {

        Object.assign(customFieldSchema,
            {
                [error[0]]: (() => {
                    switch (customFields[index].type as CustomFieldTypes) {
                        case "Checkbox": return Yup.boolean().required(error[1]);
                        case "Date": return Yup.date().required(error[1]);
                        case "Currency":
                        case "Number": return Yup.number().required(error[1]);
                        case "Select":
                        case "String":
                        default: return Yup.string().required(error[1]);
                    }
                })()
            });
    });

    return customFieldSchema;
}

const getYupSchema = (
    translate: (text: unknown, options?: any) => string,
    customFields: CustomField[],
    currentLang: Lang
) => {

    const requiredFields = customFields.filter(field => field.required);

    const customFieldsErrors = getCustomFieldRequiredErrors(requiredFields, {}, translate, currentLang);

    const customFieldSchema = getCustomFieldSchema(customFieldsErrors, requiredFields);

    const mainSchema = {
        name: Yup.string().required(translate('commons.required')),
        externalId: Yup.string().required(translate('commons.required')),
        customFields: Yup.object().shape(customFieldSchema)
    };

    return Yup.object().shape(mainSchema);
};

export default function NewEditBrand() {

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const { customFields } = useTenant();

    const { id } = useParams();

    const isEdit = !!id;

    const { brand, isBrandLoading } = useSelector((state: RootState) => state.brand);

    //---- CUSTOMFIELDS FORMATTING - START ----//
    const customContexted = customFields?.filter(x => x.enabled && x.entityType === "Brand") ?? [];

    const getCustomFieldsDefaultValues = () => {

        const output = {};

        if (customContexted.length > 0) {

            customContexted.filter((field) => field.type === "Select" ? field.selectOptions?.some(f => f.isDefault) : field.defaultValue)
                .forEach(field => {
                    if (field.type === "Select") {
                        const defaultValue = field.selectOptions?.find(f => f.isDefault);

                        Object.assign(output, { [field.id]: defaultValue?.values[0].text });
                    } else {
                        Object.assign(output, { [field.id]: field.defaultValue });
                    }
                });
        };

        return output;
    };
    //---- CUSTOMFIELDS FORMATTING - END ----//

    //---- CREATING FORM - START ----//
    const NEW_DEFAULT_NEW_BRAND = { ...DEFAULT_NEW_BRAND, customFields: getCustomFieldsDefaultValues() };

    const [brandForm, setBrandForm] = useReducer(reduce, isEdit ? (brand as (NewBrand | undefined)) ?? NEW_DEFAULT_NEW_BRAND : NEW_DEFAULT_NEW_BRAND);

    const formik = useFormik({
        initialValues: brandForm,
        validationSchema: getYupSchema(translate, customContexted, currentLang),
        validateOnMount: true,
        onSubmit: (val) => setBrandForm({ payload: val, type: 'update' })
    });

    useEffect(() => {
        if (id) dispatch(brandOperations.getBrand(id!));
        else {
            dispatch(resetBrand());
            setBrandForm({ type: 'reset' });
        }

        return () => { dispatch(resetBrand()); };
    }, [id, isEdit, currentLang]);
    //---- CREATING FORM - END ----//

    //---- HANDLING MODALS START ----//
    const [isOpenSave, setIsOpenSave] = useState(false);

    const toggleSave = () => setIsOpenSave((prev) => !prev);

    //---- HANDLING MODALS END ----//

    //---- HANDLING SAVE START ----//
    const handleSaveBrand = async () => {
        try {
            if (isEdit)
                await dispatch(brandOperations.updateBrand(brandForm as Brand)).unwrap();
            else
                await dispatch(brandOperations.createBrand(brandForm)).unwrap();

            dispatch(setSuccessMessage({
                text: translate('organization.form.brands.save'),
                goTo: PATH_DASHBOARD.brands.list,
                callback: () => { if (!isEdit) setBrandForm({ type: 'reset' }); }
            }));
        } catch (e) {
            console.error(e);
        }
        toggleSave();
    };
    //---- HANDLING SAVE END ----//

    return (
        <Page title={translate(`organization.form.brands.${isEdit ? "edit" : "new"}`)}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate(`organization.form.brands.${isEdit ? "edit" : "new"}`)}
                    links={[
                        { name: translate("commons.home"), href: PATH_DASHBOARD.root },
                        { name: translate("organization.form.brands.title"), href: PATH_DASHBOARD.brands.list }, //previousPage: 1
                        ...(brand ? [{ name: brand.name }] : []),
                        { name: translate(`commons.${isEdit ? "edit" : "new"}`) }
                    ]}
                />

                <SaveModal
                    toggle={toggleSave}
                    isOpen={isOpenSave}
                    handleSave={handleSaveBrand}
                    saveText={translate('organization.form.brands.saveConfirm')}
                />

                {isBrandLoading ? <LoadingScreen /> :
                    <Card sx={{ p: 3 }}>
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3, mb: 3,
                                gridTemplateColumns: 'repeat(2, 1fr)'
                            }}
                        >
                            <TextField
                                name={"name"}
                                label={translate('organization.form.brands.name')}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                error={formik.touched.name && !!formik.errors.name}
                                helperText={(formik.touched.name && !!formik.errors.name) && translate(formik.errors.name)}

                            />

                            <TextField
                                name={"externalId"}
                                label={translate('commons.externalId')}
                                value={formik.values.externalId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                error={formik.touched.externalId && !!formik.errors.externalId}
                                helperText={(formik.touched.externalId && !!formik.errors.externalId) && translate(formik.errors.externalId)}
                                disabled={isEdit}
                            />
                        </Box>

                        <CustomFieldParserFormik
                            customFields={customFields}
                            entity={EntityType.Brand}
                            formik={formik}
                        />

                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                            <Button
                                variant={'soft'}
                                color={"inherit"}
                                onClick={() => navigate(PATH_DASHBOARD.brands.list)}
                            >
                                {translate("commons.cancel")}
                            </Button>

                            <LoadingButton
                                variant={"contained"}
                                disabled={!formik.isValid}
                                onClick={() => {
                                    formik.handleSubmit();
                                    toggleSave();
                                }}
                            >
                                {translate(`organization.form.brands.${isEdit ? "updateButton" : "createButton"}`)}
                            </LoadingButton>
                        </Box>
                    </Card>
                }
            </Container>
        </Page>
    );
}

/*
const customFieldRemover = (values: Brand) => {

    if (customFields && customContext) {

        const customActualValues = getValues("customFields") || {};

        customContext.forEach(customField => {
            if (customField.defaultValue && !customActualValues[customField.id]) {
                customActualValues[customField.id] = customField.defaultValue;
            }
            delete values[customField.id];
        });
    }

    return values;
};
*/