import { Box, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate, } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label, { LabelColor } from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { isEqual } from 'lodash';
import useTabs from 'src/appHooks/useTabs';
import { SidebarListFilters, StatusFilters } from 'src/@types/list';
import DateZone from 'src/appComponents/DateZone';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';
import { AllStatuses, CompletedStatusesArr, DEFAULT_REQUEST_FILTERS, GreenStatusesArr, GreenStatusType, RedStatusesArr, RedStatusType, RefusedStatusesArr, RequestFilters, RequestSearchResult, RequestTypeArr, RequestUserType, UnderProcessingStatusesArr, YellowStatusesArr, YellowStatusType } from 'src/@types/request';
import BalanceIcon from '@mui/icons-material/Balance';
import { setFiltersInUrl } from 'src/redux/request/request-slices';
import { requestOperations } from 'src/redux/request';
import organizationService from "src/services/organizationService";
import { RequestReportFilters } from 'src/@types/report';
import { getFirstLowerCase, getFirstUpperCase } from 'src/appUtils/string';
import { organizationsOperations } from 'src/redux/organizations';
import GenericList from 'src/utils/list/GenericList';
import { convertStringArrayToSelectOptions } from 'src/utils/list/utils/functions';
import { OrganizationFilters } from 'src/@types/organizations';

interface OrganizationRequestsProps {
    vendor: boolean,
    carrier: boolean,
    anomaly?: boolean
}

export default function RequestList({ anomaly, vendor, carrier }: OrganizationRequestsProps) {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const theme = useTheme();

    const { organization } = useUserOrganizationContext();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, requestList, totalCount, statistics, filtersInUrl, pageIndex, pageSize } = useSelector((state: RootState) => state.request);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus, setCurrentTab: setCurrentStatus } = useTabs("");

    const [filters, setFilters] = useState<RequestFilters>(DEFAULT_REQUEST_FILTERS);

    //---- QUICKFILTERS START ----// 
    const quickFilters: StatusFilters[] = useMemo(() => [
        {
            key: '',
            label: translate('statuses.all'),
            color: 'info',
        },
        {
            key: 'completed',
            label: translate('statuses.completed'),
            color: 'success',
        },
        {
            key: 'created',
            label: translate('statuses.created'),
            color: 'success',
        },
        {
            key: 'deleted',
            label: translate('statuses.deleted'),
            color: 'error',
        },
        {
            key: 'refused',
            label: translate('statuses.refusedAndAnomaly'),
            color: 'error',
        },
        {
            key: 'underProcessing',
            label: translate('statuses.underProcessing'),
            color: 'warning',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "":
                    return statistics.total;
                case "completed":
                    return statistics.completed;
                case "created":
                    return statistics.created;
                case "deleted":
                    return statistics.deleted;
                case "refused":
                    return statistics.refused;
                case "underProcessing":
                    return statistics.underProcessing;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: RequestFilters) =>
        ((filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && field !== "customer")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_REQUEST_FILTERS[field])
        , []);

    //---- EXTRA FUNCTION TO USE BEFORE SEARCH ----//
    const findStatusName = useCallback((statusArr: AllStatuses[]) => {
        let statusName = "";

        if (isEqual(statusArr, RefusedStatusesArr)) statusName = "refused";
        else if (isEqual(statusArr, CompletedStatusesArr)) statusName = "completed";
        else if (isEqual(statusArr, UnderProcessingStatusesArr)) statusName = "underProcessing";
        else statusName = getFirstLowerCase(statusArr[0]);

        return statusName;
    }, []);

    const extraSearchFiltersChecks = useCallback((searchFilters: RequestFilters, firstRender?: boolean) => {

        let statusToCheck: string = filterStatus;

        if (firstRender && !filterStatus && searchFilters.status && searchFilters.status.length > 0) {
            const statusName = findStatusName(searchFilters.status);

            statusToCheck = statusName;

            setCurrentStatus(statusName);
        }

        if (anomaly) {
            searchFilters.status = RefusedStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
        } else {
            switch (statusToCheck) {
                case "refused":
                    searchFilters.status = RefusedStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
                    break;
                case "completed":
                    searchFilters.status = CompletedStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
                    break;
                case "underProcessing":
                    searchFilters.status = UnderProcessingStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
                    break;
                case "":
                    searchFilters.status = undefined;
                    break;
                default:
                    searchFilters.status = [getFirstUpperCase(filterStatus)] as (GreenStatusType | RedStatusType | YellowStatusType)[];
            }
        }

        return searchFilters;
    }, [anomaly, filterStatus, findStatusName, setCurrentStatus]);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: RequestFilters) => {

        let requestUser: RequestUserType = 'Customer';

        if (carrier) requestUser = 'Carrier';
        else if (vendor) requestUser = 'Vendor';

        dispatch(requestOperations.searchRequests({
            filters: filters,
            type: requestUser
        }));

    }, [carrier, vendor]);

    //---- CUSTOM STATISTICS FUNCTION ----//
    const customStatisticsSearch = useCallback((filters: RequestFilters) => {

        let requestUser: RequestUserType = 'Customer';

        if (carrier) requestUser = 'Carrier';
        else if (vendor) requestUser = 'Vendor';

        dispatch(requestOperations.searchStatistic({
            filters: filters,
            type: requestUser
        }));

    }, [carrier, vendor]);

    //---- CUSTOM STATISTICS FUNCTION ----//
    const extraFunctionInSearch = useCallback(async (filters: RequestFilters, getValues: any, setValue: any) => {
        if (filters.customerId && filters.customerId !== getValues("customer")?.id) {

            const { data } = await organizationService.detailGeneric(filters.customerId);

            setValue("customer", data);
        }
    }, []);

    //---- REPORT FILTERS ----//
    const reportFilters: RequestReportFilters = useMemo(() => (
        {
            all: filters.all || null,
            customerId: !vendor && !carrier ? organization.organizationId : null,
            customFields: filters.customFields || null,
            externalId: filters.externalId || null,
            requestType: filters.requestType || null,
            status: filters.status as string[] || null,
            vendorId: vendor && !carrier ? organization.organizationId : null,
            tags: null
        }
    ), [filters, vendor, carrier, organization]);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "externalId",
            label: translate(`returns.filter.partRef`),
            type: "TextField"
        },
        {
            name: "requestType",
            label: translate(`returns.tableHeaders.reason`),
            type: "Select",
            options: convertStringArrayToSelectOptions(RequestTypeArr.slice(1), translate, "request.type.")
        },
        {
            name: "hasClaim",
            label: translate(`returns.filter.claimed`),
            type: "SingleSwitch",
            toParse: true,
            switchesInfo: [
                {
                    name: "hasClaim",
                    label: translate(`returns.filter.claimed`)
                }
            ]
        },
        {
            name: "",
            label: translate(`commons.customer`),
            type: "Section"
        },
        {
            name: "customer",
            label: translate(`commons.customer`),
            type: "InfiniteAutocomplete",
            nameId: "customerId",
            infiniteOperation: (filters: OrganizationFilters) => dispatch(organizationsOperations.searchOrganizations({ filters, isVendor: false, check: false })).unwrap(),
            permissions: [OrganizationPermissionTypes.Customer_View],
            renderOptionComponent: (props, option) => {
                return (
                    <Box {...props} component={'li'} key={option.id} >
                        <Typography sx={{ mr: 2 }}>{option.name}</Typography>
                    </Box>
                );
            }

        }
    ], [translate]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<GridCellParams<RequestSearchResult> | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<RequestSearchResult>) => {

        setActualRow(params);

        if (params.field !== "options") {
            if (vendor && hasPermissions([
                OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation,
                OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp,
                OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView
            ], organization.roles)) {
                if (RefusedStatusesArr.includes(params.row.status) && hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly], organization.roles)) {
                    navigate(PATH_DASHBOARD.request.vendor.anomalies.detail(params.row.returnRequestId.toString(), anomaly ? "a" : "r"));
                }
                else navigate(PATH_DASHBOARD.request.vendor.backlogDetail(params.row.returnRequestId.toString()));
            }
            else if (carrier && hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp], organization.roles)) {
                navigate(PATH_DASHBOARD.request.carrier.backlogDetail(params.row.returnRequestId.toString()));
            }
            else {
                navigate(PATH_DASHBOARD.request.customer.detail(params.row.returnRequestId.toString()));
            }
        }
    };

    const handleLogs = useCallback((id: string) => {
        if (vendor)
            navigate(PATH_DASHBOARD.request.vendor.logs(id));
        else
            navigate(PATH_DASHBOARD.request.customer.logs(id));
    }, [navigate, vendor]);

    const getStatusColor = (status: string): LabelColor => {

        let color = 'default';

        if (status) {
            if (GreenStatusesArr.includes(status as GreenStatusType)) color = "success";
            else if (RedStatusesArr.includes(status as RedStatusType)) color = "error";
            else if (YellowStatusesArr.includes(status as YellowStatusType)) color = "warning";
        }

        return color as LabelColor;
    };

    //GROUP A: ADMIN, HUB BO, WAREHOUSE OPERATOR
    const COLUMNS_GROUP_A: GridColDef<RequestSearchResult>[] = useMemo(() => [
        {
            field: 'prettyName',
            headerName: `${translate('returns.tableHeaders.prettyName')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 125 : undefined
        },
        {
            field: 'itemExternalId',
            headerName: `${translate('returns.tableHeaders.partref')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, row) => {
                return row.item.productExternalId;
            }
        },
        {
            field: 'name',
            headerName: `${translate('returns.tableHeaders.partdescription')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            valueGetter: (_, row) => {
                return row.item.name;
            }
        },
        {
            field: 'customerName',
            headerName: `${translate('returns.tableHeaders.customerName')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    <Tooltip title={obj.row.customerName}>
                        <Typography variant='body2' noWrap sx={{ width: '97%' }}>
                            {obj.row.customerName}
                        </Typography>
                    </Tooltip>
                );
            }
        },
        {
            field: 'requestType',
            headerName: `${translate('returns.tableHeaders.reason')}`,
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (obj) => {

                const reason = obj.row.requestReason ? translate(`request.selectReason.${getFirstLowerCase(obj.row.requestReason)}`) : "";

                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '97%' }}>
                        <Typography variant='body2' noWrap>
                            {translate(`request.${obj.row.requestType}`)}
                        </Typography>
                        {reason &&
                            <Tooltip title={reason}>
                                <Typography variant='body2' noWrap>
                                    {reason}
                                </Typography>
                            </Tooltip>
                        }
                    </Box>
                );
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('commons.createdOn')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj: any) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'quantity',
            headerName: `${translate('returns.tableHeaders.quantity')}`,
            headerAlign: 'center',
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 75 : undefined,
            renderCell: (obj: any) => {
                return (
                    <Typography variant="body2" sx={{ px: "45%" }}>
                        {obj.row?.quantity}
                    </Typography>
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {`${translate(`commons.order.returnRequest.${obj.row.status}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                        vendor={vendor}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, handleLogs, actualRow, vendor]);

    //GROUP B: CUSTOMER, DELIVERY ADDRESS OPERATOR
    const COLUMNS_GROUP_B: GridColDef<RequestSearchResult>[] = useMemo(() => [
        {
            field: 'prettyName',
            headerName: `${translate('returns.tableHeaders.prettyName')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 125 : undefined
        },
        {
            field: 'itemExternalId',
            headerName: `${translate('returns.tableHeaders.partref')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, row) => {
                return row?.item.productExternalId;
            },
        },
        {
            field: 'name',
            headerName: `${translate('returns.tableHeaders.partdescription')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            valueGetter: (_, row) => {
                return row?.item.name;
            },
        },
        {
            field: 'partFamily',
            headerName: `${translate('returns.tableHeaders.partFamily')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            sortable: false,
            renderCell: (params) =>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '97%' }}>
                    <Typography variant='subtitle2' >
                        <b>{params.row?.item.family?.code}</b>
                    </Typography>
                    <Tooltip title={params.row?.item.family?.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            {params.row?.item.family?.name}
                        </Typography>
                    </Tooltip>
                </Box>
        },
        {
            field: 'requestType',
            headerName: `${translate('returns.tableHeaders.reason')}`,
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (obj) => {

                const reason = obj.row.requestReason ? translate(`request.selectReason.${getFirstLowerCase(obj.row.requestReason)}`) : "";

                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '97%' }}>
                        <Typography variant='body2' sx={{ wordWrap: 'break-word !important', whiteSpace: 'pre-line !important' }}>
                            {translate(`request.${obj.row.requestType}`)}
                        </Typography>
                        {reason &&
                            <Tooltip title={reason}>
                                <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'} >
                                    {reason}
                                </Typography>
                            </Tooltip>
                        }
                    </Box>
                );
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('commons.createdOn')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj: any) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'quantity',
            headerName: `${translate('returns.tableHeaders.quantity')}`,
            headerAlign: 'center',
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 75 : undefined,
            renderCell: (obj: any) => {
                return (
                    <Typography variant="body2" sx={{ px: (isDesktop ? 3.25 : 1.75) }}>
                        {obj.row?.quantity}
                    </Typography>
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {`${translate(`commons.order.returnRequest.${obj.row.status}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                        vendor={vendor}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, handleLogs, actualRow, vendor]);

    //GROUP C: CARRIER DRIVER
    const COLUMNS_GROUP_C: GridColDef<RequestSearchResult>[] = useMemo(() => [
        {
            field: 'prettyName',
            headerName: `${translate('returns.tableHeaders.prettyName')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 140 : undefined
        },
        {
            field: 'customerName',
            headerName: `${translate('returns.tableHeaders.customerName')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 215 : undefined,
            renderCell: (obj) => {
                return (
                    <Tooltip title={obj.row.customerName}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'} sx={{ width: '97%' }}>
                            {obj.row.customerName}
                        </Typography>
                    </Tooltip>
                );
            }
        },
        {
            field: 'deliveryOrganizationName',
            headerName: `${translate('returns.tableHeaders.delivery')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Tooltip title={obj.row.deliveryOrganizationName}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'} sx={{ width: '97%' }}>
                            {obj.row.deliveryOrganizationName}
                        </Typography>
                    </Tooltip>
                );
            }
        },
        {
            field: 'vendorName',
            headerName: `${translate('returns.tableHeaders.hubName')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 175 : undefined
        },
        {
            field: 'createdOn',
            headerName: `${translate('commons.createdOn')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj: any) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'bigAndHeavy',
            headerName: `${translate('returns.tableHeaders.bigAndHeavy')}`,
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 130 : undefined,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (obj) => {
                return (
                    <BalanceIcon sx={{ ...(!obj.row.bigAndHeavy && { color: theme.palette.grey[400] }) }} />
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 185 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15 || !isDesktop}
                    >
                        {`${translate(`commons.order.returnRequest.${obj.row.status}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                        vendor={vendor}
                    />
                );
            }
        }
    ], [translate, isDesktop, theme.palette.grey, openMenu, handleLogs, actualRow, vendor]);

    const COLUMNS: GridColDef<RequestSearchResult>[] = useMemo(() => {

        if (vendor) {
            return (COLUMNS_GROUP_A);
        }

        if (carrier) {
            return (COLUMNS_GROUP_C);
        }

        return (COLUMNS_GROUP_B);
    }, [vendor, carrier, COLUMNS_GROUP_B, COLUMNS_GROUP_A, COLUMNS_GROUP_C]);
    //---- HANDLE TABLE END ----//

    return (
        <GenericList
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={totalCount}
            list={requestList}
            isLoading={isLoading}
            defaultFilters={DEFAULT_REQUEST_FILTERS}
            filtersInSidebar={filtersInSidebar}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"ReturnRequest"}
            customfieldContext={["ReturnRequestApprover"]}
            quickFilters={quickFilters}
            renderQuickFilters={renderQuickFilters}
            extraSearchFiltersChecks={extraSearchFiltersChecks}
            setActualRow={setActualRow}
            handleCellClick={handleCellClick}
            setFiltersCallback={setFilters}
            filterStatus={filterStatus}
            onChangeFilterStatus={onChangeFilterStatus}
            customSearchFunc={customSearch}
            customSearchStatistics={customStatisticsSearch}
            //----------------
            extraFunctionForRequest={extraFunctionInSearch}
            //----------------
            filtersInUrl={filtersInUrl}
            setFiltersInUrl={setFiltersInUrl}
            listDescription={translate(anomaly ? 'request.anomalies.subtitle' : 'returns.detail')}
            datesGeneralFilter={{
                showDates: true
            }}
            reportFilters={reportFilters}
            isStatusArray
        />
    );
}

//-----------------------------------------------------------------------------

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string) => void,
    object: any,
    currentRow: any,
    vendor: boolean
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, object, currentRow, vendor }: OptionsComponentProps) {

    const { translate } = useLocales();

    const navigate = useNavigate();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    {handleLogs &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorViewLogs, OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerViewLogs]}>
                            <MenuItem
                                onClick={() => {
                                    handleLogs(object.row.returnRequestId);
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("commons.logs")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }
                    {vendor &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView]} absoluteVerification>
                            <MenuItem
                                onClick={() => {
                                    navigate(PATH_DASHBOARD.request.vendor.detail(object.row.returnRequestId.toString()));
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("request.customerView")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }
                </>
            }
        />
    );
}