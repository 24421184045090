import { Card, Typography, Button, Tabs, Tab, Box, useTheme } from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { PATH_DASHBOARD } from "src/routes/paths";
import { bgBlur } from "src/utils/cssStyles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocales } from "src/locales";
import useResponsive from "src/hooks/useResponsive";
import { useLocation, useNavigate, useParams } from "react-router";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import OrganizationGeneralDetail from "./tabs/OrganizationGeneralDetail";
import OrganizationUserDetail from "./tabs/OrganizationUserDetail";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { organizationsOperations } from "src/redux/organizations";
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import GroupIcon from '@mui/icons-material/Group';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import StructureOrganizationDetail from "./tabs/StructureOrganizationDetail";
import BookIcon from '@mui/icons-material/Book';
import { useAuth } from "src/appHooks/useAuth";
import { hasPermissions } from "src/utils/user";
import { ClusterPermissionTypes, OrganizationPermissionTypes } from "src/@types/permissions";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { StyledInfo, StyledRoot } from "src/utils/Detail";
import { brandOperations } from "src/redux/brand";
import { Brand } from "src/@types/brand";
import OrganizationLogs from "../OrganizationLogs";

interface OrganizationDetailProps {
    id?: string,
    tab?: string,
    type?: string,
    requestId?: string,
    isRequest?: boolean,
    isVendor: boolean
}

export default function OrganizationDetail(props: OrganizationDetailProps) {

    const { user } = useAuth();

    const { organization, hub } = useSelector((state: RootState) => state.organizations);

    const { publicBrandList } = useSelector((state: RootState) => state.brand);

    const { organization: userOrganization } = useUserOrganizationContext();

    const { translate, currentLang } = useLocales();

    const theme = useTheme();

    const isDesktop = useResponsive('up', 'lg');

    const { id, tab, type, backTo = "a" } = { ...useParams(), ...props };

    const [currentTab, setCurrentTab] = useState('general');

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const [parsedBrands, setParsedBrand] = useState<Brand[]>([]);

    const backPath = props.isVendor ? PATH_DASHBOARD.organizations.vendor : PATH_DASHBOARD.organizations.customer;

    //---------------------- TABS COMPANY ----------------------//
    const TABS_COMPANY = useMemo(() => [{
        value: 'general',
        label: `${translate('commons.general')}`,
        icon: <AccountBoxIcon />,
        component: organization && <OrganizationGeneralDetail isVendor={props.isVendor} organization={organization} parsedBrands={parsedBrands} />,
    },
    {
        value: 'structure',
        label: `${translate('commons.structure')}`,
        icon: <GroupWorkIcon />,
        component: organization && <StructureOrganizationDetail isVendor={props.isVendor} organization={organization} />
    },
    ...(hasPermissions([ClusterPermissionTypes.UserView], user.roles!) ? [{
        value: 'users',
        label: `${translate('commons.users')}`,
        icon: <GroupIcon />,
        component: (organization && id) && <OrganizationUserDetail organizationId={id} />
    }] : []),
    ...(hasPermissions([OrganizationPermissionTypes.Organization_ViewLogs], userOrganization.roles) && pathname.includes("vendorOrganizations") ? [{
        value: 'logs',
        label: `${translate('commons.logs')}`,
        icon: <BookIcon />,
        component: organization && <OrganizationLogs isVendor={props.isVendor} />
    }] : [])
    ], [translate, organization, props.isVendor, parsedBrands, user.roles, id, userOrganization.roles, pathname]);

    //---------------------- TABS BRANCH ----------------------//
    const TABS_BRANCH = useMemo(() => [{
        value: 'general',
        label: `${translate('commons.general')}`,
        icon: <AccountBoxIcon />,
        component: organization && <OrganizationGeneralDetail isVendor={props.isVendor} organization={organization} parsedBrands={parsedBrands} />,
    },
    ...(hasPermissions([ClusterPermissionTypes.UserView], user.roles!) ? [{
        value: 'users',
        label: `${translate('commons.users')}`,
        icon: <GroupIcon />,
        component: (organization && id) && <OrganizationUserDetail organizationId={id} />
    }] : []),
    ...(hasPermissions([OrganizationPermissionTypes.Organization_ViewLogs], userOrganization.roles) && pathname.includes("vendorOrganizations") ? [{
        value: 'logs',
        label: `${translate('commons.logs')}`,
        icon: <BookIcon />,
        component: organization && <OrganizationLogs isVendor={props.isVendor} />
    }] : [])
    ], [translate, organization, props.isVendor, parsedBrands, user.roles, id, userOrganization.roles, pathname]);

    //---------------------- TABS HUB ----------------------//
    const TABS_HUB = useMemo(() => [{
        value: 'general',
        label: `${translate('commons.general')}`,
        icon: <AccountBoxIcon />,
        component: organization && <OrganizationGeneralDetail isVendor={props.isVendor} organization={organization} parsedBrands={parsedBrands} />,
    },
    {
        value: 'structure',
        label: `${translate('commons.structure')}`,
        icon: <GroupWorkIcon />,
        component: organization && <StructureOrganizationDetail isVendor={props.isVendor} organization={organization} />
    },
    ...(hasPermissions([ClusterPermissionTypes.UserView], user.roles!) ? [{
        value: 'users',
        label: `${translate('commons.users')}`,
        icon: <GroupIcon />,
        component: (organization && id) && <OrganizationUserDetail organizationId={id} />
    }] : []),
    ...(hasPermissions([OrganizationPermissionTypes.Organization_ViewLogs], userOrganization.roles) && pathname.includes("vendorOrganizations") ? [{
        value: 'logs',
        label: `${translate('commons.logs')}`,
        icon: <BookIcon />,
        component: organization && <OrganizationLogs isVendor={props.isVendor} />
    }] : [])
    ], [translate, organization, props.isVendor, parsedBrands, user.roles, id, userOrganization.roles, pathname]);

    //---------------------- TABS HOLDING ----------------------//
    const TABS_HOLDING = useMemo(() => [{
        value: 'general',
        label: `${translate('commons.general')}`,
        icon: <AccountBoxIcon />,
        component: organization && <OrganizationGeneralDetail isVendor={props.isVendor} organization={organization} parsedBrands={parsedBrands} />,
    },
    {
        value: 'structure',
        label: `${translate('commons.structure')}`,
        icon: <GroupWorkIcon />,
        component: organization && <StructureOrganizationDetail isVendor={props.isVendor} organization={organization} />
    },
    ...(hasPermissions([OrganizationPermissionTypes.Organization_ViewLogs], userOrganization.roles) && pathname.includes("vendorOrganizations") ? [{
        value: 'logs',
        label: `${translate('commons.logs')}`,
        icon: <BookIcon />,
        component: organization && <OrganizationLogs isVendor={props.isVendor} />
    }] : [])
    ], [translate, organization, props.isVendor, parsedBrands, userOrganization.roles, pathname]);

    const factoryTabs = {
        holding: TABS_HOLDING,
        company: TABS_COMPANY,
        hub: TABS_HUB,
        branch: TABS_BRANCH
    } as { [key: string]: any };

    const handleEdit = () => {
        navigate(backPath.edit(id || "", type || "company"));
    };

    const onTabChange = (newValue: string) => {
        if (props.id) {
            if (props.isRequest) {
                navigate(PATH_DASHBOARD.request.vendor.organizationBacklogDetailsTab(props.requestId!, "customerInfo", type!, id!, newValue));
            } else {
                navigate(PATH_DASHBOARD.request.vendor.anomalies.organizationDetailsTab(props.requestId!, "customerInfo", type!, id!, newValue, backTo));
            }
        } else {
            navigate(backPath.detailsTab(id || "", newValue, type || "company"));
        }
        setCurrentTab(newValue);
    };

    useEffect(() => {
        setCurrentTab(tab || 'general');
    }, [tab]);

    useEffect(() => {
        if (organization?.hubId && (!hub || organization.hubId !== hub.id)) {
            dispatch(organizationsOperations.getHub(organization.hubId));
        }
    }, [hub, organization, currentLang]);

    useEffect(() => {

        if ((id && type) || (props.id && props.type)) {
            const idToSearch = props.id || id;

            try {
                dispatch(organizationsOperations.getGeneric(idToSearch!)).unwrap();
            } catch {
                navigate('/403');
            }

        }

    }, [id, type, currentLang, props.id, props.type, navigate]);

    const getBrand = useCallback(async () => {
        if (!publicBrandList) {
            await dispatch(brandOperations.getBrandList(false));
        }
    }, [publicBrandList]);

    useEffect(() => {
        getBrand();
        if (publicBrandList !== null && !!organization?.brands) {
            setParsedBrand(publicBrandList?.filter(x => organization.brands?.includes(x.id)) || []);
        }
    }, [publicBrandList, organization, getBrand]);

    return (
        <>
            <Card
                sx={{
                    mb: 3,
                    height: 280,
                    position: 'relative',
                }}
            >
                <StyledRoot
                    sx={{
                        '&:before': {
                            ...bgBlur({ color: type === 'holding' ? theme.palette.warning.lighter : theme.palette.primary.lighter })
                        }
                    }}
                >
                    <StyledInfo>
                        <CustomAvatar
                            name={''}
                            color={type === 'holding' ? "warning" : "primary"}

                            sx={{
                                mx: 'auto',
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderColor: 'common.white',
                                color: "white",
                                width: { xs: 80, md: 128 },
                                height: { xs: 80, md: 128 },
                            }}
                        >
                            {type === 'holding' ? <StoreIcon color="inherit" /> : <BusinessIcon color="inherit" />}
                        </CustomAvatar>

                        <Box
                            sx={{
                                ml: { md: 3 },
                                mt: { xs: 1, md: 0 },
                                color: 'common.white',
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            <Typography variant="overline" lineHeight={"18px"} fontWeight={700} color={"GrayText"}>{type}</Typography>
                            <Typography color={"black"} fontSize={"24px"} variant="h4">{organization?.externalId && `${organization?.externalId}-`}{`${organization?.name}`}</Typography>
                            <Typography variant="body1" color={"GrayText"}>{organization?.address?.address}</Typography>

                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Organization_Edit]}>
                                <Button variant="contained" size="small" sx={{ my: isDesktop ? 2 : 1 }} onClick={handleEdit}>
                                    {`${translate("commons.edit")}`}
                                </Button>
                            </PermissionBasedGuard>
                        </Box>
                    </StyledInfo>
                </StyledRoot>

                <Box
                    sx={{
                        width: 1,
                        //height: "20%",
                        bottom: 0,
                        zIndex: 9,
                        position: 'absolute',
                        bgcolor: 'background.paper',
                        display: "flex !important",
                        justifyContent: "flex-end !important"
                    }}
                >
                    <Tabs
                        value={currentTab}
                        onChange={(_, newValue) => onTabChange(newValue)}
                        sx={{
                            pr: { md: 3 }
                        }}
                    >
                        {factoryTabs[type?.toLowerCase()!].map((tab: typeof TABS_COMPANY[0]) => (
                            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                        ))}
                    </Tabs>
                </Box>

            </Card>

            {factoryTabs[type?.toLowerCase()!].map(
                (tab: typeof TABS_COMPANY[0]) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>
            )}
        </>
    );
}
