import { createAsyncThunk } from "@reduxjs/toolkit";
import { detail, logs, updateCategory, updateInvoice, updateParts, updateSurveillance, updateFrequency, updatePeriod, updateMinimumAmount } from "src/services/ruleService";
import { startLoading } from "./rules-slice";
import { MinimumAmount, Period, Rule, ServiceFeesInDays, SoftRuleEdit, SurveillanceRulesFormResult } from "src/@types/rules";

function formatFeesInDays(feesInDays: ServiceFeesInDays[]) {

    return feesInDays.map((fid, index) => {
        return {
            id: index + "",
            level: index,
            fromInDays: fid.fromInDays,
            toInDays: fid.toInDays,
            fee: fid.fee
        };
    });
}

function formatRuleData(rule: Rule) {

    return {
        ...rule,
        invoice: {
            enabled: rule.invoice.enabled,
            type: rule.invoice.type,
            feesInDays: formatFeesInDays(rule.invoice.feesInDays)
        },
        frequency: {
            enabled: rule.frequency.enabled,
            type: rule.frequency.type,
            feesInDays: formatFeesInDays(rule.frequency.feesInDays)
        },
    };
}

const getRule = createAsyncThunk(
    'rules/getRule',
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await detail();

            if (!data) {
                throw new Error('Something went wrong');
            }

            const formattedData = formatRuleData(data);

            return formattedData;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRuleParts = createAsyncThunk(
    'rules/updateRuleParts',
    async (products: string[], { rejectWithValue }) => {

        try {
            const { data, status } = await updateParts(products);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRuleCategory = createAsyncThunk(
    'rules/updateRuleCategory',
    async (categories: string[], { rejectWithValue, dispatch }) => {

        try {
            const { data, status } = await updateCategory(categories);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRuleInvoice = createAsyncThunk(
    'rules/updateRuleInvoice',
    async (options: SoftRuleEdit, { rejectWithValue, dispatch }) => {

        try {
            const { data, status } = await updateInvoice(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRuleFrequency = createAsyncThunk(
    'rules/updateRuleFrequency',
    async (options: SoftRuleEdit, { rejectWithValue, dispatch }) => {

        try {
            const { data, status } = await updateFrequency(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRuleSurveillance = createAsyncThunk(
    'rules/updateRuleSurveillance',
    async (options: SurveillanceRulesFormResult, { rejectWithValue, dispatch }) => {
        //dispatch(startLoading());
        try {
            const { data, status } = await updateSurveillance(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRulePeriod = createAsyncThunk(
    'rules/updateRulePeriod',
    async (options: Period, { rejectWithValue }) => {
        try {
            const { data, status } = await updatePeriod(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRuleMinimunAmount = createAsyncThunk(
    'rules/updateRuleMinimunAmount',
    async (options: MinimumAmount, { rejectWithValue }) => {
        try {
            const { data, status } = await updateMinimumAmount(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getLogs = createAsyncThunk(
    'rules/getLogs',
    async (options: { filter: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await logs(options.filter);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const rulesOperations = {
    getRule,
    getLogs,
    updateRuleSurveillance,
    updateRuleInvoice,
    updateRuleFrequency,
    updateRuleCategory,
    updateRuleParts,
    updateRuleMinimunAmount,
    updateRulePeriod
};

export default rulesOperations;