import { Box, Button, Card, Container, Divider, Grid, Step, StepLabel, Stepper } from "@mui/material";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from "src/routes/paths";
import useResponsive from "src/hooks/useResponsive";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import SaveModal from "src/components/modals/SaveModal";
import OrganizationEditForm from "src/sections/@dashboard/organizations/OrganizationEditForm";
import { DEFAULT_GENERIC_ORGANIZATION, GenericOrganization, GenericOrganizationEditWithFather } from "src/@types/organizations";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { organizationsOperations } from "src/redux/organizations";
import LoadingScreen from "src/appComponents/loading-screen";
import { resetOrganization } from "src/redux/organizations/organizations-slices";
import { defaultOrganization, globalSchema } from "src/sections/@dashboard/organizations/Constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { brandOperations } from "src/redux/brand";
import useTenant from "src/appHooks/useTenant";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

const DEFAULT_STEPS = ['organizationInfo', 'address', 'contact', 'payment', 'others', 'brand'];

export default function NewEditOrganization() {

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { isLoading: isLoading, organizationEdit, nonEditables } = useSelector((state: RootState) => state.organizations);

    const { publicBrandList } = useSelector((state: RootState) => state.brand);

    const getBrands = async () => {
        await dispatch(brandOperations.getBrandList(false));
    };

    useEffect(() => {
        getBrands();
    }, [currentLang]);

    /* HANDLING IF EDIT START */
    const { pathname } = useLocation();

    const isEdit = pathname.includes('edit');

    const { id = '', type = '' } = useParams();

    const organizationType = type.charAt(0).toUpperCase() + type.slice(1);

    useEffect(() => {
        if (id) {
            if (!nonEditables.length) {
                dispatch(organizationsOperations.getNonEditables());
            }
            dispatch(organizationsOperations.getDetailForEdit(id));
        }
        if (!isEdit) {
            dispatch(resetOrganization());
        }
    }, [id, isEdit, currentLang]);

    /* HANDLING IF EDIT END */

    /* HANDLING MODALS START */
    const [isOpenSave, setIsOpenSave] = useState(false);

    const toggleSave = () => {
        isOpenSave ? setIsOpenSave(false) : setIsOpenSave(true);
    };
    /* HANDLING MODALS END */

    /* HANDLING FORM START */
    const [step, setStep] = useState<number>(1);

    useEffect(() => {
        if (!isEdit) {
            setStep(1);
        }
    }, [isEdit]);

    const formContainerRef = useRef<{
        getValues: () => GenericOrganizationEditWithFather,
        validateStep1: () => boolean,
        validateStep2: () => boolean,
        validateStep3: () => boolean,
        validateStep4: () => boolean,
        onSave: () => GenericOrganizationEditWithFather,
    }>();

    const getSelectedBrands = () => {
        if (publicBrandList && organizationEdit && organizationEdit.brands) {
            return publicBrandList.filter(brand => organizationEdit.brands.includes(brand.id));
        }

        return [];
    };

    const [isLoadingTenant, setIsLoadingTenant] = useState<boolean>(true);

    const { customFields } = useTenant();

    useEffect(() => {
        if (customFields) {
            setIsLoadingTenant(false);
        }
    }, [customFields]);

    const customContext = useMemo(() => {
        if (customFields) {
            return [...customFields.filter(x => x.enabled && x.entityType.toLowerCase() === "organization")];
        }
    }, [customFields]);

    const getDefaultCustomFields = () => {
        if (customContext) {
            const customWithDef = customContext.filter(x => {
                if (x.type === "Select") {
                    return !!x.selectOptions?.find(f => f.isDefault);
                } else return !!x.defaultValue;
            });

            if (customWithDef && customWithDef.length >= 1) {
                const output = {};

                customWithDef.forEach(x => {
                    if (x.type === "Select") {
                        const def = x.selectOptions?.find(f => f.isDefault);

                        Object.assign(output, { [x.id]: def?.values[0].text });
                    } else {
                        Object.assign(output, { [x.id]: x.defaultValue });
                    }
                });

                return output;
            }
        }

        return null;
    };

    const defaultCustomFields = useMemo(() => getDefaultCustomFields(), [customContext]);

    const defaultValues = useMemo(() => {

        const org = defaultOrganization({
            ...organizationEdit,
            type: organizationType,
            selectedBrands: getSelectedBrands(),
            customFields: (isEdit ? organizationEdit?.customFields : defaultCustomFields) || {},
            parentId: organizationEdit?.parentId || ""
        } as GenericOrganizationEditWithFather,
            isEdit ? (organizationEdit?.parentId ? {
                id: organizationEdit?.parentId,
                type: organizationEdit?.parentType,
                name: organizationEdit?.parentName
            } as GenericOrganization : DEFAULT_GENERIC_ORGANIZATION) : DEFAULT_GENERIC_ORGANIZATION
        );

        return org;

    }, [organizationEdit, defaultCustomFields]);

    const yupSchema = useMemo(() => globalSchema, []);

    const methods = useForm<GenericOrganizationEditWithFather>({
        mode: "onChange",
        resolver: yupResolver<any>(yupSchema),
        defaultValues
    });

    const { reset, getValues } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const [onEditForm, setOnEditForm] = useState<boolean>(false);

    const [noEdit, setNoEdit] = useState<boolean>(true);

    const [errorInCustom, setErrorInCustom] = useState<boolean>(false);

    const handleEditForm = (value: boolean) => {
        setOnEditForm(value);
        if (noEdit) {
            setNoEdit(false);
        }
    };

    const checkValidity = async () => {
        let validate: boolean = false;

        if (!errorInCustom) {
            switch (step) {
                case 1: {
                    validate = await formContainerRef?.current?.validateStep1() || false;
                    break;
                }
                case 2: {
                    validate = await formContainerRef?.current?.validateStep2() || false;
                    break;
                }
                case 3: {
                    validate = await formContainerRef?.current?.validateStep3() || false;
                    break;
                }
                case 4: {
                    validate = await formContainerRef?.current?.validateStep4() || false;
                    break;
                }
            }
        }

        if (isEdit && noEdit) {
            setIsFormValid(true);
        } else if (!onEditForm && validate) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    useEffect(() => {
        if (step === 1 || step === 2 || step === 3 || step === 4) {
            checkValidity();
        }
    }, [step]);

    useEffect(() => {
        checkValidity();
    }, [onEditForm, errorInCustom]);

    /* HANDLING FORM END */

    /* HANDLING STEPS START */
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const [showBrand, setShowBrand] = useState(false);

    const STEP = useMemo(() => {
        if (!showBrand) {
            const outputArray = [...DEFAULT_STEPS];

            outputArray.pop();

            return outputArray;
        }

        return DEFAULT_STEPS;
    }, [showBrand]);

    const handleNextStep = async () => {
        if (isFormValid) {
            setStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handlePreviousStep = () => {
        setStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChangeStep = (newStep: number) => {
        if (isFormValid) {
            setStep(newStep + 1);
        }
    };
    /* HANDLING STEPS END */

    /* HANDLING SAVE START */
    const getFinalValues = (values: GenericOrganizationEditWithFather) => {
        const { selectedBrands, parentType, parent, note, ...final } = { ...values };

        const newNotes = note.filter((note) => note.text);

        return { ...final, note: newNotes, parentId: parent!.id };
    };

    const customFieldRemover = (values: GenericOrganizationEditWithFather) => {

        if (customFields) {

            const customInContext = customFields.filter(x => x.enabled && x.entityType.toLowerCase() === "organization");

            const customActualValues = getValues("customFields") || {};

            customInContext.forEach(customField => {
                if (customField.defaultValue && !customActualValues[customField.id]) {
                    customActualValues[customField.id] = customField.defaultValue;
                }
                delete values[customField.id];
            });
        }

        return values;
    };

    const handleSaveOrganization = async () => {

        toggleSave();

        if (isFormValid) {

            const res = await formContainerRef?.current?.onSave();

            const clean = customFieldRemover(res!);

            if (res) {
                let finalValues = getFinalValues({ ...organizationEdit, ...clean });

                if (isEdit) {
                    await dispatch(organizationsOperations.updateGeneric(finalValues)).unwrap();
                } else {
                    await dispatch(organizationsOperations.createGeneric(finalValues)).unwrap();
                }

            }

            dispatch(setSuccessMessage({ text: translate('organization.messages.save'), returnTo: PATH_DASHBOARD.organizations.vendor.list }));
        }
    };
    /* HANDLING SAVE END */

    const navigate = useNavigate();

    const onQuit = () => {
        navigate(PATH_DASHBOARD.organizations.vendor.list);
    };

    const getOrganizationFormValues = async (data: GenericOrganization) => {
    };

    return (
        <Page title={`${translate(`organization.form.title.${isEdit ? "edit" : "new"}`)}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                {isLoading || isLoadingTenant ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <HeaderBreadcrumbs
                            heading={`${translate(`organization.form.title.${isEdit ? "edit" : "new"}`)}`}
                            links={[
                                { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                                { name: `${translate('organization.title')}`, href: PATH_DASHBOARD.organizations.vendor.list },
                                { name: `${translate(`organization.form.title.${isEdit ? "edit" : "new"}`)}` }
                            ]}
                        />

                        <SaveModal
                            toggle={toggleSave}
                            isOpen={isOpenSave}
                            handleSave={handleSaveOrganization}
                            saveText={`${translate('organization.messages.saveConfirm')}`}
                        />

                        <Stepper sx={{ mb: 2, px: isDesktop ? 5 : 2 }} activeStep={step - 1} alternativeLabel>
                            {
                                STEP!.map((step, ind) =>
                                    <Step key={ind}>
                                        <StepLabel onClick={() => handleChangeStep(ind)}>{isDesktop ? `${translate(`organization.form.steps.${step}`)}` : ''}</StepLabel>
                                    </Step>)
                            }
                        </Stepper>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Card sx={{ mt: 2 }}>

                                    <OrganizationEditForm
                                        outerRef={formContainerRef}
                                        isEdit={isEdit}
                                        hasType={organizationType}
                                        organization={organizationEdit}
                                        step={step}
                                        onSubmitFn={getOrganizationFormValues}
                                        onEditForm={onEditForm}
                                        setOnEditForm={handleEditForm}
                                        setShowBrand={setShowBrand}
                                        showBrand={showBrand}
                                        formMethods={methods}
                                        setErrorInCustom={setErrorInCustom}
                                        customContext={customContext}
                                    />

                                    <Divider />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            px: isDesktop ? 5 : 2,
                                            py: isDesktop ? 3 : 1,
                                            gap: 1,
                                            flexDirection: isDesktop ? 'row' : 'column-reverse'
                                        }}
                                    >
                                        <Button
                                            variant="soft"
                                            size={isDesktop ? "small" : 'large'}
                                            onClick={onQuit}
                                        >
                                            {`${translate("organization.detail.backToOrganizations")}`}
                                        </Button>

                                        <Box sx={{ ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'defaul' : 'column-reverse' }}>

                                            {step > 1 && (
                                                <Button
                                                    variant="contained"
                                                    size={isDesktop ? "small" : 'large'}
                                                    onClick={handlePreviousStep}
                                                >
                                                    {`${translate("commons.previous")}`}
                                                </Button>
                                            )}

                                            {(step <= 4 || (step === 5 && showBrand)) && (
                                                <Button
                                                    variant="contained"
                                                    size={isDesktop ? "small" : 'large'}
                                                    onClick={handleNextStep}
                                                    disabled={!isFormValid}
                                                >
                                                    {`${translate("commons.next")}`}
                                                </Button>
                                            )}

                                            <Button
                                                variant="contained"
                                                size={isDesktop ? "small" : 'large'}
                                                onClick={toggleSave}
                                                sx={{ ml: 1 }}
                                                disabled={!isFormValid}
                                            >
                                                {`${translate("organization.form.saveButton")}`}
                                            </Button>

                                        </Box>
                                    </Box>

                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )}

            </Container>
        </Page>
    );
}