import { Box, Button, Card, Container, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import WarehouseRequestConditions from "./steps/WarehouseRequestConditions";
import useResponsive from "src/hooks/useResponsive";
import { useNavigate, useParams } from "react-router";
import { RootState, dispatch } from "src/redux/store";
import { requestOperations } from "src/redux/request";
import { useSelector } from "react-redux";
import DriverBarcodeScan from "../driver/steps/DriverBarcodeScan";

export default function WarehouseConfirmRequest() {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const { id } = useParams();

    const { request } = useSelector((state: RootState) => state.request);

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const [currentStep, setCurrentStep] = useState(() => {
        if (id)
            return 1;

        return 0;
    });

    const { themeStretch } = useSettingsContext();

    useEffect(() => {
        if (id && request?.id !== id)
            dispatch(requestOperations.getRequest({ id: id }));
    }, [id, request]);

    return (
        <Page title={`${translate("request.request")}: ${translate("request.warehouseManage")}`}>

            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '23vw' : '63vw',
                    p: isDesktop ? 5 : 2,
                }}>
                    <Box sx={{ textAlign: "center" }}>

                        <Box component="img" src="/assets/illustrations/illustration_docs.svg" sx={{ width: "45%", m: "auto" }} />

                        <Typography variant="h6" sx={{ mt: 3 }}>
                            {`${translate('request.messages.congrats')}`}
                        </Typography>

                        <Typography variant='body1'>
                            {`${translate('request.messages.requestSentSuccess')}`}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', justifyContent: 'center', gap: 2 }}>
                            <Button
                                variant="contained"
                                size={isDesktop ? "small" : 'large'}
                                onClick={() => {
                                    setCurrentStep(0);
                                    setIsOpen(false);
                                }}
                                sx={{ mt: 3, borderRadius: "50px !important", width: 'fit-content', mx: 'auto' }}
                            >
                                {`${translate('request.messages.nextScan')}`}
                            </Button>
                            <Button variant="text" size={isDesktop ? "small" : 'large'} onClick={() => navigate(PATH_DASHBOARD.request.vendor.list)} sx={{ mt: 3, mx: 'auto', borderRadius: "50px !important", width: 'fit-content' }}>
                                {`${translate('commons.toList')}`}
                            </Button>
                        </Box>
                    </Box>
                </Card>
            </Modal>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.request.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.request.title')}`, href: PATH_DASHBOARD.request.vendor.list },
                        { name: `${translate("request.warehouseManage")}` }
                    ]} />

                <Box>
                    {
                        currentStep === 0 && <DriverBarcodeScan customer={false} carrier={false} />
                    }
                    {
                        currentStep === 1 && <WarehouseRequestConditions
                            handlePreviousStep={() => id && navigate(PATH_DASHBOARD.request.vendor.backlogDetail(id))}
                            type={request?.requestType || ""}
                            setOpen={() => setIsOpen(true)}
                            request={request}
                        />
                    }
                </Box>
            </Container>
        </Page>);
}