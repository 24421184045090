import { Box, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { SidebarListFilters, ToolbarListFilters } from 'src/@types/list';
import { ShopBundleFilters, DEFAULT_SHOP_BUNDLE_FILTERS, ShopBundleSearchResult, BundleAvailabilityStatusesArr } from 'src/@types/webshop';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { webshopOperations } from 'src/redux/webshop';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import Label from 'src/components/label';
import { getFirstLowerCase } from 'src/appUtils/string';
import { basketOperations } from 'src/redux/basket';
import { getQuantityInBasket } from 'src/utils/basket';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import SingleProductGridElement from './SingleProductGridElement';
import AddToCartButton from './AddToCartButton';
import CartAvailabilityIncrementer from './CartAvailabilityIncrementer';
import ScoreProgressIcon from './ScoreProgressIcon';
import { getProductStatusColor } from './utils';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { convertStringArrayToSelectOptions } from 'src/utils/list/utils/functions';
import { getPrice } from 'src/utils/currency';

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

interface ProductsListDetailProps {
    isPublic?: boolean
}

export default function ProductsList({ isPublic }: ProductsListDetailProps) {

    const { translate } = useLocales();

    const { organization } = useUserOrganizationContext();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { id: webshopId } = useParams();

    const { isBundleLoading: isProductLoading, bundleList: productList, bundleTotalCount: productTotalCount } = useSelector((state: RootState) => state.webshop);

    const { basket } = useSelector((state: RootState) => state.basket);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: ShopBundleFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_SHOP_BUNDLE_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: { filters: ShopBundleFilters & { sequenceToken: any }, check: boolean }) => {
        if (webshopId) dispatch(webshopOperations.searchShopBundles({ ...filters, shopId: webshopId, isPublic: isPublic }));
    }, [isPublic, webshopId]);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() =>
        [
            { key: 'name', label: translate('webshop.detail.products.name') },
            { key: 'externalId', label: translate('webshop.detail.products.externalId') },
            { key: 'familySupplier', label: translate('webshop.detail.products.familySupplier') },
        ], [translate]);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "name",
            label: translate(`webshop.detail.products.name`),
            type: "TextField"
        },
        {
            name: "externalId",
            label: translate(`webshop.detail.products.externalId`),
            type: "TextField"
        },
        {
            name: "familySupplier",
            label: translate(`webshop.detail.products.familySupplier`),
            type: "TextField"
        },
        {
            name: "availabilityStatus",
            label: translate(`webshop.form.step.stockAvailability`),
            type: "Select",
            options: convertStringArrayToSelectOptions(BundleAvailabilityStatusesArr, translate, "statuses.")
        },
        /*         {
                    name: "minAvailableQuantity",
                    label: translate(`------`),
                    type: "TextField"
                }, */
        {
            name: "price",
            label: translate(`webshop.detail.products.grossAmount`),
            type: "InputRange"
        }
    ], [translate]);

    //---- HANDLE TABLE START ----//
    const [actualItem, setActualItem] = useState<GridCellParams<ShopBundleSearchResult> | null>(null);

    const [actualCardItem, setActualCardItem] = useState<ShopBundleSearchResult | null>(null);

    const [openCartMenu, setOpenCartMenu] = useState<HTMLElement | null>(null);

    const hasPermissionToBuy = hasPermissions([OrganizationPermissionTypes.WebShop_Buy], organization.roles) && isPublic;

    const handleOpenCartMenu = (event: React.MouseEvent<HTMLElement>) => setOpenCartMenu(event.currentTarget);

    const handleCloseCartMenu = () => setOpenCartMenu(null);

    const handleCellClick = (params: GridCellParams<ShopBundleSearchResult>) => {

        setActualItem(params);

        if (params.field !== "addToCart" && params.field !== "availability.stockQuantity" && hasPermissionToBuy) {
            navigate(PATH_DASHBOARD.webshopPublic.productDetail(webshopId ?? "", params.row.bundleId));
        }
    };

    const [quantitiesRecord, setQuantitiesRecord] = useState<Record<string, number>>({});

    const [addedQuantitiesRecord, setAddedQuantitiesRecord] = useState<Record<string, number>>({});

    const handleAddQuantity = useCallback((bundleId: string) => ({
        ...quantitiesRecord,
        [bundleId]: 1
    }), [quantitiesRecord]);

    const handleRemoveQuantity = useCallback((bundleId: string) => {
        const updatedRecord = cloneDeep(quantitiesRecord);

        delete updatedRecord[bundleId];

        return updatedRecord;
    }, [quantitiesRecord]);

    const handleChangeQuantity = useCallback((bundleId: string, action: "increase" | "decrease") => {
        setQuantitiesRecord((prev) => {
            const updatedRecord = { ...prev };

            if (action === "increase") {
                if (updatedRecord[bundleId]) updatedRecord[bundleId] += 1;
                else return handleAddQuantity(bundleId);
            } else {
                updatedRecord[bundleId] -= 1;
                if (!updatedRecord[bundleId]) return handleRemoveQuantity(bundleId);
            }

            return updatedRecord;
        });
    }, [handleAddQuantity, handleRemoveQuantity]);

    const handleAddToCart = useCallback(async (bundleId: string, quantity: number) => {
        try {
            await dispatch(basketOperations.addItemPublicBasket({
                bundleId,
                shopId: webshopId!,
                quantity
            })).unwrap();

            if (openCartMenu) {
                setTimeout(() => {
                    handleCloseCartMenu();
                }, 1500);
            }

            setQuantitiesRecord(handleRemoveQuantity(bundleId));

            setAddedQuantitiesRecord((prev) => ({
                ...prev,
                [bundleId]: quantity
            }));

        } catch (e) {
            console.error(e);
        }
    }, [handleRemoveQuantity, webshopId]);

    const getQuantityInBasketPrefilled = useCallback((bundleId: string) => getQuantityInBasket(basket, webshopId!, bundleId), [basket, webshopId]);

    const COLUMNS: GridColDef<ShopBundleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            display: "text",
            renderCell: (obj) => {

                const familyCode = obj.row.items[0].family ? "[" + obj.row.items[0].family.supplier.code + "]" : "";

                const familyDescription = obj.row.items[0].family ? (" - " + obj.row.items[0].family.supplier.description) : "";

                const familyComponent =
                    <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1.5 }}>
                        <Typography variant='body2' noWrap sx={{ mb: -0.5 }}>
                            {obj.row.items[0].name}
                        </Typography>
                        {!!familyCode &&
                            <Typography variant='body2' noWrap>
                                <b>{familyCode}</b>{familyDescription}
                            </Typography>
                        }
                    </Box>;

                return (
                    <Box sx={{ display: 'grid', gridTemplateColumns: '20% 80%', alignItems: "center", width: '100%' }}>

                        {obj.row.items[0].media[0] ?
                            <img
                                alt={""}
                                src={obj.row.items[0].media[0]?.fileInfo[0]?.url ?? ""}
                                style={{
                                    borderRadius: "15px",
                                    width: "45px",
                                    height: "45px",
                                    aspectRatio: "1",
                                    objectFit: "cover",
                                    border: "1px ridge"
                                }}
                            />
                            :
                            <Box
                                sx={{
                                    borderRadius: "15px",
                                    width: "45px",
                                    height: "45px",
                                    border: "1px ridge",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: (theme) => theme.palette.divider
                                }}
                            >
                                <NoPhotographyIcon sx={{ width: "30px", height: "30px" }} />
                            </Box>

                        }

                        <NoMaxWidthTooltip title={familyComponent}>
                            {familyComponent}
                        </NoMaxWidthTooltip>
                    </Box>
                );
            }
        },
        {
            field: 'externalId',
            headerName: translate('webshop.detail.products.externalId'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'grossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => <CurrencyAdapter value={obj.row.price.grossAmount} />
        },
        {
            field: 'unitDiscountedAmount',
            headerName: translate('webshop.detail.products.discountedAmount'),
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => <CurrencyAdapter value={obj.row.price.unitDiscountedAmount} />
        },
        {
            field: 'availability.status',
            headerName: translate('webshop.form.step.stockAvailability'),
            flex: isDesktop ? 0.55 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Label
                        color={getProductStatusColor(obj.row.availability.status)}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.${getFirstLowerCase(obj.row.availability.status)}`)}
                    </Label>
                );
            }
        },
        {
            field: 'availability.stockQuantity',
            headerName: translate('spareParts.list.tableHeaders.stockQuantity'),
            flex: isDesktop ? 0.4 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            valueGetter: (_, row) => row.availability.stockQuantity ?? "—"
        },
        {
            field: 'score',
            headerName: translate('webshop.detail.products.score'),
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => <ScoreProgressIcon progress={obj.row.score ?? 0} />
        }
    ], [translate, isDesktop]);

    const PUBLIC_COLUMNS: GridColDef<ShopBundleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {

                const familyCode = obj.row.items[0].family ? "[" + obj.row.items[0].family.supplier.code + "]" : "";

                const familyDescription = obj.row.items[0].family ? (" - " + obj.row.items[0].family.supplier.description) : "";

                const familyComponent =
                    <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1.5 }}>
                        <Typography variant='body2' noWrap sx={{ mb: -0.5 }}>
                            {obj.row.items[0].name}
                        </Typography>
                        {!!familyCode &&
                            <Typography variant='body2' noWrap>
                                <b>{familyCode}</b>{familyDescription}
                            </Typography>
                        }
                    </Box>;

                return (
                    <Box sx={{ display: 'grid', gridTemplateColumns: '20% 80%', alignItems: "center", width: '100%' }}>

                        {obj.row.items[0].media[0] ?
                            <img
                                alt={""}
                                src={obj.row.items[0].media[0]?.fileInfo[0]?.url ?? ""}
                                style={{
                                    borderRadius: "15px",
                                    width: "45px",
                                    height: "45px",
                                    aspectRatio: "1",
                                    objectFit: "cover",
                                    border: "1px ridge"
                                }}
                            />
                            :
                            <Box
                                sx={{
                                    borderRadius: "15px",
                                    width: "45px",
                                    height: "45px",
                                    border: "1px ridge",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: (theme) => theme.palette.divider
                                }}
                            >
                                <NoPhotographyIcon sx={{ width: "30px", height: "30px" }} />
                            </Box>

                        }

                        <NoMaxWidthTooltip title={familyComponent}>
                            {familyComponent}
                        </NoMaxWidthTooltip>
                    </Box>
                );
            }
        },
        {
            field: 'externalId',
            headerName: translate('webshop.detail.products.externalId'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'grossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => <CurrencyAdapter value={obj.row.price.grossAmount} />
        },
        {
            field: 'unitDiscountedAmount',
            headerName: translate('webshop.detail.products.discountedAmount'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {

                return (!!obj.row.price.unitDiscountedAmount &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>

                        <CurrencyAdapter value={obj.row.price.unitDiscountedAmount} />

                        {!!obj.row.price.totalDiscountPercentage &&
                            <Typography
                                variant={'caption'}
                                color={'text.secondary'}
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                //textAlign={'end'}
                                sx={{ mt: -0.5 }}
                            >
                                {"- " + getPrice(obj.row.price.totalDiscountPercentage) + "%"}
                            </Typography>
                        }
                    </Box>
                );
            }
        },
        {
            field: 'additionalDiscount',
            headerName: translate('webshop.detail.products.additionalDiscount'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={obj.row.price.additionalDiscountAmount}

                    />
                );
            }
        },
        {
            field: 'availability.status',
            headerName: translate('webshop.form.step.stockAvailability'),
            flex: isDesktop ? 0.55 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Label
                        color={getProductStatusColor(obj.row.availability.status)}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.${getFirstLowerCase(obj.row.availability.status)}`)}
                    </Label>
                );
            }
        },
        ...((hasPermissions([OrganizationPermissionTypes.WebShop_Buy], organization.roles)) ? [
            {
                field: 'availability.stockQuantity',
                headerName: translate('commons.quantity'),
                flex: isDesktop ? 0.8 : undefined,
                minWidth: !isDesktop ? 250 : undefined,
                renderCell: (obj) => {
                    return (
                        <CartAvailabilityIncrementer
                            translate={translate}
                            bundleId={obj.row.bundleId}
                            availability={obj.row.availability}
                            quantitiesRecord={quantitiesRecord}
                            handleChangeQuantity={handleChangeQuantity}
                            getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                        />
                    );
                }
            },
            {
                field: 'addToCart',
                headerName: "",
                flex: isDesktop ? 0.5 : undefined,
                minWidth: !isDesktop ? 250 : undefined,
                sortable: false,
                align: "right",
                renderCell: (obj) => {

                    const quantity: number = quantitiesRecord[obj.row.bundleId] ?? 0;

                    const addedQuantityToCart: number = addedQuantitiesRecord[obj.row.bundleId] ?? 0;

                    return (
                        <AddToCartButton
                            addedQuantityToCart={addedQuantityToCart}
                            bundleId={obj.row.bundleId}
                            handleAddToCart={handleAddToCart}
                            quantity={quantity}
                            translate={translate}
                        />
                    );
                }
            }] : []) as GridColDef<ShopBundleSearchResult>[]
    ], [translate, isDesktop, organization.roles, quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled, addedQuantitiesRecord, handleAddToCart]);
    // ----HANDLE TABLE END---- //

    return (
        <InfiniteScrollGenericList
            list={productList.map((bundle) => ({ ...bundle, id: bundle.bundleId, ...bundle.items[0] }))}
            isLoading={isProductLoading}
            totalCount={productTotalCount}
            defaultFilters={DEFAULT_SHOP_BUNDLE_FILTERS}
            toolbarFiltersList={toolbarFiltersList}
            filtersInSidebar={filtersInSidebar}
            datagridColumns={isPublic ? PUBLIC_COLUMNS : COLUMNS}
            handleCellClick={isPublic ? handleCellClick : undefined}
            updateCheckField={updateCheckField}
            context={"WebshopProduct"}
            customfieldContext={["SpareParts"]}
            setActualRow={setActualItem}
            customSearchFunc={customSearch}
            resetList={() => { }}
            showVatAdvice
            multipleView={{
                renderGridElement: SingleProductGridElement,
                extraProps: {
                    quantitiesRecord,
                    addedQuantitiesRecord,
                    handleChangeQuantity,
                    handleAddToCart,
                    getQuantityInBasketPrefilled,
                    webshopId,
                    hasPermissionToBuy
                },
                menuOptions: {
                    actualCardItem,
                    setActualCardItem,
                    openMenu: openCartMenu,
                    handleOpenMenu: handleOpenCartMenu,
                    handleCloseMenu: handleCloseCartMenu
                }
            }}
        />
    );
}