import { createAsyncThunk } from "@reduxjs/toolkit";
import * as discountMatrixServices from "src/services/discountMatrixServices";
import { DiscountMatrixFilters, DiscountMatrixRowsFilters, EnableDisableDiscountMatrixProps } from "src/@types/discountMatrix";
import { enableDisableDiscountMatrixSL, renameDiscountMatrixSL, startLoading, startRowsLoading } from "./discountMatrix-slices";

const searchDiscountMatrixes = createAsyncThunk(
    'discountMatrix/searchDiscountMatrixes',
    async (options: { filters: DiscountMatrixFilters, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check } = options;

        dispatch(startLoading(check));
        try {
            const { data } = await discountMatrixServices.searchDiscountMatrixes(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchDiscountMatrixRows = createAsyncThunk(
    'discountMatrix/searchDiscountMatrixRows',
    async (options: { filters: DiscountMatrixRowsFilters, check: boolean, id: string }, { dispatch, rejectWithValue }) => {

        const { filters, check, id } = options;

        dispatch(startRowsLoading(check));
        try {
            const { data } = await discountMatrixServices.searchDiscountMatrixRows(filters, id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const enableDisableDiscountMatrix = createAsyncThunk(
    'discountMatrix/enableDisableDiscountMatrix',
    async (options: EnableDisableDiscountMatrixProps, { dispatch, rejectWithValue }) => {

        try {

            const { data, status } = options.action === 'enable' ?
                await discountMatrixServices.enable(options.id) :
                await discountMatrixServices.disable(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableDiscountMatrixSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const renameDiscountMatrix = createAsyncThunk(
    'discountMatrix/renameDiscountMatrix',
    async (options: { id: string, name: string }, { dispatch, rejectWithValue }) => {

        try {

            const { data, status } = await discountMatrixServices.rename(options.id, options.name);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(renameDiscountMatrixSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const discountMatrixOperations = {
    searchDiscountMatrixes,
    searchDiscountMatrixRows,
    enableDisableDiscountMatrix,
    renameDiscountMatrix
};

export default discountMatrixOperations;