import { Container } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useSettingsContext } from 'src/components/settings';
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useCallback, useMemo } from 'react';
import { useLocales } from 'src/locales';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import useResponsive from 'src/hooks/useResponsive';
import { discountMatrixOperations } from 'src/redux/discountMatrix';
import { DEFAULT_DISCOUNT_MATRIX_ROWS_FILTERS, DiscountMatrixRowsFilters, DiscountMatrixRowsSearchResult } from 'src/@types/discountMatrix';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { isEqual } from 'lodash';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getFirstLowerCase } from 'src/appUtils/string';
import { SidebarListFilters } from 'src/@types/list';

export default function DiscountMatrixDetail() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { id } = useParams();

    const { discountMatrixRowsList, areRowsLoading, rowsTotalCount } = useSelector((state: RootState) => state.discountMatrix);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: DiscountMatrixRowsFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_DISCOUNT_MATRIX_ROWS_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: { filters: DiscountMatrixRowsFilters & { sequenceToken: string | null }, check: boolean }) => {
        if (id)
            dispatch(discountMatrixOperations.searchDiscountMatrixRows(
                {
                    ...filters,
                    filters: {
                        ...filters.filters,
                        sortField: filters.filters.sortField !== "createdOn" ? filters.filters.sortField : 'discountScaleCode',
                    },
                    id
                }));
    }, [id]);

    const COLUMNS: GridColDef<DiscountMatrixRowsSearchResult>[] = [
        {
            field: 'discountScaleCode',
            headerName: translate("discountMatrix.detail.columns.discountScaleCode"),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'discountScaleDesignation',
            headerName: translate('discountMatrix.detail.columns.discountScaleDesignation'),
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            sortable: false
        },
        {
            field: 'familySupplierCode',
            headerName: translate('discountMatrix.detail.columns.familySupplierCode'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            sortable: false,
            valueGetter: (_, row) => row.customerCategoryDescription || "—"
        },
        {
            field: 'customerCategoryDescription',
            headerName: translate('discountMatrix.detail.columns.customerCategoryDescription'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            sortable: false,
            valueGetter: (_, row) => row.customerCategoryDescription || "—"
        },
        {
            field: 'discountType',
            headerName: translate('discountMatrix.detail.columns.discountType'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            valueGetter: (_, row) => translate(`discountMatrix.detail.discountType.${getFirstLowerCase(row.discountType)}`)
        },
        {
            field: 'orderPriority',
            headerName: translate('discountMatrix.detail.columns.orderPriority'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            valueGetter: (_, row) => translate(`discountMatrix.detail.orderPriority.${getFirstLowerCase(row.orderPriority)}`)
        },
        {
            field: 'saleDiscountCode',
            headerName: translate('discountMatrix.detail.columns.saleDiscountCode'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,

        },
        {
            field: 'regularDiscountPercentage',
            headerName: translate('discountMatrix.detail.columns.regularDiscountPercentage'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            sortable: false,
            valueGetter: (_, row) => row.regularDiscountPercentage + "%"
        },
        {
            field: 'additionalDiscountPercentage',
            headerName: translate('discountMatrix.detail.columns.additionalDiscountPercentage'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            sortable: false,
            valueGetter: (_, row) => row.additionalDiscountPercentage + "%"
        },
    ];

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "customerExternalId",
            label: translate("discountMatrix.detail.columns.customerExternalId"),
            type: "TextField"
        },
        {
            name: "discountScaleCode",
            label: translate("discountMatrix.detail.columns.discountScaleCode"),
            type: "TextField"
        },
        {
            name: "customerCategoryCode",
            label: translate("discountMatrix.detail.columns.discountScaleDesignation"),
            type: "TextField"
        },
        {
            name: "saleDiscountCode",
            label: translate("discountMatrix.detail.columns.saleDiscountCode"),
            type: "TextField"
        },
        {
            name: "familySupplierCode",
            label: translate("discountMatrix.detail.columns.familySupplierCode"),
            type: "TextField"
        },
        {
            name: "orderPriority",
            label: translate("discountMatrix.detail.columns.orderPriority"),
            type: "TextField"
        }
    ], [translate]);

    return (
        <Page title={translate('discountMatrix.detail.title')}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate('discountMatrix.detail.title')}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate('discountMatrix.title'), href: PATH_DASHBOARD.discountMatrix.list },
                        { name: translate('discountMatrix.rows') }
                    ]}
                />

                <InfiniteScrollGenericList
                    list={discountMatrixRowsList}
                    context='DiscountMatrixDetail'
                    isLoading={areRowsLoading}
                    datagridColumns={COLUMNS}
                    defaultFilters={DEFAULT_DISCOUNT_MATRIX_ROWS_FILTERS}
                    totalCount={rowsTotalCount}
                    filtersInSidebar={filtersInSidebar}
                    resetList={() => { }}
                    customSearchFunc={customSearch}
                    setActualRow={() => { }}
                    updateCheckField={updateCheckField} />

            </Container>
        </Page>
    );
}