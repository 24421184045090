import { Box, Card, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CurrencyTypes } from "src/@types/vehicle";
import { Level } from "src/@types/webshop";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import useCurrency from "src/appHooks/useCurrency";
import { getFirstLowerCase } from "src/appUtils/string";
import Label from "src/components/label";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { RootState } from "src/redux/store";
import { convert } from "src/utils/currency";
import { DataGridStyle } from "src/utils/DataGridStyle";

export default function DiscountModelDetail() {

    const { displayShop } = useSelector((state: RootState) => state.webshop);

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const { currency } = useSettingsContext();

    const { rates } = useCurrency();

    const rows: Level[] = useMemo(() => {

        if (displayShop?.discountModel?.multiLevel?.thresholds)

            return displayShop.discountModel.multiLevel.thresholds.map((v, i) => ({
                level: v.level,
                additionalDiscountPercentage: v.additionalDiscountPercentage,
                toAmount: +convert(v.toAmount, CurrencyTypes.EUR, currency.label, rates).toFixed(2),
                fromAmount: +convert(v.fromAmount, CurrencyTypes.EUR, currency.label, rates).toFixed(2)
            }));

        return [];

    }, [currency, displayShop, rates]);

    const COLUMNS: GridColDef<Level>[] = [
        {
            field: 'level',
            headerName: `${translate('rules.form.level')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (param) => <Typography sx={{ px: 1.5 }}><b>{param.row.level}</b></Typography>
        },
        {
            field: 'fromAmount',
            headerName: translate('commons.from'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (param) => <Typography sx={{ px: 1.5 }}>{param.row.fromAmount + currency.symbol}</Typography>

        },
        {
            field: 'toAmount',
            headerName: translate('commons.to'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (param) => <Typography sx={{ px: 0.5 }}>{param.row.level === rows.length ? "─" : param.row.toAmount + currency.symbol}</Typography>
        },
        {
            field: 'additionalDiscountPercentage',
            headerName: translate('documents.documentHeaders.discount'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'left',
            align: 'left',
            renderCell: (param) => <Typography sx={{ px: 1.5 }}>{param.row.additionalDiscountPercentage + "%"}</Typography>
        }
    ];

    return (
        <Card sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 3, width: '100' }}>
                <Typography variant="h4" sx={{ mb: 2 }}>{translate('webshop.detail.tabs.discountModel')}</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 3 }}>
                    <Typography variant="h6">
                        {translate('webshop.form.' + getFirstLowerCase(displayShop?.discountModel?.discountType ?? ""))}
                    </Typography>

                    {displayShop?.discountModel?.flat?.appliesToNetAmount &&
                        <Label color="success" variant="soft" sx={{ px: 2 }}>
                            {translate('webshop.detail.onNetAmount')}
                        </Label>
                    }
                    {
                        displayShop?.discountModel?.multiLevel?.isMultiOrder &&
                        <Label color="success" variant="soft" sx={{ px: 2 }}>
                            {translate('webshop.form.multipleOrder')}
                        </Label>
                    }
                </Box>
                {!!displayShop?.discountModel?.flat &&
                    <Box sx={{ mt: 3 }}>
                        <GridLabeledText left={3} right={2} label="additional discount" value={displayShop?.discountModel.flat?.additionalDiscountPercentage + ""} />
                    </Box>
                }
                {
                    !!displayShop?.discountModel?.multiLevel &&
                    <Box sx={{ mt: 3 }}>
                        <DataGridPro
                            disableColumnMenu
                            disableRowSelectionOnClick
                            columns={COLUMNS}
                            rows={rows}
                            getRowId={(row) => row.level}
                            pagination={false}
                            sx={DataGridStyle}
                        />
                    </Box>
                }
            </Box>
        </Card >
    );
}