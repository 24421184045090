import { Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { CustomField, CustomFieldSuggest } from "src/@types/customField";
import RHFCurrencyTextField from "src/appComponents/RHFCurrencyTextField";
import useTenant from "src/appHooks/useTenant";
import { RHFSelect } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { convert } from "./currency";
import { CurrencyTypes } from "src/@types/vehicle";
import useCurrency from "src/appHooks/useCurrency";
import { debounce } from "lodash";
import sparePartsServices from "src/services/sparePartsServices";

interface CustomFieldSidebarRendererProps {
    context: string[],
    filterValues: any,
    setFilterValues: (value: any) => void
    sx?: SxProps<Theme>
}

export default function CustomFieldSidebarRenderer({ context, filterValues, setFilterValues, sx }: CustomFieldSidebarRendererProps) {

    const { currentLang, translate } = useLocales();

    const { customFields } = useTenant();

    const { currency: currentCurrency } = useSettingsContext();

    const { rates } = useCurrency();

    const sidebarCustomFields = useMemo(() => {

        const lowerContext = context.map((v) => v.toLowerCase());

        const fields = customFields.filter((field) => field.enabled && field.searchable && lowerContext.includes(field.entityType.toLowerCase()));

        return fields.sort((a, b) => a.name[0].text!.toLowerCase().localeCompare(b.name[0].text!.toLowerCase()));

    }, [customFields, context]);

    //---------------------------------------------------------------------------------------

    const getCustomFieldType = (id: string) => {

        const lowerContext = context.map((v) => v.toLowerCase());

        const customField = customFields.find(x => x.enabled && lowerContext.includes(x.entityType.toLowerCase()) && x.id === id);

        return customField?.type;
    };

    const handleCustomFields = (event: any, dateValue?: string, dateFieldName?: string) => {

        let customFieldList = { ...filterValues.customFields };

        let newVal = "";

        let fieldName = "";

        if (dateValue && dateFieldName) {
            newVal = dateValue;

            fieldName = dateFieldName;

        } else {
            newVal = event.target.type !== "checkbox" ? event.target.value : (event.target.checked ? "enabled" : "disabled");

            if (getCustomFieldType(event.target.id) === "Currency") {
                newVal = Math.round(convert(Number(event.target.value), currentCurrency.label, CurrencyTypes.EUR, rates)).toString();
            }

            fieldName = event.target.name;
        }

        customFieldList[fieldName] = newVal;

        const customKeys = Object.keys(customFieldList);

        customKeys.forEach((key) => {
            if (!customFieldList[key]) delete customFieldList[key];
        });

        setFilterValues({ ...filterValues, customFields: customFieldList });
    };

    const handleAutocomplete = (newVal: string, fieldName: string) => {

        let customFieldList = { ...filterValues.customFields };

        customFieldList[fieldName] = newVal;

        const customKeys = Object.keys(customFieldList);

        customKeys.forEach((key) => {
            if (!customFieldList[key]) delete customFieldList[key];
        });

        setFilterValues({ ...filterValues, customFields: customFieldList });
    };

    //-------------------------------------------------------------------------------------------

    const getValue = (field: CustomField) => {

        if (filterValues.customFields && filterValues.customFields[field.id]) {
            return filterValues.customFields[field.id];
        }

        return "";
    };

    const getDefaultSelect = (field: CustomField) => {
        if (filterValues.customFields && filterValues.customFields[field.id]) {
            return filterValues.customFields[field.id];
        }

        return field.selectOptions!.find(i => i.isDefault)?.values[0].text || '';
    };

    const getField = (field: CustomField, key: number) => {
        if (field.autoComplete) {
            return (
                <CustomFieldAutocomplete
                    id={field.id}
                    label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text || ""}
                    value={filterValues.customFields ? filterValues.customFields[field.id] : ""}
                    handleAutocomplete={handleAutocomplete}
                />
            );
        }

        switch (field.type.toLowerCase()) {
            case 'string':
                return (
                    <TextField
                        name={field.id}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        variant={"outlined"}
                        onChange={handleCustomFields}
                        value={getValue(field)}
                        sx={{ width: "100%" }}
                    />
                );
            case 'number':
                return (
                    <TextField
                        type={"number"}
                        name={field.id}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        variant={"outlined"}
                        onChange={handleCustomFields}
                        defaultValue={getValue(field)}
                        sx={{ width: "100%" }}
                    />
                );
            case 'currency':
                return (
                    <RHFCurrencyTextField
                        name={field.id}
                        id={field.id}
                        label={(field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text)}
                        currency={currentCurrency.label}
                        onChange={handleCustomFields}
                        defaultValue={getValue(field)}
                        isCustom={true}
                        sx={{ width: "100%" }}
                    />
                );
            case 'date':
                return (
                    <RHFDatePicker
                        name={field.id}
                        id={field.id}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        defaultValue={getValue(field) ? new Date(getValue(field)) : null}
                        onChangeDate={handleCustomFields}
                        sx={{ width: "100%" }}
                    />
                );
            case 'checkbox':
                return (
                    <FormGroup key={key + ' form'}>
                        <FormControlLabel
                            name={field.id}
                            id={field.id}
                            control={
                                <Checkbox
                                    required={field.required}
                                    checked={getValue(field) === "enabled"}
                                    onChange={(event) => handleCustomFields(event)}
                                />
                            }
                            label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                            sx={{ width: "100%" }}
                        />
                    </FormGroup>
                );
            case 'select':
                return (
                    <RHFSelect
                        native
                        name={field.id}
                        id={field.id}
                        onChange={handleCustomFields}

                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        defaultValue={getDefaultSelect(field)}
                        sx={{ width: "100%" }}
                    >
                        {field.selectOptions && field.selectOptions.map((option, index) => (
                            <MenuItem
                                key={index + '  ' + key}
                                hidden={!!option.values.find(l => l.text === '')}
                                value={option.values[0].text || ''}>
                                {option.values.find(x => x.culture === currentLang.value)?.text || option.values[0].text || ''}
                            </MenuItem>
                        ))}
                    </RHFSelect>);
        }
    };

    return (
        <>
            {sidebarCustomFields.length > 0 &&
                <>
                    <Typography variant="subtitle1">
                        {`${translate("commons.customField")}`}
                    </Typography>
                    <Stack spacing={1}>
                        <Box sx={{ ...sx }}>
                            {sidebarCustomFields.map((f, ind) =>
                                <Box key={f.id + "." + ind} sx={{ mb: 3 }}>
                                    {getField(f, ind)}
                                </Box>
                            )}
                        </Box>
                    </Stack>
                </>
            }
        </>

    );
}

interface CustomFieldAutocompleteProps {
    id: string,
    label: string,
    value: string,
    handleAutocomplete: (newVal: string, fieldName: string) => void
}

export function CustomFieldAutocomplete({ id, label, value, handleAutocomplete }: CustomFieldAutocompleteProps) {

    const [optionsList, setOptionList] = useState<string[]>([]);

    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const debounced = useRef(debounce(async (options: CustomFieldSuggest) => {

        let search = await sparePartsServices.customFieldSuggest(options);

        setOptionList(search.data);

        setIsSearchLoading(false);
    }, 500));

    const handleSearch = (value: string) => {
        if (value.length > 0) {
            setIsSearchLoading(true);
            debounced.current(
                {
                    field: id,
                    query: value,
                    limit: 5
                }
            );
        } else setOptionList([]);
    };

    return (
        <Autocomplete
            id={id}
            freeSolo
            options={optionsList}
            loading={isSearchLoading}

            onInputChange={(_, v) => {
                handleAutocomplete(v, id);
                handleSearch(v);
            }}

            getOptionLabel={(option: any) => typeof option !== 'string' ? option.text : ''}

            filterOptions={(options) => options}

            renderOption={(props, option: any) => {
                return (
                    <li
                        {...props}
                        key={option.text}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'initial' }}
                    >
                        {option.text}
                    </li>
                );
            }}

            renderInput={(params) => (
                <TextField {...params} value={value} label={label} />
            )}
        />
    );
}