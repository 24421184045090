import axios, { AxiosResponse } from "axios";
import { Core } from "./paths";
import { CustomField, NewCustomField } from "src/@types/customField";
import { GenericPagedResponse } from "src/@types/list";

const create = (customField: NewCustomField): Promise<AxiosResponse<CustomField>> => {
    const url = Core.admin.customField.create();

    return axios.post(url, customField);
};

const update = (customField: NewCustomField): Promise<AxiosResponse<CustomField>> => {
    const url = Core.admin.customField.update();

    return axios.put(url, customField);
};

const detail = (fieldId: string): Promise<AxiosResponse<CustomField>> => {
    const url = Core.admin.customField.detail(fieldId);

    return axios.get(url);
};

const enable = (fieldId: string): Promise<AxiosResponse> => {
    const url = Core.admin.customField.enable(fieldId);

    return axios.patch(url);
};

const disable = (fieldId: string): Promise<AxiosResponse> => {
    const url = Core.admin.customField.disable(fieldId);

    return axios.patch(url);
};

const search = (options: { pageIndex: number, pageSize: number, entityType: string }): Promise<AxiosResponse<GenericPagedResponse<CustomField>>> => {
    const url = Core.admin.customField.search();

    return axios.get(url, {
        params:
        {
            pageIndex: options.pageIndex.toString(),
            pageSize: options.pageSize.toString(),
            entityType: options.entityType
        }
    });
};

const section = (): Promise<AxiosResponse<string[]>> => {
    const url = Core.admin.customField.section();

    return axios.get(url);
};

export {
    create,
    update,
    detail,
    enable,
    disable,
    search,
    section
};