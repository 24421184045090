import { Box, Card, Stack, Switch, TextField, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { useEffect, useState } from 'react';
import { RequestNew } from 'src/@types/request';
import { OrganizationSearchResult } from 'src/@types/organizations';
import useResponsive from 'src/hooks/useResponsive';
import IncrementalInput from 'src/utils/IncrementalInput';
import { DeliveryAddressAutocomplete } from 'src/utils/requestUtils';

interface DetailProps {
  request: RequestNew;
  onChange: (name: string, value: any, item?: boolean) => void;
  available: number;
  defaultAddress: OrganizationSearchResult,
  organization: string,
  isVendor: boolean
}

export default function DetailNewParts({
  request,
  onChange,
  available,
  defaultAddress,
  isVendor,
  organization
}: DetailProps) {
  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'sm');

  const [note, setNote] = useState(request.note ?? "");

  const [noteError, setNoteError] = useState("");

  const requiredNote = request.requestReason === "CatalogueErrorN05OrK1" || request.requestReason === "Other";

  useEffect(() => {
    if (request.requestType === 'Core' && available > 0)
      onChange('quantity', 1, true);

    if (available === 0)
      onChange('quantity', 0, true);
  }, [available, request.requestType]);

  return (

    <Card sx={{ my: 2, width: '100%', flexDirection: 'column', gap: 2, display: 'flex', p: 2 }}>
      <Typography variant="subtitle1">{`${translate('request.form.complete')}`}</Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
          flexWrap: 'wrap'
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('commons.quantity')}`} <span style={{ color: 'red' }}>*</span></Typography>
          <Typography variant="body2">{`${translate('request.selectQty')}`}</Typography>
        </Stack>

        <Box
          sx={{
            m: 2,
            ml: 'auto',
            mr: 2,
            display: 'flex',
            justifyContent: isDesktop ? 'end' : 'center',
            alignItems: 'center',

          }}
        >
          <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>
            {`${translate('statuses.available')}`}: {available}
          </Typography>
          <IncrementalInput
            onChange={(name, val) => onChange(name, val, true)}
            defaultValue={
              available > 0 ?
                (request.requestType === 'Core' ? 1 : request.items[0].quantity) : 0
            }
            disabled={request.requestType === 'Core'}
            maxValue={available}
          />
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('request.big')}`}</Typography>
          <Typography variant="body2">{`${translate('request.messages.bigDesc')}`}</Typography>
        </Stack>
        <Box sx={{ m: 2, ml: 'auto' }}>
          <Switch
            name="bigAndHeavy"
            onChange={(e) => onChange(e.target.name, e.target.checked, true)}
          />
        </Box>

      </Box>
      {request.requestType === "NewPart" &&
        <Box>
          <TextField
            variant="outlined"
            name="note"
            rows={4}
            value={note}
            multiline
            required={requiredNote}
            error={!!noteError}
            onBlur={(e) => {
              if (requiredNote)
                setNoteError(e.target.value.length === 0 ? `${translate('commons.validation.requiredField')}` : '');
            }}
            helperText={noteError}
            fullWidth
            type="text"
            label={`${translate('commons.note')}`}
            placeholder={translate('request.messages.newPartNotePlaceholder')}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              if (e.target.value.length < 200) {
                onChange(e.target.name, e.target.value);
                setNote(e.target.value);
              }
            }}

          />
        </Box>}

      <Box>
        <DeliveryAddressAutocomplete
          onChange={onChange}
          defaultAddress={defaultAddress}
          required
          organization={organization}
          isVendor={isVendor}
        />
      </Box>
    </Card>
  );
}