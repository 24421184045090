import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Avatar } from '@mui/material';
import { RootState, dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import useTable from 'src/appHooks/useTable';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { isEqual } from 'lodash';
import DateZone from 'src/appComponents/DateZone';
import useResponsive from 'src/hooks/useResponsive';
import { DEFAULT_USER_FILTERS, UserFilters, UserSearchResult } from 'src/@types/user';
import { usersOperations } from 'src/redux/users';
import { setFiltersInUrl } from 'src/redux/users/users-slices';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { ToolbarListFilters } from 'src/@types/list';

interface userProps {
    organizationId: string
};

export default function OrganizationUserDetail({ organizationId }: userProps) {

    const { dense } = useTable();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const { userList, isLoading, totalCount, filtersInUrl } = useSelector((state: RootState) => state.user);

    const [filters, setFilters] = useState<UserFilters>(DEFAULT_USER_FILTERS);

    const statsKeysToDelete: string[] = ["onlyEnabled"];

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() =>
        [
            { key: 'all', label: translate('commons.all') },
            { key: 'firstname', label: translate('commons.firstname') },
            { key: 'lastname', label: translate('commons.lastname') },
            { key: 'username', label: translate('commons.username') },
            { key: 'email', label: translate('commons.email') }
        ], [translate]);

    //---- FILTERS IN URL GET/SET - START ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: UserFilters) => (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && !isEqual(filtersToCheck[field], DEFAULT_USER_FILTERS[field]), []);
    //---- FILTERS IN URL GET/SET - END ----//

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((params: { filters: UserFilters & { sequenceToken: any }, check: boolean }) => {
        dispatch(usersOperations.searchUsers({ ...params, filters: { ...params.filters, organizations: [organizationId] } }));
    }, [organizationId]);

    //---- HANDLE TABLE START ----//
    const [actualRow, setActualRow] = useState<any>(null);

    const handleCellClick = (params: GridCellParams<any>) => {
        setActualRow(params);
        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.user.detailsTab(params.id.toString(), "profile"));
        }
    };

    const COLUMNS: GridColDef<UserSearchResult>[] = useMemo(() => [
        {
            field: 'firstName',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Avatar
                            alt={obj.row.firstName + " " + obj.row.lastName}
                            src={obj.row.pictureUrl}
                            sx={{ mr: 2, width: dense ? 24 : 40, height: dense ? 24 : 40 }}
                        />
                        <Typography variant="subtitle2" noWrap>
                            {obj.row.firstName + " " + obj.row.lastName}
                        </Typography>
                    </Box>
                );
            }
        },
        {
            field: 'username',
            headerName: translate('commons.username'),
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'email',
            headerName: translate('commons.email'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined
        },
        {
            field: 'lastLogin',
            headerName: translate('user.list.tableHeaders.lastLogin'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.lastLogin}
                        shortMonth
                        noSeconds
                    />
                );
            }
        }
    ], [dense, isDesktop, translate]);
    //---- HANDLE TABLE END ----//

    return (
        <InfiniteScrollGenericList
            totalCount={totalCount}
            list={userList}
            isLoading={isLoading}
            defaultFilters={DEFAULT_USER_FILTERS}
            specificStatsKeysToDelete={statsKeysToDelete}
            //specificFullKeysToDelete={fullKeysToDelete}
            toolbarFiltersList={toolbarFiltersList}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"UserInOrganization"}
            handleCellClick={handleCellClick}
            setFiltersCallback={setFilters}
            setActualRow={setActualRow}
            customSearchFunc={customSearch}
            filtersInUrl={filtersInUrl}
            setFiltersInUrl={setFiltersInUrl}
            resetList={() => { }}
        />
    );
}