import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, MenuItem, Typography, Avatar } from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import useTable from 'src/appHooks/useTable';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import DateZone from 'src/appComponents/DateZone';
import useResponsive from 'src/hooks/useResponsive';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import { ClusterPermissionTypes } from 'src/@types/permissions';
import { groupOperations } from 'src/redux/group';
import { isEqual } from 'lodash';
import { DEFAULT_USER_FILTERS, UserFilters, UserSearchResult } from 'src/@types/user';
import { ToolbarListFilters } from 'src/@types/list';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';

export default function GroupMembersList() {

    const { dense } = useTable();

    const navigate = useNavigate();

    const { id: groupId } = useParams();

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const { isMembersLoading, membersList, membersTotalCount } = useSelector((state: RootState) => state.group);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() =>
        [
            { key: 'all', label: `${translate('commons.all')}` },
            { key: 'firstname', label: `${translate('commons.firstname')}` },
            { key: 'lastname', label: `${translate('commons.lastname')}` },
            { key: 'username', label: `${translate('commons.username')}` },
            { key: 'email', label: `${translate('commons.email')}` }
        ], [translate]);

    const updateCheckField = useCallback((field: string, filtersToCheck: UserFilters) => (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && !isEqual(filtersToCheck[field], DEFAULT_USER_FILTERS[field]), []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((params: { filters: UserFilters & { sequenceToken: any }, check: boolean }) => {
        if (groupId) dispatch(groupOperations.searchGroupMembers({ ...params, filters: { ...params.filters, groups: [groupId] } }));
    }, [groupId]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {
        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.user.detailsTab(params.id.toString(), "profile"));
        }
    };

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.user.detailsTab(id, "logs"));
    }, [navigate]);

    const handleEdit = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.user.edit(id));
    }, [navigate]);

    const COLUMNS: GridColDef<UserSearchResult>[] = useMemo(() => [
        {
            field: 'firstName',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Avatar
                            alt={obj.row.firstName + " " + obj.row.lastName}
                            src={obj.row.pictureUrl}
                            sx={{ mr: 2, width: dense ? 24 : 40, height: dense ? 24 : 40 }}
                        />
                        <Typography variant="subtitle2" noWrap>
                            {obj.row.firstName + " " + obj.row.lastName}
                        </Typography>
                    </Box>
                );
            }
        },
        {
            field: 'username',
            headerName: `${translate('commons.username')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'lastLogin',
            headerName: `${translate('user.list.tableHeaders.lastLogin')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.lastLogin}
                        shortMonth
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'email',
            headerName: `${translate('commons.email')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        handleEdit={handleEdit}
                        object={obj}
                        currentRow={actualRow}
                    />
                );
            }
        }
    ], [actualRow, handleLogs, openMenu, translate, isDesktop, handleEdit, dense]);
    //---- HANDLE TABLE END ----//

    return (
        <InfiniteScrollGenericList
            totalCount={membersTotalCount}
            list={membersList}
            isLoading={isMembersLoading}
            defaultFilters={DEFAULT_USER_FILTERS}
            toolbarFiltersList={toolbarFiltersList}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"GroupMembers"}
            setActualRow={setActualRow}
            handleCellClick={handleCellClick}
            customSearchFunc={customSearch}
            resetList={() => { }}
        />
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string) => void,
    handleEdit: (id: string) => void,
    object: any,
    currentRow: any
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, handleEdit, object, currentRow }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[ClusterPermissionTypes.UserEdit]}>
                        <MenuItem
                            onClick={() => {
                                handleEdit(object.id);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.edit")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[ClusterPermissionTypes.UserViewLogs]}>
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}