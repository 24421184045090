import { LabelColor } from 'src/components/label';
import { Brand } from './brand';
import { LocalizedString } from './commons';
import { CurrencyTypes } from './vehicle';
import { RuleTypes } from './rules';
import { INFINITE_SCROLL_LIST_FILTERS, InfiniteScrollListFilters, SequenceToken } from './list';
import { DEFAULT_PRICE_AMOUNT, PriceAmount } from './currency';

export const OganizationTypesArr = ["", "Company", "Branch", "Holding", "Hub"] as const;

export type OganizationType = typeof OganizationTypesArr[number];

export type OrganizationsQuickFilters = {
    key: string;
    label: string;
    color: LabelColor;
};

export type OrganizationsSearchFilters = {
    key: string;
    label: string;
};

export type Address = {
    address: string,
    zipCode: string,
    district: string,
    country: string,
    city: string
}

export interface OrganizationSearchResult extends SequenceToken {
    id: string,
    externalId: string,
    name: string,
    brands: string[],
    regions: string[] | null,
    parentId?: string | null,
    status: string,
    creditbalance: PriceAmount,
    creditLimit: PriceAmount,
    createdOn: string,
    type: string,
    address: Address
}

export interface OrganizationFilters extends InfiniteScrollListFilters {
    [key: string]: any,
    all?: string,
    customerParentId?: string,
    name?: string,
    externalId?: string,
    email?: string,
    isMonitored?: boolean
    type?: OganizationType | "all",
    vat?: string,
    minCreditBalance?: number,
    maxCreditBalance?: number,
    minCreditLimit?: number,
    maxCreditLimit?: number,
    tags?: string[],
    groups?: string[],
    blacklists?: string[],
    whitelists?: string[]
}

export type OrganizationStatistics = {
    [key: string]: number
    all: number,
    enabled: number,
    disabled: number,
    blocked: number
}

export interface GenericOrganizationToService {
    [key: string]: any
    id: string,
    parentId: string,
    timeZoneId: string,
    preferredCurrency: CurrencyTypes
    status: string,
    type: OganizationType | "",
    createdOn: Date,
    country: string,
    name: string,
    externalId: string,
    vat: string,
    note: string,
    address: {
        address: string,
        zipCode: string,
        district: string,
        country: string,
        city: string,
    } | null,
    contact: {
        privateEmail: string,
        businessEmail: string,
        privatePhone: string,
        businessPhone: string,
        privateMobilePhone: string,
        businessMobilePhone: string,
        fax: string,
        note: string
    } | null,
    payment: {
        iban: string,
        mode: string,
        creditLimit: number,
        balance: number
    } | null,
    customFields: Record<string, string> | null,
    tags: string[],
    brands: string[],
    regions: string[]
}

export interface GenericOrganizationForEdit extends Omit<GenericOrganizationToService, 'note'> {
    note: LocalizedString[]
}

export interface GenericOrganization extends GenericOrganizationToService {
    [key: string]: any
    parentType: OganizationType | "",
    selectedBrands: Brand[],
    parent: OrganizationSearchResult | null,
    hubId: string | null,
    parentName: string,
    isMonitored: boolean
}

export interface GenericOrganizationEditWithFather extends GenericOrganizationForEdit {
    [key: string]: any
    parentType: OganizationType | "",
    selectedBrands: Brand[],
    parent: OrganizationSearchResult | null,
    hubId: string | null,
    parentName: string
}

type RequestPerType = {
    NewPart: number,
    UsedArticleIsDamage: number,
    PackagingDamaged: number,
    ProductDamaged: number
}

export type SurveillanceMonth = {
    year: number,
    month: number,
    returnRequestPerType: RequestPerType,
    numberOfOrderedItems: number,
    numberOfReturnedItems: number
}

type SurveillanceThreshold = {
    enabled: boolean,
    type: RuleTypes,
    newPart: number,
    damagePackaging: number,
    damageUnPackaging: number,
    quality: number,
    fee: number
}

export type OrganizationSurveillance = {
    [key: string]: any
    percentage: number,
    months: SurveillanceMonth[],
    surveillance: SurveillanceThreshold
}
/* --------------------------------------------------- */

const DEFAULT_PAYMENT = {
    iban: "",
    mode: "",
    creditLimit: 0,
    balance: 0
};

const DEFAULT_CONTACT = {
    privateEmail: "",
    businessEmail: "",
    privatePhone: "",
    businessPhone: "",
    privateMobilePhone: "",
    businessMobilePhone: "",
    fax: "",
    note: ""
};

const DEFAULT_ADDRESS = {
    address: "",
    zipCode: "",
    district: "",
    country: "",
    city: "",
};

export const DEFAULT_PARENT: OrganizationSearchResult = {
    id: "",
    externalId: "",
    name: "",
    brands: [],
    regions: [],
    parentId: "",
    status: "",
    creditbalance: DEFAULT_PRICE_AMOUNT,
    creditLimit: DEFAULT_PRICE_AMOUNT,
    createdOn: "",
    type: "",
    address: DEFAULT_ADDRESS,
    searchSequenceToken: null
};

export const DEFAULT_GENERIC_ORGANIZATION: GenericOrganization = {
    id: "",
    type: "",
    status: "Enabled",
    createdOn: new Date(),
    country: "",
    name: "",
    externalId: "",
    vat: "",
    address: DEFAULT_ADDRESS,
    contact: DEFAULT_CONTACT,
    payment: DEFAULT_PAYMENT,
    customFields: null,
    tags: [],
    brands: [],
    regions: [],
    parentId: "",
    parentType: "",
    selectedBrands: [],
    parent: DEFAULT_PARENT,
    note: "",
    hubId: null,
    parentName: '',
    timeZoneId: '',
    preferredCurrency: CurrencyTypes.EUR,
    isMonitored: false
};

export const DEFAULT_ORGANIZATION_FILTERS: OrganizationFilters = {
    ...INFINITE_SCROLL_LIST_FILTERS,
    all: '',
    externalId: '',
    type: 'all',
    name: '',
    vat: '',
    email: '',
    minCreditBalance: undefined,
    maxCreditBalance: undefined,
    minCreditLimit: undefined,
    maxCreditLimit: undefined,
    tags: [],
    customFields: {},
    isMonitored: false
};

export const DEFAULT_ORGANIZATION_SURVEILLANCE_VALUE: OrganizationSurveillance = {
    percentage: 0,
    months: [
        {
            year: 0,
            month: 0,
            returnRequestPerType: {
                NewPart: 0,
                UsedArticleIsDamage: 0,
                PackagingDamaged: 0,
                ProductDamaged: 0
            },
            numberOfOrderedItems: 0,
            numberOfReturnedItems: 0
        }
    ],
    surveillance: {
        enabled: true,
        type: "Soft",
        newPart: 0,
        damagePackaging: 0,
        damageUnPackaging: 0,
        quality: 0,
        fee: 0
    }
};

export const DEFAULT_ORGANIZATION_STATISTICS: OrganizationStatistics = {
    all: 0,
    enabled: 0,
    disabled: 0,
    blocked: 0
};