import { Typography } from "@mui/material";
import { DEFAULT_ORGANIZATION_FILTERS, GenericOrganization, OrganizationFilters, OrganizationSearchResult } from "src/@types/organizations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { useLocales } from "src/locales";
import Label, { LabelColor } from 'src/components/label';
import { PATH_DASHBOARD } from "src/routes/paths";
import { brandOperations } from "src/redux/brand";
import { organizationsOperations } from "src/redux/organizations";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { isEqual } from "lodash";
import { ToolbarListFilters } from "src/@types/list";
import useResponsive from "src/hooks/useResponsive";
import InfiniteScrollGenericList from "src/utils/list/InfiniteScrollGenericList";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { getFirstLowerCase } from "src/appUtils/string";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";

interface StructureOrganizationDetailProps {
    organization: GenericOrganization;
    isVendor: boolean
};

export default function StructureOrganizationDetail({ organization, isVendor }: StructureOrganizationDetailProps) {

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const { isSearchLoading: isLoading, organizationList, totalCount } = useSelector((state: RootState) => state.organizations);

    const { isLoading: isBrandLoading, publicBrandList } = useSelector((state: RootState) => state.brand);

    const [filters, setFilters] = useState<OrganizationFilters>(DEFAULT_ORGANIZATION_FILTERS);

    useEffect(() => {
        if (!publicBrandList)
            dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() => [
        {
            key: 'all',
            label: translate('commons.all')
        },
        {
            key: 'name',
            label: translate('commons.name')
        },
        {
            key: 'externalId',
            label: translate('commons.code')
        }
    ], [translate]);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: OrganizationFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean" || typeof filtersToCheck[field] === "number")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_ORGANIZATION_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((params: { filters: OrganizationFilters & { sequenceToken: any }, check: boolean }) => {
        dispatch(organizationsOperations.searchOrganizations({ ...params, isVendor: false, filters: { ...params.filters, customerParentId: organization.id } }));
    }, [isVendor]);

    //---- HANDLE TABLE START ----//
    const [actualRow, setActualRow] = useState<any>(null);

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== 'options') {
            if (isVendor)
                navigate(PATH_DASHBOARD.organizations.vendor.detail(params.id.toString(), params.row.type.toString().toLowerCase()));
            else
                navigate(PATH_DASHBOARD.organizations.customer.detail(params.id.toString(), params.row.type.toString().toLowerCase()));
        }
    };

    const getStatusColor = (status: string): LabelColor => {

        let color = 'default';

        switch (status) {
            case "Enabled":
                color = 'success';
                break;
            case "Disabled":
                color = 'warning';
                break;
            case "Blocked":
                color = 'error';
                break;
        }

        return color as LabelColor;
    };

    const COLUMNS: GridColDef<OrganizationSearchResult>[] = [
        {
            field: 'externalId',
            headerName: translate('commons.code'),
            headerAlign: 'left',
            flex: 1
        },
        {
            field: 'name',
            headerName: translate('commons.name'),
            headerAlign: 'left',
            flex: 1.25
        },
        {
            field: 'brands',
            headerName: translate('commons.brand') + "/" + translate('commons.category'),
            headerAlign: 'left',
            flex: 1,
            renderCell: (val) =>
                <Typography variant="body2">
                    {organization.brands && publicBrandList && publicBrandList.filter(brand => val.row.brands && val.row.brands.includes(brand.id)).map(brand => brand.name).join(', ')}
                </Typography>
        },
        {
            field: 'type',
            headerName: translate('organization.list.table.type'),
            headerAlign: 'left',
            flex: 0.75
        },
        {
            field: 'credit',
            headerName: translate('organization.list.table.credit'),
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 150 : undefined,
            renderCell: (obj) => <CurrencyAdapter value={obj.row.creditLimit} />
        },
        {
            field: 'status',
            headerName: translate('returns.tableHeaders.status'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {translate(`statuses.${getFirstLowerCase(obj.row.status)}`)}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: 0.3,
            renderCell: () => <ArrowOutwardIcon />,
            sortable: false
        }
    ];
    //---- HANDLE TABLE END ----//

    return (
        <InfiniteScrollGenericList
            list={organizationList}
            isLoading={isLoading || isBrandLoading}
            totalCount={totalCount}
            defaultFilters={DEFAULT_ORGANIZATION_FILTERS}
            toolbarFiltersList={toolbarFiltersList}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"Organization"}
            setActualRow={setActualRow}
            handleCellClick={handleCellClick}
            setFiltersCallback={setFilters}
            customSearchFunc={customSearch}
            resetList={() => { }}
            showVatAdvice
        />
    );
}
