import { Box, Container, Tab, Tabs } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import { useSettingsContext } from 'src/components/settings';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import RequestTab from 'src/sections/@dashboard/rules/listTabs/RequestTab';
import InvoiceTab from 'src/sections/@dashboard/rules/listTabs/InvoiceTab';
import FrequencyTab from 'src/sections/@dashboard/rules/listTabs/FrequencyTab';
import SurveillanceTab from 'src/sections/@dashboard/rules/listTabs/SurveillanceTab';
import { RuleTabs } from 'src/@types/rules';
import { rulesOperations } from 'src/redux/rules';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import LoadingScreen from 'src/appComponents/loading-screen';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';

export default function RulesList() {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { rulesTab } = useParams();

    const { isLoading, rule } = useSelector((state: RootState) => state.rules);

    useEffect(() => {
        if (!rule)
            dispatch(rulesOperations.getRule());
    }, [rule]);

    const [currentTab, setCurrentTab] = useState('request');

    const onTabChange = (tabName: string) => {
        navigate(PATH_DASHBOARD.rules.listTab(tabName as RuleTabs));
        setCurrentTab(tabName);
    };

    useEffect(() => {
        setCurrentTab(rulesTab || 'request');
    }, [rulesTab]);

    const TABS = useMemo(() => [
        {
            value: 'request',
            label: `${translate('rules.list.tabs.request')}`,
            component: <RequestTab />
        },
        {
            value: 'invoice',
            label: `${translate('rules.list.tabs.invoice')}`,
            component: <InvoiceTab />
        },
        {
            value: 'frequency',
            label: `${translate('rules.list.tabs.frequency')}`,
            component: <FrequencyTab />
        },
        {
            value: 'surveillance',
            label: `${translate('rules.list.tabs.surveillance')}`,
            component: <SurveillanceTab />
        }
    ], [translate]);

    return (
        <Page title={`${translate('menu.management.rules.title')}`}>
            {isLoading ? (<LoadingScreen />) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('menu.management.rules.title')}`}
                        links={[
                            { name: translate("commons.home"), href: PATH_DASHBOARD.root },
                            { name: `${translate("commons.rules")}` }
                        ]}
                    />
                    <Tabs
                        value={currentTab}
                        onChange={(_, newValue) => onTabChange(newValue)}
                        sx={{ mb: 3 }}
                    >
                        {TABS.map((tab) => (
                            <Tab key={tab.value} value={tab.value} label={tab.label} />
                        ))}
                    </Tabs>

                    {TABS.map((tab) =>
                        tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>
                    )}

                </Container>
            )}
        </Page>
    );
}
