import { Box, Checkbox, FormControlLabel, FormGroup, InputAdornment, MenuItem, Stack, Switch, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { useMemo } from "react";
import { CustomField, EntityType } from "src/@types/customField";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import Label from "src/components/label";
import { useLocales } from "src/locales";
import { getCustomFieldLabel, getCustomFieldsBySection, getLocalizedString } from "./CustomFieldManagment";
import { useSettingsContext } from "src/components/settings";
import { DatePicker } from "@mui/x-date-pickers-pro";

interface CustomFieldParserProps {
    customFields: CustomField[],
    entity: EntityType,
    callBack?: (e: any) => void
}

export default function CustomFieldParser({ customFields, entity, callBack }: CustomFieldParserProps) {

    const parsed = customFields.filter(f => f.entityType === entity && f.enabled);

    const sections = new Set(parsed.map(s => s.section));

    const { currentLang } = useLocales();

    const handleCallBack = (e: any) => {
        if (callBack)
            callBack(e);
    };

    const what = (field: CustomField, key: number) => {
        switch (field.type.toLowerCase()) {
            case 'string':
                return (<RHFTextField
                    name={field.id}
                    key={field.id + key}
                    id={field.id}
                    label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                    required={field.required}
                    onChange={handleCallBack}
                    helperText={
                        (field.validationMessage !== null && field.validationMessage.length > 0) ? (
                            field.validationMessage.find(x => x.culture === currentLang.value)?.text || field.validationMessage[0].text) :
                            ''
                    }
                    defaultValue={field.defaultValue}
                />);
            case 'number':
                return (
                    <RHFTextField
                        name={field.id}
                        id={field.id}
                        key={field.id + key}
                        onChange={handleCallBack}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        type="number"
                        helperText={
                            (field.validationMessage !== null && field.validationMessage.length > 0) ? (
                                field.validationMessage.find(x => x.culture === currentLang.value)?.text || field.validationMessage[0].text) :
                                ''
                        }
                        defaultValue={field.defaultValue} />
                );
            case 'checkbox':
                return (
                    <FormGroup key={key + ' form'}>
                        <FormControlLabel
                            name={field.id}
                            id={field.id}
                            key={field.id + key}
                            onChange={handleCallBack}
                            control={<Checkbox required={field.required} />}
                            label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text} />
                    </FormGroup>
                );
            case 'select':
                return (
                    <RHFSelect
                        native
                        name={field.id}
                        id={field.id}
                        key={field.id + key}
                        onChange={handleCallBack}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        defaultValue={field.selectOptions!.find(i => i.isDefault) || ''}
                    >

                        {field.selectOptions && field.selectOptions.map((option, index) => (
                            <option
                                key={index + '  ' + key}
                                hidden={!!option.values.find(l => l.text === '')}
                                value={option.values[0].text || ''}>
                                {option.values.find(x => x.culture === currentLang.value)?.text || option.values[0].text || ''}
                            </option>)
                        )}

                    </RHFSelect>);
        }
    };

    return (<>
        {Array.from(sections).map(
            (section, index) => (
                <Box key={index + '.main.box'}>
                    <Stack key={index} sx={{ mt: 3, mb: 4 }}>
                        {section &&
                            <Label hidden={section === ''} key={index + 'label'} sx={{ textTransform: 'uppercase', position: 'absolute' }}>
                                {section}
                            </Label>
                        }
                    </Stack>
                    <Box
                        sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                        }}
                        key={index + 'box'}
                    >
                        {parsed.filter(field => field.section === section).map(field => what(field, index))}
                    </Box>
                </Box>
            ))
        }</>);
}

function getNestedProperty(obj: Record<string, any>, path: string): any {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
}

interface CustomFieldParserFormikProps<T = unknown> {
    customFields: CustomField[],
    entity: EntityType,
    formik: FormikProps<T>,
    keyToCustomfield?: string
}
/**
 * 
 * @param {CustomField[]} customFields array of custom fields, can be all custom fields or filtered by entity type
 * @param {EntityType} entity entity type to filter custom fields
 * @param {FormikProps<T>} formik formik object
 * @param {string} keyToCustomfield Dot-notated path to the customField parameter.  
 * It must have a trailing dot. By default, it is "customFields.".  
 * Example: "myObj.sub.field."
 */

export function CustomFieldParserFormik<T = unknown>({ customFields, entity, formik, keyToCustomfield = "customFields." }: CustomFieldParserFormikProps<T>) {

    const { currentLang, translate } = useLocales();

    const { currency } = useSettingsContext();

    const parsed = useMemo(() => customFields.filter(f => f.entityType === entity && f.enabled), [customFields, entity]);

    const FieldsBySection = useMemo(() => getCustomFieldsBySection(parsed), [parsed]);

    const getFieldByType = (field: CustomField, key: string | number) => {

        const dotNotedPath = keyToCustomfield + field.id;

        const hasError = getNestedProperty(formik.touched, dotNotedPath) && !!getNestedProperty(formik.errors, dotNotedPath);

        switch (field.type.toLowerCase()) {
            case "string":
                return <TextField
                    id={dotNotedPath}
                    name={dotNotedPath}
                    key={key}
                    value={getNestedProperty(formik.values as Record<string, unknown>, dotNotedPath) ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={getCustomFieldLabel(customFields, field.id, currentLang)}
                    required={field.required}
                    error={hasError}
                    helperText={hasError && translate(getNestedProperty(formik.errors, dotNotedPath))}
                />;
            case "number":
                return <TextField
                    id={dotNotedPath}
                    name={dotNotedPath}
                    key={key}
                    value={getNestedProperty(formik.values as Record<string, unknown>, dotNotedPath) ?? ""}
                    onChange={(e) => {
                        if (!isNaN(+e.target.value))
                            formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    label={getCustomFieldLabel(customFields, field.id, currentLang)}
                    required={field.required}
                    error={hasError}
                    helperText={hasError && translate(getNestedProperty(formik.errors, dotNotedPath))}
                />;
            case "currency":
                return <TextField
                    id={dotNotedPath}
                    name={dotNotedPath}
                    key={key}
                    value={getNestedProperty(formik.values as Record<string, unknown>, dotNotedPath) ?? ""}
                    onChange={(e) => {
                        if (!isNaN(+e.target.value))
                            formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    label={getCustomFieldLabel(customFields, field.id, currentLang)}
                    required={field.required}
                    error={hasError}
                    helperText={hasError && translate(getNestedProperty(formik.errors, dotNotedPath))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>
                    }}
                />;
            case "date":
                return <DatePicker
                    name={dotNotedPath}
                    key={key}
                    views={['year', 'month', 'day']}
                    value={getNestedProperty(formik.values as Record<string, unknown>, dotNotedPath) ?
                        new Date(getNestedProperty(formik.values as Record<string, unknown>, dotNotedPath)) : null}
                    label={getCustomFieldLabel(customFields, field.id, currentLang)}

                    onAccept={(val) => {
                        let date = "";
                        if (val)
                            date = val.toISOString();

                        formik.setFieldValue(dotNotedPath, date, true).then(() => formik.setFieldTouched(dotNotedPath, true));

                    }}
                    onChange={(fromDateValue, inputval) => {
                        if (!inputval.validationError) {

                            const date = fromDateValue?.toISOString() ?? null;

                            formik.setFieldValue(dotNotedPath, date, true).then(() => formik.setFieldTouched(dotNotedPath, true));

                        }
                    }}
                    slotProps={{
                        textField: {
                            error: hasError,
                            required: field.required,
                            helperText: hasError && translate(getNestedProperty(formik.errors, dotNotedPath))
                        },
                        field: { clearable: true },
                        actionBar: {
                            actions: ['clear'],
                        },
                    }}

                />;
            case "select":
                return <TextField
                    id={dotNotedPath}
                    name={dotNotedPath}
                    select
                    key={key}
                    value={getNestedProperty(formik.values as Record<string, unknown>, dotNotedPath) ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={getCustomFieldLabel(customFields, field.id, currentLang)}
                    required={field.required}
                    error={hasError}
                    helperText={hasError && translate(getNestedProperty(formik.errors, dotNotedPath))}
                >
                    {field.selectOptions?.map((option, index) => (
                        <MenuItem
                            hidden={!!option.values.find(l => l.text === '')}
                            value={option.values[0].text ?? ""}
                            key={index}
                        >
                            {getLocalizedString(option.values, currentLang) ?? ""}
                        </MenuItem>
                    )) ?? <></>}
                </TextField>;
            case "checkbox":
                return <FormControlLabel
                    label={getCustomFieldLabel(customFields, field.id, currentLang)}
                    value={getNestedProperty(formik.values as Record<string, unknown>, dotNotedPath) ?? ""}
                    id={dotNotedPath}
                    name={dotNotedPath}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={field.required}
                    control={<Switch />}
                />;
            default: return <></>;
        }

    };

    return (
        <Box>
            {Object.entries(FieldsBySection).map(([section, fields], index) => (
                <Box key={index + '.main.box'}>
                    <Stack key={index} sx={{ mt: 3, mb: 4 }}>
                        {section &&
                            <Label hidden={!section} key={index + 'label'} sx={{ textTransform: 'uppercase', position: 'absolute' }}>
                                {section}
                            </Label>
                        }
                    </Stack>
                    <Box
                        sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                        }}
                        key={index + 'box'}
                    >
                        {Object.values(fields).map((field, key) => getFieldByType(field, key))}
                    </Box>
                </Box>
            ))}
        </Box>
    );
}