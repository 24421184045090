import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganizationSearchResult, GenericOrganization, OrganizationStatistics, GenericOrganizationEditWithFather, OrganizationSurveillance, DEFAULT_ORGANIZATION_STATISTICS } from "src/@types/organizations";
import organizationsOperations from "./organizations-operations";
import { ErrorResponse } from "src/@types/commons";
import { GenericPagedResponse } from "src/@types/list";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";

interface OrganizationsReducer {
    /* COMMONS */
    error: ErrorResponse,
    isLoading: boolean,
    /* OWNER */
    isOwnersLoading: boolean,
    owners: GenericOrganization[] | null;
    /* CUSTOMER CARE */
    customerCareVendors: GenericOrganization[] | null;
    customerCareLoading: boolean,
    /* ORGANIZATION */
    organization: GenericOrganization | null,
    organizationEdit: GenericOrganizationEditWithFather | null,
    nonEditables: string[],
    hub: GenericOrganization | null,
    surveillance: OrganizationSurveillance | null,
    /* ORGANIZATION LIST */
    isSearchLoading: boolean,
    isInfiniteScrolling: boolean,
    totalCount: number,
    organizationList: OrganizationSearchResult[],
    statistics: OrganizationStatistics,
    filtersInUrl: string,
    /* POWERSEARCH */
    vendorOrganizationsPowersearch: OrganizationSearchResult[],
    customerOrganizationsPowersearch: OrganizationSearchResult[],
    vendorPowersearchLoading: boolean,
    customerPowersearchLoading: boolean,
    /* LOGS */
    logsLoading: boolean,
    logsList: GenericPagedResponse<LogsItem>
};

const initialState: OrganizationsReducer = {
    /* COMMONS */
    error: null,
    isLoading: false,
    /* OWNER */
    isOwnersLoading: false,
    owners: null,
    /* CUSTOMER CARE */
    customerCareVendors: null,
    customerCareLoading: false,
    /* ORGANIZATION */
    organization: null,
    organizationEdit: null,
    nonEditables: [],
    hub: null,
    surveillance: null,
    /* ORGANIZATION LIST */
    isSearchLoading: false,
    isInfiniteScrolling: false,
    totalCount: 0,
    organizationList: [],
    statistics: DEFAULT_ORGANIZATION_STATISTICS,
    filtersInUrl: "",
    /* POWERSEARCH */
    vendorOrganizationsPowersearch: [],
    customerOrganizationsPowersearch: [],
    vendorPowersearchLoading: false,
    customerPowersearchLoading: false,
    /* LOGS */
    logsLoading: false,
    logsList: DEFAULT_LOGS_DATA
};

const OrganizationsSlice = createSlice({
    name: 'organization',
    initialState,
    extraReducers: (builder) => {
        builder
            //-------------------------------------------
            .addCase(organizationsOperations.createGeneric.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(organizationsOperations.createGeneric.fulfilled, (state) => {
                state.isLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.updateGeneric.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(organizationsOperations.updateGeneric.fulfilled, (state) => {
                state.isLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getGeneric.rejected, (state, action) => {
                state.organization = initialState.organization;
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getGeneric.fulfilled, (state, action) => {
                state.organization = action.payload as GenericOrganization;
                state.isLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getHub.rejected, (state, action) => {
                state.hub = initialState.hub;
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getHub.fulfilled, (state, action) => {
                state.hub = action.payload;
                state.isLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.searchOrganizations.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.organizationList = initialState.organizationList;
                state.totalCount = initialState.totalCount;
                state.isInfiniteScrolling = false;
                state.isSearchLoading = false;
            })
            .addCase(organizationsOperations.searchOrganizations.fulfilled, (state, action) => {
                state.organizationList = state.isInfiniteScrolling ? state.organizationList.concat(action.payload.results) : action.payload.results;
                state.totalCount = action.payload.totalCount;
                state.isInfiniteScrolling = false;
                state.isSearchLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.searchOrganizationsPowerSearch.rejected, (state, action) => {
                state.error = action.error.message as string;

                if (action.meta.arg.isVendor) {
                    state.vendorOrganizationsPowersearch = initialState.vendorOrganizationsPowersearch;
                    state.vendorPowersearchLoading = false;
                } else {
                    state.customerOrganizationsPowersearch = initialState.customerOrganizationsPowersearch;
                    state.customerPowersearchLoading = false;
                }
            })
            .addCase(organizationsOperations.searchOrganizationsPowerSearch.fulfilled, (state, action) => {
                const { isVendor, data } = action.payload;

                if (isVendor) {
                    state.vendorOrganizationsPowersearch = data.results;
                    state.vendorPowersearchLoading = false;
                } else {
                    state.customerOrganizationsPowersearch = data.results;
                    state.customerPowersearchLoading = false;
                }
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getNonEditables.rejected, (state, action) => {
                state.nonEditables = initialState.nonEditables;
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getNonEditables.fulfilled, (state, action) => {
                state.nonEditables = (action.payload as string[]).map((str) => {
                    let output = str;
                    if (str.includes("customFields")) {
                        output.replace("customFields.", "");
                    }

                    return output;
                });
                state.isLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.searchStatistic.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.isSearchLoading = false;
            })
            .addCase(organizationsOperations.searchStatistic.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isSearchLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getOrganizationLogs.rejected, (state, action) => {
                state.logsLoading = false;
                state.error = action.error.message as string;
                state.logsList = initialState.logsList;
            })
            .addCase(organizationsOperations.getOrganizationLogs.fulfilled, (state, action) => {
                state.logsLoading = false;
                state.logsList = action.payload;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getOwner.rejected, (state, action) => {
                state.owners = initialState.owners;
                state.isOwnersLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getOwner.fulfilled, (state, action) => {
                state.owners = action.payload;
                state.isOwnersLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getCustomerCareVendors.rejected, (state, action) => {
                state.customerCareVendors = initialState.customerCareVendors;
                state.customerCareLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getCustomerCareVendors.fulfilled, (state, action) => {
                state.customerCareVendors = action.payload;
                state.customerCareLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getDetailForEdit.rejected, (state, action) => {
                state.isLoading = false;
                state.organizationEdit = null;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getDetailForEdit.fulfilled, (state, action) => {
                state.organizationEdit = action.payload as GenericOrganizationEditWithFather;
                state.isLoading = false;
            })
            //-------------------------------------------
            .addCase(organizationsOperations.getOrganizationSurveillance.rejected, (state, action) => {
                state.isLoading = false;
                state.surveillance = initialState.surveillance;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getOrganizationSurveillance.fulfilled, (state, action) => {
                state.surveillance = action.payload;
                state.isLoading = false;
            })
            //-------------------------------------------
            ;
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startSearchLoading(state, action: PayloadAction<boolean | undefined>) {
            state.isSearchLoading = true;
            if (action.payload) state.isInfiniteScrolling = true;
        },
        startOwnerLoading(state) {
            state.isOwnersLoading = true;
        },
        startCustomerCareLoading(state) {
            state.customerCareLoading = true;
        },
        startPowerSearchLoading(state) {
            state.vendorPowersearchLoading = true;
            state.customerPowersearchLoading = true;
        },
        startLogsLoading(state) {
            state.logsLoading = true;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        //RESET ORGANIZATION
        resetOrganization(state) {
            state.organization = null;
            state.organizationEdit = null;
        },
        resetOrganizationList(state) {
            state.organizationList = initialState.organizationList;
        }
    }
});

export const {
    startLoading,
    resetOrganization,
    startSearchLoading,
    startLogsLoading,
    startPowerSearchLoading,
    startOwnerLoading,
    resetOrganizationList,
    startCustomerCareLoading,
    setFiltersInUrl
} = OrganizationsSlice.actions;

export default OrganizationsSlice.reducer;
