import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Box } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";
import { useLocales } from "src/locales";

interface StepButtonsProps {
    changeStep?: Dispatch<SetStateAction<number>>,
    returnPage?: string
    confirm?: boolean,
    save?: boolean,
    onSave?: VoidFunction,
    disabled?: boolean,
    loading?: boolean
}

export default function StepButtons({ changeStep, confirm, onSave, save, disabled, returnPage, loading }: StepButtonsProps) {

    const { translate } = useLocales();

    const navigate = useNavigate();

    return <Box
        sx={{
            justifyContent: 'space-between',
            display: 'flex',
            gap: 1
        }}
    >
        <Button
            variant="soft"
            color="inherit"
            sx={{ borderRadius: 100 }}
            onClick={() => {
                if (returnPage)
                    navigate(returnPage);
                else if (changeStep)
                    changeStep(prev => prev - 1);
            }}
        >
            {returnPage ? translate('request.goBack') : translate("commons.goBack")}
        </Button>
        <Box sx={{ display: 'flex', gap: 2 }}>
            {save && <Button
                variant="outlined"
                sx={{ borderRadius: 100 }}
                startIcon={<Save />}
                onClick={() => {
                    if (onSave) onSave();
                }}
            >
                {translate('commons.justSave')}
            </Button>}
            {confirm ?
                <LoadingButton
                    variant='contained'
                    disabled={disabled}
                    loading={loading}
                    color='primary'
                    sx={{ borderRadius: 100 }}
                    onClick={() => {
                        if (onSave) onSave();
                    }}
                >
                    {`${translate('commons.confirm')}`}
                </LoadingButton>
                :
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        if (changeStep) changeStep(prev => prev + 1);
                        if (onSave) onSave();
                    }}
                    sx={{ borderRadius: 100 }}
                    disabled={disabled}
                >
                    {`${translate('commons.next')}`}
                </Button>
            }

        </Box>
    </Box>;
}