import { Box, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, alpha } from '@mui/material';
import { format } from 'date-fns';
import { useLocales } from 'src/locales';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Media, Warranty, WarrantyInvoice } from 'src/@types/request';
import { vehicleOperations } from 'src/redux/vehicle';
import { useSettingsContext } from 'src/components/settings';
import { debounce } from 'lodash';
import { fortyAlertCheck, FortyDaysAlert } from 'src/components/request-utils/FeesAndNotes';
import { WarrantyDetailStyle } from '../newRequestFlow/detail/DetailWarranty';
import { Drop } from '../../backlogRequest/BacklogWarrantyConfirm';
import brandOperations from 'src/redux/brand/brand-operations';

interface WarrantyAmendProps {
    isAR: boolean,
    warranty: Warranty,
    setWarranty: Dispatch<SetStateAction<Warranty>>,
    files: Record<string, (File & { section?: string }) | null>,
    setFiles: Dispatch<SetStateAction<Record<string, (File & { section?: string }) | null>>>,
    setDeleteList: (list: string[]) => void,
    deleteList: string[],
    warrantyMedia: (Media | null)[],
    setWarrantyMedia: Dispatch<SetStateAction<(Media | null)[]>>
}

export default function WarrantyAmend({ isAR, warranty, setWarranty, files, setFiles, setDeleteList, deleteList, warrantyMedia, setWarrantyMedia }: WarrantyAmendProps) {

    const { translate } = useLocales();

    const { currency: currentCurrency, metricSystem: currentMetricSystem } = useSettingsContext();

    const { publicBrandList } = useSelector((state: RootState) => state.brand);

    const { models } = useSelector((state: RootState) => state.vehicle);

    const [showCurrency, setShowCurrency] = useState(!!(warranty?.ratePerHour?.withoutVat));

    const [showMileage2, setShowMileage2] = useState(!!(warranty?.invoices[2]?.mileage));

    const [showMileage3, setShowMileage3] = useState(!!(warranty?.invoices[3]?.mileage));

    useEffect(() => {
        if (!publicBrandList) dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    const handleDrop = (acceptedFiles: File[], inputName: string) => {
        setFiles((prevFileObjects) => ({
            ...prevFileObjects,
            [inputName]: acceptedFiles[0],
        }));
    };

    const handleDelete = (name: string) => {
        setFiles((prevFileObjects) => ({
            ...prevFileObjects,
            [name]: null,
        }));
    };

    const handleDeletePreloaded = (id: string) => {

        const index = warrantyMedia.findIndex((media) => media && media.mediaId === id);

        const mediaList = [...warrantyMedia];

        mediaList[index] = null;

        setWarrantyMedia(mediaList);

        setDeleteList(deleteList.concat([id]));
    };

    const onChangeDate = (value: Date | null, indexToCheck: number) => {
        if (value) {
            const newInvoice: WarrantyInvoice = { ...warranty.invoices[indexToCheck], date: format(value, 'yyyy-MM-dd') };

            setWarranty({ ...warranty, invoices: warranty.invoices.map((invoice, index) => index === indexToCheck ? newInvoice : invoice) });
        }
    };

    const onChangeInvoiceNum = (value: string, indexToCheck: number) => {
        if (/^[0-9]*$/.test(value) || isAR) {
            const newInvoice: WarrantyInvoice = { ...warranty.invoices[indexToCheck], number: value };

            setWarranty({ ...warranty, invoices: warranty.invoices.map((invoice, index) => index === indexToCheck ? newInvoice : invoice) });
        }
    };

    const onChangeMileage = (value: number, indexToCheck: number) => {
        if (!isNaN(value)) {
            const newInvoice: WarrantyInvoice = { ...warranty.invoices[indexToCheck], mileage: value };

            setWarranty({ ...warranty, invoices: warranty.invoices.map((invoice, index) => index === indexToCheck ? newInvoice : invoice) });
        }
    };

    useEffect(() => {
        if (!isAR)
            setWarranty(prev => ({ ...prev, ratePerHour: { ...prev.ratePerHour!, currentCurrency: currentCurrency.label } }));
    }, [currentCurrency, isAR, setWarranty]);

    const setWarrantyDelayed = debounce((obj) => setWarranty(obj), 300);

    const showFortyAlert = useMemo(() => {

        if (warranty.invoices[0].date) return fortyAlertCheck(new Date(warranty.invoices[0].date), new Date());

        return false;
    }, [warranty.invoices]);

    return (
        <Box sx={{ ...WarrantyDetailStyle }}>
            {isAR ?
                <>
                    <Box>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            {`${translate('request.form.attachDoc')}`}
                        </Typography>

                        <WarrantyMedia
                            name={"maddDoc"}
                            index={0}
                            warrantyMedia={warrantyMedia}
                            files={files}
                            handleDrop={handleDrop}
                            handleDelete={handleDelete}
                            handleDeletePreloaded={handleDeletePreloaded}
                        />

                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, mt: 2, mb: showFortyAlert ? 0 : 2 }}>

                            <TextField
                                required
                                label={`${translate('request.form.maddNum')}`}
                                onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 0)}
                                value={warranty.invoices[0].number || ""}
                            />
                            <DatePicker
                                disableFuture
                                label={`${translate('request.form.maddDate')}`}
                                views={['year', 'month', 'day']}
                                format={'dd/MM/yyyy'}
                                value={warranty.invoices[0].date ? new Date(warranty.invoices[0].date) : null}
                                onAccept={(value) => onChangeDate(value, 0)}
                                onChange={(fromDateValue, inputValue) => {
                                    if (!inputValue.validationError) {
                                        onChangeDate(fromDateValue, 0);
                                    }
                                }}
                                slotProps={{ textField: { required: true } }}
                            />
                        </Box>
                    </Box>

                    {showFortyAlert && <FortyDaysAlert />}
                </>
                :
                <>
                    <Typography variant="h6">{`${translate('request.form.complete')}`}</Typography>

                    <Box sx={{ my: 2 }}>

                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.firstInvoice')}`}</Typography>

                            <WarrantyMedia
                                name={"firstInvoice"}
                                index={0}
                                warrantyMedia={warrantyMedia}
                                files={files}
                                handleDrop={handleDrop}
                                handleDelete={handleDelete}
                                handleDeletePreloaded={handleDeletePreloaded}
                            />

                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>
                                <TextField
                                    required
                                    label={`${translate('request.invoiceNum')}`}
                                    onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 0)}
                                    value={warranty.invoices[0].number || ""}
                                />
                                <DatePicker
                                    disableFuture
                                    label={`${translate('request.invoiceDate')}`}
                                    views={['year', 'month', 'day']}
                                    format={'dd/MM/yyyy'}
                                    value={warranty.invoices[0].date ? new Date(warranty.invoices[0].date) : null}
                                    onAccept={(value) => onChangeDate(value, 0)}
                                    onChange={(fromDateValue, inputValue) => {
                                        if (!inputValue.validationError) {
                                            onChangeDate(fromDateValue, 0);
                                        }
                                    }}
                                    slotProps={{ textField: { required: true } }}
                                />
                            </Box>
                        </Box>

                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.secondInvoice')}`}</Typography>

                            <WarrantyMedia
                                name={"secondInvoice"}
                                index={1}
                                warrantyMedia={warrantyMedia}
                                files={files}
                                handleDrop={handleDrop}
                                handleDelete={handleDelete}
                                handleDeletePreloaded={handleDeletePreloaded}
                            />

                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>
                                <TextField
                                    required
                                    label={`${translate('request.invoiceNum')}`}
                                    onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 1)}
                                    value={warranty.invoices[1].number || ""}
                                />
                                <DatePicker
                                    disableFuture
                                    label={`${translate('request.invoiceDate')}`}
                                    views={['year', 'month', 'day']}
                                    format={'dd/MM/yyyy'}
                                    value={warranty.invoices[1].date ? new Date(warranty.invoices[1].date) : null}
                                    onAccept={(value) => onChangeDate(value, 1)}
                                    onChange={(fromDateValue, inputValue) => {
                                        if (!inputValue.validationError) {
                                            onChangeDate(fromDateValue, 1);
                                        }
                                    }}
                                    slotProps={{ textField: { required: true } }}
                                />
                            </Box>
                        </Box>

                        <Divider sx={{ my: 3 }} />

                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.vehicleInfo')}`}</Typography>

                            <WarrantyMedia
                                name={"vehicleInfo"}
                                index={2}
                                warrantyMedia={warrantyMedia}
                                files={files}
                                handleDrop={handleDrop}
                                handleDelete={handleDelete}
                                handleDeletePreloaded={handleDeletePreloaded}
                            />

                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>

                                <FormControl>
                                    <InputLabel required>{`${translate('request.form.vehicleBrand')}`}</InputLabel>
                                    <Select
                                        label={`${translate('request.form.vehicleBrand')}`}
                                        value={warranty.vehicle?.brand}
                                        onChange={(e) => {
                                            setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, brand: e.target.value } });
                                            dispatch(vehicleOperations.getModels(e.target.value.toLowerCase()));
                                        }}
                                    >
                                        {publicBrandList?.map((brand, ind) => (brand.enabled &&
                                            <MenuItem key={ind} value={brand.id}>
                                                {brand.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <InputLabel required>{`${translate('commons.model')}`}</InputLabel>
                                    <Select
                                        name="model"
                                        label={`${translate('commons.model')}`}
                                        disabled={warranty.vehicle?.brand === '' || models.length === 0}
                                        value={warranty.vehicle?.model}
                                        onChange={(e) => setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, model: e.target.value } })}
                                        sx={{ background: (theme) => (warranty.vehicle?.brand === '' || models.length === 0) ? alpha(theme.palette.grey[300], 0.40) : "" }}
                                    >
                                        {warranty.vehicle?.brand && Object.values(models).map(({ text }) => (
                                            <MenuItem key={text} value={text}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    required
                                    label={`${translate('commons.vin')}`}
                                    onChange={(e) => setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, vin: e.currentTarget.value } })}
                                    value={warranty.vehicle?.vin}
                                />

                                <TextField
                                    required
                                    label={`${translate('commons.plate')}`}
                                    onChange={(e) => setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, plate: e.currentTarget.value } })}
                                    value={warranty.vehicle?.plate}
                                />

                                <DatePicker
                                    disableFuture
                                    label={`${translate('request.form.dateAdmission')}`}
                                    views={['year', 'month', 'day']}
                                    format={'dd/MM/yyyy'}
                                    value={warranty.vehicle?.registrationDate ? new Date(warranty.vehicle.registrationDate) : null}
                                    onAccept={(value) => {
                                        if (value) setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, registrationDate: format(value, 'yyyy-MM-dd') } });
                                    }}
                                    onChange={(fromDateValue, inputValue) => {
                                        if (!inputValue.validationError && fromDateValue) setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, registrationDate: format(fromDateValue, 'yyyy-MM-dd') } });
                                    }}
                                    slotProps={{ textField: { required: true } }}
                                />
                            </Box>
                        </Box>

                        <Divider sx={{ my: 3 }} />

                        <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.invoiceCustomer')}`}</Typography>

                        <Box>
                            <Typography variant="subtitle1" sx={{ mb: 2 }}>{`${translate('request.form.invoiceRepair')}`}</Typography>

                            <WarrantyMedia
                                name={"firstRepairInvoice"}
                                index={3}
                                warrantyMedia={warrantyMedia}
                                files={files}
                                handleDrop={handleDrop}
                                handleDelete={handleDelete}
                                handleDeletePreloaded={handleDeletePreloaded}
                            />

                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2, "& MuiOutlinedInput-root": { pr: 0 } }}>

                                <TextField
                                    required
                                    label={`${translate('request.invoiceNum')}`}
                                    onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 2)}
                                    value={warranty.invoices[2].number || ""}
                                />
                                <DatePicker
                                    disableFuture
                                    label={`${translate('request.invoiceDate')}`}
                                    views={['year', 'month', 'day']}
                                    format={'dd/MM/yyyy'}
                                    value={warranty.invoices[2].date ? new Date(warranty.invoices[2].date) : null}
                                    onAccept={(value) => onChangeDate(value, 2)}
                                    onChange={(fromDateValue, inputValue) => {
                                        if (!inputValue.validationError) {
                                            onChangeDate(fromDateValue, 2);
                                        }
                                    }}
                                    slotProps={{ textField: { required: true } }}
                                />
                                <TextField
                                    required
                                    label={`${translate('commons.mileage')}`}
                                    value={warranty.invoices[2].mileage || ""}
                                    onChange={(e) => onChangeMileage(+e.currentTarget.value, 2)}
                                    InputProps={{ endAdornment: showMileage2 ? <InputAdornment position="start">{currentMetricSystem ? "KM" : "MI"}</InputAdornment> : null }}
                                    onFocus={() => { if (!showMileage2) setShowMileage2(true); }}
                                    onBlur={() => { if (showMileage2 && !warranty.invoices[2].mileage) setShowMileage2(false); }}
                                />
                            </Box>
                        </Box>

                        <Box>
                            <Typography variant="subtitle1" sx={{ mb: 2 }}>{`${translate('request.form.invoiceSecondRepair')}`}</Typography>

                            <WarrantyMedia
                                name={"secondRepairInvoice"}
                                index={4}
                                warrantyMedia={warrantyMedia}
                                files={files}
                                handleDrop={handleDrop}
                                handleDelete={handleDelete}
                                handleDeletePreloaded={handleDeletePreloaded}
                            />

                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>

                                <TextField
                                    required
                                    label={`${translate('request.invoiceNum')}`}
                                    onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 3)}
                                    value={warranty.invoices[3].number || ""}
                                />
                                <DatePicker
                                    disableFuture
                                    label={`${translate('request.invoiceDate')}`}
                                    views={['year', 'month', 'day']}
                                    format={'dd/MM/yyyy'}
                                    value={warranty.invoices[3].date ? new Date(warranty.invoices[3].date) : null}
                                    onAccept={(value) => onChangeDate(value, 3)}
                                    onChange={(fromDateValue, inputValue) => {
                                        if (!inputValue.validationError) {
                                            onChangeDate(fromDateValue, 3);
                                        }
                                    }}
                                    slotProps={{ textField: { required: true } }}
                                />
                                <TextField
                                    required
                                    label={`${translate('commons.mileage')}`}
                                    value={warranty.invoices[3].mileage || ""}
                                    onChange={(e) => onChangeMileage(+e.currentTarget.value, 3)}
                                    InputProps={{ endAdornment: showMileage3 ? <InputAdornment position="start">{currentMetricSystem ? "KM" : "MI"}</InputAdornment> : null }}
                                    onFocus={() => { if (!showMileage3) setShowMileage3(true); }}
                                    onBlur={() => { if (showMileage3 && !warranty.invoices[3].mileage) setShowMileage3(false); }}
                                />

                            </Box>
                        </Box>

                        <Divider sx={{ my: 3 }} />

                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('commons.moreDetail')}`}</Typography>

                            <Box sx={{ my: 2 }}>
                                <Box sx={{ display: "flex" }}>
                                    <Typography variant="subtitle1">{`${translate('request.issueDescription')}`}</Typography>
                                    <Typography variant="subtitle1" sx={{ color: 'error.main' }}>*</Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder={`${translate('request.form.notePlaceholder')}`}
                                    defaultValue={warranty.note}
                                    onChange={(e) => setWarrantyDelayed({ ...warranty, note: e.currentTarget.value })}
                                    error={(warranty.note?.length || 0) > 72}
                                    helperText={(warranty.note?.length || 0) > 72 && `${translate('request.noteErrorText')}`}
                                    sx={{ mt: 1 }}
                                />
                            </Box>

                            <Box sx={{ my: 2 }}>
                                <Box sx={{ display: "flex" }}>
                                    <Typography variant="subtitle1">{`${translate('request.diagnosis')}`}</Typography>
                                    <Typography variant="subtitle1" sx={{ color: 'error.main' }}>*</Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder={`${translate('request.form.notePlaceholder')}`}
                                    defaultValue={warranty.diagnosis}
                                    onChange={(e) => setWarrantyDelayed({ ...warranty, diagnosis: e.currentTarget.value })}
                                    error={warranty.diagnosis ? warranty.diagnosis.length > 72 : false}
                                    helperText={(warranty.diagnosis ? warranty.diagnosis.length > 72 : false) && `${translate('request.noteErrorText')}`}
                                    sx={{ mt: 1 }}
                                />
                            </Box>

                            <Box sx={{ my: 2 }}>
                                <Box sx={{ display: "flex" }}>
                                    <Typography variant="subtitle1">{`${translate('request.remedy')}`}</Typography>
                                    <Typography variant="subtitle1" sx={{ color: 'error.main' }}>*</Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    placeholder={`${translate('request.form.notePlaceholder')}`}
                                    defaultValue={warranty.solution}
                                    onChange={(e) => setWarrantyDelayed({ ...warranty, solution: e.currentTarget.value })}
                                    error={(warranty.solution?.length || 0) > 72}
                                    helperText={(warranty.solution?.length || 0) > 72 && `${translate('request.noteErrorText')}`}
                                    sx={{ mt: 1 }}
                                />
                            </Box>

                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>
                                <TextField
                                    label={`${translate('request.timeRepair')}`}
                                    type={"number"}
                                    value={warranty.hoursSpent || ""}
                                    onChange={(e) => setWarranty({ ...warranty, hoursSpent: Number(e.currentTarget.value) })}
                                />
                                <TextField
                                    label={`${translate('request.hourRateRepair')}`}
                                    type={"number"}
                                    value={warranty.ratePerHour?.withoutVat || ""}
                                    onChange={(e) => setWarranty({ ...warranty, ratePerHour: { ...warranty.ratePerHour!, withoutVat: Number(e.currentTarget.value) } })}
                                    InputProps={{ startAdornment: showCurrency ? <InputAdornment position="start">{currentCurrency.symbol}</InputAdornment> : null }}
                                    onFocus={() => { if (!showCurrency) setShowCurrency(true); }}
                                    onBlur={() => { if (showCurrency && !warranty.ratePerHour?.withoutVat) setShowCurrency(false); }}
                                />

                            </Box>
                        </Box>
                    </Box>
                </>
            }
        </Box>
    );
}

//----------------------------------------------------------------------------------------------------
interface WarrantyMediaProps {
    name: string,
    index: number,
    warrantyMedia: (Media | null)[],
    files: Record<string, (File & { section?: string }) | null>,
    handleDrop: (acceptedFiles: File[], inputName: string) => void,
    handleDelete: (name: string) => void,
    handleDeletePreloaded: (id: string) => void
}

function WarrantyMedia({ name, index, warrantyMedia, files, handleDrop, handleDelete, handleDeletePreloaded }: WarrantyMediaProps) {

    const { translate } = useLocales();

    return (
        <>
            {!warrantyMedia[index] ?
                (
                    !files[name] ? (
                        <Drop inputName={name} onDrop={(files) => handleDrop(files, name)} />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                background: '#F4F6F8',
                                p: 2,
                                borderRadius: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                                <InsertDriveFileIcon />
                                <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                                    {files[name].name}
                                </Typography>
                            </Box>
                            <IconButton
                                sx={{ ml: 'auto', color: 'inherit' }}
                                onClick={() => handleDelete(name)}
                            >
                                <DeleteForeverIcon color="inherit" />
                            </IconButton>
                        </Box>
                    )
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            background: '#F4F6F8',
                            p: 2,
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                            <InsertDriveFileIcon />
                            <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                                {`${translate(`request.form.${name}`)}`}
                            </Typography>
                        </Box>
                        <IconButton
                            sx={{ ml: 'auto', color: 'inherit' }}
                            onClick={() => handleDeletePreloaded(warrantyMedia![index]!.mediaId!)}
                        >
                            <DeleteForeverIcon color="inherit" />
                        </IconButton>
                    </Box>
                )}
        </>
    );
}