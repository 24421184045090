import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, List, ListItem, Select, TextField } from "@mui/material";
import Page from "src/appComponents/Page";
import LoadingScreen from "src/appComponents/loading-screen";
import { useLocales } from "src/locales";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import { ReactNode, useEffect, useState } from "react";
import { CustomField, NewCustomField, SelectOptions } from "src/@types/customField";
import { PATH_DASHBOARD } from "src/routes/paths";
import LocalizedInput from "src/appComponents/LocalizedInput";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router";
import { detail, section, update } from "src/services/customFieldServices";
import { LocalizedString } from "src/@types/commons";
import { IconButtonAnimate } from "src/components/animate";
import { StarIcon } from "src/theme/overrides/CustomIcons";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as Yup from 'yup';
import SaveModal from "src/components/modals/SaveModal";
import { convert } from "src/utils/currency";
import { CurrencyTypes } from "src/@types/vehicle";
import useCurrency from "src/appHooks/useCurrency";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";

export default function CustomFieldEdit() {

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const { rates } = useCurrency();

    const [isLoading, setIsLoandig] = useState(true);

    const [visibility, setVisiblity] = useState('');

    const { themeStretch, currency } = useSettingsContext();

    const [field, setField] = useState<CustomField>();

    const { id } = useParams();

    const [searchable, setSearchable] = useState(false);

    const [autoComplete, setAutoComplete] = useState(false);

    const [check, setCheck] = useState(false);

    const [locError, setLocError] = useState<Record<number, string>>({});

    const [isOpen, setIsOpen] = useState(false);

    const [sections, setSections] = useState<string[]>([]);

    const [newSection, setNewSection] = useState("");

    const handleSearchable = (e: any) => {
        setSearchable(e.target.checked);
        setField({ ...field!, searchable: e.target.checked });
        if (!e.target.checked)
            setAutoComplete(false);
    };

    const handleAutocomplete = (e: any) => {
        setAutoComplete(e.target.checked);
        setField({ ...field!, autoComplete: e.target.checked });
    };

    const handleCheck = (e: any) => {
        setField({ ...field!, required: e.target.checked });
        setCheck(e.target.checked);
    };

    const [errors, setErrors] = useState<any>({});

    const [lang, setLang] = useState('En');

    const [updateVar, setUpdateVar] = useState(false);

    const LocalizedStringSchema = Yup.object().shape({
        text: Yup.string().trim().required(),
        culture: Yup.string().trim().required()
    });

    const sectionFactory: Record<string, ReactNode> = {
        User: <></>,
        Vehicle: <></>,
        Organization:
            <>
                <option value={'General'}>{`${translate('organization.detail.general')}`}</option>
                <option value={'Address'}>{`${translate('commons.address')}`}</option>
                <option value={'Contact'}>{`${translate('organization.form.contacts')}`}</option>
                <option value={'Fiscal'}>{`${translate('organization.detail.fiscal')}`}</option>
            </>,
        RvMatrix: <></>,
        Brand: <></>,
        "": <></>
    };

    const schema = Yup.object().shape({
        name: Yup.array().of(LocalizedStringSchema),
        select: Yup.string().trim().test('req', `${translate('customField.validation.required')}`, function (value) {
            const field = this.parent.type;

            if (field && field === 'select')
                return value !== '';

            return true;
        }),
        defaultValue: Yup.string().trim().test('wrongFormat', `${translate('customField.validation.wrongFormat')}`, function (value) {
            const { format, type } = this.parent;

            let regex: RegExp | null = null;

            if (type === 'date') {

                regex = /^(?!3000)(?!00)(?:(?:[1-2]\d{3})|(?:[1-9]\d{2})|0[1-9]\d|00[1-9])-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))$/;
                let regex2 = /^(?:(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/(?:(?!3000)(?!00)(?:(?:[1-2]\d{3})|(?:[1-9]\d{2})|0[1-9]\d|00[1-9])))$/;

                if (value) {
                    return (regex.test(value) || regex2.test(value)) && value !== 'Invalid Date';
                }

                return true;
            }

            if (type === 'currency') {
                regex = /^-?\d+(\.\d{1,2})?(,\d{1,2})?$/;
                if (regex && value) {
                    return regex.test(value);
                }

                return true;
            }

            switch (format) {
                case 'alphanumeric':
                    regex = /^[a-zA-Z0-9]+$/;
                    break;
                case 'address':
                    regex = /^[a-zA-Z0-9\s.,'-]+$/;
                    break;
                case 'email':
                    regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    break;
                case 'number':
                    regex = /^-?\d+$/;
                    break;
                case 'float':
                    regex = /^-?\d+(\.\d+)?(,\d+)?$/;
                    break;
            }
            if (regex && value) {
                return regex.test(value);
            }

            return true;
        })

    });

    const getOptions = (elem: SelectOptions) => {
        if (elem)
            return elem.values.find(x => x.culture === lang)?.text || elem.values[0].text;
    };

    const getData = async (id: string) => {
        const det = await detail(id);

        setField(det.data);
        setVisiblity(det.data.visibility);
        setCheck(det.data.required);
        setSearchable(det.data.searchable);
        setAutoComplete(det.data.autoComplete);

        const sec = await section();

        setSections(sec.data);
        setIsLoandig(false);
    };

    useEffect(() => {
        if (id)
            getData(id);
    }, [id, currentLang]);

    const handleBlur = (e: any) => {
        setLocError(e);
    };

    const onChangeTabs = (tabs: LocalizedString[], name: string) => {

        setField({ ...field!, [name]: tabs });
    };

    const handleB = (e: any) => {
        setUpdateVar(!updateVar);

        schema.validateAt(e.target.name, { ...field })
            .then(() => setErrors((prev: any) => ({ ...prev, [e.target.name]: '' })))
            .catch((err) => setErrors((prev: any) => ({ ...prev, [e.target.name]: err.message })));
    };

    const handleSelectOptions = (e: any, index: number) => {
        if (field!.selectOptions![index]) {
            let opt = field!.selectOptions![index];
            opt.values.find(x => x.culture === lang) ?
                opt.values.find(x => x.culture === lang)!.text = e.target.value as string :
                opt.values.push({ culture: lang, text: e.target.value });
            let out = field!.selectOptions!;
            out[index] = opt;
            if (index === field!.selectOptions!.length - 1) {
                out.push({ values: [{ culture: "En", text: "" }], isDefault: false });
            }
            setField({ ...field!, selectOptions: out });
        }
    };

    const handleFavourite = (index: number) => {
        let opt = field!.selectOptions!;
        opt.forEach((x, ind) => { if (ind === index) return; x.isDefault = false; });
        opt[index].isDefault = !opt[index].isDefault;
        setField({ ...field!, selectOptions: opt });
    };

    const handleDeleteOption = (index: number) => {
        let list = field!.selectOptions!.filter((_, ind) => ind !== index);
        setField({ ...field!, selectOptions: list });

    };

    const handleSubmit = async () => {
        setIsOpen(false);
        if (field!.type === 'currency' && field!.defaultValue) {

            let val = field!.defaultValue.replace(',', '.');
            val = val.replace(/[^\d.-]/g, '');
            field!.defaultValue = convert(+val, currency.label, CurrencyTypes.EUR, rates).toString();
        }
        if (newSection)
            field!.section = newSection;

        await update(field! as NewCustomField);

        dispatch(setSuccessMessage({ text: translate('customField.messages.updateSuccess'), returnTo: PATH_DASHBOARD.tenant.CustomFieldList }));
    };

    const handleDate = (date: Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            setField({ ...field!, defaultValue: dayjs(date).format('YYYY-MM-DD') });
        }
        else {
            setField({ ...field!, defaultValue: 'Invalid Date' });
        }
    };

    const handleVisibility = (e: any) => {
        setField({ ...field!, visibility: e.target.value });
        setVisiblity(e.target.value);
    };

    return (
        <Page title={`${translate('commons.edit')}`}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <SaveModal
                        isOpen={isOpen}
                        toggle={() => setIsOpen(!isOpen)}
                        handleSave={() => handleSubmit()}
                        saveText={`${translate('customField.messages.updateQuestion')}`}
                    />

                    <HeaderBreadcrumbs
                        heading={field!.name[0].text || '-'}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('commons.customField')}`, href: PATH_DASHBOARD.tenant.CustomFieldList },
                            { name: `${field!.name[0].text}` },
                            { name: `${translate('customField.form.title.edit')}` }
                        ]}
                    />
                    <Box sx={{ p: 3 }} >

                        <LocalizedInput
                            defaultTabs={field!.name}
                            error={!!errors.nameError}
                            label={`${translate('commons.name')}`}
                            onChangeTabs={(e) => onChangeTabs(e, 'name')}
                            onChangeLang={(lang) => setLang(lang)}
                            onBlur={handleBlur}
                        >

                            <Box sx={{
                                mt: 2,
                                mb: 2,
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                pt: 3,
                                gridTemplateColumns: 'repeat(2, 2fr)',
                            }}>
                                <div hidden={field!.type.toLowerCase() !== 'string' && field!.type.toLowerCase() !== 'number'}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        label={`${translate('commons.format')}`}
                                        value={field!.format}
                                        name="format"
                                        disabled
                                    />
                                </div>
                                <div hidden={field!.type.toLowerCase() !== 'date'}>
                                    <DatePicker
                                        views={['year', 'month', 'day']}
                                        format={'dd/MM/yyyy'}
                                        value={field!.defaultValue ? dayjs(field!.defaultValue).toDate() : null}
                                        onAccept={(val) => setField({ ...field!, defaultValue: val ? format(new Date(val), 'yyyy-MM-dd') : "" })}
                                        onChange={(fromDateValue, inputval) => {
                                            if (!inputval.validationError)
                                                handleDate(dayjs(fromDateValue));
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: !!errors.defaultValue,
                                                fullWidth: true,
                                                helperText: errors.defaultValue,
                                                sx: { width: '100%' },
                                                onFocus: () => setUpdateVar(!updateVar),
                                                onBlur: handleB,
                                                name: "defaultValue"
                                            }
                                        }}
                                    />
                                </div>
                                <div hidden={field!.type.toLowerCase() !== 'currency'}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        label={`${translate('customField.form.defaultValue')}`}
                                        onFocus={() => setUpdateVar(!updateVar)}
                                        onBlur={handleB}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment> }}
                                        error={!!errors.defaultValue}
                                        helperText={errors.defaultValue}
                                        value={field!.defaultValue}
                                        onChange={(e) => setField({ ...field!, [e.target.name]: e.target.value })}
                                        name="defaultValue"
                                    />
                                </div>
                                <div hidden={field!.type.toLowerCase() !== 'string' && field!.type.toLowerCase() !== 'number'}>

                                    <TextField
                                        sx={{ width: '100%' }}
                                        label={`${translate('customField.form.defaultValue')}`}
                                        value={field!.defaultValue}
                                        onChange={(e) => setField({ ...field!, [e.target.name]: e.target.value })}
                                        InputProps={{ startAdornment: field!.type.toLowerCase() === 'currency' ? <InputAdornment position="start">{currency.symbol}</InputAdornment> : "" }}
                                        name="defaultValue"
                                        onFocus={() => setUpdateVar(!updateVar)}
                                        onBlur={handleB}
                                        error={!!errors.defaultValue}
                                        helperText={errors.defaultValue}
                                    />
                                </div>

                                <div hidden={field!.type.toLowerCase() !== 'checkbox'}>
                                    <FormControl fullWidth>
                                        <InputLabel required id='select' >{`${translate('customField.form.defaultValue')}`}</InputLabel>
                                        < Select label={`${translate('customField.form.defaultValue')}`}
                                            value={field!.defaultValue ? field!.defaultValue : 'disabled'}
                                            onChange={(e) => setField({ ...field!, [e.target.name]: e.target.value })}
                                            name="defaultValue"
                                            native>
                                            <option value={'disabled'}>{`${translate('commons.disabled')}`}</option>
                                            <option value={'enabled'}>{`${translate('commons.enabled')}`}</option>
                                        </Select>
                                    </FormControl>
                                </div>
                                <FormControl>
                                    <InputLabel >{`${translate('customField.form.section')}`}</InputLabel>
                                    <Select
                                        id="section"
                                        label={`${translate('customField.form.section')}`}
                                        onChange={(e) => { setField({ ...field!, [e.target.name]: e.target.value }); setNewSection(""); }}
                                        value={field?.section}
                                        native
                                        name='section' >
                                        <option value='OtherInfo' >{`${translate('customField.section.otherInfo')}`}</option>
                                        {
                                            sectionFactory[field!.entityType]
                                        }
                                        {sections.map((sec, index) => (
                                            <option value={sec} key={index}>{sec}</option>
                                        ))}
                                        <option value='-1'>{`${translate('customField.section.custom')}`}</option>
                                    </Select>
                                </FormControl>
                                <Box hidden={field?.section !== '-1'}>
                                    <TextField value={newSection} fullWidth id="newSection" name="newSection" onChange={(e) => setNewSection(e.target.value)} placeholder="Extra" label='section name' />
                                </Box>

                                <FormControl fullWidth >
                                    <InputLabel >{`${translate('commons.visibility')}`}</InputLabel>
                                    <Select
                                        label={`${translate('commons.visibility')}`}
                                        onChange={handleVisibility}
                                        name="visibility"
                                        native
                                        value={visibility}
                                        id='visibility'>
                                        <option value='Always' >{`${translate('commons.always')}`}</option>
                                        <option value='OnlyIfNotEmpty'>{`${translate('commons.onlyIfNotEmpty')}`}</option>
                                        <option value='Never'>{`${translate('commons.never')}`}</option>
                                    </Select>
                                </FormControl>

                            </Box>

                            {field && field.type.toLowerCase() === 'select' && <Box hidden={field!.type.toLowerCase() !== 'select'}>
                                <List>

                                    <ListItem>

                                        <TextField
                                            name="select"
                                            onFocus={() => setUpdateVar(!updateVar)}
                                            onBlur={handleB}
                                            error={!!errors.select}
                                            helperText={errors.select}
                                            label={`${translate('commons.option')} 1`} value={field!.selectOptions && getOptions(field!.selectOptions[0])}
                                            onChange={(e) => handleSelectOptions(e, 0)}
                                        />

                                        <IconButtonAnimate onClick={() => handleFavourite(0)}>
                                            <StarIcon sx={{ color: field!.selectOptions && !!field!.selectOptions[0].isDefault ? 'primary.dark' : 'primary.lighter' }} />
                                        </IconButtonAnimate>

                                    </ListItem>

                                    {field!.selectOptions && field!.selectOptions.slice(1).map((elem, index) => (
                                        <ListItem key={index}>

                                            <TextField
                                                key={index}
                                                label={`${translate('commons.option')} ${index + 2}`}
                                                value={getOptions(elem)}
                                                onChange={(e) => handleSelectOptions(e, index + 1)}
                                            />

                                            <IconButtonAnimate onClick={() => handleFavourite(index + 1)} >
                                                <StarIcon sx={{ color: elem.isDefault ? 'primary.dark' : 'primary.lighter' }} />
                                            </IconButtonAnimate>

                                            <IconButton onClick={() => handleDeleteOption(index + 1)}>
                                                <DeleteForeverIcon />
                                            </IconButton>

                                        </ListItem>
                                    ))}

                                </List>
                            </Box>}

                            <FormControlLabel
                                label={<b>{`${translate("commons.required")}`}</b>}
                                onChange={handleCheck}
                                control={<Checkbox name='required' checked={check} />}
                            />

                            <FormControlLabel
                                label={<b>{`${translate("commons.searchable")}`}</b>}
                                onChange={handleSearchable}
                                control={<Checkbox name='searchable' checked={searchable} />}
                            />

                            <FormControlLabel
                                label={<b>{`${translate("commons.autocomplete")}`}</b>}
                                onChange={handleAutocomplete}
                                disabled={!searchable}
                                control={<Checkbox name='autocomplete' checked={autoComplete} />}
                            />

                            <div hidden={!check}>
                                <LocalizedInput
                                    defaultTabs={field!.validationMessage ? field!.validationMessage : undefined}
                                    label={`${translate('customField.form.validationMessage')}`}
                                    onChangeTabs={(e) => onChangeTabs(e, 'validationMessage')}
                                    //onChangeLang={(lang) => setLang(lang)}
                                    reset={!check}
                                    onBlur={handleBlur}
                                />
                            </div>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.CustomFieldList)}>
                                    {`${translate("commons.cancel")}`}
                                </Button>
                                <LoadingButton disabled={Object.values(locError).some(l => l !== '') || (field?.entityType.toLowerCase() === 'select' && !field.selectOptions)} type="submit" variant="contained" onClick={() => setIsOpen(true)}>
                                    {`${translate("role.form.save")}`}
                                </LoadingButton>
                            </Box>

                        </LocalizedInput>
                    </Box>

                </Container>
            )}

        </Page>
    );
}