import { OrganizationPermissionTypes } from "src/@types/permissions";
import { useAuth } from "src/appHooks/useAuth";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import useResponsive from "src/hooks/useResponsive";
import { hasPermissions } from "src/utils/user";

export default function JiraIntegration() {

    const { organization } = useUserOrganizationContext();

    const { isAuthenticated, user } = useAuth();

    const isDesktop = useResponsive('up', 'lg');

    if (isAuthenticated &&
        !user?.isGod &&
        isDesktop &&
        hasPermissions([OrganizationPermissionTypes.Support_TicketManagement], organization?.roles ?? [])) {

        const script = document.createElement('script');

        script.setAttribute('data-jsd-embedded', 'true');
        script.dataset.key = 'ee2852ad-6ecc-4bd5-9939-bc69e6b1c162';
        script.dataset.baseUrl = "https://jsd-widget.atlassian.com";
        script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';

        script.onload = () => {
            window.document.dispatchEvent(
                new Event("DOMContentLoaded", {
                    bubbles: true,
                    cancelable: true,
                })
            );
        };

        document.body.appendChild(script);

    }

    return null;
}