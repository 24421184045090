import { Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import { useSettingsContext } from 'src/components/settings';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { RuleTabs } from 'src/@types/rules';
import { rulesOperations } from 'src/redux/rules';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import LoadingScreen from 'src/appComponents/loading-screen';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import InvoiceRulesEdit from './InvoiceRulesEdit';
import FrequencyRulesEdit from './FrequencyRulesEdit';
import PartCategories from './PartCategories';
import PartBlacklist from './PartBlacklist';
import VinBlacklist from './VinBlacklist';

export default function RulesEdit() {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const { rulesTab, ruleType } = useParams();

    const { isLoading, rule } = useSelector((state: RootState) => state.rules);

    useEffect(() => {
        if (!rule) {
            dispatch(rulesOperations.getRule());
        }
    }, [rule]);

    const getEditTitle = () => {
        if (!ruleType) {
            return "edit" + rulesTab?.charAt(0).toUpperCase() + rulesTab?.slice(1);
        }

        return "edit" + ruleType?.charAt(0).toUpperCase() + ruleType?.slice(1);
    };

    const renderEditForm = () => {
        if (rulesTab === "request") {
            switch (ruleType) {
                case "partCategories":
                    return <PartCategories />;
                case "partBlacklist":
                    return <PartBlacklist />;
                case "partVin":
                    return <VinBlacklist />;
            }
        } else {
            switch (rulesTab) {
                case "invoice":
                    return <InvoiceRulesEdit />;
                case "frequency":
                    return <FrequencyRulesEdit />;
            }
        }
    };

    return (
        <Page title={translate('rules.form.edit.title')}>

            {isLoading ? (<LoadingScreen />) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={translate('commons.rules')}
                        links={[
                            { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                            { name: translate("commons.rules"), href: PATH_DASHBOARD.rules.listTab(rulesTab as RuleTabs) },
                            { name: translate(`rules.form.edit.${getEditTitle()}`) }
                        ]}
                    />

                    {renderEditForm()}

                </Container>
            )}
        </Page>
    );
}
