import { Box, Container, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Request, RequestOrg } from "src/@types/request";
import DateZone from "src/appComponents/DateZone";
import { Logo } from "src/appComponents/logo";
import { useLocales } from "src/locales";
import { useBarcode } from "next-barcode";
import useTenant from "src/appHooks/useTenant";
import { ReactNode, useCallback } from "react";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";
import { Address } from "src/@types/organizations";
import { getFirstLowerCase } from "src/appUtils/string";
import { CheckCircle } from "@mui/icons-material";

interface ReturnLabelProps {
    request: Request
    docExternalId: {
        orderId: string,
        invoiceExternalId: string,
        deliveryNoteExternalId: string
    }[],
    returnToCustomer?: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: '1px solid black',
    borderBottom: 'none',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    color: 'black',
    textTransform: 'uppercase'
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& td': {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
}));

const StyledAddressTypography = styled(Typography)(({ theme }) => ({
    color: '#303030',
    fontFamily: 'Public Sans',
    fontWeight: '400',
    wordWrap: 'break-word',
}));

export default function ReturnLabel({ request, docExternalId, returnToCustomer }: ReturnLabelProps) {

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    const customFieldName = useCallback((id: string) => {

        return getCustomFieldLabel(customFields, id, currentLang);

    }, [currentLang, customFields]);

    const { inputRef } = useBarcode({
        value: request.barcode,
        options: { displayValue: true }
    });

    const confirmFields = ['request.confirmOrigin', 'request.confirmCarrier', 'request.confirmDestination'];

    const breakPage = (request.items.length + request.exceededItems.length) > 6;

    return (
        <Container sx={{ px: 2 }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>

                <Box id='LEFT CONTAINER'
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        transform: 'rotate(-90deg)',
                        flexShrink: 'inherit',
                        width: '20%',
                        mr: 8,
                        justifyContent: 'space-evenly'
                    }}
                >
                    <canvas ref={inputRef} style={{ maxWidth: '300%' }} />
                </Box>

                <Box sx={{ display: 'grid', width: '80%' }}>

                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', breakInside: 'avoid-column' }}
                        id='RIGHT CONTAINER'
                    >
                        <Box
                            sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', height: '100%' }}
                            id='FIRST ROW'
                        >
                            <Box sx={{ border: '3.50px black dotted', width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        color: 'black',
                                        fontSize: 30,
                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}
                                >
                                    {returnToCustomer ? translate(`request.anomalies.detail.return`).toString() : translate(`request.${request.requestType}`).toString()}</Typography>
                            </Box>

                            {returnToCustomer &&
                                <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr' }, width: '50%' }}>
                                    <ReturnBox
                                        label={translate('request.form.returnReason')}
                                        value={translate(`request.${request.requestType}`)}
                                    />

                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"] &&
                                        <ReturnBox
                                            label={customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                                            value={request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"]}
                                        />
                                    }
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] &&
                                        <ReturnBox
                                            label={customFieldName("stellantis-europe-returnrequestapprover-sapcsps-code")}
                                            value={request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"]}
                                        />
                                    }
                                    {request.requestType === "Warranty" &&
                                        <ReturnBox
                                            label={translate('request.detail.dossier')}
                                            value={request.warranty && (request.warranty.invoices.length > 1 ? request.approverNotes?.customFields["invoice"] + "" : request.warranty.invoices[0].number) || ""}
                                        />
                                    }
                                </Box>
                            }
                            {!returnToCustomer && request.requestType !== "Core" && request.requestType !== "Warranty" &&
                                <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr' }, width: '50%' }} >
                                    <ReturnBox
                                        label={translate('commons.action')}
                                        value={translate(`request.${request.approverNotes && getFirstLowerCase(request.approverNotes.approvedType)}`)}
                                    />

                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"] &&
                                        <ReturnBox
                                            label={customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                                            value={request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"]}
                                        />

                                    }
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] &&
                                        <ReturnBox
                                            label={customFieldName("stellantis-europe-returnrequestapprover-sapcsps-code")}
                                            value={request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"]}
                                        />
                                    }

                                </Box>}
                            {
                                !returnToCustomer && request.requestType === "Warranty" &&
                                <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr' }, width: '50%' }} >
                                    <ReturnBox
                                        label={translate('request.detail.dossier')}
                                        value={request.warranty && (request.warranty.invoices.length > 1 ? request.approverNotes?.customFields["invoice"] + "" : request.warranty.invoices[0].number) || ""}
                                    />
                                </Box>

                            }

                        </Box>

                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', mt: 3, breakInside: 'avoid-region' }}
                            id='SECOND ROW'
                        >
                            <TableContainer >
                                <Table sx={{ borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>{translate(`request.partReference`)}</StyledTableCell>
                                            {request.requestType === "Core" &&
                                                <StyledTableCell>{translate(`request.detail.depositRef`)}</StyledTableCell>
                                            }
                                            <StyledTableCell>{translate(`commons.quantity`)}</StyledTableCell>
                                            <StyledTableCell>{translate(`documents.filter.docRef`)}</StyledTableCell>
                                            <StyledTableCell>{translate(`request.big`)}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {request.items.map((row, index) => {
                                            const doc = docExternalId.find(d => d.orderId === row.orderId);

                                            const number = row.product.customFields['stellantis-europe-spareparts-deposit-number'] ?? "";

                                            const description = row.product.customFields['stellantis-europe-spareparts-deposit'] ?? "";

                                            return (
                                                <StyledTableRow key={index}>
                                                    <TableCell><b>{row.product.externalId}</b>  {row.product.name}</TableCell>
                                                    {request.requestType === "Core" &&
                                                        <TableCell><b>{number}</b> {description}</TableCell>
                                                    }
                                                    <TableCell>{row.requiredQuantity || row.approvedQuantity}</TableCell>
                                                    <TableCell>{(doc?.invoiceExternalId || doc?.deliveryNoteExternalId) ?? ""}</TableCell>
                                                    <TableCell align="center">{row.bigAndHeavy && <CheckCircle fontSize="medium" />}</TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                        {request.exceededItems.map((row, index) => {
                                            const doc = docExternalId.find(d => d.orderId === row.order?.id);

                                            const number = row.product.customFields['stellantis-europe-spareparts-deposit-number'] ?? "";

                                            const description = row.product.customFields['stellantis-europe-spareparts-deposit'] ?? "";

                                            return (
                                                <StyledTableRow key={index}>
                                                    <TableCell>{row.product.externalId + " " + row.product.name}</TableCell>
                                                    {request.requestType === "Core" &&
                                                        <TableCell><b>{number}</b> {description}</TableCell>
                                                    }
                                                    <TableCell>{row.quantity}</TableCell>
                                                    <TableCell>{(doc?.invoiceExternalId || doc?.deliveryNoteExternalId) ?? ""}</TableCell>
                                                    <TableCell align="center">{row.bigAndHeavy && <CheckCircle fontSize="medium" />}</TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box sx={{
                            breakBefore: breakPage ? 'page' : undefined,
                            breakInside: breakPage ? 'avoid' : undefined
                        }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 0', width: '100%', mb: 3 }}>

                                <Addresses returnToCustomer={returnToCustomer} request={request} />

                            </Box>

                            <Box
                                sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 auto', mb: 3 }}
                                id='THIRD ROW'
                            >
                                {confirmFields.map((field, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            border: '0.50px black solid',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1, p: 1, pb: '60px'
                                        }}
                                    >
                                        <Typography sx={{

                                            fontFamily: 'Public Sans',
                                            fontWeight: '700',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}>
                                            {`${translate(field)}`}
                                        </Typography>

                                    </Box>))}

                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 0', gap: 1, }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', gap: 1, fontFamily: 'Public Sans',
                                fontWeight: '500',
                                textTransform: 'uppercase',
                                lineHeight: 18,
                                wordWrap: 'break-word'
                            }}>
                                <Typography>{`${translate('commons.validationDate')}: `} </Typography>
                                <DateZone date={request.createdOn} onlyDate />
                            </Box>
                            <Logo sx={{ height: '30px' }} disabledLink />
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', gap: 1, fontFamily: 'Public Sans',
                                fontWeight: '500',
                                textTransform: 'uppercase',
                                lineHeight: 18,
                                wordWrap: 'break-word'
                            }}>
                                <Typography>{`${translate('commons.printDate')}: `}</Typography>
                                <DateZone date={new Date()} onlyDate />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container >);
}

function formatAddress(addr?: Address) {
    if (addr)
        return [
            addr.address,
            addr.zipCode,
            addr.district,
            addr.city,
            addr.country
        ]
            .filter((part) => part && part.trim() !== "")
            .join(", ");

    return "";
}

interface AddressesProps {
    request: Request,
    returnToCustomer?: boolean
}

function Addresses({ request, returnToCustomer }: AddressesProps) {

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    const [urgent, normal] = [request.deliveryOrganization?.customFields["stellantis-europe-organization-urgent-tour"], request.deliveryOrganization?.customFields["stellantis-europe-organization-normal-tour"]];

    const tour = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography><b>{translate('request.detail.tour')}:</b></Typography>
            {normal &&
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography>{getCustomFieldLabel(customFields, "stellantis-europe-organization-normal-tour", currentLang)}: </Typography>
                    <Typography>{normal}</Typography>
                </Box>
            }
            {urgent &&
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography>{getCustomFieldLabel(customFields, "stellantis-europe-organization-urgent-tour", currentLang)}: </Typography>
                    <Typography>{urgent}</Typography>
                </Box>
            }
        </Box>);

    return <>
        {request.deliveryOrganization &&
            <AddressBox
                org={!returnToCustomer ? request.deliveryOrganization : request.vendor}
                isFrom
                note={request.note}
            />
        }
        {request.deliveryOrganization &&
            <AddressBox
                org={returnToCustomer ? request.deliveryOrganization : request.vendor}
                tour={returnToCustomer && (urgent || normal) && tour}
            />
        }
    </>;
}

interface AddressBoxProps {
    org: RequestOrg,
    isFrom?: boolean,
    note?: string,
    tour?: ReactNode
}

function AddressBox({ isFrom, org, note, tour }: AddressBoxProps) {

    const { translate } = useLocales();

    const address = formatAddress(org.address);

    return (
        <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', flex: 1, p: 2 }}>

            <StyledAddressTypography sx={{
                color: "black",
                fontSize: 12,
                fontWeight: '700',
                textTransform: 'uppercase',
                mb: 1
            }}>
                {isFrom ? translate(`commons.from`) : translate(`commons.shipTo`)}
            </StyledAddressTypography>

            <StyledAddressTypography>
                <b>{org.externalId}</b>
            </StyledAddressTypography>
            <StyledAddressTypography
                sx={{
                    mb: 1
                }}>
                <b>{org.name}</b>
            </StyledAddressTypography>
            <StyledAddressTypography>
                {org.address.address}
            </StyledAddressTypography>
            <StyledAddressTypography sx={{ mb: 2 }}>
                {address}
            </StyledAddressTypography>
            {tour}
            {note &&
                <Typography>
                    {`${translate('commons.note')}: ` + (note ?? "")}
                </Typography>}
        </Box>
    );
}

interface ReturnBoxProps {
    label: string,
    value: string
}

function ReturnBox({ label, value }: ReturnBoxProps) {

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, width: '100%' }}>
            <Box sx={{ border: '0.50px black solid', p: 2 }} >
                <Typography sx={{
                    fontSize: 12.5,
                    fontFamily: 'Public Sans',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    wordWrap: 'break-word'
                }}>
                    {label}
                </Typography>
            </Box>

            <Box sx={{ border: '0.50px black solid', p: 2 }}>

                <Typography
                    sx={{
                        color: '#303030',
                        fontSize: 12.5,
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        textTransform: 'uppercase',
                        wordWrap: 'break-word'
                    }}
                >
                    {value}
                </Typography>

            </Box>
        </Box>);
}