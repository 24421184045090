import { createAsyncThunk } from "@reduxjs/toolkit";
import { BasketRuleFilters, EnableDisableBasketRuleSliceProps, EnableDisableShopSliceProps, NewBasketRule, NewShop, ShopFilters, ShopBundleFilters, PublicShopFilters, PublicShopFromBundlesFilters, PublicShopSearchResult } from "src/@types/webshop";
import * as shopServices from "src/services/webshopServices";
import { enableDisableBasketSL, enableDisableWebshopSL, startBasketLoading, startLogsLoading, startWebshopLoading, startWebshopBundleLoading, startShopCreationLoading, startCustomerStatisticsLoading } from "./webshop-slices";
import { LogsFilters } from "src/@types/logs";
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import { InfiniteScrollListFilters } from "src/@types/list";

//---- BASKET RULES -----------------------------------------------------------------

const createBasketRule = createAsyncThunk(
    'webshop/createBasketRule',
    async (basketRule: NewBasketRule, { dispatch, rejectWithValue }) => {
        try {
            const { data, status } = await shopServices.createBasketRule(basketRule);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateBasketRule = createAsyncThunk(
    'webshop/updateBasketRule',
    async (params: { basketRule: NewBasketRule, id: string }, { dispatch, rejectWithValue }) => {
        try {
            const { basketRule, id } = params;

            const { data, status } = await shopServices.updateBasketRule(basketRule, id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getBasketRule = createAsyncThunk(
    'webshop/getBasketRule',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startBasketLoading(false));
        try {
            const { data } = await shopServices.detailBasketRule(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchBasketRules = createAsyncThunk(
    'webshop/searchBasketRules',
    async (options: { filters: BasketRuleFilters, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check } = options;

        dispatch(startBasketLoading(check));
        try {
            const { data } = await shopServices.searchBasketRules(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getBasketRuleLogs = createAsyncThunk(
    'webshop/getBasketRuleLogs',
    async (params: { id: string, filters: LogsFilters }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await shopServices.searchBasketRuleLogs(params.id, nullifyEmptyKeys(params.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableDisableBasketRule = createAsyncThunk(
    'webshop/enableDisableBasketRule',
    async (options: EnableDisableBasketRuleSliceProps, { dispatch, rejectWithValue }) => {

        try {
            const { data, status } = options.action === 'Enable' ?
                await shopServices.enableBasketRule(options.id) :
                await shopServices.disableBasketRule(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableBasketSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getBasketRuleStatistics = createAsyncThunk(
    'webshop/getBasketRuleStatistics',
    async (options: BasketRuleFilters, { rejectWithValue, dispatch }) => {
        dispatch(startBasketLoading(false));
        try {
            const { data } = await shopServices.basketRuleStatistics(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

//---- WEBSHOP ----------------------------------------------------------------------

const createShop = createAsyncThunk(
    'webshop/createShop',
    async (shop: NewShop, { dispatch, rejectWithValue }) => {
        dispatch(startShopCreationLoading());
        try {
            const { data, status } = await shopServices.createShop(shop);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateShop = createAsyncThunk(
    'webshop/updateShop',
    async (params: { webShop: NewShop, id: string }, { dispatch, rejectWithValue }) => {
        dispatch(startShopCreationLoading());
        try {
            const { webShop, id } = params;

            const { data, status } = await shopServices.updateShop(webShop, id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getShop = createAsyncThunk(
    'webshop/getShop',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startWebshopLoading(false));
        try {
            const { data } = await shopServices.detailShop(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getDisplayShop = createAsyncThunk(
    'webshop/getDisplayShop',
    async (options: { id: string, isPublic?: boolean }, { dispatch, rejectWithValue }) => {
        dispatch(startWebshopLoading(false));
        try {

            const { data } = await (options.isPublic ? shopServices.detailShopPublic(options.id) : shopServices.detailDisplayShop(options.id));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchShops = createAsyncThunk(
    'webshop/searchShops',
    async (options: { filters: ShopFilters, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check } = options;

        dispatch(startWebshopLoading(check));
        try {
            const { data } = await shopServices.searchShop(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getShopLogs = createAsyncThunk(
    'webshop/getShopLogs',
    async (params: { id: string, filters: LogsFilters }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await shopServices.searchShopLogs(params.id, nullifyEmptyKeys(params.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getShopStatistics = createAsyncThunk(
    'webshop/getShopStatistics',
    async (options: ShopFilters, { rejectWithValue, dispatch }) => {
        dispatch(startWebshopLoading(false));
        try {
            const { data } = await shopServices.shopStatistics(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const duplicateShop = createAsyncThunk(
    'webshop/duplicateShop',
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const { data, status } = await shopServices.duplicateShop(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            //dispatch(openTemplate({ id: id }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const archiveShop = createAsyncThunk(
    'webshop/archiveShop',
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const { data, status } = await shopServices.archiveShop(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            //dispatch(openTemplate({ id: id }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const enableDisableShop = createAsyncThunk(
    'webshop/enableDisableShop',
    async (options: EnableDisableShopSliceProps, { dispatch, rejectWithValue }) => {

        try {
            const { data, status } = options.action === 'Enable' ?
                await shopServices.enableShop(options.id) :
                await shopServices.disableShop(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableWebshopSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

//---- WEBSHOP - PRODUCTS -----------------------------------------------------------

const searchShopBundles = createAsyncThunk(
    'webshop/searchShopBundles',
    async (options: { shopId: string, filters: ShopBundleFilters, check: boolean, isPublic?: boolean }, { dispatch, rejectWithValue }) => {

        const { shopId, filters, check, isPublic } = options;

        dispatch(startWebshopBundleLoading(check));
        try {
            const { data } = await shopServices.searchShopBundles(shopId, filters, isPublic);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getShopBundle = createAsyncThunk(
    'webshop/getShopBundle',
    async (bundleId: string, { dispatch, rejectWithValue }) => {
        dispatch(startWebshopLoading(false));
        try {
            const { data } = await shopServices.detailShopBundle(bundleId);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return (data as any).result;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

//---- WEBSHOP STATISTICS ----------------------------------------------------------------------
const getAdminShopOrderStatistics = createAsyncThunk(
    'webshop/getAdminShopOrderStatistics',
    async (shopId: string, { dispatch, rejectWithValue }) => {
        try {
            const { data, status } = await shopServices.getAdminShopOrderStatistics(shopId);

            if (status > 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }

);

const getAdminShopCustomerOrderStatistics = createAsyncThunk(
    'webshop/getAdminShopCustomerOrderStatistics',
    async (options: { shopId: string, customerId: string }, { dispatch, rejectWithValue }) => {
        try {
            const { data, status } = await shopServices.getAdminShopCustomerOrderStatistics(options.shopId, options.customerId);

            if (status > 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }

);

const searchShopOrderCustomers = createAsyncThunk(
    'webshop/searchShopOrderCustomers',
    async (options: { shopId: string, filters: InfiniteScrollListFilters }, { dispatch, rejectWithValue }) => {
        dispatch(startCustomerStatisticsLoading());
        try {
            const { data, status } = await shopServices.searchShopOrderCustomers(options.shopId, options.filters);

            if (status > 400)
                throw new Error('Something went wrong');

            return data;
        }
        catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

//---- PUBLIC - WEBSHOP -------------------------------------------------------------

const searchPublicShops = createAsyncThunk(
    'webshop/searchPublicShops',
    async (options: { filters: PublicShopFilters, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check } = options;

        dispatch(startWebshopLoading(check));
        try {
            const { data } = await shopServices.searchPublicShop(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchPublicShopsByBundle = createAsyncThunk(
    'webshop/searchPublicShopsByBundle',
    async (options: { filters: PublicShopFromBundlesFilters, check: boolean }, { dispatch, rejectWithValue }) => {

        const { filters, check } = options;

        dispatch(startWebshopLoading(check));
        try {
            const { data } = await shopServices.searchPublicShopFromBundle(filters);

            const ids = Array.from(new Set(data.results.map(v => v.shopId)));

            let newShops: PublicShopSearchResult[] = [];

            if (ids.length > 0) {
                const { data: newShopsByIds } = await shopServices.searchShopByIds(ids);

                newShops = newShopsByIds;
            }

            if (!data) {
                throw new Error('Something went wrong');
            }

            return newShops;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);
//---- PUBLIC - WEBSHOP STATISTICS ----------------------------------------------------------------------

const getPublicShopOrderStatistics = createAsyncThunk(
    'webshop/getPublicShopOrderStatistics',
    async (shopId: string, { dispatch, rejectWithValue }) => {
        try {
            const { data, status } = await shopServices.getPublicShopOrderStatistics(shopId);

            if (status > 400)
                throw new Error('Something went wrong');

            return data;

        }
        catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    });

//-----------------------------------------------------------------------------------

const webshopOperations = {
    createBasketRule,
    updateBasketRule,
    getBasketRule,
    searchBasketRules,
    getBasketRuleLogs,
    enableDisableBasketRule,
    getBasketRuleStatistics,
    createShop,
    updateShop,
    getShop,
    searchShops,
    getShopLogs,
    getShopStatistics,
    duplicateShop,
    archiveShop,
    enableDisableShop,
    getDisplayShop,
    searchShopBundles,
    searchPublicShops,
    getShopBundle,
    searchPublicShopsByBundle,
    getPublicShopOrderStatistics,
    getAdminShopOrderStatistics,
    searchShopOrderCustomers,
    getAdminShopCustomerOrderStatistics
};

export default webshopOperations;