import { alpha } from '@mui/material/styles';

export const ToolbarActionButtonStyle = {
    height: "46px",
    border: "1px",
    borderStyle: "groove",
    borderColor: (theme: { palette: { divider: any; }; }) => theme.palette.divider,
    borderRadius: "10px",
    color: (theme: { palette: { primary: { main: any; }; }; }) => theme.palette.primary.main,
    ":hover": {
        backgroundColor: (theme: { palette: { primary: { lighter: string; }; }; }) => alpha(theme.palette.primary.lighter, 0.35),
        borderColor: (theme: { palette: { primary: { main: any; }; }; }) => theme.palette.primary.main
    }
};