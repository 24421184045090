import { AddAPhoto } from "@mui/icons-material";
import { Typography, Box, TextField } from "@mui/material";
import { forwardRef, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { Address, OrganizationSearchResult } from "src/@types/organizations";
import { AltInfiniteAutocomplete } from "src/appComponents/InfiniteAutocomplete";
import { useLocales } from "src/locales";
import { organizationsOperations } from "src/redux/organizations";
import { RootState, dispatch } from "src/redux/store";

export function checkImageSize(files: File[], callback: (files: File[]) => void, minWidth: number = 100, minHeight: number = 100): string | void {
    files.forEach(file => {
        const reader = new FileReader();

        reader.onload = (e) => {

            const result = e.target?.result;

            if (typeof result === 'string') {
                const img = new Image();

                img.src = result;

                img.onload = () => {
                    const minWidth = 100;

                    const minHeight = 100;

                    if (img.width < minWidth || img.height < minHeight) {
                        return 'uploader.messages.sizeError';
                    } else {

                        callback(files);
                    }
                };
            } else {
                return 'uploader.messages.uploadError';
            }
        };

        reader.readAsDataURL(file);
    });
}

interface DropzoneDamagedQualityProps {
    setFiles: (files: File[]) => void
}

export const DropzoneDamagedQuality = forwardRef(function DropzoneDamagedQuality({ setFiles }: DropzoneDamagedQualityProps, ref) {

    const { translate } = useLocales();

    const [error, setError] = useState("");

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop: (acceptedFiles) => {
            const error = checkImageSize(acceptedFiles, setFiles);

            if (error)
                setError(translate(error));
        },
        accept: {
            'image/png': ['.png'],
            'image/jpeg': [".jpg", ".jpeg"]
        },
        maxSize: 5000000,
    });

    return (
        <Box>
            <div

                {...getRootProps({
                    style: {
                        display: 'flex',
                        padding: '20px',
                        height: '150px',
                        width: '150px',
                        minWidth: '150px',
                        minHeight: '150px',
                        borderRadius: '8px',
                        background: '#F4F6F8',
                        color: (error || fileRejections.length) ? "red" : '#bdbdbd',
                        outline: 'none',
                        cursor: 'pointer',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: (error || fileRejections.length) ? "1px solid red" : undefined
                    },

                })}
            >
                <input  {...getInputProps()} />
                <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <AddAPhoto />
                    <Typography variant="caption">{`${translate('commons.uploadPhoto')}`}</Typography>
                </Box>
            </div>
            {error &&
                <Typography variant='subtitle2' sx={{ color: "error.main" }}>{error}</Typography>
            }
        </Box>
    );
});

interface DeliveryAddressAutocompleteProps {
    onChange: (name: string, value: any) => void,
    defaultAddress?: OrganizationSearchResult,
    required?: boolean,
    organization: string,
    isVendor: boolean
}

export function DeliveryAddressAutocomplete({ onChange, defaultAddress, required, organization, isVendor }: DeliveryAddressAutocompleteProps) {

    const { translate } = useLocales();

    const { isSearchLoading } = useSelector((state: RootState) => state.organizations);

    const getAddress = (address: Address): string[] => {
        let addr = '';
        let city = '';
        let country = '';

        addr += address.address ? address.address + '' : '';
        addr += address.district ? ', ' + address.district : '';
        city += address.zipCode ? ', ' + address.zipCode : '';
        city += address.city ? ', ' + address.city + ' ' : '';
        country += address.country ? address.country : '';

        return [addr, city, country];
    };

    const defaultAdd = useMemo(() => defaultAddress, []);

    return <AltInfiniteAutocomplete<OrganizationSearchResult>

        id="deliveryCompanyId"
        searchCallBack={(params) =>
            dispatch(organizationsOperations.searchOrganizations({
                filters: {
                    ...params,
                    status: 'Enabled',
                    customerParentId: isVendor ? organization : undefined
                },
                check: !!params.sequenceToken,
                isVendor: !isVendor
            })).unwrap()
        }
        loading={isSearchLoading}
        defaultValue={defaultAdd}
        onChange={(_, v) =>
            onChange('deliveryCompanyId', v ? [(v as OrganizationSearchResult).id, (v as OrganizationSearchResult)] : ["", ""])
        }
        getOptionLabel={(option) => getAddress(option.address).join('')}
        filterOptions={(options) => options}
        renderOption={(props, option) => {
            return (
                <li
                    {...props}
                    key={option.id}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'initial' }}
                >
                    <Typography>
                        <b>{option.name}</b>
                    </Typography>
                    <Typography>{getAddress(option.address).join('')}</Typography>
                </li>
            );
        }}
        renderInput={(params) => (
            <TextField  {...params} value={defaultAddress} required={required} label={`${translate('request.deliveryAdd')}`} />
        )}
    />;
}