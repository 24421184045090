import { LocalizedString } from "./commons";

export enum EntityType {
    'User' = 'User',
    'Vehicle' = 'Vehicle',
    'RvMatrix' = 'RvMatrix',
    'Brand' = 'Brand',
    'Organization' = 'Organization',
    'SpareParts' = 'SpareParts',
    "Invoice" = "Invoice",
    'Order' = 'Order',
    'DeliveryNote' = 'DeliveryNote',
    'CreditNote' = 'CreditNote',
    'OrderItem' = 'OrderItem',
    'ReturnRequestApprover' = 'ReturnRequestApprover',
    "Containers" = "Containers",
    "ContainerExtraItems" = "ContainerExtraItems"
}

export type CustomFieldTypes = 'String' | 'Number' | 'Select' | 'Checkbox' | 'Currency' | 'Date';

export type CustomFieldContext = {
    customFields: CustomField[];
}

export type CustomFieldSearchFilters = {
    key: string;
    label: string;
};

export type SelectOptions = {
    values: LocalizedString[],
    isDefault: boolean
}

export type NewCustomField = {
    [key: string]: any,
    id: string,
    name: LocalizedString[],
    entityType: string,
    type: string,
    defaultValue: string | null,
    visibility: string
    enabled: boolean,
    required: boolean,
    searchable: boolean,
    autoComplete: boolean,
    validationMessage: LocalizedString[] | null,
    selectOptions: { values: LocalizedString[], isDefault: boolean }[] | null,
    regEx: string | null,
    section: string,
    format: string
}

export type CustomField = {
    [key: string]: any,
    id: string,
    createdOn: Date,
    name: LocalizedString[],
    entityType: string,
    autoComplete: boolean,
    type: string,
    defaultValue: string,
    section: string,
    visibility: string,
    format: string,
    regEx: string,
    enabled: boolean,
    required: boolean,
    searchable: boolean,
    validationMessage: LocalizedString[] | null,
    selectOptions: SelectOptions[] | null
}

export type CustomFieldSuggest = {
    [key: string]: any,
    field: string,
    query: string,
    limit: number
}

export const DEFAULT_PARAMS: NewCustomField = {
    id: '',
    name: [],
    entityType: '',
    type: '',
    visibility: "Always",
    defaultValue: '',
    enabled: false,
    required: false,
    searchable: false,
    autoComplete: false,
    validationMessage: null,
    selectOptions: null,
    regEx: null,
    section: 'OtherInfo',
    format: ''
};

export const DEFAULT_CUSTOM_FIELD: CustomField = {
    id: '',
    createdOn: new Date(),
    name: [],
    entityType: '',
    type: '',
    defaultValue: '',
    autoComplete: false,
    visibility: 'Always',
    section: '',
    regEx: '',
    format: '',
    enabled: false,
    required: false,
    searchable: false,
    validationMessage: null,
    selectOptions: null,
};

export const rvMatrix = [EntityType.RvMatrix];

export const spareParts = [
    EntityType.CreditNote,
    EntityType.DeliveryNote,
    EntityType.OrderItem,
    EntityType.Invoice,
    EntityType.Order,
    EntityType.ReturnRequestApprover,
    EntityType.SpareParts,
    EntityType.Containers,
    EntityType.ContainerExtraItems
];

export const core = [
    EntityType.User,
    EntityType.Vehicle,
    EntityType.Organization,
    EntityType.Brand
];

