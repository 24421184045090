import { createAsyncThunk } from "@reduxjs/toolkit";
import { startCustomerCareLoading, startLoading, startLogsLoading, startOwnerLoading, startSearchLoading } from "./organizations-slices";
import { OrganizationFilters, GenericOrganizationForEdit } from "src/@types/organizations";
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import organizationService from "src/services/organizationService";

const searchOrganizationsPowerSearch = createAsyncThunk(
    'customer/searchOrganizationsPowerSearch',
    async (options: { filters: OrganizationFilters, isVendor: boolean }, { rejectWithValue }) => {

        const { filters, isVendor } = options;

        try {
            const { data } = await organizationService.search(filters, isVendor);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return { data, isVendor };

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchOrganizations = createAsyncThunk(
    'organizations/searchOrganizations',
    async (options: { filters: OrganizationFilters, check: boolean, isVendor: boolean }, { rejectWithValue, dispatch }) => {

        const { filters, check, isVendor } = options;

        dispatch(startSearchLoading(check));
        try {
            const { data } = await organizationService.search(filters, isVendor);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchStatistic = createAsyncThunk(
    'organizations/searchStatistic',
    async (options: { filters: OrganizationFilters, isVendor: boolean }, { rejectWithValue, dispatch }) => {

        const { filters, isVendor } = options;

        dispatch(startSearchLoading());
        try {
            const { data } = await organizationService.searchStatistics(filters, isVendor);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getNonEditables = createAsyncThunk(
    'organizations/getNonEditables',
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await organizationService.getNonEditables();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getOwner = createAsyncThunk(
    'organizations/getOwner',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startOwnerLoading());
        try {
            const { data } = await organizationService.getOwners(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getCustomerCareVendors = createAsyncThunk(
    'organizations/getCustomerCareVendors',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startCustomerCareLoading());
        try {
            const { data } = await organizationService.getOwners(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/* ------- GENERIC ------- */
const createGeneric = createAsyncThunk(
    'organizations/createGeneric',
    async (organization: GenericOrganizationForEdit, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {

            Object.keys(organization).forEach(key => {
                if (["createdOn", "id"].includes(key)) {
                    delete organization[key];
                }
            });

            const { data } = await organizationService.createGeneric(nullifyEmptyKeys(organization));

            if (!data) {
                throw new Error('Something went wrong');
            }
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateGeneric = createAsyncThunk(
    'organizations/updateGeneric',
    async (organization: GenericOrganizationForEdit, { rejectWithValue, dispatch }) => {
        try {

            Object.keys(organization).forEach(key => {
                if (["createdOn"].includes(key)) {
                    delete organization[key];
                }
            });

            const { status } = await organizationService.updateGeneric(nullifyEmptyKeys(organization));

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.message);
        }
    }
);

const getGeneric = createAsyncThunk(
    'organizations/getGeneric',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await organizationService.detailGeneric(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getDetailForEdit = createAsyncThunk(
    'organizations/getDetailForEdit',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await organizationService.detailEdit(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getHub = createAsyncThunk(
    'organizations/getHub',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await organizationService.detailGeneric(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getOrganizationLogs = createAsyncThunk(
    'organizations/getOrganizationLogs', async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await organizationService.searchLogs(options.id, nullifyEmptyKeys(options.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getOrganizationSurveillance = createAsyncThunk(
    'organizations/getOrganizationSurveillance',
    async (id: string, { rejectWithValue, dispatch }) => {
        //dispatch(startLoading());
        try {
            const { data } = await organizationService.searchSurveillance(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const organizationsOperations = {
    searchOrganizationsPowerSearch,
    getNonEditables,
    createGeneric,
    updateGeneric,
    getGeneric,
    getHub,
    getOrganizationLogs,
    searchOrganizations,
    searchStatistic,
    getOwner,
    getCustomerCareVendors,
    getDetailForEdit,
    getOrganizationSurveillance
};

export default organizationsOperations;
