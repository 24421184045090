import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Box, FormControlLabel, Switch, TablePagination, MenuItem, Divider, Typography } from '@mui/material';
import { RootState, dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import Label, { LabelColor } from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import { isArray, isEqual, isObject, remove } from 'lodash';
import DateZone from 'src/appComponents/DateZone';
import useResponsive from 'src/hooks/useResponsive';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import { useSnackbar } from 'notistack';
import { DEFAULT_REPORT_FILTERS, ReportFilters, ReportSearchResult, ReportTypeData } from 'src/@types/report';
import { clearReport, resetTemplates, setFiltersInUrl } from 'src/redux/reports/reports-slices';
import { reportsOperations } from 'src/redux/reports';
import ReportFilterSidebar from 'src/sections/@dashboard/report/filters/ReportFilterSidebar';
import ReportFilterSummary from 'src/sections/@dashboard/report/filters/ReportFilterSummary';
import { PATH_DASHBOARD } from 'src/routes/paths';

const BASIC_FILTER_OPTIONS = {
    pageIndex: 0,
    pageSize: 10
};

interface ReportListProps {
    isScheduled?: boolean,
    firstRender: boolean,
    setFirstRender: (value: boolean) => void
}

export default function ReportList({ isScheduled, firstRender, setFirstRender }: ReportListProps) {
    const {
        page,
        setPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const { reportList, isLoading, totalCount, pageIndex, pageSize } = useSelector((state: RootState) => state.reports);

    const [filters, setFilters] = useState<ReportFilters>({ ...DEFAULT_REPORT_FILTERS });

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [resetFormElement, setResetFormElement] = useState("");

    const [searchTriggerAfterDisable, setSearchTriggerAfterDisable] = useState(false);

    const methods = useForm({ defaultValues: DEFAULT_REPORT_FILTERS });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected
    const statsKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending"], []);

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key));
    }, [statsKeysToRemove]);

    const isDefault = useCallback((filter: ReportFilters, controller?: ReportFilters) => {

        const check = controller ? controller : DEFAULT_REPORT_FILTERS;

        const found = remove(Object.keys(check), statsKeyRemover)
            .find((element) => {
                if (isArray(filter[element]) && isArray(check[element])) {
                    return !isEqual(filter[element], check[element]);
                } else if (isObject(filter[element]) && isObject(check[element])) {

                    const strFilters = JSON.stringify(filter[element], Object.keys(filter[element]).sort());

                    const strDefaultFilters = JSON.stringify(check[element], Object.keys(check[element]).sort());

                    return strFilters !== strDefaultFilters;

                } else {
                    return filter[element] !== check[element];
                }
            });

        return (!found);
    }, [statsKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- CLOSE AND OPEN SIDEBAR FUNC ----//
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = () => {
        if (resetForm) {
            reset(DEFAULT_REPORT_FILTERS);
        };
        setOpenSidebar(false);
    };

    //---- CLEAR FROM SUMMARY FUNC ----//
    const handleClearFromSummary = (section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    };

    //---- FILTERS IN URL GET/SET - START ----//
    const location = useLocation();

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters).filter(x => filters[x] && (isArray(filters[x]) ? filters[x].length > 0 : filters[x] !== DEFAULT_REPORT_FILTERS[x]))
            .map((key) => {
                if (isArray(filters[key]) || isObject(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate]);

    const getPageAndSize = useCallback(() => {
        if (firstRender) {
            return [pageSize, pageIndex];
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    useEffect(() => {
        dispatch(resetTemplates());
        dispatch(clearReport());
    }, []);

    const getFiltersFromUrl = useCallback(() => {

        const { hash } = location;

        let searchFilters = {};

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                name: searchParams.get('name'),
                outputType: searchParams.get('outputType'),
                recurringType: searchParams.get('recurringType'),
                reportType: searchParams.get('reportType')
            };

        } else {
            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null
            };
        }

        searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

        return searchFilters as ReportFilters;

    }, [location, order, orderBy, getPageAndSize]);
    //---- FILTERS IN URL GET/SET - END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: ReportFilters) => {

        var searchFilters: any = {};

        if (values === DEFAULT_REPORT_FILTERS) {
            searchFilters = BASIC_FILTER_OPTIONS;
        } else {
            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                name: values.name !== DEFAULT_REPORT_FILTERS.name ? values.name : null,
                outputType: values.outputType !== DEFAULT_REPORT_FILTERS.outputType ? values.outputType : null,
                recurringType: values.recurringType !== DEFAULT_REPORT_FILTERS.recurringType ? values.recurringType : null,
                reportType: values.reportType !== DEFAULT_REPORT_FILTERS.reportType ? values.reportType : null
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [order, orderBy, page, rowsPerPage, updateFiltersInUrl]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Users list and statistics using filters
    const onSearch = useCallback((filtersFromUrl: ReportFilters) => {

        updateFiltersInUrl(filtersFromUrl);

        dispatch(reportsOperations.searchReports({ filters: filtersFromUrl, isScheduled: !!isScheduled }));

    }, [updateFiltersInUrl, isScheduled]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filtersToCheck: ReportFilters, olderFilters: ReportFilters) => {

        const differencesToOld = Object.keys(filtersToCheck).filter((filter) => filtersToCheck[filter] !== olderFilters[filter]);

        if (differencesToOld.includes("sortField") || differencesToOld.includes("sortAscending")) return true;

        const differencesToFilters = Object.keys(olderFilters).filter((filter) => olderFilters[filter] !== filtersToCheck[filter]);

        return differencesToFilters.includes("sortField") || differencesToFilters.includes("sortAscending");

    }, []);

    useEffect(() => {

        let searchFilters = getFiltersFromUrl();

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label || searchTriggerAfterDisable || firstRender) {

            if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;

            if (firstRender) setFirstRender(false);

            setFilters(searchFilters as ReportFilters);

            setTimeout(() => {
                onSearch(searchFilters as ReportFilters);
            }, searchTriggerAfterDisable ? 350 : 0);

            setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
            setSearchTriggerAfterDisable(false);
        }

    }, [isScheduled, firstRender, searchTriggerAfterDisable, location, page, rowsPerPage, orderBy, order, currentLang, lastUsedLang, onSearch, getFiltersFromUrl, filters, setPage, setRowsPerPage, isOrderDiff, pageIndex, pageSize, setFirstRender]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = () => {
        fetchData(getValues());
        setResetForm(false);
        setOpenSidebar(false);
        setPage(0);
    };

    //---- FILTERS RESET - START ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = () => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        setPage(0);
        setResetForm(true);
        fetchData(DEFAULT_REPORT_FILTERS);
        reset(DEFAULT_REPORT_FILTERS);
    };

    const handleResetSingleFilter = (fieldName: string, value?: any) => {
        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues);
            }
        } else {
            formValues[fieldName] = DEFAULT_REPORT_FILTERS[fieldName];
            setValue(fieldName, DEFAULT_REPORT_FILTERS[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);
        }
    };
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            if (isScheduled) navigate(PATH_DASHBOARD.reports.scheduled.detail(params.id.toString(), 'general', params.row.reportType.toLowerCase() === "orderdocument" ? "document" : params.row.reportType));
            else navigate(PATH_DASHBOARD.reports.recurring.detail(params.id.toString(), "general", params.row.reportType.toLowerCase() === "orderdocument" ? "document" : params.row.reportType));
        }
    };

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || reportList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const handleEdit = useCallback((id: string, type: ReportTypeData) => {
        if (isScheduled) navigate(PATH_DASHBOARD.reports.scheduled.edit(id, type));
        else navigate(PATH_DASHBOARD.reports.recurring.edit(id, type));
    }, [navigate, isScheduled]);

    const handleEnable = useCallback(async (id: string, type: ReportTypeData) => {

        type = type.toLowerCase() === "orderdocument" ? 'document' : type;

        let x = await dispatch(reportsOperations.enableReport({ id: id, type: type }));
        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate('commons.enabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        setSearchTriggerAfterDisable(true);
    }, [enqueueSnackbar, translate]);

    const handleDisable = useCallback(async (id: string, type: ReportTypeData) => {

        type = type.toLowerCase() === "orderdocument" ? 'document' : type;

        let x = await dispatch(reportsOperations.disableReport({ id: id, type: type }));
        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate('commons.enabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        setSearchTriggerAfterDisable(true);
    }, [enqueueSnackbar, translate]);

    const COLUMNS: GridColDef<ReportSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'reportType',
            headerName: `${translate('report.tableHeaders.reportType')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            valueGetter: (params: string) => {
                return `${translate(`report.types.${params[0].toLowerCase() + params.slice(1)}`)}`;
            }
        },
        {
            field: 'lastExecution',
            headerName: `${translate('report.tableHeaders.lastExecution')}`,
            flex: isDesktop ? 1.1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <DateZone
                        date={obj.row.lastExecution}
                        shortMonth
                        noSeconds
                    />
                );
            }
        },
        ...(isScheduled ? [] : [
            {
                field: 'nextExecution',
                headerName: `${translate('report.tableHeaders.nextExecution')}`,
                flex: isDesktop ? 1.1 : undefined,
                minWidth: !isDesktop ? 200 : undefined,
                sortable: false,
                renderCell: (obj: any) => {
                    return (
                        <DateZone
                            date={obj.row.nextExecution}
                            shortMonth
                            noSeconds
                        />
                    );
                }
            },
        ]),
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                    />
                );
            }
        },
        ...(isScheduled ? [
            {
                field: 'hasError',
                headerName: `${translate('commons.status')}`,
                flex: isDesktop ? 0.9 : undefined,
                minWidth: !isDesktop ? 175 : undefined,
                headerAlign: 'center',
                align: "center",
                renderCell: (obj: any) => {
                    let status = 'warning';

                    if (obj.row.hasError)
                        status = 'error';
                    else if (obj.row.lastExecution)
                        status = 'success';

                    return (
                        <Label
                            color={status as LabelColor}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {status !== "warning" ? `${translate(`commons.${status}`)}` : `${translate('commons.waiting')}`}
                        </Label>
                    );
                }
            }
        ] as GridColDef<ReportSearchResult>[]
            : [
                {
                    field: 'recurringType',
                    headerName: `${translate('report.tableHeaders.recurringType')}`,
                    flex: isDesktop ? 1 : undefined,
                    minWidth: !isDesktop ? 200 : undefined,
                    sortable: false,
                    valueGetter: (params: string) => {
                        if (params)
                            return `${translate(`report.types.${params[0].toLowerCase() + params.slice(1)}`)}`;
                    }
                },
                {
                    field: 'enabled',
                    headerName: `${translate('commons.status')}`,
                    flex: isDesktop ? 0.9 : undefined,
                    minWidth: !isDesktop ? 175 : undefined,
                    headerAlign: 'center',
                    align: "center",
                    renderCell: (obj: any) => {

                        return (
                            <Label
                                color={obj.row.enabled ? 'success' : 'error'}
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {obj.row.enabled ? `${translate("statuses.enabled")}` : `${translate("statuses.disabled")}`}
                            </Label>
                        );
                    }
                }] as GridColDef<ReportSearchResult>[]),
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 80 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleEdit={handleEdit}
                        handleEnable={handleEnable}
                        handleDisable={handleDisable}
                        object={obj}
                        currentRow={actualRow}
                        isScheduled={isScheduled}
                    />
                );
            }
        }

    ], [actualRow, openMenu, translate, isDesktop, handleDisable, handleEdit, handleEnable, isScheduled]);

    useEffect(() => {
        if (totalCount <= rowsPerPage * page) onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, totalCount]);
    //---- HANDLE TABLE END ----//

    return (
        <Card>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, pb: 2 }}>
                <Box>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        {`${translate(isScheduled ? 'report.scheduled.subtitle' : 'report.recurring.subtitle')}`}
                    </Typography>
                </Box>

                <Box sx={{ mt: 2, display: 'flex' }}>
                    <FormProvider methods={methods}>
                        <ReportFilterSidebar
                            isDefault={isDefault}
                            isOpen={openSidebar}
                            onFilter={handleSearchFilters}
                            onOpen={handleOpenSidebar}
                            onClose={handleCloseSidebar}
                            onResetAll={handleResetAllFilter}
                            reset={resetForm}
                            resetFormElement={resetFormElement}
                            filters={{ ...formValues, ...(getFiltersFromUrl()) }}
                            setResetFormElement={setResetFormElement}
                            isScheduled={!!isScheduled}
                        />
                    </FormProvider>
                </Box>
            </Box>

            <ReportFilterSummary
                filters={openSidebar ? DEFAULT_REPORT_FILTERS : { ...formValues, ...getFiltersFromUrl() }}
                isShowReset={!isDefault({ ...formValues, ...(getFiltersFromUrl()) }) && !openSidebar}
                onResetAll={handleResetAllFilter}
                onResetFilter={handleResetSingleFilter}
            />

            <Divider />

            <Box>
                <DataGrid
                    rows={reportList}
                    columns={COLUMNS}
                    pagination
                    paginationModel={{
                        page: page,
                        pageSize: rowsPerPage
                    }}
                    density={(dense && reportList.length > 0) ? 'compact' : 'standard'}
                    sortingMode={"server"}
                    onSortModelChange={handleSort}
                    loading={isLoading}
                    disableColumnResize
                    slots={{
                        noRowsOverlay: noData,
                        footer: () => (
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: { xs: "90vw", md: "auto" }
                                }}
                            >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 30]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                    sx={{
                                        overflow: "hidden",
                                        "& .MuiTablePagination-input": {
                                            ml: { xs: 0.5, md: "default" },
                                            mr: { xs: 3.5, md: "default" }
                                        }
                                    }}
                                />
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{
                                        px: { xs: 0, sm: 3 },
                                        py: { xs: 0, sm: 1.5 },
                                        pb: { xs: 1.5, sm: 0 },
                                        mx: 0,
                                        top: 0,
                                        justifyContent: "center",
                                        width: { xs: "90vw", sm: "auto" },
                                        position: { sm: 'absolute' }
                                    }}
                                />
                            </Box>
                        )
                    }}
                    disableColumnMenu
                    pageSizeOptions={[5, 10, 15, 30]}
                    disableRowSelectionOnClick
                    onCellClick={(param) => {
                        if (!window.getSelection()?.toString())
                            handleCellClick(param);
                    }}
                    sx={{
                        ...DataGridStyle,
                        cursor: 'pointer',
                        height: getHeight(),
                        maxHeight: getMaxHeight()
                    }}
                />
            </Box>

        </Card>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleEdit: (id: string, type: ReportTypeData) => void,
    handleEnable: (id: string, type: ReportTypeData) => Promise<void>,
    handleDisable: (id: string, type: ReportTypeData) => Promise<void>,
    object: any,
    currentRow: any
    isScheduled?: boolean
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleEdit, handleEnable, handleDisable, object, currentRow, isScheduled }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    {!isScheduled &&
                        <MenuItem
                            onClick={() => {
                                if (!object.row.enabled)
                                    handleEnable(object.id, object.row.reportType);
                                else
                                    handleDisable(object.id, object.row.reportType);
                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {object.row.enabled ? `${translate("commons.disable")}` : `${translate("commons.enable")}`}
                        </MenuItem>
                    }

                    {isScheduled && object.row.downloadUri &&
                        <MenuItem onClick={() => window.open(object.row.downloadUri, '_blank')}>
                            {`${translate("report.detail.download")}`}
                        </MenuItem>
                    }

                    <MenuItem
                        onClick={() => {
                            handleEdit(object.id, object.row.reportType.toLowerCase() === "orderdocument" ? "document" : object.row.reportType);
                            handleCloseMenu();
                        }}
                    >
                        {isScheduled ? `${translate("report.form.title.reschedule")}` : `${translate("commons.edit")}`}
                    </MenuItem>
                </>
            }
        />
    );
}